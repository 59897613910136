<central-header title="Projects" actions="{{ !projects?.length ? '' : (view === 'grid' ? 'projects' : 'projects+') }}"
	breakpoint="6xl" [status]="this.status" (action)="this.header($event)">
</central-header>

<failed-request *ngIf="'failed' === status"></failed-request>

<ng-container *ngIf="status === 'success' && projects.length">
	<div class="team-content ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16 animate__animated animate__fadeIn">

		<!-- Grid View -->
		<ng-container *ngIf="view === 'grid'">
			<div class="sites">
				<div class="site" *ngFor="let project of projects">
					<div class="preview">
						<img *ngIf="this.getScreenshot(project)" [centralCachedSrc]="this.getScreenshot(project)" />
						<div class="preview-placeholder ctw-bg-white">
							<div class="message">
								<div class="spinner-container"
									*ngIf="this.projectService.getEnvironmentUrl(project) && !this.getScreenshot(project)">
									<mat-progress-spinner mode="indeterminate" color="primary"
										diameter="30"></mat-progress-spinner>
								</div>
								<ng-container *ngIf="!this.projectService.getEnvironmentUrl(project)">
									<mat-icon>photo_size_select_actual</mat-icon>
									<div>
										Preview Unavailable
									</div>
								</ng-container>
							</div>
							<img class="ctw-border ctw-border-solid ctw-border-imh-gray" width="450"
								src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9'%3E%3C/svg%3E" />
						</div>
					</div>

					<div class="overlay ctw-z-10" [routerLink]="['/projects', project.id]"></div>
					<div class="site__info ctw-border ctw-border-solid ctw-border-imh-gray">
						<div class="info">
							<div class="label ctw-text-lg notranslate">
								{{ project.label || 'Unlabeled Project' }}
							</div>
							<em class="ctw-absolute ctw-text-imh-gray-200 ctw-text-sm">Started:
								{{ project.created_at | amTimeAgo }}</em>
							<div class="status" [class]="this.getStatus(project)">

								<svg fill="#aaaaaa" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="24px"
									viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
									class="wordpress-icon" [class.live]="this.getEnvironments(project).servers">
									<g id="SVGRepo_bgCarrier" stroke-width="0" />
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
									<g id="SVGRepo_iconCarrier">
										<g>
											<path
												d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
										</g>
									</g>
								</svg>

							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<!-- List View -->
		<ng-container *ngIf="view === 'table'">
			<div class="transparent">
				<table mat-table [dataSource]="projects">
					<ng-container matColumnDef="type">
						<th mat-header-cell *matHeaderCellDef>TYPE</th>
						<td mat-cell (click)="this.router.navigate(['/projects', project.id])"
							*matCellDef="let project">
							<mat-icon *ngIf="isWordpress(project)" class="ctw-ml-1 ctw-mt-2"><svg fill="#aaaaaa"
									version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="24px"
									viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
									class="wordpress-icon" [class.live]="this.getEnvironments(project)['servers']">
									<g id="SVGRepo_bgCarrier" stroke-width="0" />
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
									<g id="SVGRepo_iconCarrier">
										<g>
											<path
												d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
										</g>
									</g>
								</svg> </mat-icon>
						</td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>NAME</th>
						<td mat-cell matTooltip="View Project Dashboard"
							(click)="this.router.navigate(['/projects', project.id])" *matCellDef="let project">
							<span class="notranslate">{{ project.label }}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="age">
						<th mat-header-cell *matHeaderCellDef>STARTED</th>
						<td mat-cell (click)="this.router.navigate(['/projects', project.id])"
							*matCellDef="let project">
							<span class="notranslate">{{ project.created_at | amTimeAgo }}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="servers">
						<th mat-header-cell *matHeaderCellDef class="ctw-text-center">CLOUD SERVERS</th>
						<td mat-cell (click)="this.router.navigate(['/projects', project.id])" *matCellDef="let project"
							class="ctw-text-center">
							<span class="notranslate">{{ this.getEnvironments(project)['servers'] }}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="playgrounds">
						<th mat-header-cell *matHeaderCellDef class="ctw-text-center">PLAYGROUNDS</th>
						<td mat-cell (click)="this.router.navigate(['/projects', project.id])" *matCellDef="let project"
							class="ctw-text-center">
							<span class="notranslate">{{ this.getEnvironments(project)['playgrounds'] }}</span>
						</td>
					</ng-container>

					<ng-container matColumnDef="environments">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell (click)="this.router.navigate(['/projects', project.id])"
							*matCellDef="let project">

						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr class="ctw-cursor-pointer hover:ctw-bg-black hover:ctw-bg-opacity-05" mat-row
						*matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</div>
		</ng-container>
	</div>
</ng-container>

<div class="animate__animated animate__fadeIn no-content ctw-flex ctw-flex-align-center {{this.appService.isNoticeShowing() ? 'notice' : ''}}"
	*ngIf="status === 'success' && !projects.length">
	<div class="ctw-mx-auto">
		<img class="ctw-mx-auto"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/multiple-websites.svg" />
		<h2 class="ctw-text-center">Instant On WordPress</h2>
		<div class="ctw-mx-auto ctw-max-w-xl ctw-text-center">
			<div class="ctw-text-lg ctw-mb-4">
				Create fully functional WordPress sites on demand with 1-click playground environments and dedicated
				Cloud
				Servers.
			</div>
			<central-progress-button color="primary" [state]="this.newProjectState" (click)="this.newProject()">
				Start Your Team's First Project
				<mat-icon class="next">navigate_next</mat-icon>
			</central-progress-button>
		</div>
	</div>
</div>
