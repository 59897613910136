import { Component, Input } from '@angular/core';

@Component({
	// eslint-disable-next-line
	selector: 'progress-button',
	templateUrl: 'progress-button.component.html',
})
export class ProgressButtonComponent {
	@Input() public color = 'accent';
	@Input() public state: string;
	@Input() public disabled: boolean;
	@Input() public type = 'mat-raised-button';
}
