<ng-container>
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mb-16">
		<h2>PHP Version</h2>
		<dash-card icon="code" cardTitle="PHP Version" class="dash-card profile" disabled="true" [transparent]="true">
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn ">
				<p>
					The WordPress community currently
					<span class="ctw-inline-flex"><a href="https://wordpress.org/about/requirements/" target="_blank">
							recommends at least PHP 7.4</a><mat-icon
							class="ctw-ml-1 ctw-w-3 ctw-mt-1 ctw-mr-1 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					for maximum compatibility with older plugins. However, PHP
					versions less than PHP 8.1 have reached official
					<span class="ctw-inline-flex"><a href="https://www.php.net/eol.php" target="_blank">End Of
							Life</a><mat-icon
							class="ctw-ml-1 ctw-w-3 ctw-mt-1 ctw-mr-1 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					and thus are
					<span class="ctw-inline-flex"><a href="https://www.php.net/supported-versions.php"
							target="_blank">not recommended by PHP</a><mat-icon
							class="ctw-ml-1 ctw-w-3 ctw-mt-1 ctw-mr-1 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					WordPress core is compatible with PHP 8.1+ and PHP 8.1+ has speed improvements over PHP 7.4.
				</p>
				<form #f="ngForm" (ngSubmit)="openDialog(f)" novalidate>

					<mat-radio-group [(ngModel)]="selectedVersion" name="version" aria-label="Select your PHP version"
						class="ctw-flex ctw-flex-col ctw-py-4">
						<ng-container *ngFor="let op of versions">
							<mat-radio-button *ngIf="op.allowed || selectedVersion === op.version"
								[checked]="selectedVersion === op.version" [disabled]="!this.available"
								value="{{ op.version }}" name="version" [class]="op.eol ? 'ctw-text-red-700' : ''">
								<div class="ctw-inline-flex ctw-w-24" [class.ctw-font-bold]="setVersion === op.version">
									PHP {{ op.version }}
								</div>
								<span class="ctw-text-sm mat-stroked-button ctw-text-green-700"
									*ngIf="op.recommended && setVersion !== op.version">
									Recommended</span>
								<span class="ctw-text-sm mat-stroked-button ctw-text-yellow-700 ctw-mr-3"
									*ngIf="op.securityFixesOnly">Security
									Fixes Only</span>
								<span class="ctw-text-sm mat-stroked-button ctw-text-red-700 ctw-mr-3"
									*ngIf="op.eol">EOL</span>
								<span class="ctw-text-sm mat-flat-button mat-accent ctw-font-bold"
									*ngIf="setVersion === op.version">Active</span>
							</mat-radio-button>
						</ng-container>
					</mat-radio-group>
					<p class="ctw-py-4">
						<mat-card appearance="outlined" *ngIf="!setVersionIsRecommended"
							class="{{this.setVersionIsEOL ? 'alert ctw-m-0' : ''}}">
							<mat-card-content
								class="ctw-flex ctw-text-{{this.setVersionIsEOL ? 'red-700' :'imh-gray-200'}}">
								<mat-icon class="ctw-mr-1" [class.ctw-text-red-700]="this.setVersionIsEOL">{{
									this.setVersionIsEOL ? 'warning' : 'info' }}</mat-icon>
								<i *ngIf="this.setVersionIsEOL" class="ctw-w-full">Your current PHP version is no longer
									supported and may expose your system to security vulnerabilities and performance
									issues. Please update to the latest PHP version immediately to ensure optimal
									functionality and security. We recommend the most recent version
									of PHP (8.3) that your site
									is compatible with but a minimum of PHP 8.2.</i>
								<i *ngIf="!this.setVersionIsEOL" class="ctw-w-full">We recommend the most recent version
									of PHP (8.3) that your site
									is compatible with but a minimum of PHP 8.2.</i>
							</mat-card-content>
						</mat-card>
					</p>
					<div class="ctw-text-right ctw-mt-4">
						<button *ngIf="this.projectService.environment.fields.environment_usage === 'unknown'"
							mat-button mat-ripple type="button" routerLink="../overview" class="ctw-mr-4">
							Cancel
						</button>
						<central-progress-button *ngIf="this.available" [state]="requestState" type="submit"
							mat-raised-button color="primary" [disabled]="
								'disabled' === this.requestState ||
								selectedVersion === setVersion
							">
							Switch to{{
							selectedVersion === setVersion
							? '...'
							: ' PHP ' + selectedVersion
							}}</central-progress-button>
						<central-progress-button *ngIf="!this.available" [state]="requestState" type="submit"
							mat-raised-button color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During
							Update</central-progress-button>
					</div>
				</form>
			</div>
		</dash-card>
	</div>
</ng-container>
