<failed-request *ngIf="'failed' === fetchState"></failed-request>
<p class="notice" *ngIf="'unauthorized' === fetchState && 0">
	Accounts with "Editor" or "Member" role cannot view team billing
	information
</p>
<form #form="ngForm" (ngSubmit)="form.valid && this.submit()" class="ctw-mx-auto ctw-max-w-2xl ctw-pt-8 ctw-mb-16">
	<div class="contact">
		<div class="ctw-mb-10">
			<h2>
				Payment Method
			</h2>
		</div>
		<mat-card class="credit-card ctw-mb-10" *ngIf="!this.changeCard">
			<mat-icon>credit_card</mat-icon>
			<div>
				<mat-spinner diameter="30" *ngIf="fetchState !== 'ok'"></mat-spinner>
				<div *ngIf="fetchState === 'ok'">
					<span class="ctw-pr-1">{{
						this.paymentMethod.card_type
						}}</span>&nbsp;XXXX-<span [class.mask-pii]="this.isDemo">{{ this.paymentMethod.last_four
						}}</span>
				</div>
				<div *ngIf="fetchState === 'ok'">
					<span class="ctw-pr-1">Expires</span>&nbsp;<span class="notranslate"
						[class.mask-pii]="this.isDemo">{{ this.paymentMethod.month
						}} / {{
						this.paymentMethod.year
						}}</span>
				</div>
			</div>
			<button mat-button mat-stroked-button mat-primary (click)="this.changeCard = true"
				[disabled]="fetchState !== 'ok'" class="credit-card-change-cta">
				Change
			</button>
		</mat-card>
		<div class="ctw-mb-10" *ngIf="!this.hasInternalBilling && this.changeCard" [hidden]="fetchState !== 'ok'">
			<central-recurly-credit-card #creditCard [hidden]="!this.changeCard"></central-recurly-credit-card>
			<a class="saved-card" *ngIf="this.paymentMethod.zip && this.changeCard"
				(click)="this.changeCard = false">Use saved card</a>
		</div>
		<bg-contact #contact [excludedInputs]="['email', 'organization', 'phone']"
			[hidden]="fetchState !== 'ok'"></bg-contact>
		<div class="update-message ctw-mt-4 ctw-mb-8 ctw-text-center">
			<div class="ctw-text-green-700" [hidden]="this.updateState !== 'success'">
				Successfully updated your {{
				this.configurationService.config.groupLabels[
				'group-label-title-case'
				]
				}}'s details.
			</div>
			<div class="ctw-text-red-700" [hidden]="this.updateState !== 'failed'">
				An error occurred while processing your request.
			</div>
		</div>
		<div class="action-buttons ctw-text-right" [hidden]="fetchState !== 'ok'">
			<button mat-button type="button" (click)="this.cancel.emit()" *ngIf="this.cancelable">
				Cancel
			</button>
			<progress-button color="primary" [state]="this.updateState" class="billing-info-update-cta">Update
				{{
				!this.isOrganization
				? 'Your
				Personal'
				: "Your Team's"
				}}
				Billing Info</progress-button>
		</div>
	</div>
</form>
