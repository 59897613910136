import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ConfigurationService } from '../configuration.service';

@Injectable()
export class DecoderService {
	public storageName = 'id_token';

	public constructor(public configurationService: ConfigurationService) {
		this.storageName =
			this.configurationService.config.loginStorageName || 'id_token';
	}

	/**
	 * Check if the token is valid. (as far as we can tell).
	 *
	 * @since 1.3
	 *
	 * @return  Is this a valid token?
	 */
	public validToken(): boolean {
		// we stopped checking expiry because it was causing issues with the clock being out of sync.
		return this.getToken() && this.tokenDecodable()
			? true
			: false;
	}

	public getToken() {
		return window.localStorage.getItem(this.storageName);
	}

	public removeToken() {
		window.localStorage.removeItem(this.storageName);
	}

	public setToken(token: string) {
		window.localStorage.setItem(this.storageName, token);
	}

	public tokenDecodable() {
		if ( this.configurationService.config.remoteTokenExpirationChecks ) {
			return !! this.getTokenVal( 'iss' );
		} else {
			return this.tokenNotExpired();
		}
	}

	/**
	 * Check if the saved token is not expired.
	 *
	 * @since 1.3
	 *
	 * @return  Is the token expired?
	 */
	public tokenNotExpired(): boolean {
		const expiration = this.getTokenVal('exp');

		let isExpired = true;

		if (expiration) {
			isExpired = expiration < this.getUnixStamp();
		}

		return !isExpired;
	}

	/**
	 * Get Unix timestamp.
	 *
	 * @since 1.3
	 *
	 * @return  [description]
	 */
	public getUnixStamp(): number {
		return Math.round(+new Date() / 1000);
	}

	/**
	 * Get a value from the token.
	 *
	 * @since 1.3
	 *
	 * @param   param Get a value from the token.
	 * @return        Get a token value.
	 */
	public getTokenVal(param: string) {
		let val: any;
		const decoded = this.getTokenDecoded();

		if (decoded[param]) {
			val = decoded[param];
		} else if (decoded['payload']) {
			val = decoded['payload'][param];
		}

		return val;
	}

	/**
	 * Get the decoded value of the token.
	 *
	 * @since 1.3
	 *
	 * @return  Decoded value of the token.
	 */
	public getTokenDecoded(): object {
		let token = {};
		try {
			token = jwtDecode(this.getToken());
		} catch (e) {
			token = {};
		}

		return token;
	}
}
