<bgc-header-logo></bgc-header-logo>
<div class="login valign">
	<div [hidden]="requestStatus !== 'fail'">
		<h2 class="fail loading-message">Unable to Confirm Account</h2>
		<p class="error-message">Please try again later</p>
		<button [routerLink]="['/login']" mat-raised-button color="accent">
			Back to Login
		</button>
	</div>
	<h2 class="loading-message" [hidden]="requestStatus !== 'progress'">
		Confirming Account
	</h2>
	<h2 class="loading-message" [hidden]="requestStatus !== 'success'">
		Signing In
	</h2>
	<div
		[class.load-complete]="requestStatus === 'success'"
		[hidden]="requestStatus === 'fail'"
		class="circle-loader"
	>
		<div class="checkmark draw"></div>
	</div>
</div>
