import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
	public namespace = 'BGCentral';

	/**
	 * Get an item from local storage.
	 *
	 * @since 1.3
	 *
	 * @param   key Items index.
	 */
	public getItem(key: string): any {
		const storage = this.getStorage();
		return storage[key];
	}

	/**
	 * Set a namespaced item within local storage.
	 *
	 * @since 1.3
	 *
	 * @param  key   Key to save under.
	 * @param  value Value to save.
	 */
	public setItem(key: string, value: any): void {
		const storage = this.getStorage();
		storage[key] = value;
		window.localStorage.setItem(this.namespace, JSON.stringify(storage));
	}

	/**
	 * Set a namespaced item within local storage.
	 *
	 * @since 1.3
	 *
	 * @param  key   Key to delete.
	 */
	public removeItem(key: string): void {
		const storage = this.getStorage();
		delete storage[key];
		window.localStorage.setItem(this.namespace, JSON.stringify(storage));
	}

	/**
	 * Remove All localstorage items.
	 *
	 * @since 1.3
	 */
	public clear(): void {
		window.localStorage.removeItem(this.namespace);
	}

	/**
	 * Get storage, cover.
	 *
	 * @since 1.3
	 *
	 * @return  Get entire storage object.
	 */
	private getStorage() {
		const storage = window.localStorage.getItem(this.namespace) || '{}';
		return JSON.parse(storage);
	}
}
