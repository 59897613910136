import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService, ConfigurationService } from '../../core';

@Component({
	templateUrl: './agent-access-dialog.component.html',
	styleUrls: ['./agent-access-dialog.component.scss'],
})
export class AgentAccessDialogComponent implements OnInit {
	public state = 'loading';
	public fingerprint = '';
	public key = '';

	constructor(
		public snackbar: MatSnackBar,
		private apiService: ApiService,
		public configurationService: ConfigurationService
	) {}

	ngOnInit() {
		this.fetchKey();
	}

	public fetchKey() {
		this.apiService.get('/v1/support/public-key', {}).subscribe({
			next: (response: any) => {
				this.key = response.key;
				this.fingerprint = response.fingerprint;
				this.state = 'success';
			},
			error: () => (this.state = 'failed'),
		});
	}

	public copyKey() {
		this.snackbar.open('Copied to Clipboard', '', {
			duration: 3000,
		});
	}
}
