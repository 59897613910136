import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, AuthService, PollingService, ProfileService } from '@central/ng-shared';
import { HubspotTrackingService } from 'libs/ng-shared/src/lib/tracking/hubspot/hubspot.service';

import _uniq from 'lodash/uniq';
import { CatalogSearch } from '../catalog/catalog-search';
import { Payload } from './payload';
import { AmpOpApiService } from '../../amp/amp-op-api/amp-op-api.service';
import { AppService } from '../../app.service';

interface ICartItem {
	label: string;
	quantity: number;
	product_key: string;
	name: string;
	price_in_cents: number;
	code: string;
	transaction_type: string;
	term: any;
	ref_code: string;
	options: any;
}

@Component({
	selector: 'bg-cart',
	templateUrl: 'cart.component.html',
	styleUrls: ['cart.component.scss'],
})
export class CartComponent implements OnChanges, OnDestroy {
	@Input() public packages: any;
	@Input() public discountResource: any;
	@Input() public lockCartQuantity = false;
	@Input() public staticPackages = [];
	@Input() public term = 'monthly';
	@Input() public discountObject: any;
	@Input() public couponData: any;
	@Input() public isUpdatePlan: false;
	@Input() public subscriptionUuid: string;
	@Input() public organizationId: string;
	@Input() public checkoutMode: string;
	@Input() public ampTerms: any[] = null;
	@Input() public ampDomain: any = {};
	@Input() public dataCenter: any = 2;
	@Input() public dataCenters: any = [];


	@Output() public cartValidation = new EventEmitter();
	@Output() public cartUpdated = new EventEmitter();
	@Output() public cartTotalUpdated = new EventEmitter();

	private hubspotDeal;

	public currencyFormatter;
	public displayedColumns: string[] = ['quantity', 'product', 'price'];
	public dataSource: any[] = [];
	public cart: ICartItem[] = [];
	public total = '';
	public subTotal = '';
	public discount = {};
	public hasDiscount = false;
	public allowsTermDiscount = true;
	public updateCredit = 0;
	public fetchedCredit = false;
	public lineItems;
	public status = 'loading';
	public subtitle = '';
	public catalog;
	public catalogFetched = false;
	public monthlyEnabled = true;
	public yearlyEnabled = true;
	public currencies = ['USD'];
	public currency = 'USD';
	public locale = 'en-US';
	public availableAddons = [];
	public allowedAddons = [];
	public ampCartInfo = {};
	public dataCenterMap = {
		'iad': 1,
		'lax': 2,
		'ams': 3,
	}

	public packageSubtitles = {
		'boldgrid-backup': 'Total Upkeep Premium',
		'post-and-page-builder': 'Post and Page Builder Premium',
		'cloud-wordpress': 'Cloud WordPress Premium',
	};

	public packageMap = {
		premium: ['wpc_premium'],
		crio: ['wpc_crio'],
		'crio-bundle': ['wpc_premium', 'wpc_crio'],
		'cloud-wordpress': ['wpc_premium'],
		'premium-boldgrid-backup': ['wpc_premium'],
		'premium-post-and-page-builder': ['wpc_premium'],
		'boldgrid-backup': ['wpc_tukp'],
		'post-and-page-builder': ['wpc_ppbp'],
		'10_coin': ['10_coin'],
		'20_coin': ['20_coin'],
		'40_coin': ['40_coin'],
		'speedcoach-tier-1-monthly': ['speedc_tier1_monthly'],
		'speedcoach-tier-1-yearly': ['speedc_tier1_yearly'],
		'speedcoach-tier-2-monthly': ['speedc_tier2_monthly'],
		'speedcoach-tier-2-yearly': ['speedc_tier2_yearly'],
		'speedcoach-tier-3-monthly': ['speedc_tier3_monthly'],
		'speedcoach-tier-3-yearly': ['speedc_tier3_yearly'],
		'cloud-wp-tier-1-monthly': ['cwp_tier1_monthly'],
		'cloud-wp-tier-1-yearly': ['cwp_tier1_yearly'],
		'cloud-wp-tier-2-monthly': ['cwp_tier2_monthly'],
		'cloud-wp-tier-2-yearly': ['cwp_tier2_yearly'],
		'cloud-wp-tier-3-monthly': ['cwp_tier3_monthly'],
		'cloud-wp-tier-3-yearly': ['cwp_tier3_yearly'],
	};

	public constructor(
		private appService: AppService,
		public apiService: ApiService,
		public ampOpApiService: AmpOpApiService,
		private route: ActivatedRoute,
		private authService: AuthService,
		public profileService: ProfileService,
		private hubspotService: HubspotTrackingService,
		public pollingService: PollingService,
	) {
		this.route.params.subscribe((params) => {
			if (
				params['products'] &&
				this.packageSubtitles[params['products']]
			) {
				this.subtitle = this.packageSubtitles[params['products']];
			}
		});

		this.hubspotDeal = setInterval(() => {
			this.recordHubspotDeal();
		}, 10000);

		this.setModels();
	}

	public ngOnChanges(changes: any) {
		const packages = changes.packages;
		if (packages && packages.currentValue) {
			if (!this.catalogFetched) {
				this.catalogFetched = true;
				this.fetchCatalog();
			}

			this.disableCartQuantity();
		}

		setTimeout(() => {
			this.calculateTotal();
		});
	}

	public ngOnDestroy() {
		this.hubspotService.abandonCart();
		clearInterval(this.hubspotDeal);
	}

	public fetchCatalog() {
		this.status = 'loading';
		if (this.checkoutMode === 'amp') {
			this.availableAddons = [];
			const hosting = this.packages.find(e => e.type === 'hosting');
			if(hosting) {
				if(hosting.hasOwnProperty('configs') && this.allowedAddons.length === 0) {
					const selected = this.packages.filter(e => this.ampOpApiService.configurationCategories.includes(e.info.ProductType.replace('Addon:','')))
					this.allowedAddons = this.ampOpApiService.getAvailableAddons(hosting.info, selected)
				}
			}
			this.packages.forEach(e => {
				if(e.hasOwnProperty('addons')) {
					e.addons.forEach(a => {
						let allowed = true;
						const allowedAddonInfo = this.allowedAddons.find(b => b.Id === a.AddonDetailsId)
						if(e.hasOwnProperty('configs')) {
							if(e.configs.length > 0) {
								if(this.allowedAddons.length > 0 && allowedAddonInfo === undefined ) {
									allowed = false
								}
							}
						}
						if(allowed && (allowedAddonInfo === undefined || (allowedAddonInfo.hasOwnProperty('Included') && allowedAddonInfo['Included'] === false))) {
							let addonIndx = -1;
							if(this.availableAddons.length > 0){
								addonIndx = this.availableAddons.findIndex(addon => addon.ProductName === a.ProductName)
							}
							if(addonIndx < 0) {
								this.availableAddons.push(a);
							}
						}
					})
				}
				
			})
			this.catalog = this.packages;
			this.sortAmpTerms()
			this.setCart();
			this.setDataSource();
			if (this.cart.length > 0) {
				this.ampCartInfo = this.ampOpApiService.getStoredCart();
				this.ampOpApiService.saveCart(this.packages)
				this.status = 'success';
			} else {
				this.status = 'failed';
			}
			this.cartValidation.emit();
		} else {
			this.appService.getCatalog().subscribe(
				(catalog: any) => {
					this.catalog = catalog;
					this.setCart();
					this.setDataSource();

					// Get all used currencies from products in catalog.
					for ( const property in catalog ) {
						if ( catalog[property].price_in_cents ) {
							for ( const currency in catalog[property].price_in_cents ) {
								if ( currency && this.currencies.indexOf( currency ) === -1 ) {
									this.currencies.push( currency );
								}
							}
						}
					}

					if (this.cart.length) {
						if(this.fetchedCredit || !this.isUpdatePlan) {
							this.status = 'success';
						}
					} else {
						this.status = 'failed';
					}

					this.cartValidation.emit();
				},
				() => {
					this.status = 'failed';
					this.cartValidation.emit();
				}
			);
		}
	}

	public updateAmpAddons(packages,addons) {
		this.packages = packages
		this.allowedAddons = addons;
		this.fetchCatalog();
	}

	public setCart() {
		if(this.checkoutMode === 'default') {
			let products = [];
			this.packages.forEach((product) => {
				if (product.options?.data_center) {
					product.options.data_center = Object.keys(this.dataCenterMap).find(dc => this.dataCenterMap[dc] === this.dataCenter)
				}
				if (product.planCode) {
					if (this.couponData?.item_codes.includes(product.planCode)) {
						product.options = {
							...product.options,
							coupon_codes: [this.couponData?.coupon_code],
						};
					}
					products.push({
						code: product.planCode,
						quantity: product.quantity ?? 1,
						options: product.options,
					});
				} else if (this.packageMap[product]) {
					const formattedPackage = this.packageMap[product].map((val) => ({ code: val, options: {} }));

					products = [...products, ...formattedPackage];
				} else if ('string' === typeof product) {
					products.push({ code: product, options: {} });
				}
			});

			// Find the products in catalog
			const search = new CatalogSearch(this.catalog);
			this.cart = search.findCatalogItems(products, this.term);
		} else {
			this.cart = this.packages.map(e => {
				e = {...e}
				let purchaseType = 'plan';
				if(e.type !== 'hosting') {
					purchaseType = 'addon';
				} else {
					e.options['data_center'] = this.dataCenter;
				}
				e.term = {
					label: e.plan_interval_length.toString()+" "+e.plan_interval_unit_display,
					code: e.planCode,
					purchaseType: purchaseType
				}
				return e;
			})
		}
	}

	public setDataSource() {
		const dataSource = [];
		this.cart.forEach((item) => {
			const price_in = item['options']?.price?.[( this.currency )]
				|| item['options']?.price
				|| item.price_in_cents?.[( this.currency )]
				|| item.price_in_cents;

			const existingEntry = dataSource.findIndex((product: any) => product.code === item.code && item.code !== 'domain-gtld-1');
			let included = false;
			if (-1 !== existingEntry) {
				dataSource[existingEntry].quantity =
					dataSource[existingEntry].quantity + 1;
			} else {
				let ampProductType = null;
				if(this.checkoutMode === 'amp') {
					ampProductType = item['type'];
					if(item['info'].hasOwnProperty('AddonIncluded')){
						included = item['info']['AddonIncluded'];
					}
				}
				dataSource.push({
					quantity: item.quantity ?? 1,
					product: item.label,
					term: item.term.label,
					is_recurring: item.transaction_type === 'recurring',
					allows_multiple: item['options']?.allows_multiple ?? true,
					price: price_in / 100,
					code: item.code,
					domain: item['options']?.domain_name,
					amp_product_type: ampProductType,
					included: included,
					discounts: item['discounts']
				});
			}

			if (
				item.hasOwnProperty('options') &&
				item['options']?.hasOwnProperty('allows_term_discount')
			) {
				this.allowsTermDiscount = item['options'].allows_term_discount;
			}
		});
		// Sort alphabetically.
		if(this.checkoutMode === 'default') {
			dataSource.sort((a, b) => (a.product < b.product ? -1 : 1));
			this.dataSource = dataSource;
		}
		// If AMP checkout, keep hosting at the top
		if(this.checkoutMode === 'amp') {
			dataSource.sort((a, b) => (a.product < b.product ? -1 : 1));
			let newDS = [];
			newDS = [...newDS,...dataSource.filter(e => e.amp_product_type === 'hosting')];
			newDS = [...newDS,...dataSource.filter(e => e.product.includes('Domain Registration'))];
			newDS = [...newDS,...dataSource.filter(e => e.product.includes('Domain Privacy'))];
			const addonList = dataSource.filter(e =>
					e.amp_product_type !== 'hosting' &&
					!e.product.includes('Domain Registration') &&
					!e.product.includes('Domain Privacy'));
			newDS = [...newDS,...addonList];
			this.dataSource = newDS;
		}

		this.calculateTotal();
	}

	/**
	 * Get paramters to post on to backend payment.
	 *
	 * @since 1.13.0
	 *
	 * @return Payload.
	 */
	public getPayload() {
		return new Payload().format(this.cart);
	}
	/**
	 * Format discounts.
	 *
	 * @since 1.21.0
	 *
	 * @return Discounts.
	 */
	public getCartDiscounts() {
		return [];
	}
	/**
	 * Return a list of packages purchased.
	 *
	 * @since 1.13.0
	 *
	 * @return List of package labels.
	 */
	public getPackageLabels() {
		const packageNames = [];

		for (const item of this.cart) {
			packageNames.push(item.label);
		}

		return _uniq(packageNames);
	}

	public toCents(amt){
		return Math.round((Math.abs(amt) / 100) * 10000);
	}

	/**
	 * Calculate the cart total.
	 *
	 * @since 1.13.0
	 *
	 * @return Value of cart.
	 */
	public calculateTotal() {
		let subTotal = 0.0;
		this.cart.forEach((item) => {
			const price_in = item['options']?.price?.[(this.currency )]
				|| item['options']?.price
				|| item.price_in_cents?.[(this.currency )]
				|| item.price_in_cents;

			for (let i = 0; i < item.quantity; i++) {
				subTotal += price_in / 100;
			}
		});

		// const discount = this.calcDiscount( subTotal );
		const discount = this.parseDiscountObject();

		if (discount.type === 'percent') {
			discount.amount = subTotal * discount.amount;
		}

		this.handleTermToggle();

		this.subTotal = subTotal.toFixed(2);
		this.total = Math.max(subTotal - discount.amount, 0).toFixed(2);
		this.hasDiscount = !!discount.amount;
		this.discount = {
			type: discount.type,
			amount: discount.amount.toFixed(2),
			value: discount.type === 'percent'
				? discount.value
				: (discount.amount / subTotal * 100).toFixed(0),
		};

		// Checking that this.total !== 0 prevents the credit from being applied twice.
		if ( this.isUpdatePlan && this.cart && Number(this.total) ) {
			const plan = this.cart.find((item) => item['type'] === 'hosting');
			this.getPartialPrice( plan.code );
		}

		this.recordHubspotDeal();
		if(this.checkoutMode === 'amp') {
			this.cartTotalUpdated.emit(this.getFormattedCurrency(this.total));
		}
	}

	/**
	 * Change the quantity of an item in the cart. This clones an existing cart
	 * item in the array.
	 *
	 * @since 1.13.0
	 *
	 * @param  quantity      Number of products.
	 * @param  productTermId The terms ID.
	 */
	public changeQuantity(quantity: number, code: string, input: any): void {
		quantity = quantity && quantity > 0 ? quantity : 1;

		// Prevent the display from showing more than 99.
		if (quantity > 99) {
			quantity = 99;
			input.value = quantity;
		}

		input.value = quantity;

		// This is weird.. I had lol, when I copied it over.. But it works!
		const product = this.cart.find((val: any) => val.code === code);
		this.cart = this.cart.filter((val: any) => val.code !== code);

		product.quantity = quantity;
		this.cart.push({ ...{}, ...product });

		if(this.checkoutMode === 'amp') {
			const prodIndex = this.packages.findIndex((val: any) => val.code === code)
			this.packages[prodIndex].quantity = quantity;
		}

		this.setDataSource();
		this.calculateTotal();
		this.getPayload();
	}

	private disableCartQuantity() {
		this.lockCartQuantity = false;
		for (const product of this.packages) {
			if (product['options'] && product['options']['connect_id']) {
				this.lockCartQuantity = true;
			}
		}
	}

	private fetchCartSuccess() {
		this.status = 'success';
		this.cartValidation.emit();
	}

	public hasRecurring(): boolean {
		return !!this.cart.find((val: any) => !!val.is_recurring);
	}

	public hasKeyService(): boolean {
		return !!this.cart.find((val) => val['type'] === 'service' && 'key' === val['service'].type);
	}

	public updateProducts() {
		this.setCart();
		this.setDataSource();
		this.cartUpdated.emit();
	}

	public updateTerms() {
		const oldCart = this.cart;
		const newPackages = [this.ampTerms.find(e => e.planCode === this.term)];
		const domainPackage = this.packages.findIndex(e => e.type === 'domain');
		if(domainPackage > -1) {
			newPackages.push(this.packages[domainPackage])
		}
		this.packages = newPackages;
		const ampDiscountObject = {
			type:"dollars",
			amount: 0.00
		}
		this.packages.forEach(p => {
			if(['hosting','domain'].includes(p.type)) {
				p['options']['domain_name'] = this.ampDomain.name;
			}
			if(p.hasOwnProperty('configs')) {
				p['configs'].forEach(a => {
					const inAmpConfigs = oldCart.find(v => v['info'].ProductName === a.ProductName );
					if(inAmpConfigs) {
						this.packages.push(this.ampOpApiService.createCatalogPackage(a))
					}

				})
			}
			if(p.hasOwnProperty('addons')) {
				p['addons'].forEach(a => {
					const inAmpAddons = oldCart.find(v => v['info'].ProductName === a.ProductName );
					if(inAmpAddons) {
						a.quantity = inAmpAddons.quantity;
						this.changeAddon(true,a, false)
					}

				})
			}
			if(p.hasOwnProperty('discounts')) {
				if(p.discounts.amount > 0) {
					ampDiscountObject.amount += parseFloat(p.discounts.amount) * 100
				}
			}
		})
		this.discountObject = JSON.stringify(ampDiscountObject)
		this.fetchCatalog();
		this.cartUpdated.emit();
	}

	productHasDiscount(product) {
		let result = false;
		if(product.hasOwnProperty('discounts') && product['discounts'] !== undefined) {
			if(product['discounts'].hasOwnProperty('amount')) {
				if(product.discounts.amount > 0) {
					result = true;
				}
			}
		}
		return result;
	}

	public displayDiscountedPrice(product) {
		const productPrice = product.price;
		let discountPrice = 0;
		if(product.discounts?.amount) {
			discountPrice = product.discounts.amount;
		}
		return ((productPrice - discountPrice) * product.quantity).toFixed(2);
	}

	public displayDiscountPercent(term) {
		const price = term.price_in_cents / 100;
		const discountPrice = price - term.discounts.amount;
		return (100 - (discountPrice / price * 100)).toFixed(0)
	}

	public isHosting(index,product) {
		if(product.amp_product_type === 'hosting') {
			return true;
		}

	}

	public hasAddons() {
		const availableAddons = this.packages.filter(e => e.addons.length > 0);
		return availableAddons.length > 0;
	}

	public perTermPricing(addon) {
		let displayPrice = 0.00;
		let months = parseInt(addon.TermLength,10);
		if(addon.TermType === 'year') {
			months = months * 12;
		}
		displayPrice = parseFloat(addon.Price) / months;
		return displayPrice.toFixed(2);
	}

	public showQuantity(addon) {
		return parseInt(addon.AllowedQuantity,10) > 1;
	}

	public changeAddon(checked,addon, update = true) {
		const indx = this.packages.findIndex(v => v.info.ProductName === addon.ProductName);
		const allowed = this.allowedAddons.filter(e => e.AddonDetailsId === addon.AddonDetailsId).length > 0
		const addonIndx = this.availableAddons.findIndex(v => v.ProductName === addon.ProductName && allowed);
		if(checked === false && indx >= 0) {
			if(addonIndx >= 0) {
				this.availableAddons[addonIndx].quantity = 1;
			}
			this.packages.splice(indx, 1);
		} else if(checked === true) {
			let domain = null;
			if(addon.ProductType === 'Addon:Domain') {
				domain = this.ampDomain.name;
			}
			const addonInfo = this.ampOpApiService.createCatalogPackage(addon, domain);
			if(indx < 0) {
				this.packages.push(addonInfo);
			}
		}
		if(update) {
			this.fetchCatalog();
		}
	}

	public isSelected(addon) {
		return this.cart.findIndex(e => e['info'].ProductName === addon.ProductName) >= 0;
	}

	public getAddonInfo(addon) {
		let textInfo = '';
		switch(addon.ProductName) {
			case 'Google Workspace':
				textInfo = "Get a professional email at your domain, plus vital web-based business tools.";
				break;
			case 'Backup Manager':
				textInfo = 'Get secure daily auto-backups of your website files & databases with a 1-click restore.';
				break;
			case 'Dedicated IP':
				textInfo = 'Enable the use of SSL certificates for securing transactions & sensitive information.';
				break;
			case 'Domain Privacy':
				textInfo = 'Protect your personal information from spammers with domain privacy.';
				break;
			case 'Migration Support':
				textInfo = 'One WordPress Website Migration Only. Does not include email transfers.';
				break;
		}
		return textInfo;
	}

	public sortedAddons() {
		const addons = [...this.availableAddons];
		addons.sort((a, b) => (a.ProductName < b.ProductName ? -1 : 1));
		return addons;
	}

	public sortAmpTerms() {
		this.ampTerms.sort((a, b) => 
			{
				const aLength = a.plan_interval_unit.includes('year') ? a.plan_interval_length * 12 : a.plan_interval_length;
				const bLength = b.plan_interval_unit.includes('year') ? b.plan_interval_length * 12 : b.plan_interval_length;
				return aLength < bLength ? -1 : 1
			})
	}

	public termHasDiscount(term) {
		return term.hasOwnProperty('discounts') && term.discounts.amount > 0;
	}

	public showTermPrice(term, discounted = false) {
		let termPrice = 0;
		const termDiscount = this.termHasDiscount(term);
		if(termDiscount && discounted === true) {
			const discount = term.price_in_cents - (term.discounts.amount * 100)
			termPrice = discount / 100;
		} else {
			termPrice = term.price_in_cents / 100;
		}
		let months = parseInt(term.plan_interval_length,10);
		if(term.plan_interval_unit.includes("year")) {
			months = months * 12;
		}
		return (termPrice / months).toFixed(2);
	}

	public parseDiscountObject() {
		let discountType = 'N/A';
		let discountNumber = 0;
		let discountObjectParsed: any;

		if (this.discountObject) {
			discountObjectParsed = JSON.parse(this.discountObject);
			discountType = discountObjectParsed.type;
			discountNumber = discountObjectParsed.amount / 100;
			this.handleDiscoutTerms();
		}

		return {
			type: discountType,
			amount: discountNumber,
			value: discountType === 'percent' ? discountObjectParsed.amount : discountNumber,
		};
	}

	public handleDiscoutTerms() {
		if (this.couponData) {
			const planCodes = this.couponData.plan_codes;
			this.monthlyEnabled = planCodes.includes('wpc_project_monthly');
			this.yearlyEnabled = planCodes.includes('wpc_project_yearly');
			this.term = this.monthlyEnabled ? 'monthly' : 'yearly';
			this.term = this.yearlyEnabled ? 'yearly' : 'monthly';
		}
	}

	public handleTermToggle() {
		if (this.isUpdatePlan) {
			this.monthlyEnabled = this.term === 'monthly' ? true : false;
			this.yearlyEnabled = this.term === 'yearly' ? true : false;
		}
	}

	getPartialPrice( plan ) {
		this.status = 'loading';
		let headers = this.apiService.getHeaders({});
		headers = headers.append('X-Organization-Id', this.organizationId);
		this.apiService.http.post(
			this.apiService.formatter.getUrl(
				'/v1/wpcr/subscriptions/' + this.subscriptionUuid + '/update-subscription/preview?plan_code=' + plan
			), {}, { headers }).subscribe(
			(res: any) => {
				this.updateCredit = res.invoice_collection.charge_invoice.paid;
				this.lineItems = res.invoice_collection.charge_invoice.line_items;
				for(const lineItem of this.lineItems) {
					const cartPlan = this.cart.find((item) => item['code'] === lineItem['add_on_code']);
					this.updateCredit += (cartPlan.price_in_cents[this.currency]/100) - lineItem.amount;
				}
				this.total = Math.max(Number(this.total) - this.updateCredit, 0).toFixed(2);
				this.fetchedCredit = true;
				if(this.cart.length) {
					this.status = 'success';
				}
			},
			() => {
				//this.status = 'failed';
			}
		);
	}

	public getFormattedCurrency(value) {
		this.currencyFormatter = new Intl.NumberFormat(this.locale, {
			style: 'currency',
			currency: this.currency,
		});

		return this.currencyFormatter.format(value);
	}

	public setModels() {
		if (this.authService.getAccountId()) {
			this.apiService.get(`/v1/accounts/${this.authService.getAccountId()}/profile`, {}).subscribe({
				next: (response: any) => {
					this.currency = response.currency;
					this.locale = response.locale;
				},
				error: (error) => {
					console.log('error', error);
				},
			});
		}
	}

	currencySelected(currency) {
		this.currency = currency;
		this.updateProducts();
	}

	recordHubspotDeal() {
		if (this.cart.length && this.authService.getAccountId()) {
			if (this.hubspotService.getDeal() === '') {
				this.hubspotService.setDeal(
					this.authService.getAccountId(),
					this.profileService.data.options['public_hubspot_deal'] ?? ''
				);
			}

			this.hubspotService.updateCart({
				items: JSON.stringify(this.cart),
				amount: this.total,
			}).subscribe((dealId) => {
				this.profileService
					.update({
						public_hubspot_deal: dealId
					});
			});
		}
	}
}
