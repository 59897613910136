import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification.service';
import { NotificationsComponent } from './notifications.component';
import { MatIconModule } from '@angular/material/icon';
import { MomentModule } from 'ngx-moment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CoreModule } from '../core/core.module';

@NgModule({
	declarations: [NotificationsComponent],
	imports: [CommonModule, MomentModule, MatProgressBarModule, MatIconModule, CoreModule],
	providers: [NotificationService],
})
export class NotificationsModule {}
