<mat-card class="ctw-max-w-2xl ctw-mx-auto oops">
	<div class="icon"><mat-icon>report_problem</mat-icon></div>
	<div class="message">
		<h3>Oops! That's not right.</h3>
		<h4>Something went wrong.</h4>
		<p>
			We're sorry. We're working on getting this fixed as soon as we can.
			Try again and see if this corrects your problem.
		</p>
		<button mat-raised-button mat-ripple type="button" color="accent" (click)="this.reload()">
			Okay
		</button>
	</div>
</mat-card>
