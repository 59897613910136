import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ProfileService } from '../services/profile/profile.service';
import { ConfigurationService } from '../../core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'central-account-selector',
	templateUrl: './account-selector.component.html',
	styleUrls: ['./account-selector.component.scss'],
})
export class AccountSelectorComponent implements OnInit {
	@Input() defaultAccountId;
	@Input() defaultType = 'account';
	@Input() presentation = 'select';
	@Input() inputAppearance = 'fill';
	@Input() permission: string;
	@Output() accountChanged = new EventEmitter();

	message: string;
	subscription: Subscription;

	public accounts = [];
	public currentAccount;
	public accountAccess = [];
	public roleRequired = {
		manage: ['owner'],
		write: ['maintainer', 'owner'],
		read: ['member', 'maintainer', 'owner'],
	};

	constructor(
		public configurationService: ConfigurationService,
		public profileService: ProfileService
	) {
		if (
			!this.configurationService.config.brandConfig.applicationName.includes(
				'OpenMetal'
			)
		) {
			this.defaultType = 'organization';
		}
	}

	ngOnInit(): void {
		this.profileService.onReady().subscribe(() => {
			if (this.defaultType === 'organization') {
				this.accounts = this.profileService.data.account_access.filter(
					(account) => account['type'] === 'organization'
				);
			} else {
				this.accounts = this.profileService.data.account_access;
			}

			this.accountAccess = this.sortByOrganization(this.accounts);
			this.currentAccount = this.getDefaultAccount();
			this.selectionChange(this.currentAccount);
		});
	}

	private sortByOrganization([...accountAccess]) {
		return accountAccess.sort((accessA) =>
			'organization' === accessA.type ? -1 : 1
		);
	}

	public hasMultiAccess() {
		return this.accountAccess.length > 1;
	}

	public hasPermission(accountAccess): boolean {
		let hasPermission = true;

		if (this.roleRequired[this.permission || '']) {
			if (
				!this.roleRequired[this.permission].includes(accountAccess.role)
			) {
				hasPermission = false;
			}
		}

		return hasPermission;
	}

	public getDefaultAccount() {
		let defaultAccount;

		// Set the default account to an organization if the user has one.
		if (!this.defaultAccountId && this.defaultType === 'organization') {
			defaultAccount = this.profileService.data.account_access.find(
				(account) =>
					account.type === 'organization' &&
					this.hasPermission(account)
			);
		}

		// If no org found, lookup by id, falling back to personal account.
		if (!defaultAccount) {
			const currentId =
				this.defaultAccountId ||
				this.profileService.authService.getAccountId();
			defaultAccount = this.profileService.data.account_access.find(
				(account) => account.account_id === currentId
			);
		}

		return defaultAccount;
	}

	public selectionChange(account) {
		this.accountChanged.emit(account);
	}

	public getLabel() {
		return (this.currentAccount &&
			'organization' === this.currentAccount.type) ||
			'organization' === this.defaultType
			? this.configurationService.config.groupLabels[
					'group-label-title-case'
			  ]
			: 'Personal Account';
	}
}
