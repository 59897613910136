import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'central-disable-ssl-dialogue',
	templateUrl: './disable-ssl-dialogue.component.html',
	standalone: true,
	imports: [
		MatDialogModule,
		MatButtonModule,
	]
})

export class DisableSslDialogueComponent {
	constructor(
		public dialogRef: MatDialogRef<DisableSslDialogueComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public closeDialogue(ref) {
		this.dialogRef.close(ref);
	}
}