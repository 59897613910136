import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { MatSort } from '@angular/material/sort';
import { ProjectService } from '../../../../../project.service';

@Component({
	selector: 'central-jobs',
	templateUrl: './jobs.component.html',
	styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort) sort: MatSort;
	project_id = null;
	/**
	 *
	 * [{"Id":445,"UserAdminId":101339,"status":"fail","Name":"environment_publish","Type":"long_task","Class":"\\Project\\Environment\\Production","Function":"runWordpressAnsible","Parameters":"{\u0022Method\u0022:{\u0022account_id\u0022:101339,\u0022project_id\u0022:\u0022PGZYNLVpg3\u0022,\u0022domain\u0022:\u0022vps91754.inmotionhosting.com\u0022,\u0022phase\u0022:\u0022setup-wordpress\u0022,\u0022environment_id\u0022:197}}","Options":"[]","Response":"null","ExpectedReturnVal":"success","ScheduledTime":"2023-03-28T15:36:51+00:00","AbortTime":"2023-03-28T16:36:51+00:00","StartedAt":"2023-03-28T15:36:57+00:00","CompletedAt":"2023-03-28T15:36:57+00:00","CreatedAt":"2023-03-28T15:36:52+00:00","UpdatedAt":"2023-03-28T15:36:58+00:00"}]
	 */
	public columnsToDisplay = [
		//'UserAdminId',
		'Name',
		'StartedAt',
		'status',
		//'CompletedAt',
		//'Duration',
		//'UpdatedAt',
		'Action'
	];
	job_data = null;
	state = 'init';
	interval = null;
	constructor(
		public apiService: ApiService,
		private route: ActivatedRoute,
		public configService: ConfigurationService,
		public projectService: ProjectService
	) {

	}

	ngOnInit(): void {
		this.fetch();
		this.interval = setInterval(() => {
			this.fetch();
		}, 10000);
	}
	ngOnDestroy(): void {
		clearInterval(this.interval);
	}
	fetch(): void {
		this.state = 'submitted';
		this.route.paramMap.subscribe((params) => {
			this.apiService.get('/v1/jobs', { project_id: this.projectService.project.id})
			.subscribe((data) => {
				this.job_data = data;
				this.job_data.sort((a, b) =>
					new Date(b.StartedAt).getTime() - new Date(a.StartedAt).getTime());
				this.state = 'success';
			});
		});

	}

	action(element, action): void {
		const request = {
			status: null
		};
		if (action === 'Stop') {
			request.status = 'fail';
		} else {
			request.status = 'pending';
		}
		this.apiService.post('/v1/jobs/' + element.Id, request).subscribe((data) => {
			this.fetch();
		});
	}

	getDuration(element): string {
		const start = new Date(element.StartedAt + '+00:00');
		let end = new Date(element.CompletedAt + '+00:00');
		if (end.getTime() < start.getTime()) {
			end = new Date();
		}
		const diff = end.getTime() - start.getTime();
		const hours = Math.floor(diff / (1000 * 60 * 60));
		const minutes = Math.floor(diff / (1000 * 60)) % 60;
		const seconds = Math.floor(diff / 1000) % 60;
		let duration = '';
		if (hours > 0) {
			duration += hours + 'h ';
		}
		if (minutes > 0) {
			duration += minutes + 'm ';
		}
		if (seconds > 0) {
			duration += seconds + 's ';
		}
		return duration;
	}
}
