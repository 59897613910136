<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-my-16 notranslate">
	<ng-container *ngIf="!this.isAnsibleControled">
		<p>
			Playground environments currently do not have any Agent overrides
			available.<br /><br />
		</p>
	</ng-container>
	<ng-container *ngIf="this.isAnsibleControled">
		<central-ansible></central-ansible>
		<central-jobs></central-jobs>
	</ng-container>
</div>