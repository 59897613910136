import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@central/ng-shared';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'bg-discount',
	templateUrl: 'discount.component.html',
	styleUrls: ['discount.component.scss'],
})
export class DiscountComponent implements OnInit, OnDestroy {
	@ViewChild('dashcard') public dashcard: any;
	@Input() public supportManualEntry = true;
	@Input() public manualDiscount: any;

	public code = '';
	public status = 'pending';
	public requiresSignin = false;
	public subscription: any;
	public autoDiscount: any;

	private discounts: any[] = [];

	public constructor(
		public apiService: ApiService,
		private authService: AuthService
	) {}

	public ngOnInit() {
		// On login state change, make sure this user can still use the code.
		this.subscription = this.authService.authStateChange.subscribe(() => {
			if (this.code) {
				this.fetch(this.code);
			}
		});
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	/**
	 * Get discount object.
	 *
	 * @since 1.21.0
	 *
	 * @return Discount.
	 */
	public getDiscount() {
		return this.discounts[0] || null;
	}

	/**
	 * Load the code into the form.
	 *
	 * @since 1.21.0
	 *
	 * @param  code Code
	 */
	public autoload(code: string): void {
		if (code && '1' === code) {
			this.supportManualEntry = true;
		} else if (code) {
			this.fetch(code, true);
		}
	}

	/**
	 * Find available discounts based on current cart.
	 *
	 * @since X.X.X
	 */
	public findDiscounts(packages: any[]): void {
		if (
			packages.length &&
			this.authService.isLoggedIn() &&
			packages[0].product_term_id
		) {
			this.apiService
				.get('getAutoDiscount', { cart: JSON.stringify(packages) })
				.subscribe(
					(response) => {
						this.discounts.push(response);
						this.dashcard.expanded = true;
					},
					() => ''
				);
		}
	}

	/**
	 * Fetch a valid dicount by code.
	 *
	 * @since 1.21.0
	 *
	 * @param  code Discount code.
	 * @param  silent Suppress errors.
	 */
	public fetch(code: string, silent = false): void {
		this.code = code;
		this.status = 'submitted';
		this.requiresSignin = false;

		this.apiService.get('getDiscount', { code }).subscribe(
			(response) => {
				this.discounts.push(response);
				this.status = 'success';
				this.dashcard.expanded = true;
			},
			(response) => {
				const errors = response.errors || [];
				this.requiresSignin = !!this.getError(errors, [
					'requires_signin',
				]);
				this.status = 'failed';

				// If code is passed from URL/Cookie and is invalid, dont show errors.
				if (silent && !this.requiresSignin) {
					this.status = 'pending';
					this.dashcard.expanded = false;
				} else if (silent) {
					this.dashcard.expanded = true;
				}
			}
		);
	}

	/**
	 * Remove a discount from list.
	 *
	 * @since  1.21.0
	 * @param  discount Discount object.
	 */
	public removeDiscount(discount: any): void {
		const index = this.discounts.findIndex((val) => val.id === discount.id);
		this.discounts.splice(index, 1);
	}

	/**
	 * Get any errors by name.
	 *
	 * @since 1.21.0
	 *
	 * @param  errors List of errors.
	 * @param  names  list of names.
	 * @return        All matched values.
	 */
	private getError(errors: any[], names: any[]): any[] {
		return errors.find((val: object) => -1 !== names.indexOf(val['name']));
	}
}
