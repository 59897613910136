import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../../../../project.service';

@Component({
	selector: 'central-agent-override-form',
	templateUrl: './agent-override.component.html',
	styleUrls: ['./agent-override.component.scss'],
})
export class AgentOverrideComponent {
	@Input() overrideState = 'Loading';
	@Input() overrides: any;
	@Output() overridenBy = new EventEmitter<any>();

	public comment: string;
	public state = 'pending';
	private apiService: ApiService;
	public overrideEligible: boolean;
	public projectId: string;
	public formError: string;
	public formControl = new UntypedFormControl('', [Validators.required]);

	constructor(
		matSnackBar: MatSnackBar,
		httpClient: HttpClient,
		apiService: ApiService,
		projectService: ProjectService
	) {
		this.projectId = projectService.project.id;
		this.apiService = apiService;
		this.overrideEligible =
			!!projectService.environment.fields.semaphore_template_id;
	}

	public submitForm(data) {
		this.state = 'submitted';
		if (this.formControl.hasError('required')) {
			this.state = 'pending';
			return;
		}
		let formState = true;
		if ('Enabled' === this.overrideState) {
			formState = false;
		}
		const postData = {
			state: formState,
			comment: this.comment,
		};

		this.apiService
			.post('/v1/override/' + this.projectId + '/create', postData)
			.subscribe((response) => {
				this.loadOverrides();
			});
	}

	public loadOverrides() {
		this.apiService
			.get('/v1/override/' + this.projectId + '/state', {})
			.subscribe((response) => {
				this.apiService
					.get('/v1/override/' + this.projectId, {})
					.subscribe((history: any) => {
						this.overrides = history;
						if (true === response['state']) {
							const user =
								history[0]['Comment'] !== 'User Disabled'
									? history[0].UserAdmin
									: 'User';
							this.overrideState = 'Enabled';
							this.overridenBy.emit(user);
							this.state = 'success';
						} else {
							this.overrideState = 'Disabled';
							this.state = 'success';
						}
					});
			});
	}
}
