import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { SupportComponent } from './support.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MessageComponent } from './message/message.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CoreModule } from '../core/core.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { BrowserModule } from '@angular/platform-browser';
import { TicketComponent } from './ticket/ticket.component';
import { ListTicketsComponent } from './list-tickets/list-tickets.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MomentModule } from 'ngx-moment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { AgentAccessDialogComponent } from './agent-access-dialog/agent-access-dialog.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
@NgModule({
	declarations: [
		SupportComponent,
		MessageComponent,
		TicketComponent,
		ListTicketsComponent,
		AgentAccessDialogComponent,
	],
	imports: [
		BrowserModule,
		CommonModule,
		CoreModule,
		MatIconModule,
		MatSelectModule,
		MatCardModule,
		MatProgressBarModule,
		MatDialogModule,
		MatTabsModule,
		MatFormFieldModule,
		MatChipsModule,
		MatBadgeModule,
		MatButtonModule,
		MomentModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		SupportRoutingModule,
		MatTableModule,
		ClipboardModule,
	],
	exports: [
		MessageComponent,
	],
})
export class SupportModule {}
