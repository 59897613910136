import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@central/ng-shared';

// tslint:disable-next-line:no-submodule-imports
import { map } from 'rxjs/operators';

@Injectable()
export class ProductService {
	constructor(public apiService: ApiService) {}

	public getPackagesPrices(options: object) {
		return this.apiService.post(options['call'], options['type']).pipe(
			map((response) => {
				const result = response['result'];

				if (result && result.data && result.data.success) {
					return result.data;
				}
			})
		);
	}
}
