<ng-container *ngIf="this.paymentPage">
	<div class="lead ctw-text-left">
		<div class="header">
			<h3>Purchase Complete</h3>
			<button mat-raised-button mat-button color="primary" [routerLink]="this.getPurchaseRoute()"
				class="ctw-float-right">
				Continue
			</button>
		</div>
		<div class="content">
			<p>
				Your receipt can be
				accessed any time in your Team's
				<a [routerLink]="['/account/' + this.account + '/receipts']">Transaction History</a>. Thank you
				for your
				business!
			</p>

			<ng-content></ng-content>
		</div>
	</div>
	<div class="ctw-text-right dashboard"></div>
</ng-container>
<div *ngIf="this.products.length && this.receipt" class="receipt-divider"></div>
<div class="receipt-wrapper ctw-text-left" *ngIf="receipt">
	<central-receipt [invoice]="this.receipt"></central-receipt>
</div>
