import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { CoreModule } from '@central/ng-shared';
import { SharedModule } from '../../../shared/shared.module';
import { BillingModule } from '../billing.module';

import { PostPurchaseComponent } from './view/post-purchase/post-purchase.component';
import { InvoiceViewComponent } from './view/view.component';
import { InvoiceListComponent } from './list/list.component';

@NgModule({
	imports: [
		SharedModule,
		MomentModule,
		BillingModule,
		CoreModule
	],
	declarations: [
		InvoiceListComponent,
		InvoiceViewComponent,
		PostPurchaseComponent,
	],
	exports: [PostPurchaseComponent],
})
export class InvoiceModule {}
