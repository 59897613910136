import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-domain-third-party-delete-dialog',
	templateUrl: './domain-third-party-delete-dialog.component.html',
	styleUrls: ['./domain-third-party-delete-dialog.component.scss'],
})
export class DomainThirdPartyDeleteDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<DomainThirdPartyDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}
}
