import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedStorageService extends LocalStorageService {
	/**
	 * This class is the same as local storage except it uses a different namespace as the LocalStorageService.
	 * This means that it WILL be cleared when the user logs out.
	 *
	 * ALSO, doesnt used usernamespaces. It shared accrossed users.
	 */
	public baseNamespace = 'BGCentralShared_';
}
