import { Component } from '@angular/core';
import { BrandingService } from '@central/ng-shared';

@Component({
	selector: 'bgc-header-logo',
	styles: [
		`
			.bg-logo-small img {
				margin: 0 auto 2rem;
				width: 10rem;
			}
		`,
	],
	template: `
		<a
			class="bg-logo-small"
			href="{{ this.brandingService.getBrandOpt('marketingSite') }}"
		>
			<img src="{{ this.brandingService.getThemedAssetUrl('logo2.svg') }}" />
		</a>
	`,
})
export class HeaderLogoComponent {
	constructor(public brandingService: BrandingService) {}
}
