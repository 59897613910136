import { Injectable } from '@angular/core';
import { SharedStorageService } from '@central/ng-shared';

@Injectable()
export class CartService {
	public constructor(public localStorageService: SharedStorageService) {}

	/**
	 * Get the cart.
	 *
	 * @since 1.23.0
	 *
	 * @return Get the cart object.
	 */
	public get() {
		return this.localStorageService.getItem('cart');
	}

	/**
	 * Set the cart service.
	 *
	 * @since 1.23.0
	 *
	 * @param  cart Cart Settings.
	 * @return      Set the cart.
	 */
	public set(cart: object) {
		this.localStorageService.setItem('cart', cart);
	}
}
