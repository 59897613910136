export default {
	freeDomains: [
		'gmail.com',
		'aol.com',
		'att.net',
		'catmail.app',
		'comcast.net',
		'crispmail.com',
		'gmail.co',
		'gmail.com',
		'googlemail.com',
		'hotmail.com',
		'live.com',
		'mailforspam.com',
		'memail.com',
		'msn.com',
		'outlook.com',
		'pm.me',
		'protonmail.com',
		'sbcglobal.net',
		'tom.com',
		'yahoo.com',
		'yandex.com',
	],
};
