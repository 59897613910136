import { Injectable } from '@angular/core';
import { SubTrackingService } from '../base/sub-tracking.service';

@Injectable({
    providedIn: 'root',
})
export class LinkedinService extends SubTrackingService {
	/**
	 * Track user signups.
	 */
	public signup(): void {
		const elem = document.createElement('img');
		elem.style.display = 'none';
		elem.src = `https://px.ads.linkedin.com/collect/?pid=2390348&conversionId=2710764&fmt=gif`;
		document.body.appendChild(elem);
	}
}
