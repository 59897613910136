<div data-private>
    <h2 mat-dialog-title class="ctw-capitalize">Add New Card</h2>
    <mat-dialog-content class="ctw-min-h-[275px]">
        <div class="ctw-w-full ctw-flex ctw-justify-between ctw-pb-3 ctw-flex-wrap"
            *ngIf="this.newBillingInfo === false">
            <div *ngIf="this.data.accountInfo.contactInputs.hasOwnProperty('user')" class="ctw-leading-snug" data-private>
                <span class="ctw-font-bold">{{this.data.accountInfo.contactInputs.user.first_name}} {{this.data.accountInfo.contactInputs.user.last_name}}</span><br />
                <span *ngIf="this.data.accountInfo.contactInputs.user.company_name">
                    {{this.data.accountInfo.contactInputs.user.company_name}}<br />
                </span>
                <span>{{this.data.accountInfo.contactInputs.user.address1}}</span><br />
                <span *ngIf="this.data.accountInfo.contactInputs.user.address2">
                    {{this.data.accountInfo.contactInputs.user.address2}}<br />
                </span>
                <span>{{this.data.accountInfo.contactInputs.user.city}} {{this.data.accountInfo.contactInputs.user.state}}, {{this.data.accountInfo.contactInputs.user.zip}}</span><br />
                <span>{{ this.getCountryName(this.data.accountInfo.contactInputs.user.country)}}</span><br />
                <span>{{this.data.accountInfo.contactInputs.user.phone}}</span><br />
                <span>{{this.data.accountInfo.contactInputs.user.email}}</span><br />
            </div>
        </div>
        <div>
            <amp-cc #newcc></amp-cc>
        </div>
        <div [class]="this.newBillingInfo ? 'ctw-pb-3' : 'ctw-pb-1'">
            <mat-checkbox name="new-billing" [(ngModel)]="this.newBillingInfo">
                Enter New Billing Information
            </mat-checkbox>
        </div>
        <div>
            <bg-contact [hidden]="this.newBillingInfo === false" class="bg-contact" #billingInputs [excludedInputs]="['email']"
                [forceLogin]="false" [useEmailInput]="false"  [minimalForm]="false"></bg-contact>
        </div>
        <div class="ctw-text-red-700">{{errorMessage}}</div>
    </mat-dialog-content>
    <div class="ctw-mt-10">
        <div class="ctw-text-right ctw-w-full">
            <button mat-button mat-dialog-close type="button" (click)="closeDialogue(false)">Cancel</button>
            <central-progress-button [state]="status" (click)="addNewCard()">Add</central-progress-button>
        </div>
    </div>
</div>