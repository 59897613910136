import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules, Routes } from '@angular/router';

import {
	AccountRoutes,
	AgentGuard,
	GuestGuard,
	JwtComponent,
} from '@central/ng-shared';

import { AmpRoutes } from './amp/amp.routes';
import { PageNotFoundComponent } from './shared/404/page-not-found.component';
import { ReadmeComponent } from './readme/readme.component';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'projects',
	},
	{
		path: 'wordpress',
		pathMatch: 'prefix',
		redirectTo: '',
	},
	{
		path: '',
		loadChildren: () =>
			import('./guest/guest.module').then((m) => m.GuestModule),
	},
	{
		path: 'projects',
		loadChildren: () =>
			import('./project/project.module').then((m) => m.ProjectModule),
		canActivate: [GuestGuard],
	},
	{
		path: 'domains',
		loadChildren: () =>
			import('./domain/domain.module').then((m) => m.DomainModule),
		canActivate: [GuestGuard],
	},
	{
		path: 'email',
		loadChildren: () =>
			import('./email/email.module').then((m) => m.EmailModule),
		canActivate: [GuestGuard],
	},
	{
		path: 'guide',
		loadChildren: () =>
			import('./guide/guide.module').then((m) => m.GuideModule),
	},
	{
		path: 'agent',
		loadChildren: () =>
			import('./agent/agent.module').then((m) => m.AgentModule),
		canActivate: [AgentGuard],
	},
	{
		path: 'readme',
		component: ReadmeComponent,
		canActivate: [AgentGuard],
	},
	...AccountRoutes,
	...AmpRoutes,
	// redirectTo must be local or query params don't get forwarded.
	// See https://github.com/angular/angular/issues/28661
	{ path: 'welcome', redirectTo: '/guide/project/signup' },
	{ path: 'sign-up', redirectTo: 'guide/project/signup', pathMatch: 'full' },
	{ path: 'token', component: JwtComponent },
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
