import { Component, Input, OnInit } from '@angular/core';
import { ApiService, ConfigurationService } from '../../../core';

@Component({
	selector: 'central-account-options',
	templateUrl: './account-options.component.html',
	styleUrls: ['./account-options.component.scss'],
})
export class AccountOptionsComponent implements OnInit {
	public options : any = [];
	public changes : any = {};
	public state = 'pending';
	public userProfile : any;
	@Input() public userId : any;

	constructor(
		public configurationService: ConfigurationService,
		private apiService: ApiService,
	) {
		this.options = this.configurationService.config.editableAccountOptions || [];
	}

	ngOnInit(): void {
		this.initValues();
	}

	public onChanges( id : string ) {
		this.changes[id] = this.options.find((option: any) => option.id === id).value;
	}

	public initValues() {
		this.apiService.get(`/v1/accounts/${this.userId}/profile`, {}).subscribe( {
			next: (response: any) => {
				this.options.forEach((option: any) => {
					this.userProfile = response;
					option.value = this.userProfile?.options[option.id] || '';
				});
			},
			error: (error) => {
				console.log( 'error', error );
			},
		} );
	}

	public hasChanges() {
		return Object.keys(this.changes).length === 0;
	}

	public update() {
		this.state = 'submitted';

		const url = this.apiService.formatter.getUrl( `/v1/accounts/${this.userId}/profile` );
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.apiService.http.post(url, this.changes, { headers }).subscribe(
			(response: any) => {
				this.userProfile = response.options;
				this.initValues();
				this.state = 'success';
			},
			(error) => {
				this.state = 'error';
			}
		);
	}

}
