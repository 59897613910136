import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { finalize } from 'rxjs/operators';

interface Package {
    type?: string;
    productId?: string;
    configs?: any[];
    info?: {
        ProductType: string;
    };
}

interface Category {
    type: string;
    label: string;
    configs: any[];
    selected: any;
    hide: boolean;
}

interface CategoryConfig {
    ProductType: string;
    AddonDetailsId: string;
    LegacyName: string;
    AddonAutoSelected?: boolean;
}

@Component({
	selector: 'amp-config',
	templateUrl: 'amp-config.component.html',
})
export class AmpConfigComponent {
	@Input() public packages: Package[] = [];
	@Input() public configs: Category[] = [];
	@Input() public categories: Category[] = [
		{type: 'Control Panel Options', label: 'Control Panel', configs: [], selected: {}, hide: false},
		{type: 'Optimized Configurations', label: 'Optimized Configurations', configs: [], selected: {}, hide: false},
		
	];
    public selectedPackage: Package = {};
    public selectedCartInfo = {};
    public selectedAddons = [];
    public selected = [];
    public availableAddonArray = [];
    public availableAddonIds = [];
    public forceCatalogUpdate = false;
    public loading = false;
    public freeCss = "ctw-text-xs ctw-font-bold ctw-bg-green-500 ctw-rounded ctw-m-1 ctw-h-5 ctw-w-10 ctw-p-1 ctw-text-white ctw-text-center";

	@Output() public configChanged = new EventEmitter();
	@Output() public configComplete = new EventEmitter();
	@Output() public configureError = new EventEmitter();

	public constructor(public ampOpApiService: AmpOpApiService) {}

    /**
     * Initializes the component by fetching the AMP catalog and setting up configurations
     * @param update - Whether to trigger a configuration update event (default: true)
     */
    public setup(update = true) {
        this.loading = true;
        this.ampOpApiService.fetchAmpCatalog(null, this.forceCatalogUpdate)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: (catalog) => {
                    if(catalog.hasOwnProperty('data')) {
                        this.selectedPackage = this.packages.filter(e => e.type === 'hosting')[0];
                        this.selectedCartInfo = catalog['data'].find(e=> e.Id === this.selectedPackage['productId'])
                        if(this.selectedCartInfo === undefined) {
                            this.forceCatalogUpdate = true;
                            this.setup(update);
                        } else {
                            if(this.forceCatalogUpdate === true) {
                                this.forceCatalogUpdate = false;
                            }
                            const preSelected = this.packages.filter(e => this.categories.map(c => c.type).includes(e.info.ProductType.replace('Addon:','')))
                            const preSelectedIds = preSelected.map(p => p.productId)
                            const selected = [];
                            if(this.selectedPackage.hasOwnProperty('configs')) {
                                this.selectedPackage?.configs?.forEach(c => {
                                    if(preSelectedIds.includes(c.AddonDetailsId)) {
                                        selected.push(c)
                                    }
                                })
                            }

                            this.selected = selected

                            this.updateConfiguration(update,selected)
                        }
                    } else {
                        if(this.forceCatalogUpdate === false) {
                            this.forceCatalogUpdate = true;
                            this.setup(update);
                        } else {
                            this.configureError.emit()
                        }
                    }
                },
                error: (error) => {
                    this.configureError.emit(error);
                }
            });
    }

    /**
     * Gets the selected configurations from either the provided array or currently selected configs
     * @param selected - Optional array of selected configurations
     * @returns Array of selected configurations
     * @private
     */
    private getSelectedConfigs(selected: any[] | null): any[] {
        return selected ?? this.configs.filter(e => e.selected).map(e => e.selected);
    }

    /**
     * Processes configurations for a specific category
     * @param category - The category to process
     * @param selected - Array of selected configurations
     * @param availableAddonIds - Array of available addon IDs
     * @private
     */
    private processCategoryConfigs(category: Category, selected: any[], availableAddonIds: string[]): void {
        const catConfigs = this.selectedPackage?.configs?.filter(config => 
            config?.ProductType === `Addon:${category.type}` && 
            (availableAddonIds.length === 0 || 
            (config?.AddonDetailsId && availableAddonIds.includes(config.AddonDetailsId)))
        ) || [];

        if (catConfigs.length === 0) {
            category.hide = true;
            return;
        }

        category.configs = catConfigs;
        category.hide = false;
        this.setSelectedConfig(category, selected, catConfigs);
        category.configs.sort((a, b) => (a.LegacyName < b.LegacyName ? -1 : 1));
    }

    /**
     * Sets the selected configuration for a category based on various conditions
     * @param category - The category to set the configuration for
     * @param selected - Array of selected configurations
     * @param catConfigs - Array of category configurations
     * @private
     */
    private setSelectedConfig(category: Category, selected: any[], catConfigs: CategoryConfig[]): void {
        const pkgSelected = selected.find(e => e.ProductType === `Addon:${category.type}`);
        
        if (pkgSelected) {
            category.selected = catConfigs.find(e => e.AddonDetailsId === pkgSelected.AddonDetailsId);
            return;
        }

        // Find auto-selected or conditionally required config
        catConfigs.some(config => {
            const addon = this.availableAddonArray.find(a => a.Id === config.AddonDetailsId);
            if (addon?.Conditional && 
                this.ampOpApiService.isConditionallyRequired(this.selectedCartInfo, addon, selected)) {
                category.selected = config;
                return true;
            }
            if (config.AddonAutoSelected) {
                category.selected = config;
                return true;
            }
            return false;
        });
    }

    /**
     * Updates the configuration state and emits changes if required
     * @param update - Whether to emit configuration changes
     * @param selected - Optional array of selected configurations
     */
    public updateConfiguration(update = true, selected: any[] | null = null): void {
        const selectedConfigs = this.getSelectedConfigs(selected);
        
        // Update available addons
        this.availableAddonArray = this.ampOpApiService.getAvailableAddons(this.selectedCartInfo, selectedConfigs);
        this.availableAddonIds = this.availableAddonArray.map(e => e.Id);

        // Process categories
        const newCategories = structuredClone(this.categories);
        newCategories.forEach(category => {
            this.processCategoryConfigs(category, selectedConfigs, this.availableAddonIds);
        });

        // Update component state
        this.selectedAddons = newCategories
            .filter(cat => cat.selected)
            .map(cat => cat.selected);
        this.configs = newCategories.filter(cat => !cat.hide);

        if (update) {
            this.configChanged.emit(newCategories);
        }
    }

    /**
     * Emits the configuration complete event
     */
    public configSet() {
        this.configComplete.emit();
    }

    /**
     * Checks if a configuration is selected for a given category
     * @param config - The configuration to check
     * @param category - The category to check against
     * @returns Boolean indicating if the configuration is selected
     */
    public isSelected(config, category) {
        let id = null;
        if(category.selected !== undefined && category.selected.hasOwnProperty('AddonDetailsId')) {
            id = category.selected.AddonDetailsId;
        }
        return config.AddonDetailsId === id;
    }

    /**
     * Generates the price display string for a configuration
     * @param config - The configuration to show the price for
     * @param category - The category of the configuration
     * @returns Formatted price string with HTML
     */
    public showPrice(config, category) {
        const pricedConfigs = ['Control Panel Options'];
        if (!pricedConfigs.includes(config.ProductType.replace('Addon:', ''))) {
            return '';
        }

        const price = parseFloat(config.DiscountPrice);
        if (price <= 0) {
            return ' + free';
        }

        const termLength = config.TermType === 'year' 
            ? parseInt(config.TermLength, 10) * 12 
            : parseInt(config.TermLength, 10);

        const perMonth = termLength > 1 
            ? `<span class="ctw-text-xs"> ($${(price / termLength).toFixed(2)} /mo) </span>`
            : '';

        return ` + $${price.toFixed(2)}${perMonth}`;
    }
}