import { Component } from '@angular/core';
import { AuthService, ProfileService } from '../../authentication/services';
import { ConfigurationService } from '../../core';
import { AccountService } from '../account.service';

@Component({
	selector: 'central-account-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})

export class SettingsComponent {
	public isAdmin;
	public isImpersonating;
	public usesPassword;
	public features;
	public status = 'success';
	public displayedColumns = ['provider', 'identity', 'action']

	constructor(
		public accountService: AccountService,
		public authService: AuthService,
		public configService: ConfigurationService,
		private profileService: ProfileService
	) {
		this.profileService.onReady().subscribe(() => {
			this.isAdmin = this.authService.isAdmin();
			this.isImpersonating = this.authService.isImpersonating();
			this.features = this.configService.config.features;
			this.usesPassword = !this.profileService.data['firebase'];
		});
	}
}
