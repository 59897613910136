import {
	Component,
	OnDestroy,
} from '@angular/core';
import { AppComponent } from '../app.component';
import { AmpOpApiService } from './amp-op-api/amp-op-api.service';

@Component({
	selector: 'dev-amp',
	template: ` <router-outlet class="amp-router-outlet"></router-outlet> `,
})
export class AmpComponent implements OnDestroy {
	public dataFeed = [];
	constructor(
		public appComponent: AppComponent,
		public ampOpApi: AmpOpApiService
	) {
		this.appComponent.forceGuestView = false;
		this.appComponent.disableGuestView = true;
	}

	public ngOnDestroy() {
		this.appComponent.disableGuestView = false;
	}
}
