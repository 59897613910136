<div *ngIf="!isMenuItem" class="option ctw-mt-5 notranslate">
	<div>
		<h4 class=" ctw-mb-0 ctw-font-bold">Beta Tester</h4>
		<p>Enables this account to experience new features and functionality early.</p>
		<p>Current State: <span class="{{ this.isTester ? 'ctw-text-green-700' : 'ctw-text-red-700' }}">{{ this.isTester
				? 'Enabled' : 'Disabled' }}</span></p>
	</div>

	<div class="ctw-mt-2">
		<progress-button [state]="this.state" (click)="toggleState()"
			[type]="this.isTester ? 'mat-stroked-button ctw-text-imh-red' : 'ctw-bg-green-200'">
			{{ this.isTester ? 'Disable' : 'Enable' }} Testing
		</progress-button>
	</div>
</div>
<button mat-menu-item *ngIf="isMenuItem" (click)="toggleState()">
	<mat-icon *ngIf="this.isTester" class="ctw-text-yellow-700">bug_report</mat-icon>
	<span *ngIf="this.isTester" class="notranslate ctw-text-yellow-700">Disable Testing</span>
	<mat-icon *ngIf="!this.isTester" class="ctw-text-green-700">bug_report</mat-icon>
	<span *ngIf="!this.isTester" class="notranslate ctw-text-green-700">Enable Testing</span>
</button>