<mat-spinner diameter="100" color="accent" *ngIf="status === 'loading'"></mat-spinner>
<div class="login valign" *ngIf="'success' === this.status">
	<a href="{{ this.brandingService.getBrandOpt('marketingSite') }}" tabindex="-1">
		<img class="bg-logo ctw-mx-auto" src="{{ this.brandingService.getThemedAssetUrl('logo2.svg') }}" />
	</a>
	<ng-content></ng-content>
	<login-form [disableRedirect]="true" (onLoginSuccess)="this.onLogin($event)"
		[authMethods]="this.application['fields']['auth_methods']"></login-form>
	<div class="links">
		<a href="{{ this.brandingService.getBrandOpt('supportUrl') }}" target="_blank" mat-button>Help</a>
		<a href="{{ this.brandingService.getBrandOpt('privacyPolicy') }}" target="_blank" mat-button>Privacy</a>
		<a href="{{ this.brandingService.getBrandOpt('termsOfService') }}" target="_blank" mat-button>Terms</a>
	</div>
</div>
<div class="failed" *ngIf="status === 'failed'">
	<h2>Oops!</h2>
	<h3>Unable to verify your requested application.</h3>
	<button (click)="reset()" mat-button mat-raised-button color="accent">
		Okay
	</button>
</div>
