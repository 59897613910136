import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { CoreModule } from '@central/ng-shared';
import { SharedModule } from '../../../shared/shared.module';

import { SubscriptionComponent } from './index';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@NgModule({
	declarations: [SubscriptionComponent, ConfirmationDialogComponent],
	imports: [MomentModule, SharedModule, CoreModule],
	exports: [],
})
export class SubscriptionModule {}
