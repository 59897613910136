import { Injectable } from '@angular/core';

@Injectable()
export class PublishService {
	public packages = [];

	constructor() {}

	public hasProducts() {
		return this.packages.length > 0;
	}
}
