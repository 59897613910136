<!-- When processing free items we expect the transaction to be posted immediately. No Form shown. -->
<div class="loading" *ngIf="state === 'submitted'">
	<mat-progress-spinner class="component-loader" *ngIf="this.checkoutMode === 'default'" mode="indeterminate"
		color="primary"></mat-progress-spinner>
	<p *ngIf="this.checkoutMode === 'default'" class="ctw-text-black">Processing</p>
	<progress-message *ngIf="this.checkoutMode === 'amp'" [progressMessages]="this.progressMessages" />
</div>
<failed-request *ngIf="this.processingFreeCart && state === 'failed'"></failed-request>

<failed-request *ngIf="this.cartFailed()"></failed-request>

<div class="row payment-grid flex flex-wrap ctw-text-left" [hidden]="this.cartFailed()">
	<div class="col payment-column" [hidden]="this.checkoutMode === 'amp' && this.hideAmpConfiguration === false">
		<mat-card id="error-message" [hidden]="!showForms() || !hasError()" class="alert error-message">
			<strong class="error">ERROR:</strong> {{ this.errorMessage }}
		</mat-card>
		<amp-account-auth *ngIf="this.checkoutMode === 'amp'" [hidden]="!showForms()"
			[includeBackButton]="includeBackButton" (checkedEmail)="ampEmailChecked($event)"
			(ampLogin)="ampLogin($event)" (goBack)="this.paymentBack.emit()" (accountTypeChanged)="accountTypeChanged()"
			(ampLogout)="ampLogout($event)" #ampAccountInfo>
		</amp-account-auth>
		<form [hidden]="!showForms() && !this.forceLogin" [class.animate__fadeIn]="showForms()" novalidate
			(ngSubmit)="this.formSubmission()" #form="ngForm">
			<dash-card *ngIf="this.useTeamSelector" disabled="1" cardTitle="Select the Team that will manage this {{
					this.teamPurchase
				}}" icon="groups" class="{{ this.cardClass }} team-selector">
				<div class="ctw-py-4 ctw-px-8">
					<central-account-selector [defaultAccountId]="this.organizationId" inputAppearance="standard"
						permission="manage" (accountChanged)="accountChanged($event)"></central-account-selector>
				</div>
			</dash-card>
			<div [hidden]="!this.hasCard()">
				<dash-card *ngIf="this.fetchState === 'success'" disabled="1" cardTitle="Payment Method"
					icon="credit_card" class="{{ this.cardClass }} billing-address">
					<div class="payment-method">
						<mat-form-field appearance="fill">
							<mat-label>Saved Billing Details</mat-label>
							<mat-select [(value)]="selectedMethod">
								<mat-option [value]="this.paymentMethod.last_four" *ngIf="this.hasCard() && this.phoneIsValid164">
									{{
									this.paymentMethod.card_type +
									' ending in ' +
									this.paymentMethod.last_four
									}}
								</mat-option>
								<mat-option [value]="'new'">
									Use New Card
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div *ngIf="selectedMethod !== 'new'" class="payment-terms">
						<div class="alert alert-default terms-alert">
							<span *ngIf="hasRecurring">
								By ordering you are agreeing to our complete
								<a href="{{
										this.configurationService.config
											.brandConfig.termsOfService
									}}" target="_blank">Terms of Service</a>. You authorize us to charge your card now, and
								at the time of each renewal period. You can
								cancel this subscription at any time by
								e-mailing support&#64;inmotionhosting.com.
							</span>
							<span *ngIf="!this.hasRecurring">
								By ordering you are agreeing to our complete
								<a href="{{
										this.configurationService.config
											.brandConfig.termsOfService
									}}" target="_blank">Terms of Service.</a>
							</span>
							<div class="error" *ngIf="invalidInputState(termsInput)">
								You must accept Terms of Service
							</div>
							<p>
								<mat-checkbox #termsInput="ngModel" name="accept-agreement"
									[(ngModel)]="this.termsAgree" class="accept-agreement" required>
									I Agree
								</mat-checkbox>
							</p>
						</div>
						<p class="ctw-text-right must-signin" [hidden]="
								!contactInputs.emailInput?.requiresSignin()
							">
							<mat-icon class="error">warning</mat-icon> You must
							<a class="sign-in" (click)="
									contactInputs.emailInput?.opendDialogWithErrors()
								">sign in</a>
							to submit an order for
							<strong>{{
								contactInputs.emailInput?.value || ''
								}}</strong>.
						</p>
						<div class="ctw-text-right action-buttons">
							<button *ngIf="includeBackButton" [disabled]="state === 'submitted'" mat-button mat-ripple
								type="button" (click)="this.paymentBack.emit()">
								Back
							</button>
							<central-progress-button color="primary" [disabled]="
									!!contactInputs.emailInput?.requiresSignin()
								" [state]="captchaSubmitted ? 'submitted' : ''" class="ctw-ml-3">Submit Order</central-progress-button>
						</div>
					</div>
				</dash-card>
			</div>
			<dash-card [hidden]="this.hideContactform()" disabled="1"
				[cardTitle]="this.checkoutMode === 'amp' ? 'Account Information' : 'Billing Address'"
				icon="person_pin_circle" class="{{ this.cardClass }} billing-address">
				<bg-contact class="bg-contact" #contactInputs [excludedInputs]="this.excludedInputs"
					[forceLogin]="this.forceLogin" [loginModalTitle]="this.loginModalTitle"
					[useEmailInput]="this.checkoutMode === 'default'" [readOnlyForm]="readOnlyForm"
					[loginModalSubTitle]="this.loginModalSubTitle" [minimalForm]="this.minimalForm"
					[organizationEmail]="this.organizationEmail" [vatTax]="this.vatTax"></bg-contact>
			</dash-card>
			<dash-card [hidden]="this.hideAccountType()" disabled="1" cardTitle="Account Type (Internal Only)"
				icon="lists" class="{{ this.cardClass }} account-type">
				<div class="ctw-p-8" *ngIf="this.internalIp && this.selectedMethod === 'new'">
					<mat-radio-group class="ctw-w-full ctw-flex ctw-flex-col sm:ctw-flex-row ctw-flex-wrap"
						[(ngModel)]="this.customerType" name="customerType" aria-label="Customer Type">
						<mat-radio-button class="ctw-mr-5 ctw-mb-3" *ngFor="let ct of ['Live','Test','Employee','Comp']"
							[value]="ct">
							<span>{{ct}}</span>
						</mat-radio-button>
					</mat-radio-group>
				</div>
			</dash-card>
			<dash-card [hidden]="this.hideNewPaymentMethod()" disabled="1" cardTitle="Payment Method" icon="credit_card"
				class="{{ this.cardClass }}">
				<img class="secure-server" src="assets/img/payment-icons/order-secure-server-lock.png"
					alt="Secure Server Lock" title="Secure Server Lock" />
				<div class="ctw-p-8" *ngIf="this.checkoutMode === 'amp'">
					<amp-payment-method #ampPayment (paymentMethodChanged)="changePaymentMethod()"
						(displayNewCard)="displayAddNewCardDialog($event)"></amp-payment-method>
					<div class="alert alert-default terms-alert">
						<div class="ctw-leading-tight">
							<span class="ctw-font-semibold">Agreement</span>
							<p>By clicking “I AGREE,” you are placing an order for the product(s) listed and agree to
								our
								<a href="{{
									this.configurationService.config.brandConfig
										.termsOfService
								}}" target="_blank">Terms of Service</a> and
								<a href="{{
								this.configurationService.config.brandConfig
									.privacyPolicy
							}}" target="_blank">Privacy Policy</a>, which describe our renewal terms and cancellation policy.
							</p>
							<p *ngIf="getAmpPaymentMethod() === 'Credit Card'">ALL PLANS AND PRODUCTS AUTOMATICALLY
								RENEW UNLESS YOU CANCEL.</p>
							<p>
								<span *ngIf="getAmpPaymentMethod() === 'Credit Card'">
									You agree that <strong>{{ ampCartTotal }} {{ this.cart?.currency ?
										this.cart.currency : 'USD'}} </strong>
									will be charged to your credit card upon approved account activation.
									You authorize your payment method to be charged on a recurring basis until you
									cancel.
								</span>
								<span *ngIf="getAmpPaymentMethod() === 'Check'">
									We will invoice you by email for <strong>{{ ampCartTotal }} {{ this.cart?.currency ?
										this.cart.currency : 'USD'}}</strong>,
									and will activate or renew your approved account upon receipt of payment.
								</span>
							</p>
						</div>
						<div class="error" *ngIf="invalidInputState(termsInput)">
							You must accept Terms of Service
						</div>
						<p>
							<mat-checkbox #termsInput="ngModel" name="accept-agreement" [(ngModel)]="this.termsAgree"
								(change)="ampOrderValid()" class="accept-agreement" required>
								I Agree
							</mat-checkbox>
						</p>
					</div>
					<div class="ctw-text-right action-buttons">
						<button *ngIf="includeBackButton" [disabled]="state === 'submitted'" mat-button mat-ripple
							type="button" (click)="this.paymentBack.emit()">
							Back
						</button>
						<central-progress-button class="ctw-ml-3" color="primary" [disabled]="ampOrderValid() === false"
							*ngIf="this.showSubmitButton" [state]="captchaSubmitted ? 'submitted' : ''">
							Submit Order
						</central-progress-button>
						<paypal-button (paypalResult)="handlePaypalResult($event)"></paypal-button>
					</div>
					<re-captcha #captcha (resolved)="captchaSubmitted = false; submit($event)" class="g-recaptcha"
						size="invisible" captchaSubmitted siteKey="{{ captchaKey }}"></re-captcha>
				</div>
				<div class="content" *ngIf="this.checkoutMode === 'default'">
					<central-recurly-credit-card class="central-recurly-credit-card"
						*ngIf="this.checkoutMode ==='default'"></central-recurly-credit-card>
					<div *ngIf="selectedMethod === 'new'" class="alert alert-default terms-alert ctw-mx-4">
						<span *ngIf="hasRecurring">
							By ordering you are agreeing to our complete
							<a href="{{
									this.configurationService.config.brandConfig
										.termsOfService
								}}" target="_blank">Terms of Service</a>. You authorize us to charge your card now, and at
							the time of each renewal period. You can cancel this
							subscription at any time by e-mailing
							support&#64;inmotionhosting.com.
						</span>
						<span *ngIf="!this.hasRecurring">
							By ordering you are agreeing to our complete
							<a href="{{
									this.configurationService.config.brandConfig
										.termsOfService
								}}" target="_blank">Terms of Service<mat-icon
									class="newtab ctw-h-5 ctw-ml-1 ctw-mr-1 ctw-relative ctw-text-base ctw-text-imh-blue ctw-w-3.5">open_in_new</mat-icon></a>.
						</span>
						<div class="error" *ngIf="invalidInputState(termsInput)">
							You must accept Terms of Service
						</div>
						<p>
							<mat-checkbox #termsInput="ngModel" name="accept-agreement" [(ngModel)]="this.termsAgree"
								class="accept-agreement" required>
								I Agree
							</mat-checkbox>
						</p>
					</div>
					<p class="ctw-text-right must-signin" [hidden]="!contactInputs.emailInput?.requiresSignin()">
						<mat-icon class="error">warning</mat-icon> You must
						<a class="sign-in" (click)="
								contactInputs.emailInput?.opendDialogWithErrors()
							">sign in</a>
						to submit an order for
						<strong>{{
							contactInputs.emailInput?.value || ''
							}}</strong>.
					</p>
					<div class="ctw-text-right action-buttons ctw-mx-4 ctw-mb-4">
						<button *ngIf="includeBackButton" [disabled]="state === 'submitted'" mat-button mat-ripple
							type="button" (click)="this.paymentBack.emit()">
							Back
						</button>
						<central-progress-button class="ctw-ml-3" color="primary"
							[disabled]="!!contactInputs.emailInput?.requiresSignin()"
							[state]="captchaSubmitted ? 'submitted' : ''">
							{{ this.isTrial() ? 'Start Trial' : 'Submit Order' }}
						</central-progress-button>
					</div>
					<re-captcha #captcha (resolved)="captchaSubmitted = false; submit($event)" class="g-recaptcha"
						size="invisible" captchaSubmitted siteKey="{{ captchaKey }}"></re-captcha>
				</div>
			</dash-card>
		</form>
	</div>
	<div class="col config-column" [hidden]="this.checkoutMode !== 'amp' || this.hideAmpConfiguration">
		<amp-config [packages]="this.packages" (configChanged)="this.changedConfiguration($event)" 
		(configComplete)="this.configComplete.emit($event)" />
	</div>
	<div class="col cart-column" [hidden]="!showForms() && !this.forceLogin">
		<bg-cart *ngIf="!this.isPreviousOrder && this.packages" (cartValidation)="this.afterCartInit()"
			[ampDomain]="this.ampDomain" [term]="this.term" [packages]="this.packages"
			(cartUpdated)="handleCartUpdate()" #cart (cartTotalUpdated)="setCartTotal($event)"
			[discountObject]="this.getDiscountObject()" [dataCenter]="this.dataCenter" [dataCenters]="dataCenters"
			[couponData]="this.getCouponData()" [isUpdatePlan]="this.isUpdatePlan" [checkoutMode]="this.checkoutMode"
			[ampTerms]="this.ampTerms" [subscriptionUuid]="this.subscriptionUuid"
			[organizationId]="this.organizationId"></bg-cart>
		<bg-discount #discount hidden></bg-discount>
		<div *ngIf="(!hideGuarantee && !this.isUpdatePlan) || this.isAddPlan" class="purchase-info">
			<div *ngIf="'monthly' === this.term">
				<p><strong>30-Day Money-Back Guarantee</strong></p>
				<p>
					Our industry leading money back guarantee allows you to have
					peace of mind and to try our services risk-free for 30 days.
				</p>
			</div>
			<div *ngIf="'yearly' === this.term">
				<p><strong>90-Day Money-Back Guarantee</strong></p>
				<p>
					Our industry leading money back guarantee allows you to have
					peace of mind and to try our services risk-free for 90 days.
				</p>
			</div>
		</div>
		<div *ngIf="!this.isAddPlan && this.getCouponData()" class="purchase-info">
			<p class="ctw-text-center">A credit card is required to experience the UltraStack VPS.</p>
			<p class="ctw-text-center">Don't want to use a card? <a (click)="this.skipPurchase()">Explore Platform
					InMotion</a> instead.</p>
		</div>
	</div>
</div>
<ng-container *ngIf="state === 'success' && !this.disablePostPurchase">
	<post-purchase [account]="this.organizationId" [team]="this.accountService.account?.name" [project]="this.projectId"
		[returnWpUrl]="returnWpUrl" [products]="this.receipt.products" [receipt]="this.receipt.invoice">
		<p *ngIf="this.isNewUser">
			We've sent a confirmation email. Before you can log in to InMotion
			Central, you'll need to click the activation link within your email.
			If this address is incorrect, please
			<a [routerLink]="['/account/contact-info']">Update Your Email Address</a>
			now.
		</p>
	</post-purchase>
</ng-container>
<ng-container *ngIf="state === 'success' && this.checkoutMode === 'amp'">
	<op-receipt [receipt]="this.receipt" [ampButtonStatus]="this.showGoToAmpButton"></op-receipt>
</ng-container>
