declare let jQuery: any;

export class Post {
	public postForm(url: string, params: any, newTab = true) {
		const form = jQuery(`<form method='POST' style='display:none;'></form>`)
			.attr({
				action: url,
			})
			.appendTo(document.body);

		if (newTab) {
			form.attr('target', '_blank');
		}

		for (const i in params) {
			if (params.hasOwnProperty(i)) {
				jQuery('<input type="hidden" />')
					.attr({
						name: i,
						value: params[i],
					})
					.appendTo(form);
			}
		}

		form.submit();
		form.remove();
	}
}
