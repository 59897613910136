<div *ngIf="!this.isModal" [class.container]="this.standalone">
	<div *ngIf="status === 'pending' || status === 'submitted'">
		<form (ngSubmit)="this.resend()">
			<ng-container *ngIf="!token">
				<h3>Resend Confirmation Email?</h3>
				<p>
					Enter your email address &amp; we'll resend your
					confirmaiton email.
				</p>
				<mat-form-field>
					<input matInput class="validated" name="emailField" email [(ngModel)]="this.email"
						placeholder="e.g. youremail@gmail.com" dividerColor="accent" type="email" ngDefaultControl
						required #emailField="ngModel" />
					<mat-hint align="start" class="animated" [class.slideInUp]="
							!emailField.valid &&
							emailField.dirty &&
							emailField.touched
						">
						You must provide a valid email.
					</mat-hint>
				</mat-form-field>
			</ng-container>
			<ng-container *ngIf="token">
				<p class="title">Account Confirmation</p>
				<p>
					It looks like this account isn't completely set up yet. You
					should have received a "Welcome" email. Please click the
					getting started link in the email to complete the setup
					process.
					<span class="resend">If you didn't receive it, click the button below.</span>
				</p>
			</ng-container>
			<div class="action-buttons">
				<button type="button" [disabled]="'submitted' === this.status" (click)="this.onCompleteAction()"
					mat-raised-button>
					Go Back
				</button>
				<progress-button color="primary" [state]="this.status">Send Email</progress-button>
			</div>
		</form>
	</div>

	<div *ngIf="status === 'success'">
		<p class="title">Sent! Check Your Email</p>
		<p>
			Head over to your mailbox to get your welcome email and complete
			account setup.
		</p>
		<div class="action-buttons">
			<button mat-raised-button color="primary" (click)="this.onCompleteAction()">
				Okay, Got It!
			</button>
		</div>
	</div>

	<div *ngIf="status === 'failed'">
		<p class="title">Something went wrong.</p>
		<p>
			We're sorry, an unexpected issue occurred while processing your
			request. Please try again and see if it corrects your problem.
		</p>
		<div class="action-buttons">
			<button mat-raised-button color="accent" (click)="this.onCompleteAction()">
				Okay
			</button>
		</div>
	</div>
</div>

<div *ngIf="this.isModal" class="ctw-text-center">
	<div *ngIf="status === 'pending' || status === 'submitted'">
		<form (ngSubmit)="this.resend()">
			<ng-container *ngIf="!token">
				<h3>Resend Confirmation Email?</h3>
				<p>
					Enter your email address &amp; we'll resend your
					confirmaiton email.
				</p>
				<mat-form-field>
					<input matInput class="validated" name="emailField" email [(ngModel)]="this.email"
						placeholder="e.g. youremail@gmail.com" dividerColor="accent" type="email" ngDefaultControl
						required #emailField="ngModel" />
					<mat-hint align="start" class="animated" [class.slideInUp]="
							!emailField.valid &&
							emailField.dirty &&
							emailField.touched
						">
						You must provide a valid email.
					</mat-hint>
				</mat-form-field>
			</ng-container>
			<ng-container *ngIf="token">
				<h2 class="title ctw-font-bold">Welcome Back!</h2>
				<p>
					You were sent an <span class="ctw-font-bold">Account Confirmation</span> email during your last
					visit. Please click the Getting Started link in the email to finish setting up your account or have
					another resent below.
				</p>
			</ng-container>
			<div class="action-buttons">
				<button type="button" [disabled]="'submitted' === this.status" (click)="this.onCompleteAction()"
					mat-raised-button>
					Go Back
				</button>
				<progress-button color="primary" [state]="this.status"><mat-icon>email</mat-icon> Resend
					Email</progress-button>
			</div>
		</form>
	</div>

	<div *ngIf="status === 'success'">
		<h2 class="title ctw-font-bold">Sent!</h2>
		<p>
			Head over to your mailbox to get your <span class="ctw-font-bold">Account Confirmation</span> email and
			complete account setup.
		</p>
		<div class="action-buttons ctw-text-right">
			<button mat-raised-button color="primary" (click)="this.onCompleteAction()" class="ctw-w-full">
				Okay, Got It!
			</button>
		</div>
	</div>

	<div *ngIf="status === 'failed'">
		<h2 class="title ctw-font-bold ctw-text-center"> Oops...</h2>
		<p>
			We're sorry, an unexpected issue occurred while processing your
			request. Please try again and see if it corrects your problem.
		</p>
		<div class="action-buttons ctw-text-right">
			<button mat-raised-button color="primary" (click)="this.onCompleteAction()" class="ctw-w-full">
				Okay
			</button>
		</div>
	</div>
</div>
