import { Location } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { Animations } from '../../shared/animation/animations';
import { ApiService } from '@central/ng-shared';
import { FormModel } from './forgot-password.model';

@Component({
	selector: 'forgot-password',
	templateUrl: 'forgot-password.component.html',
	styleUrls: ['forgot-password.component.scss'],
	animations: Animations.unauth,
})
export class ForgotPasswordComponent {
	@HostBinding('class.collpased-logo') public collpasedLogo = true;
	@HostBinding('@routeAnimation') public animation = 'true';

	public model = new FormModel('');
	public state = 'pending';

	constructor(public apiService: ApiService, public location: Location) {}

	public success() {
		this.state = 'success';
	}

	public fail() {
		this.state = 'fail';
	}

	public onSubmit(isValid: boolean) {
		if (this.state !== 'submitted') {
			if (isValid) {
				this.resetPassword();
			} else {
				this.state = 'fail';
			}
		}
	}

	public resetPassword() {
		this.state = 'submitted';

		this.apiService
			.post('resetPassword', { email: this.model.email })
			.subscribe(
				(response) => {
					const result = response['result'];

					if (result && result.data && result.data.success) {
						this.success();
					} else {
						this.fail();
					}
				},
				(err) => {
					this.fail();
				}
			);
	}
}
