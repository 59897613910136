import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmailComponent } from './email.component';
import { EmailNewComponent } from './email-new/email-new.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: EmailComponent,
	},
	{
		path: 'new',
		pathMatch: 'full',
		component: EmailNewComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EmailRoutingModule {}
