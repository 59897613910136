import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RollbarService } from '@central/ng-shared';

/**
 * This class represents the navigation bar component.
 */
@Component({
	selector: 'page-not-found',
	templateUrl: 'page-not-found.component.html',
	styles: [
		`
			.page-not-found {
				margin-top: 8%;
			}
			.container {
				width: 100%;
			}
			p {
				font-size: 1.4em;
				margin-bottom: 1.5em;
			}
			.centered-content {
				position: relative;
				z-index: 1;
			}
			img {
				width: 200px;
				position: relative;
				left: -10px;
			}
		`,
	],
})
export class PageNotFoundComponent implements OnInit {
	@HostBinding('class.animated') public animated = true;
	@HostBinding('class.fadeIn') public fadeIn = true;
	@HostBinding('class.host-transition') public hostTransition = true;

	constructor(
		public rollbarService: RollbarService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.rollbarService.rollbar.warn( 'Page or route not found.', {route: this.router.url, status: 404});
	}
}
