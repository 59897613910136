import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApiService, ConfigurationService } from '../core';
import { AuthService, ProfileService } from '../authentication/services';
import { LocalStorageService } from "../core/local-storage/local-storage.service";

@Injectable()
export class AccountService {
	public authAccountUpdate = new BehaviorSubject({});
	public id;
	public account;
	public access;
	public isUser;

	public roles = {
		read: ['owner', 'maintainer', 'member'],
		write: ['owner', 'maintainer'],
		manage: ['owner'],
	};

	private messageSource = new BehaviorSubject('default message source');
	currentMessage = this.messageSource.asObservable();

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public localStorage: LocalStorageService,
		public configService: ConfigurationService,
		public profileService: ProfileService
	) {}

	public fetch(accountId: string) {
		return new Observable((observer) => {
			if (this.account && this.id === accountId) {
				this.authAccountUpdate.next(this.account);
				observer.next(this.account);
				observer.complete();
			} else {
				if (this.configService.config.brandConfig.id === 'imh') {
					const fetchAccount = (acctId) => {
						this.apiService.get('/v1/accounts/' + acctId, {}).subscribe(
							(account) => {
								this.id = acctId;
								this.account = account;
								this.authAccountUpdate.next(account);
								if (this.isOrganization() && this.configService.config.brandConfig.id === 'imh') {
									this.localStorage.setItem('team', this.account.name);
									if (acctId !== this.profileService.data.options.public_selected_team) {
										this.profileService.update({ public_selected_team: acctId }).subscribe();
									}
								}
								observer.next(this.account);
								observer.complete();
							},
							(error) => {
								this.account = null;
								this.id = null;
								observer.error(error);
							}
						);
					}
					if (accountId) {
						fetchAccount(accountId);
					} else {
						// Customer reloaded their logged-in user's settings page, or naviated directly to it
						// from outside of the application. accountId will be undefined when this services fetch
						// method is called, rendering a non-functioning view. We need to try again once auth completes.
						this.profileService.onReady().subscribe(() => {
							fetchAccount(this.profileService.data.account_access[0].account_id)
						})
					}
				} else {
					this.apiService.get('/v1/accounts/' + accountId, {}).subscribe(
						(account) => {
							this.id = accountId;
							this.account = account;
							this.authAccountUpdate.next(account);
							observer.next(this.account);
							observer.complete();
						},
						(error) => {
							this.account = null;
							this.id = null;
							observer.error(error);
						}
					);
				}
			}
		});
	}

	public getResourceId() {
		return this.id;
	}

	public isUserAccountOwner() {
		// eslint-disable-next-line eqeqeq
		return this.id == this.authService.getAccountId();
    }

	public updateAccount(updateAccount) {
		this.account = updateAccount;
		this.messageSource.next(updateAccount);
	}

	public isOrganization() {
		if (this.configService.config.brandConfig.id === 'imh') {
			return !this.isUser;
		}
		return !!this.account?.organization_id;
    }

    public isUserOrganization() {
        return this.profileService.data.type === 'org'
    }

	public hasAccess(action) {
		const currentAccount =
			this.profileService.data.account_access.find(
				(account) => account.account_id === this.getResourceId()
			);

		return -1 !== this.roles[action].indexOf(currentAccount?.role);
	}
}
