import { ErrorHandler, Injectable } from '@angular/core';
import { RollbarService } from './rollbar.service';
import _debounce from 'lodash/debounce';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	private trackDebounced: any;

	/**
	 * Maximum number of errors to report per page load.
	 *
	 * @since 1.11.0
	 */
	private maxErrors = 20;

	/**
	 * Number of errors reported.
	 *
	 * @since 1.11.0
	 */
	private errorCount = 0;

	constructor(public rollbarService: RollbarService) {
		this.trackDebounced = _debounce((err: any) => {
			// This is a failsafe to prevent over reporting.
			if (this.errorCount < this.maxErrors) {
				this.errorCount++;
				this.rollbarService.rollbar.error(err.originalError || err);
			}
		}, 500);
	}

	handleError(err: any): void {
		this.trackDebounced(err);
		console.error(err);
	}
}
