// eslint-disable-next-line
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BgQueryEncoder } from './query.encoder';

export class Format {
	public config;

	public constructor(config) {
		this.config = config;
	}

	/**
	 * Get default headers for use with Bg API.
	 *
	 * @since 1.11.0
	 *
	 * @return object Headers.
	 */
	public getHeaders() {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded',
			}),
		};
	}

	/**
	 * Get the Body for form-urlencoded requests.
	 *
	 * @since 1.11.0
	 *
	 * @param  requestData Object.
	 * @return             HttpParams.
	 */
	public getBody(requestData: object): HttpParams {
		let urlSearchParams = new HttpParams({ encoder: new BgQueryEncoder() });

		Object.entries(requestData).forEach(([key, value]) => {
			urlSearchParams = urlSearchParams.append(key, value);
		});

		return urlSearchParams;
	}

	/**
	 * Get the URL for the endpoint based on its name.
	 *
	 * @since 1.11.0
	 *
	 * @param  name Name of endpoint.
	 * @return      Full Url.
	 */
	public getUrl(name: string): string {
		let host = this.config['host'];
		let apiCall = this.config.apiData['apiCalls'][name];

		if (this.config.useMocks) {
			host = '';
			apiCall = this.config.apiData['mockData'][name];
		}

		if (!apiCall) {
			apiCall = name;
		}

		return host + apiCall;
	}
}
