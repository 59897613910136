<h2 mat-dialog-title class="ctw-capatalize">
	<ng-container *ngIf="!this.replaceKeyView">Connect Key</ng-container>
	<ng-container *ngIf="this.replaceKeyView">
		<span *ngIf="this.state !== 'success'">Are you sure?</span>
		<span *ngIf="this.state === 'success'">Connect Key Replaced</span>
	</ng-container>
</h2>
<mat-dialog-content>
	<div *ngIf="!this.replaceKeyView">
		<p>
			This key is used to connect your website to Platform I. It
			should already be saved in your WordPress settings, so you only need
			to enter it if prompted.
		</p>
		<mat-form-field appearance="outline">
			<mat-label>Central Connect Key</mat-label>
			<input class="connect-key" matInput readonly [(ngModel)]="this.connectKey" />
			<button class="ctw-absolute ctw-top-0 ctw-right-0" mat-raised-button [matTooltip]="this.copyText"
				matTooltipPosition="above" (click)="copy()">
				Copy
			</button>
		</mat-form-field>
	</div>
	<div *ngIf="this.replaceKeyView">
		<div *ngIf="this.state !== 'success'" [class.success]="state === 'success'">
			<p class="alert ctw-mb-6">
				<strong class="error">WARNING</strong>: Your current Connect Key
				will become invalid. You must update your WordPress
				installation(s) with the new key.
			</p>
			<p *ngIf="errorMessage" class="error animate__animated animate__fadeInUp">
				Error: {{ errorMessage }}
			</p>
			<form #confirmationForm="ngForm" novalidate (ngSubmit)="onSubmit(confirmation.value === true)"
				class="ctw-ml-3">
				<mat-checkbox name="confirmation" required [(ngModel)]="model.confirmCheck" #confirmation="ngModel">
					Yes, I would like to replace my existing Connect Key.
				</mat-checkbox>
				<div class="error" *ngIf="confirmation.value !== true && state === 'fail'">
					* Click the checkbox to continue.
				</div>
			</form>
		</div>
		<div *ngIf="this.state === 'success'" class="animate__animated animate__fadeIn">
			<p>
				Your Connect Key has been replaced. The previous Connect Key is
				no longer valid. You'll be prompted to update your Connect Key
				when you log in to your WordPress dashboard.
			</p>
			<mat-form-field appearance="outline">
				<mat-label>New Connect Key</mat-label>
				<input class="new-connect-key" matInput readonly [(ngModel)]="this.newConnectKey" />
				<button class="ctw-absolute ctw-top-0 ctw-right-0" mat-raised-button [matTooltip]="this.copyText"
					matTooltipPosition="above" (click)="copy()">
					Copy
				</button>
			</mat-form-field>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-pb-5">
	<button *ngIf="
			!this.replaceKeyView ||
			(this.state !== 'success' && this.replaceKeyView)
		" mat-raised-button mat-ripple type="button" [mat-dialog-close]="true" color="primary">
		OK
	</button>
	<button *ngIf="!this.replaceKeyView && 0" (click)="replaceKey()" mat-raised-button color="warn">
		Replace Key
	</button>
	<button *ngIf="this.state === 'success' && this.replaceKeyView" mat-raised-button mat-ripple type="button"
		color="primary" [mat-dialog-close]="true">
		Okay, Got It!
	</button>
	<button *ngIf="this.state !== 'success' && this.replaceKeyView" mat-raised-button mat-raised-button mat-ripple
		color="warn" (click)="this.ngForm.ngSubmit.emit()" [disabled]="state === 'submitted'"
		class="btn-spinner ctw-flex ctw-my-4">
		<mat-spinner diameter="20" *ngIf="state === 'submitted'"></mat-spinner>Continue
	</button>
</mat-dialog-actions>
