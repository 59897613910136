import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BrandingService, ProfileService } from '@central/ng-shared';
import { AppService } from '../../app.service';

@Injectable()
export class MetaService {
	public baseDescription = '`';
	public configs = {};

	constructor(
		private router: Router,
		private location: Location,
		private meta: Meta,
		private titleService: Title,
		private brandingService: BrandingService,
		private appService: AppService,
		private profileService: ProfileService,
	) {
		this.baseDescription = this.getBaseDescription();
		this.configs = this.getConfigs();
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.navigationChange(event);
			}
		});
		this.profileService.onProfileUpdate.subscribe(() => {
			const metaValues =
			this.findMatchingRoute(location.path()) || this.getConfigs()['default'];

		for (const metaValue of metaValues) {
			this.meta.updateTag(metaValue);

			if ('title' === metaValue.name) {
				this.titleService.setTitle(metaValue.content);
			}
		}
		})
	}

	public getConfigs() {
		return {
			default: [
				{
					name: 'title',
					content: this.getAppName(),
				},
				{
					name: 'og:title',
					content: this.getAppName(),
				},
				{
					name: 'description',
					content: this.baseDescription,
				},
			],
			'/login': [
				{
					name: 'title',
					content: 'Login | ' + this.getAppName(),
				},
				{
					name: 'og:title',
					content: 'Login | ' + this.getAppName(),
				},
				{
					name: 'description',
					content:
						`Log in to your ${this.getAppName()} account. ${this.baseDescription}`,
				},
			],
			'/forgot-password': [
				{
					name: 'title',
					content:
						'Reset Password | ' + this.getAppName(),
				},
				{
					name: 'og:title',
					content:
						'Reset Password | ' + this.getAppName(),
				},
				{
					name: 'description',
					content:
						'Reset your InMotion Central Password. ' +
						this.baseDescription,
				},
			],
			'/checkout$': [
				{
					name: 'title',
					content: 'Checkout | ' + this.getAppName(),
				},
				{
					name: 'og:title',
					content: 'Checkout | ' + this.getAppName(),
				},
				{
					name: 'robots',
					content: 'noindex, nofollow',
				},
			],
			'/projects/.*/setup': [
				{
					name: 'title',
					content:
						'Get Started | ' + this.getAppName(),
				},
			],
			'/projects/.*/overview': [
				{
					name: 'title',
					content:
						'Site Overview | ' + this.getAppName(),
				},
			],
			'/projects/.*/recover': [
				{
					name: 'title',
					content: 'Site Recovery | ' + this.getAppName(),
				},
			],
			'/projects/.*/optimize': [
				{
					name: 'title',
					content:
						'Site Optimizations | ' + this.getAppName(),
				},
			],
			'/projects/.*': [
				{
					name: 'title',
					content:
						'Project Dashboard | ' +
						this.getAppName(),
				},
			],
			'/projects': [
				{
					name: 'title',
					content:
						this.getTeamName() + ' Projects | ' + this.getAppName(),
				},
			],
			'/domains/manage/.*/dns': [
				{
					name: 'title',
					content:
						'DNS Records | ' + this.getAppName(),
				},
			],
			'/domains/manage/.*': [
				{
					name: 'title',
					content:
						'Registration Details | ' + this.getAppName(),
				},
			],
			'/domains/manage': [
				{
					name: 'title',
					content:
						this.getTeamName() + ' Domains | ' + this.getAppName(),
				},
			],
			'/domains': [
				{
					name: 'title',
					content: 'Find a New Domain | ' + this.getAppName(),
				},
			],
			'/email/new': [
				{
					name: 'title',
					content:
						'New Email Subscription | ' +
						this.getAppName(),
				},
			],
			'/email': [
				{
					name: 'title',
					content: this.getTeamName() + ' Email | ' + this.getAppName(),
				},
			],
			'/account/preferences': [
				{
					name: 'title',
					content:
						'My Preferences | ' + this.getAppName(),
				},
			],
			'/account/memberships': [
				{
					name: 'title',
					content:
						'My Teams | ' + this.getAppName(),
				},
			],
			'/account/notifications': [
				{
					name: 'title',
					content:
						'My Notifications | ' + this.getAppName(),
				},
			],
			'/team/.*/receipts/.*': [
				{
					name: 'title',
					content: 'Receipt | ' + this.getAppName(),
				},
			],
			'/team/.*/receipts': [
				{
					name: 'title',
					content: this.getTeamName() + ' Receipts | ' + this.getAppName(),
				},
			],
			'/team/.*/subscriptions': [
				{
					name: 'title',
					content: this.getTeamName() + ' Subscriptions | ' + this.getAppName(),
				},
			],
			'/team/.*/help/requests': [
				{
					name: 'title',
					content: this.getTeamName() + ' Support Requests | ' + this.getAppName(),
				},
			],
			'/team/.*/preferences': [
				{
					name: 'title',
					content: this.getTeamName() + ' Preferences | ' + this.getAppName(),
				},
			],
			'/team/.*/members': [
				{
					name: 'title',
					content: this.getTeamName() + ' Members | ' + this.getAppName(),
				},
			],
			'/amp/checkout': [
				{
					name: 'title',
					content:
						'Secure Order Process - Configure your Hosting Plan | ' + this.getAppName(),
				},
				{
					name: 'og:title',
					content:
						'Secure Order Process - Configure your Hosting Plan | ' + this.getAppName(),
				},
				{
					name: 'description',
					content: '',
				},
			],
		};
	}

	/**
	 * Each time the user navigates to a new page update the meta values.
	 *
	 * @since 1.4.3
	 *
	 * @param event Navigation Event.
	 */
	public navigationChange(event: NavigationEnd) {
		let url = event.urlAfterRedirects ? event.urlAfterRedirects : event.url;
		url = url && url.split ? url.split('?')[0] : '';
		const metaValues =
			this.findMatchingRoute(url) || this.getConfigs()['default'];

		for (const metaValue of metaValues) {
			this.meta.updateTag(metaValue);

			if ('title' === metaValue.name) {
				this.titleService.setTitle(metaValue.content);
			}
		}
	}

	/**
	 * Find a route from the config.
	 *
	 * @since 1.13.0
	 *
	 * @param  url URL of route
	 * @return     Route Name.
	 */
	private findMatchingRoute(url: string): any[] {
		let route;

		for (const routeName of Object.keys(this.configs)) {
			if (-1 !== url.search(routeName)) {
				route = this.getConfigs()[routeName];
				break;
			}
		}

		return route;
	}

	private getAppName() {
		if (this.location.path().match(/^\/amp/)) {
			return 'InMotion Hosting, Inc.';
		}
		return this.brandingService.getAppName();
	}

	private getBaseDescription() {
		if (this.location.path().match(/^\/amp/)) {
			return `Configure your plan`;
		}
		return `Host and manage your WordPress websites with ${this.getAppName()}. Create, stage, and launch WordPress sites from one dashboard.`;
	}

	private getTeamName() {
		return this.appService.sessionStorage.getItem('team') || 'Team';
	}
}
