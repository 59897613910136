<mat-expansion-panel #matPanel class="dash-card card ctw-m-0" (opened)="this.opened.emit()" [expanded]="this.expanded"
	[disabled]="this.disabled" [class.notranslate]="this.notranslate" [class.transparent]="this.transparent">
	<mat-expansion-panel-header *ngIf="this.showHeader" collapsedHeight="auto" expandedHeight="auto"
		[class.ctw-bg-neutral-200]="!this.clean">
		<mat-panel-title class="dash-card-title valign-wrapper ctw-cursor-default" [class.ctw-mr-0]="disabled">

			<span *ngIf="customIcon" class="{{ customIcon }} ctw-text-imh-gray-200"></span>
			<svg *ngIf="icon === 'blueprint'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
				viewBox="0 0 60 60" fill="none">
				<path d="M5 23.75C5 21.6789 6.67893 20 8.75 20H10V25H5V23.75Z" fill="black"></path>
				<rect x="5" y="30" width="5" height="5" fill="black"></rect>
				<rect x="15" y="20" width="5" height="5" fill="black"></rect>
				<rect x="35" y="40" width="5" height="5" fill="black"></rect>
				<rect x="5" y="40" width="5" height="5" fill="black"></rect>
				<path d="M5 50H10V55H8.75C6.67893 55 5 53.3211 5 51.25V50Z" fill="black"></path>
				<rect x="15" y="50" width="5" height="5" fill="black"></rect>
				<rect x="25" y="50" width="5" height="5" fill="black"></rect>
				<path d="M35 50H40V51.25C40 53.3211 38.3211 55 36.25 55H35V50Z" fill="black"></path>
				<rect x="17.5" y="5" width="37.5" height="37.5" rx="3.75" fill="black"></rect>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M31.6761 24.042L34.109 29.3945L36.542 24.042L41.8945 21.609L36.542 19.1761L34.109 13.8235L31.6761 19.1761L26.3235 21.609L31.6761 24.042ZM41.8945 31.3408L41.8945 31.3409L41.8946 31.3408C41.8946 31.3408 41.8945 31.3408 41.8945 31.3408C41.8945 31.3408 41.8945 31.3408 41.8945 31.3408Z"
					fill="white"></path>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M31.6761 24.042L34.109 29.3945L36.542 24.042L41.8945 21.609L36.542 19.1761L34.109 13.8235L31.6761 19.1761L26.3235 21.609L31.6761 24.042ZM41.8945 31.3408L41.8945 31.3409L41.8946 31.3408C41.8946 31.3408 41.8945 31.3408 41.8945 31.3408C41.8945 31.3408 41.8945 31.3408 41.8945 31.3408Z"
					fill="white"></path>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M40.2725 31.0164L41.8945 34.5848L43.5165 31.0164L47.0848 29.3944L43.5165 27.7725L41.8945 24.2041L40.2725 27.7725L36.7042 29.3944L40.2725 31.0164ZM47.084 35.8805L47.0848 35.8823L47.0857 35.8805C47.0851 35.8805 47.0845 35.8805 47.084 35.8805ZM44.4897 33.2872C44.4897 33.2872 44.4897 33.2872 44.4897 33.2872L44.4897 33.2872L44.4897 33.2872ZM49.6781 33.2863C49.6781 33.2869 49.6781 33.2875 49.6781 33.288L49.68 33.2872L49.6781 33.2863Z"
					fill="white"></path>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M40.2725 31.0164L41.8945 34.5848L43.5165 31.0164L47.0848 29.3944L43.5165 27.7725L41.8945 24.2041L40.2725 27.7725L36.7042 29.3944L40.2725 31.0164ZM47.084 35.8805L47.0848 35.8823L47.0857 35.8805C47.0851 35.8805 47.0845 35.8805 47.084 35.8805ZM44.4897 33.2872C44.4897 33.2872 44.4897 33.2872 44.4897 33.2872L44.4897 33.2872L44.4897 33.2872ZM49.6781 33.2863C49.6781 33.2869 49.6781 33.2875 49.6781 33.288L49.68 33.2872L49.6781 33.2863Z"
					fill="white"></path>
			</svg>
			<mat-icon class="ctw-z-10 ctw-text-imh-gray-200" *ngIf="icon !== 'blueprint'">{{ icon }}</mat-icon>

			<span class="valign title-text ctw-flex">
				<span class="title ctw-capitalize" *ngIf="cardTitle">{{ cardTitle }}</span>
				<ng-container *ngIf="!isTemplate">
					<ng-container *ngFor="let sub of subTitle">
						<span class="sub-title ctw-text-base ctw-flex" *ngIf="sub">
							{{ sub }}
						</span>
					</ng-container>
					<ng-content select="[subTitleHTML]"></ng-content>
				</ng-container>
				<ng-container *ngIf="isTemplate">
					<ng-container *ngTemplateOutlet="subTitle"></ng-container>
				</ng-container>
			</span>
			<a *ngIf="iframe" class="address-bar ctw-inline-block ctw-text-base ctw-z-10"
				[class]="iframe.includes('https') || iframe.includes('trial-') ? 'ssl' : ''" href="{{iframe }}"
				target="_blank">
				<div>{{ iframe.match('demo3.cloudwp.dev') || iframe.match('demo3.playgrounds.cloud') ?
					iframe.split('/')[3] : iframe }}</div>
				<mat-icon
					class="ctw-text-sm ctw-float-right ctw-p-1 ctw-absolute ctw-top-3.5 ctw-right-4 ctw-mr-2">open_in_new</mat-icon>
			</a>

			<span help1 *ngIf="help && this.centralArticle && !this.transparent" (click)="this.openHelp()"
				class="ctw-flex ctw-ml-auto ctw-cursor-pointer">
				<mat-icon class="ctw-z-10">{{this.centralArticle.youtube ? 'smart_display' : 'help' }}</mat-icon>
			</span>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<ng-template matExpansionPanelContent>
		<span help2 *ngIf="help && this.centralArticle && this.transparent" (click)="this.openHelp()"
			class="ctw-absolute ctw-opacity-50 help ctw-cursor-pointer" matTooltip="Learn More">
			<mat-icon class="ctw-z-10">{{this.centralArticle.youtube ? 'smart_display' : 'help' }}</mat-icon>
		</span>
		<div>
			<ng-content></ng-content>
		</div>
	</ng-template>
</mat-expansion-panel>
