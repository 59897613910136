import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from '../../../core/api/api.service';
import { ProfileService } from '../../../authentication/services/profile/profile.service';
import { ConfigurationService } from '../../../core/configuration.service';

@Component({
	selector: 'central-leave-organization-dialog',
	templateUrl: './leave-organization-dialog.component.html',
	styleUrls: ['./leave-organization-dialog.component.scss'],
})
export class LeaveOrganizationDialogComponent {
	public state = 'pending';
	public errorMessage = '';

	constructor(
		private apiService: ApiService,
		public profileService: ProfileService,
		public configurationService: ConfigurationService,
		public dialogRef: MatDialogRef<LeaveOrganizationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	public leaveOrganization() {
		this.errorMessage = '';
		this.state = 'submitted';
		this.apiService
			.delete(
				`/v1/organizations/` +
					this.data.organizationId +
					'/memberships/' +
					this.data.membershipId,
				{}
			)
			.subscribe({
				next: () => {
					this.state = 'success';
					this.profileService.fetch();
					this.dialogRef.close();
				},
				error: (error) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
			});
	}
}
