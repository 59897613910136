<ng-container *ngIf="'loading' === componentState"></ng-container>
<ng-container *ngIf="this.status === 'ready' && 'success' !== componentState"><mat-spinner diameter="24"
		color="primary"></mat-spinner>Loading Domains...
</ng-container>

<p *ngIf="'error' === componentState">
	Error loading domains
	<button class="simple-button" (click)="fetchDomains()">[try again]</button>
</p>

<ng-container *ngIf="'success' === componentState">
	<mat-form-field *ngIf="this.status === 'ready'" appearance="outline">
		<mat-label>{{this.label}}</mat-label>
		<mat-select [disabled]="this.disabled" [(ngModel)]="selectedDomainName"
			(ngModelChange)="domainSelectChanged(selectedDomainName)"
			[class.validating]="this.validating === 'submitted'">
			<mat-option *ngIf="!options.length" disabled>No Domains Found</mat-option>
			<ng-container *ngIf="options.length">
				<mat-option *ngFor="let option of options" [value]="option"><span class="notranslate">{{
						option
						}}</span>{{ option === this.hostname ? ' (Original Hostname)' : '' }}</mat-option>
			</ng-container>
			<mat-option #manual *ngIf="allowManual" [value]="
					enteredDomainName !== undefined && enteredDomainName !== ''
						? enteredDomainName
						: ' '
				">Enter Manually</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field *ngIf="
			allowManual &&
			selectedDomainName !== '' &&
			!options.includes(selectedDomainName) &&
			selectedDomainName !== undefined &&
			this.status === 'ready'
		" floatLabel="always">
		<mat-label>Domain Name</mat-label>
		<input matInput type="text" [(ngModel)]="enteredDomainName"
			(ngModelChange)="domainSelectChanged(enteredDomainName)" placeholder="example.com" />
		<div *ngIf="this.invalid" class="ctw-absolute ctw-mt-3 ctw-text-red-700 ctw-text-sm">
			A fully qualified domain name (FQDN) is required.<br>
			<span class="ctw-text-imh-gray-200">eg. somethingcool.com [or] shop.somethingcool.com</span>
		</div>
	</mat-form-field>
</ng-container>