import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ParamMap } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ApiService, BrandingService, ConfigurationService } from '../../../core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoginComponent } from '../../login/login.component';
import { SharedDialogComponent } from './shared-dialog/shared-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'central-user-inspection',
	templateUrl: './user-inspection.component.html',
	styleUrls: ['./user-inspection.component.scss'],
})
export class UserInspectionComponent implements OnInit, OnDestroy {

	@Output() public refreshEvent = new EventEmitter();

	// State of the program
	public state = 'submitted';

	// Variables for the inspection data collection
	public id: any;
	public data: any;
	public options: any;
	public ip_results: any;
	public phone_fraud: any;
	public fingerprintjs: any;
	public fraudScore: any;
	public billing: any;
	public hubspotProperties: any;
	public tempAuth: any;

	// Shortened variables
	public latitude: any;
	public longitude: any;
	public mapURL: any;

	// Variables for marking fraud
	public fraudData: any;

	// Fraud button enable and disable variables
	public fraud: boolean;
	public valid: boolean;

	public recurlyUrl;
	public zendeskUrl;
	public hubspotUrl;

	public fraudStatusState = 'pending';
	public fraudStatus = 'Not Reviewed';
	public hsPropertyState = 'loading';

	private subscriptions = [];

	constructor(
		private matDialog: MatDialog,
		private route: ActivatedRoute,
		public apiService: ApiService,
		public snackbar: MatSnackBar,
		private clipboard: Clipboard,
		public configService: ConfigurationService,
		public brandingService: BrandingService
	) {}

	ngOnInit(): void {
		this.getFraudReport();
		this.getHubspotData();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'refresh':
				this.refresh();
				break;
			case 'tempAuth':
				this.createTempAuth();
				break;
			case 'loginAsUser':
				this.openLoginAsUserDialog();
				break;
		}
	}

	// Basic Functions //
	// Refresh The Page
	public refresh() {
		this.state = 'submitted';
		this.ngOnInit();
		this.refreshEvent.emit();
	}

	public openLoginAsUserDialog() {
		this.matDialog.open(LoginComponent, {
			disableClose: true,
			width: '500px',
			data: {
				user_id: this.id,
				email: this.data.email,
			},
		});
	}

	// Functions to grab data for users //
	// Get data from the fraud-report
	private getFraudReport() {
		this.subscriptions.push(
			this.route.paramMap.subscribe((params: ParamMap) => {
				this.id = params.get('user_id');
				this.apiService
					.get(`/v1/agents/fraud-report/` + this.id, {})
					.subscribe({
						next: (account: any) => {
							this.data = account;

							// Nested Array easy calls
							this.options = this.data.options;
							this.ip_results = this.data.sign_ip_results;
							this.phone_fraud = this.data.phone_fraud_score;
							this.fingerprintjs = this.data.fingerprintjs;
							this.billing = this.data.billing_info;

							// Fixing up location code.
							this.latitude = this.fingerprintjs.latitude;
							this.longitude = this.fingerprintjs.longitude;
							this.mapURL =
								'https://maps.google.com/?q=' +
								this.latitude +
								',' +
								this.longitude;

							// Checking fraud status
							if (this.data.is_fraud === true) {
								this.fraud = true;
								this.valid = false;
							} else if (this.data.is_fraud === false) {
								this.fraud = false;
								this.valid = true;
							}

							this.zendeskUrl = this.getZendeskUrl();
							this.recurlyUrl = this.getRecurlyUrl();
							this.hubspotUrl = this.getHubspotUrl();

							// If data grabbed, display is loaded
							if (this.data?.email) {
								this.state = 'success';
							} else {
								this.state = 'fail';
								// RE: https://app.rollbar.com/a/inmotionhosting/fix/item/Platform-I-Central/1924
								// Something is not right. Send to rollbar for review.
								console.warn("Account data incomplete", this.data);
							}
						},
						error: (error) => {
							this.state = 'fail';
						},
					});
			})
		)
	}

	public getRecurlyUrl() {
		const account = this.data?.account_access?.find((_account) => (
			_account.role === 'owner' &&
			_account.type === (this.brandingService.getAppName().includes('InMotion')
				? 'organization'
				: 'user')
		));

		const url = account
			? `https://${this.configService.config.recurly.domain}/accounts/${account.uuid}`
			: null;

		return url;
	}

	public getZendeskUrl() {
		const zendesk_id =
			this.data.options?.internal_zdesk_account_org_id ?? null;
		const url = zendesk_id
			? `${this.configService.config.zendesk.url}agent/organizations/${zendesk_id}`
			: null;
		return url;
	}

	public getHubspotUrl() {
		const hubspot_id = this.data.hubspot_id ?? null;
		const url = hubspot_id
			? `https://app.hubspot.com/contacts/${this.configService.config.hubspot.forms.trial.portalId}/contact/${hubspot_id}`
			: null;
		return url;
	}

	public getHubspotData() {
		this.hubspotProperties = null;
		this.hsPropertyState = 'loading';
		this.apiService
			.get('/v1/hubspot/' + this.id + '/properties', {})
			.subscribe(
				(properties) => {
					this.hubspotProperties = properties;
					const prefix = this.configService.config.appPrefix;
					this.fraudStatus =
						this.hubspotProperties[prefix + '_fraud_review'] ??
						'Not Reviewed';
					this.hsPropertyState = 'success';
				},
				() => (this.hsPropertyState = 'failed')
			);
	}

	public fraudUpdate() {
		const url = this.apiService.formatter.getUrl(
			`/v1/agents/fraud-report/accounts/` + this.id + `/status`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.fraudStatusState = 'submitted';

		this.apiService.http
			.post(url, { status: this.fraudStatus }, { headers })
			.subscribe(
				(response: any[]) => {
					this.fraudStatus = response['status'];
					this.snackbar.open('Fraud Status Updated', 'Dismiss', {
						duration: 3000,
					});
					this.fraudStatusState = 'success';
				},
				(error) => {
					this.fraudStatusState = 'failed';
				}
			);
	}

	public createTempAuth() {
		this.apiService
			.post(`/v1/agents/accounts/${this.id}/temporary-login-code`, {})
			.subscribe({
				next: (response: any) => {
					this.tempAuth = response.password;
					this.matDialog.open(SharedDialogComponent, {
						disableClose: true,
						width: '500px',
						data: {
							titleIcon: 'admin_panel_settings',
							title: 'Temporary Auth',
							content: 'A temporary authorization has been issued for this user. The following password can be used for the next 15 minutes. Upon login with this code, the user will be prompted to set a new password.',
							shouldCopy: true,
							itemToCopy: this.tempAuth,
							itemToCopyName: 'Temporary Password'
						},
					});
				}
			});
	}

	public copyValue(element, name) {
		this.clipboard.copy(element.innerText);
		this.snackbar.open(`${name} copied to clipboard!`, '', {
			...this.configService.config?.snackBar || {duration: 4000}
		});
	}
}
