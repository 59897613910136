<mat-card class="warning complete-notification card-small ctw-my-16 oops">
	<div class="animate__animated animate__fadeIn">
		<h3 class="">Oops! That's not right.</h3>
		<h4 class="">Something went wrong.</h4>
		<p>
			We're working on getting this fixed as soon as we can.
			Try again and see if this corrects your problem.
		</p>
		<div class="ctw-text-right ctw-mt-10">
			<button mat-raised-button mat-ripple type="button" color="primary" (click)="this.reload()">
				OK
			</button>
		</div>
	</div>
</mat-card>
