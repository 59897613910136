import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// eslint-disable-next-line
declare let twq: any;
// eslint-disable-next-line
declare let twttr: any;

@Injectable()
export class TwitterAnalyticsService {
	constructor(private router: Router) {
		this.setupPageView();
	}

	/**
	 * Send page view on navigation.
	 *
	 * @since 1.9.0
	 */
	public setupPageView(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.send('PageView');
			}
		});
	}

	/**
	 * Track user signups.
	 */
	public signup(): void {
		if (twttr) {
			twttr.conversion.trackPid('o2jho');
		}
	}

	/**
	 * Append the tracking script once the application has loaded.
	 *
	 */
	public appendScript(): void {
		const elem = document.createElement('script');

		elem.innerHTML = `
		!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
		},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
		a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
		twq('init','o1pe0');
		`;

		document.body.appendChild(elem);
	}

	/**
	 * Track the purchase event.
	 */
	public purchase(data: object): void {
		if (!data['cart']) {
			return;
		}
		const cart = data['cart'];

		this.send('Purchase', {
			value: +cart.total,
			num_items: cart.dataSource.length,
			currency: 'USD',
		});
	}

	/**
	 * Send the data to twitter.
	 *
	 * Simply a wrapper of the twq method.
	 *
	 * @param   data      Data to pass to google.
	 */
	public send(category: string, data?: object): void {
		if ('undefined' !== typeof twq) {
			twq('track', category, data || {});
		}
	}
}
