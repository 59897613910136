import { Inject, Injectable, Optional } from "@angular/core";
import { LocalStorageService } from "./local-storage/local-storage.service";
import baseConfig from './config';

export const CENTRAL_ENVIORNMENT_CONFIG = 'CENTRAL_ENVIORNMENT_CONFIG';
@Injectable({
	providedIn: 'root',
})
export class ConfigurationService extends LocalStorageService {
	private _config: any;
	constructor(@Optional() @Inject(CENTRAL_ENVIORNMENT_CONFIG) config: any) {
		super();
		this.config = config;
	}
	set config(config: any) {
		this._config = { ...baseConfig, ...config };
	}
	get config() {
		return this._config;
	}
	public hasFeature(feature: string) {
		const val = this.config.features[feature] || this.privateBeta(feature);
		return val ? val : false;
	}

	public privateBeta(feature: string) {
		const val = this.config.beta[feature] && this.getItem('tester');
		return val ? val : false;
	}
}
