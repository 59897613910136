<central-header *ngIf="this.configService.config.brandConfig.id === 'imh'"
	title="{{accountService.isOrganization() ? 'Team' : 'My'}} Preferences" [status]="this.status">
</central-header>

<central-organization-settings *ngIf="accountService.isOrganization()" [accountId]="accountService.getResourceId()"
	class="ctw-z-10"></central-organization-settings>

<ng-container *ngIf="!accountService.isOrganization()">
	<div *ngIf="this.configService.config.brandConfig.id === 'imh'" [hidden]="!this.accountService.account?.account_id">
		<div class="ctw-max-w-2xl ctw-mx-auto ctw-relative ctw-z-2">
			<central-user-settings></central-user-settings>
		</div>
		<div class="ctw-max-w-2xl ctw-mx-auto ctw-pt-8">
			<h2>Security</h2>
			<central-enable-two-factor *ngIf="this.features?.twoFactor" class="two-factor-enable">
			</central-enable-two-factor>
		</div>
		<div *ngIf="!this.usesPassword || (this.usesPassword && this.profileService.data['providers']?.length)"
			class="ctw-max-w-2xl ctw-mx-auto ctw-pt-12 ctw-mb-{{ this.usesPassword ? '4' : '20' }}">
			<h3>Authentication Providers</h3>
			<p>
				We've made logging in super easy and secure by integrating popular third-party authentication providers
				like Google with more options coming soon.
			</p>
			<table mat-table [dataSource]="this.profileService.data['providers']"
				[class.imh]="this.configService.config.brandConfig.id === 'imh'" class="ctw-mt-6">
				<ng-container matColumnDef="provider">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let provider" class="ctw-flex">
						<img src="/assets/img/{{provider.name}}-provider.svg">
						&nbsp; &middot;&middot;&middot; &nbsp;
						<img src="{{provider.image}}">
					</td>
				</ng-container>

				<ng-container matColumnDef="identity">
					<th mat-header-cell *matHeaderCellDef>Identity</th>
					<td mat-cell *matCellDef="let provider">
						<span *ngIf="provider.identity">{{ provider.identity }}</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="action" stickyEnd>
					<th mat-header-cell *matHeaderCellDef aria-label="row actions">
						&nbsp;
					</th>
					<td mat-cell *matCellDef="let provider" class="ctw-text-right">
						<!-- TODO: Allow deletion here or inform to disconnect at 3rd party -->
						<span class="ctw-text-green-700">Connected</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>
		</div>
		<div *ngIf="this.usesPassword" class="ctw-max-w-2xl ctw-mx-auto ctw-pt-12 ctw-mb-20">
			<h3>Password Reset</h3>
			<p *ngIf="this.configService.config.brandConfig.id === 'imhs'">
				You may reset your password below if you are not logging in with Google.
			</p>
			<central-update-password appearance="{{
                    this.configService.config.brandConfig.id === 'imh'
                        ? 'standard'
                        : 'fill'
                }}" class="password-update"></central-update-password>
		</div>
	</div>
	<div *ngIf="this.configService.config.brandConfig.id !== 'imh'" [hidden]="this.status !== 'success'">
		<div class="ctw-max-w-2xl ctw-mx-auto">
			<central-user-settings></central-user-settings>
			<central-update-password appearance="fill" class="password-update"></central-update-password>
			<central-enable-two-factor *ngIf="this.features?.twoFactor" class="two-factor-enable">
			</central-enable-two-factor>
		</div>
	</div>
</ng-container>

<ng-content select="[contentHeader]"></ng-content>

<ng-container *ngIf="!accountService.isOrganization()">
	<central-admin-settings
		*ngIf="(this.configService.config.brandConfig.id !== 'imh' && this.isAdmin) || this.isImpersonating"
		[hidden]="this.configService.config.brandConfig.id === 'imh' && this.status !== 'success'"></central-admin-settings>
</ng-container>
