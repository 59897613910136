import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, ProfileService } from '@central/ng-shared';
import { ApiService, GoogleAnalyticsService } from '@central/ng-shared';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from '../../app.service';

@Injectable()
export class PaymentService {
	public observer: any;
	public paymentOptions: any;

	// 45 second timeout on payments.
	public paymentTimeout = 45000;
	public genericErrorMessage =
		'We encountered  an unexpected error while processing your request. Please try again.';

	public organizationId = '';

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
		public googleAnalytics: GoogleAnalyticsService,
		public cookieService: CookieService
	) {}

	/**
	 * Process the transaction.
	 *
	 * This call chains 2 API calls.
	 * 1. Encrypt CC
	 * 2. Add Products
	 *
	 * @param  options Transaction inputs.
	 * @return         Observable.
	 */
	public process(options: object, isUpdatePlan = false) {
		this.paymentOptions = options;

		if (options['organizationId']) {
			this.organizationId = options['organizationId'];
		}

		this.addProducts(options, isUpdatePlan).subscribe(
			(receipt: any) => {
				// This happens if recptcha fails... returns 200, needs to be fixed.
				if (!receipt.products && !isUpdatePlan) {
					this.fail(this.genericErrorMessage, receipt);
					return;
				}

				this.apiService.timeout = this.apiService.defaultTimeout;
				this.googleAnalytics.trackConversion({
					orderId: receipt.invoice.invoice_number,
					total: receipt.invoice.total_in_cents / 100,
				});
                this.profileService
                    .update({
                        public_hubspot_deal: '',
                    })
					.subscribe();

				// Resolve custom observable.
				this.observer.next(receipt);
				this.observer.complete();
			},
			(errors: any) => {
				this.fail(this.genericErrorMessage, errors);
			}
		);

		// Create a top level observer to be resolved when the entire process completes.
		return new Observable((observer: any) => {
			this.observer = observer;
		});
	}

	/**
	 * Fail the transaction.
	 *
	 * This is called if litle fails paypage or if the transaction fails to the asset server.
	 *
	 * @since 1.0.0
	 *
	 * @param  message  Failure message.
	 * @param  response Response from server.
	 */
	private fail(message: string, response?: object): void {
		// Override message from api call, if exists.
		if ('string' === typeof response) {
			message = response;
		} else if (
			response &&
			response['error'] &&
			response['error']['errors'] &&
			response['error']['errors'][0] &&
			response['error']['errors'][0]['message']
		) {
			message = response['error']['errors'][0]['message'];
		} else if (response && 'Invalid Captcha.' === response['message']) {
			message = 'The reCaptcha challenge was submitted incorrectly.';
		}

		this.apiService.timeout = this.apiService.defaultTimeout;
		this.observer.error(message);
	}

	/**
	 * Add the products to the account.
	 *
	 * @since 1.0.0
	 *
	 * @param  transactionResponse Transaction.
	 * @return                     Observable.
	 */
	private addProducts(transactionResponse: any, isUpdatePlan = false) {
		this.apiService.timeout = this.paymentTimeout;

		let payload = {
			email: transactionResponse['email'],
			'g-recaptcha-response': transactionResponse['captcha'],
			billing_info: {
				token: transactionResponse.token,
			},
            hubspot_deal: this.profileService.data.options['public_hubspot_deal'] ?? '',
			currency: transactionResponse['currency'] ?? this.profileService.data.currency,
		};

		const mktgp = this.cookieService.get('mktgp');
		if (mktgp) {
			payload['mktgp'] = mktgp;
		}

		payload = { ...payload, ...transactionResponse.cart };

		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		let url = this.apiService.formatter.getUrl('/v1/wpcr/transaction');

		if (this.organizationId !== '') {
			const transactionUrl =
				'/v1/wpcr/accounts/' + this.organizationId + '/transaction';
			url = this.apiService.formatter.getUrl(transactionUrl);
		}

		if (transactionResponse.organizationId) {
			if (
				transactionResponse.organizationId === 'default message source'
			) {
				headers = headers.append(
					'X-Organization-Id',
					this.authService.getAccountId()
				);
			} else {
				// Add organization id to the header.
				headers = headers.append(
					'X-Organization-Id',
					transactionResponse.organizationId
				);
			}
		}

		if ( isUpdatePlan ) {
			const plan = transactionResponse['cart']['plans'].find((item) => item.code === 'wpc_project_monthly' || item.code === 'wpc_project_yearly');
			const addonCode = plan['addons'][0]['code'];
			payload = {
				plan_code: addonCode
			} as any;
			const updateTransactionUrl = '/v1/wpcr/subscriptions/' + transactionResponse['subscriptionUuid'] + '/update-subscription';
			url = this.apiService.formatter.getUrl(updateTransactionUrl);
		}

		return this.apiService.http.post(url, payload, { headers });
	}
}
