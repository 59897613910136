import { OnDestroy, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../project.service';
import { ApiService, PollingService } from '@central/ng-shared';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'apps/central/src/environments/base';
import { AppService } from '../../../app.service';


@Injectable()
export class AnsibleService implements OnDestroy {

	public state;
	public status = 'pending';

	public override;
	public overriddenBy = '';
	public playbookStatus = new ReplaySubject(1);
	public latestVersion;
	public projectService: ProjectService;

	private jobQueue = {};
	private isPolling = false;
	private ansibleHosts;
	private subscriptions = [];

	constructor(
		public pollingService: PollingService,
		public activatedRoute: ActivatedRoute,
		public apiService: ApiService,
		private appService: AppService,
	) {
		this.subscriptions.push(
			this.playbookStatus.subscribe((msg: any) => {
				this.state = msg;
				if (msg !== 'pending' && msg !== 'complete' && msg !== 'playbook-overridden') {
					this.doPolling();
				}
				//console.log('Ansible State:', this.state);
			})
		)
	}

	ngOnDestroy(): void {
		console.log('Ansible Service - Unsubscribing Project');
		this.pollingService.stopPoll();
		this.subscriptions.forEach(sub => sub.unsubscribe());
		Object.keys(this.jobQueue).forEach((job: any) => {
			if (this.jobQueue[job]) {
				this.jobQueue[job].unsubscribe();
				delete this.jobQueue[job];
			}
		})
	}

	public bind(serviceRef) {
		// To avoid cirlular depencency,
		// bind service to current projectService instance.
		this.projectService = serviceRef;
		// TODO: Account for multiple servers
		// ...
		const index = 0;
		this.ansibleHosts = this.projectService.project.children.filter(env => env.fields.environment_type === 'vps')

		if (this.ansibleHosts.length) {
			this.getLatestVersion();
			this.apiService
				.get(
					'/v1/override/' + this.projectService.project.id + '/state',
					{}
				)
				.subscribe((response: any) => {
					if (response.state) {
						this.apiService
							.get('/v1/override/' + this.projectService.project.id, {})
							.subscribe((history: any) => {
								this.state = 'playbook-overridden';
								this.override = true;
								this.overriddenBy =
									history[0]['Comment'] !== 'User Disabled'
										? 'Agent'
										: 'User';
								this.playbookStatus.next(this.state);
							});
					} else {
						this.override = false;
						this.playbookStatus.next(
							this.ansibleHosts[index].fields?.playbook_running === 'complete' ? 'pending' :
							this.ansibleHosts[index].fields?.playbook_running || 'pending'
						)
					}
				});
		}
	}

	public runPlaybook(args) {
		// TODO: Account for multiple servers
		// ...
		const index = 0;
		return new Observable((observer) => {
			this.apiService
				.post(
					'/v1/environments/' +
						this.ansibleHosts[index].id +
						'/run-playbook',
					args
				)
				.subscribe(
					() => {
						this.playbookStatus.next('playbook-started');
						observer.next();
						observer.complete();
					},
					(error: any[]) => {
						this.playbookStatus.next('complete');
						if (error['errors']) {
							this.projectService.rollbarService.rollbar.warn(
								error['errors'][0]['message']
							)
						} else {
							this.projectService.rollbarService.rollbar.warn(
								error
							)
						}
						observer.error();
						observer.complete();
					}
				);
		})
	}

	public toggleOverride() {
		let comment = 'User Disabled Management';
		if (this.override) {
			comment = 'User Enabled Management';
		}
		const postData = {
			state: this.override ? false : true,
			comment: comment,
		};

		this.apiService
			.post('/v1/override/' + this.projectService.project.id + '/create', postData)
			.subscribe(() => {
				this.appService.showConfirmation(`UltraStack Management has been ${this.override ? 'Enabled' : 'Disabled'}!`)
				this.bind(this.projectService);
			});
	}

	private doPolling() {
		// TODO: Account for multiple servers
		// ...
		const index = 0;
		if (!this.jobQueue[this.ansibleHosts[index].id]) {
			this.isPolling = true;
			setTimeout(() => {
				this.jobQueue[this.ansibleHosts[index].id] = this.pollingService
					.startPoll(
						() => this.projectService.reloadProject(false, true),
						(response: any) => response.children.filter(env => env.fields.environment_type === 'vps')[0]?.fields.playbook_running === 'complete',
						{ interval: 5000, timeout: 600000 }
					)
					.subscribe(
						(project: any) => {
							this.setStatus(project);
						},
						(error) => {
							console.log(error)
							this.state = 'playbook-request-failed';
						}
				)
				// Delay polling by a minute to prevent race condition with Job Queue cron
				// when starting a playbook run (playbook-started)
				// 55 seconds + 5 second polling interval
			}, this.state === 'playbook-started' ? 15000 : 100)
		}
	}

	private setStatus(project: any) {
		this.pollingService.stopPoll()
		this.isPolling = false;
		if (this.jobQueue[project.children.filter(env => env.fields.environment_type === 'vps')[0].id]) {
			this.jobQueue[project.children.filter(env => env.fields.environment_type === 'vps')[0].id].unsubscribe()
			delete this.jobQueue[project.children.filter(env => env.fields.environment_type === 'vps')[0].id];
		}
		this.playbookStatus.next(
			project.children.filter(env => env.fields.environment_type === 'vps')[0]?.fields.playbook_running
		)
		setTimeout(() => {
			this.playbookStatus.next(
				project.children.filter(env => env.fields.environment_type === 'vps')[0]?.fields.playbook_running === 'complete' ? 'pending' :
				project.children.filter(env => env.fields.environment_type === 'vps')[0]?.fields.playbook_running || 'pending'
			)
		}, 1000)
	}

	private getLatestVersion() {
		this.appService.getPlaybookVersion(this.projectService.project.fields.project_type).subscribe((data) => {
			this.latestVersion = data
		})
	}
}
