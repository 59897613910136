import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';
import { ProjectService } from '../../../../project.service';
import { PollingService } from '@central/ng-shared';
import { DisableSslDialogueComponent } from './disable-ssl-dialogue.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AnsibleService } from '../../../services/ansible.service';

@Component({
	selector: 'central-auto-ssl',
	templateUrl: './auto-ssl.component.html',
	styleUrls: ['./auto-ssl.component.scss'],
})

export class AutoSslComponent implements OnInit, OnDestroy {

	public state = 'loading';
	public available = true;

	public enableSsl = false;
	public playbookRunning = false;
	public playbookError = false;
	public disabled;

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		public projectService: ProjectService,
		public pollingService: PollingService,
		public dialog: MatDialog,
		private profileService: ProfileService,
		private ansibleService: AnsibleService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((msg) => {
						// TODO: Disallow functionality if container is stopped.
						if (this.projectService.environmentState !== 'pending') {
							this.subscriptions.push(
								this.ansibleService.playbookStatus.subscribe((data) => {
									if (data === 'complete') {
										// Loaded info needed for view.
										this.projectService.reloadProject().subscribe(() => {
											this.updateFields();
											this.disabled = this.projectService.environment.fields.site_url
												.includes(this.projectService.environment.fields.hostname);
												this.state = 'success';
												this.available = true;
										});
									} else {
										if (data === 'pending') {
											this.updateFields();
											this.disabled = this.projectService.environment.fields.site_url
												.includes(this.projectService.environment.fields.hostname);
											this.state = 'success';
											this.available = true;
										} else {
											// Playbook is running or playbooks are disabled.
											this.state = data === 'playbook-overridden' ? 'disabled' : 'unavailable';
											this.available = false;
										}
									}
								})
							)
						}
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	public setOverrideState() {
		this.playbookError = false;
		this.enableSsl = !this.enableSsl;
		this.handleEnabledSsl();
	}

	public openDialogue() {
		const dialogueRef = this.dialog.open(DisableSslDialogueComponent, {
			width: '500px',
		});
		dialogueRef.afterClosed().subscribe(result => {
			if (result === true) {
				this.playbookError = false;
				this.enableSsl = false;
				this.handleEnabledSsl();
			}
		});
	}

	private handleEnabledSsl() {
		this.ansibleService.runPlaybook({
			enable_ssl: this.enableSsl,
		}).subscribe({
			next: () => {
				this.playbookRunning = true;
				this.updateFields();
			},
			error: () => {
				this.playbookRunning = false;
				this.updateFields();
				this.playbookError = true;
			}
		});
	}

	private updateFields() {
		const backendSslEnabled = this.projectService.environment.fields.ssl_enabled;
		if (backendSslEnabled === 1 || backendSslEnabled === "1" || backendSslEnabled === true || backendSslEnabled === "true") {
			this.enableSsl = true;
		} else {
			if (this.projectService.environment.fields.site_url.match('https://')) {
				this.projectService.updateEnvironmentField({
					ssl_enabled: true,
				}).subscribe(() => {
					this.enableSsl = true;
				})
			} else {
				this.enableSsl = false;
			}
		}
		if (this.projectService.environment.fields.playbook_running) {
			this.playbookRunning = this.projectService.environment.fields.playbook_running !== 'complete';
		} else {
			this.playbookRunning = false;
		}
		this.playbookError = this.projectService.environment.fields.playbook_error ?? false;
		// If Playbook is in error state do not indicate SSL is installed
		if (this.playbookError) {
			this.enableSsl = false;
		}
	}

	/*
	private startPolling() {
		this.pollingService.startPoll(
			() => this.projectService.reloadProject(),
			() => !this.projectService.environment.fields?.playbook_running || this.projectService.environment.fields.playbook_running === 'complete',
		).subscribe(() => {
			this.updateFields();
			this.playbookRunning = false;
		},(err) => {
			console.log('Polling Error', err);
			this.playbookRunning = false;
			this.updateFields();
			this.playbookError = true;
		});
	}*/
}
