<div class="label ctw-absolute" [class.contact_form]="inContactForm && this.showLabel"
	*ngIf="!inContactForm || this.showLabel">
	<label>Phone Number<span *ngIf="this.isRequired"> *</span></label>
</div>
<!-- Clear error when user changes phone num -->
<input [class.contact_form]="inContactForm" [class.ctw-mt-3]="!inContactForm" [class.formatted]="!inContactForm"
	[readonly]="readOnlyForm" type="tel" #phone (input)="this.errorMessage = ''" />
<mat-hint *ngIf="this.errorMessage" class="error" [class.contact_form_error]="inContactForm">
	{{ this.errorMessage }}
</mat-hint>
