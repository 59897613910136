<div>
    <mat-form-field>
        <mat-label>Create Password</mat-label>
        <input
            matInput
            #passwordWithValidation
            autocomplete="off"
            name="15tj3ksp"
            [type]="inputType"
            (ngModelChange)="this.updatePassword(passwordWithValidation.value)"
            required
            [formControl]="
                passwordComponentWithValidation.passwordFormControl
            "
            placeholder="Password"
        />
        <button
            type="button"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Show Password"
            (click)="
                inputType =
                    'password' === inputType ? 'text' : 'password'
            "
        >
            <mat-icon>remove_red_eye</mat-icon>
        </button>
        <mat-hint align="end" aria-live="polite">
            {{ passwordWithValidation.value.length }} / 100
        </mat-hint>
        <mat-error
            *ngIf="
                passwordComponentWithValidation.passwordFormControl.hasError(
                    'required'
                )
            "
        >
            Password is required
        </mat-error>
        <mat-error
            *ngIf="
                passwordComponentWithValidation.passwordFormControl.hasError(
                    'pattern'
                )
            "
        >
            Password is not valid
        </mat-error>
    </mat-form-field>

    <div class="password-hint" 
        [hidden]="!passwordWithValidation.value || isValid">
        <mat-password-strength
            #passwordComponentWithValidation
            [min]="minPasswordChar"
            [max]="100"
            [enableSpecialCharRule]="true"
            [enableUpperCaseLetterRule]="true"
            [enableLowerCaseLetterRule]="true"
            [enableDigitRule]="true"
            
            [password]="passwordWithValidation.value"
            (onStrengthChanged)="onStrengthChanged($event)"
        >
        </mat-password-strength>
        
        <mat-password-strength-info
            [passwordComponent]="passwordComponentWithValidation"
            upperCaseCriteriaMsg="contains at least one upper case character"
            lowerCaseCriteriaMsg="contains at least one lower case character"
            digitsCriteriaMsg="contains at least one numeric character"
        ></mat-password-strength-info>
    </div>
</div>