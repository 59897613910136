import { Component, OnInit } from '@angular/core';
import { AuthService, ProfileService } from '@central/ng-shared';

@Component({
	template: '',
})
export class ReadmeComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private profileService: ProfileService,
	) { }

	ngOnInit(): void {
		this.profileService.onReady().subscribe(() => {
			if (this.authService.isImpersonating()) {
				const token = this.profileService.authService.jwtHelper.getTokenDecoded()['payload'];
				location.href = `https://inmotioncentral.dev/?auth_token=${token['readme']}`;
			} else {
				location.href = `https://inmotioncentral.dev/?auth_token=${this.profileService.data['readme']}`;
			}
		})
	}
}
