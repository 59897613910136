<mat-dialog-content>
	<div class="notranslate">
		<h3 *ngIf="this.configService.config.brandConfig.id !== 'imh'">{{this.data.title}}</h3>
		<h2 *ngIf="this.configService.config.brandConfig.id === 'imh'" class="ctw-flex">
			<mat-icon *ngIf="this.data.titleIcon" class="ctw-relative ctw-text-red-700 ctw-mr-2">{{this.data.titleIcon
				}}</mat-icon>
			{{this.data.title}}
		</h2>
		<p>
			{{this.data.content}}
		</p>
		<mat-form-field appearance="outline" *ngIf="this.data.shouldCopy">
			<mat-label>{{this.data.itemToCopyName}}</mat-label>
			<input class="connect-key" matInput readonly [(ngModel)]="this.data.itemToCopy" />
			<button class="ctw-absolute ctw-top-0 ctw-right-0" mat-raised-button [matTooltip]="this.data.copyText"
				matTooltipPosition="above" (click)="copy()">
				Copy
			</button>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-p-0 ctw-pb-5 notranslate">
	<button mat-raised-button mat-ripple type="button" [mat-dialog-close]="true" color="primary">
		OK
	</button>
</mat-dialog-actions>
