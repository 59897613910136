import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import currency from 'currency.js';

import { AccountService, ApiService, AuthService, ProfileService } from '@central/ng-shared';
import { ProjectService } from '../../../project/project.service';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
	public currencyJs = currency;
	public status = 'loading';
	public billingSubscriptions = [];
	public projects = {
		active: [],
		inactive: [],
	};

	private subscriptions = [];

	constructor(
		public dialog: MatDialog,
		public accountService: AccountService,
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
		public projectService: ProjectService,
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.accountService.authAccountUpdate.subscribe(() => {
				this.status = 'loading';
				if (this.accountService.id) {
					this.getSubscriptions();
					this.fetchProjects();
				}
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	fetchProjects(): void {
		this.projectService.fetchProjects().subscribe((active: any) => {
			this.projects.active = active;
		});
		this.apiService.get('/v1/projects/history', {}).subscribe(
			(inactive: any) => {
				this.projects.inactive = inactive;
			},
			() => {}
		);
	}

	getSubscriptions(): void {
		this.apiService
			.get(`/v1/wpcr/accounts/${this.accountService.id}/subscriptions`, {})
			.subscribe(
				(subscriptions: any) => {
					this.billingSubscriptions = subscriptions;
					this.billingSubscriptions.sort((a, b) =>
						new Date(a.current_term_ends_at).getTime() - new Date(b.current_term_ends_at).getTime());
					this.status = 'success';
				},
				() => {
					this.status = 'failed'
				}
			);
	}

	getProjectLabelById(projectId): object {
		const active = this.projects.active.find((prj) => prj.id === projectId);
		const inactive = this.projects.inactive.find(
			(prj) => prj.id === projectId
		);
		const status = active ? 'active' : 'inactive';
		const label = active ? active?.label : inactive?.label;
		return { status, label };
	}

	openDialog(data) {
		// Pass in organization ID for the various methods in dialog component to use.
		data.organizationId = this.accountService.id;
		data.isOrg =
			this.profileService.data.type === 'org' &&
			this.profileService.data.account_access[0].account_id !==
				this.accountService.id;

		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data,
			width: '500px',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.billingSubscriptions = this.billingSubscriptions.map((obj) => obj.uuid === result.uuid ? result : obj);
			}
		});
	}

	getRenewalDate(subscription): string {
		const now = new Date();
		const startedAt = new Date(subscription.current_term_started_at);
		return now < startedAt
			? subscription.current_term_started_at
			: subscription.current_term_ends_at;
	}

	getFormattedTerm(term): string {
		switch(term) {
			case '12 months':
				return 'year';
				break;
			case '1 month':
				return 'month';
				break;
			default:
				return term;
		}
	}
}
