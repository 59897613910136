
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@central/ng-shared';

@Injectable()
export class AmpLocalStorageService extends LocalStorageService {
	/**
	 * This class is the same as local storage except it uses a different namespace as the LocalStorageService.
	 * This means that it will not be cleared when the user logs out.
	 *
	 * This will not work before login.
	 *
	 */
	public baseNamespace = 'AmpCentral';

	public constructor() {
		super();

		// Temporarily update the namespace to the base namespace.
		this.namespace = this.baseNamespace;
	}
}