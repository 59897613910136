import { Injectable } from '@angular/core';
import { SocketService } from '../../socket';
import { AuthService, ProfileService } from '.';
import { first } from 'rxjs/operators'

@Injectable()
export class SessionService {

	constructor(
		private _authService: AuthService,
		private _profileService: ProfileService,
		private _socketService: SocketService
	) {
		this._scheduleSessionExpiration();
		this._listenForSessionExpiration();
	}

	/**
	 * When the user logs in, but after the websocket has authenticated, send a session_init event.
	 */
	private _scheduleSessionExpiration() {
		this._authService.onLogin.subscribe(() => {
			this._socketService.onAuthenticated.pipe(first()).subscribe(() => {
				this._socketService.getSocket().emit('sessionInit');
			} );
		});
	}

	/**
	 * When the websocket believes the session has expired, fetch the profile to confirm.
	 *
	 * If the profile fetch fails, the session has expired and the user will be logged out.
	 */
	private _listenForSessionExpiration() {
		this._socketService.getSocket().on('sessionExpiration', () => {
			this._profileService.fetch();
		} );
	}
}
