import { Injectable } from '@angular/core';

@Injectable()
export abstract class SupportService {
	constructor() {}

	public ticketPriorities = [
		{ value: 'normal', label: 'Normal' },
		{ value: 'urgent', label: 'Emergency' },
	];

	public abstract getAccount();

	public abstract getAvailableClarityHours(hid);

	public abstract getCloud();

	public abstract getSupportOptions(accountId?);

	public abstract submitTicket(data);
}
