<form #form="ngForm" (ngSubmit)="phoneNumber.isValid() && form.valid && submit()" class="form" [class]="
		this.configService.config.brandConfig.id === 'imh'
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-2xl ctw-mt-32 ctw-mb-8'
			: ''
	">
	<div class="ctw-mb-10">
		<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
			<h2>Contacts</h2>
			<p>
				We'll use the following information when we need to communicate with you.
			</p>
		</ng-container>
		<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
			<h2 *ngIf="this.configService.config.brandConfig.id !== 'imh'">Personal Profile</h2>
			<p>
				Update your personal information
			</p>
		</ng-container>
	</div>
	<mat-form-field appearance="{{ this.configService.config.inputAppearance || 'fill' }}"
		[class.ctw-mb-0]="this.configService.config.brandConfig.id === 'imh'">
		<mat-label>Name</mat-label>
		<input matInput [(ngModel)]="name" [disabled]="!this.accountService.hasAccess('write')" required
			#nameField="ngModel" name="name" placeholder="Name" />
		<mat-error>
			<ng-container *ngIf="nameField.errors?.required">This field is required.</ng-container>
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="{{ this.configService.config.inputAppearance || 'fill' }}"
		[class.ctw-mb-0]="this.configService.config.brandConfig.id === 'imh'">
		<mat-label>Primary Email</mat-label>
		<input [(ngModel)]="primaryEmail" [disabled]="!this.accountService.hasAccess('write')" email type="email"
			#primaryEmailField="ngModel" required matInput name="primaryEmail" placeholder="jdoe@company.com" />
		<mat-error>
			<ng-container *ngIf="primaryEmailField.errors?.required">This field is required.</ng-container>
			<ng-container *ngIf="primaryEmailField.errors?.email">Invalid Format. Please use the following format
				user@domain.tld</ng-container>
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="fill" *ngIf="
			this.accountService.apiService.configService.config.stackName ===
			'openmetal_central' && 0
		">
		<mat-label>Business Contact Email</mat-label>
		<input [(ngModel)]="secondaryEmail" [disabled]="!this.accountService.hasAccess('write')" email type="email"
			#secondaryEmailField="ngModel" required centralFreeEmailValidator matInput name="secondaryEmail"
			placeholder="jdoe@company.com" />
		<mat-error>
			<ng-container *ngIf="secondaryEmailField.errors?.required">This field is required.</ng-container>
			<ng-container *ngIf="secondaryEmailField.errors?.email">Invalid Format. Please use the following format
				user@domain.tld</ng-container>
			<ng-container *ngIf="secondaryEmailField.errors?.freeEmail">Invalid Email. Please use an email from a non
				free email
				provider.</ng-container>
		</mat-error>
	</mat-form-field>

	<div [hidden]="!this.accountService.hasAccess('write')">
		<central-phone-number [value]="this.contactPhone" #phoneNumber></central-phone-number>
	</div>
	<div class="update-message ctw-my-8 ctw-text-center">
		<div class="ctw-text-green-700" [hidden]="this.state !== 'success'">
			Successfully updated your account details.
		</div>
		<div class="ctw-text-red-700" [hidden]="this.state !== 'failed'">
			An error occurred while processing your request.
		</div>
	</div>
	<div class="action-buttons ctw-text-right">
		<progress-button [state]="state" color="{{ this.configService.config.buttonColor || 'primary' }}">Update
			{{
			this.accountService.apiService.configService.config.brandConfig
			.id === 'imh'
			? 'Contact Info'
			: ''
			}}</progress-button>
	</div>
</form>
