import {
	Component,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
	selector: 'bg-guest',
	template: ` <router-outlet class="guest-router-outlet"></router-outlet> `,
})
export class GuestComponent implements OnDestroy {
	constructor(public appComponent: AppComponent) {
		this.appComponent.forceGuestView = true;
	}

	public ngOnDestroy() {
		this.appComponent.forceGuestView = false;
	}
}
