<div class="ctw-min-h-screen ctw-w-full ctw-flex ctw-items-center ctw-justify-center">
	<div class="ctw-max-w-sm">
		<div>
			<div class="ctw-flex ctw-flex-col ctw-text-center ctw-items-center ctw-mb-8"
				*ngIf="('pending' === registration?.loginForm?.state || 'submitted' === registration?.loginForm?.state || this.authService.isLoggedIn()) && ((this.invitedEmail && this.authService.getEmail()?.toLowerCase() === this.invitedEmail?.toLowerCase()) || !this.authService.isLoggedIn())">
				<mat-icon class="ctw-text-8xl ctw-w-24 ctw-h-24">mark_email_read</mat-icon>
				<h2>You received an invite!</h2>
				<div *ngIf="this.teamName">
					<p>You have received an invite to join {{ this.teamName }}</p>
					<button class="accept-invitation" mat-button color="primary" mat-raised-button
						*ngIf="this.authService.isLoggedIn() && state === 'pending'" (click)="acceptInvite()">
						Accept
					</button>
				</div>
				<mat-spinner diameter="50" color="accent"
					*ngIf="this.authService.isLoggedIn() && !this.teamName"></mat-spinner>
			</div>
			<div
				*ngIf="this.authService.isLoggedIn() && this.invitedEmail && this.authService.getEmail().toLowerCase() !== this.invitedEmail.toLowerCase()">
				<h4 class="error">Warning:</h4>
				<p>The user you are logged in as does not match the email address this invite was sent to. Please log in
					to the email address the invite was sent to to accept it.</p>
			</div>
			<bg-registration #registration [wasInvited]="true"></bg-registration>
		</div>
		<mat-spinner diameter="100" color="accent" *ngIf="state === 'submitted' || state === 'success'"
			ctw-mx-auto></mat-spinner>
		<ng-container *ngIf="registration.isComplete()">
			<div class="oops" *ngIf="state === 'failed'">
				<h4>An Error Occurred</h4>
				<p>
					We were unable to complete your request. Please try clicking the
					link in your email again or request a new invite from the owner.
				</p>
				<p *ngIf="this.errorMessage">
					Error: <span class="error">{{ this.errorMessage }}</span>
				</p>
				<div class="ctw-mt-5">
					<button mat-button color="primary" mat-raised-button routerLink="/">
						Okay
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
