<form #form="ngForm" (ngSubmit)="form.valid && deleteMember()"
	*ngIf="this.configurationService.config.brandConfig.id !== 'imh'">
	<h3 mat-dialog-title class="ctw-capitalize">
		Revoke
		{{
		data.membership.account
		? data.membership.account.name
		: data.membership.invite_email
		}}'s access?
	</h3>
	<mat-dialog-content>
		<p>
			Revoking this member's access cannot be undone. A new invitation
			will have to be sent.
		</p>
		<p class="error error-message" *ngIf="this.state === 'failed'">
			An error occurred removing this member. Please try again.
		</p>
	</mat-dialog-content>

	<div class="confirm">
		<mat-checkbox name="confirm" [(ngModel)]="confirm" required>Remove member from
			{{
			this.configurationService.config.groupLabels[
			'group-label-lower-case'
			]
			}}</mat-checkbox>
	</div>

	<div class="action-buttons ctw-text-right ctw-mt-8">
		<button mat-button type="button" (click)="this.dialogRef.close()">
			Close
		</button>
		<progress-button color="warn" [disabled]="!confirm" [state]="state">Remove Member</progress-button>
	</div>
</form>

<form #form="ngForm" (ngSubmit)="form.valid && deleteMember()"
	*ngIf="this.configurationService.config.brandConfig.id === 'imh'">
	<h2>
		Are You Sure?
	</h2>
	<mat-dialog-content>
		<p class="alert">
			<strong class="error">WARNING</strong>: Revoking this member's access cannot be undone. A new invitation
			will need to be sent to bring them back into the Team.
		</p>
		<p class="error error-message" *ngIf="this.state === 'failed'">
			An error occurred removing this member. Please try again.
		</p>
	</mat-dialog-content>

	<div>
		<mat-checkbox name="confirm" [(ngModel)]="confirm" required>Yes, I would like to remove {{
			data.membership.account
			? data.membership.account.name
			: data.membership.invite_email
			}} from the
			{{
			this.configurationService.config.groupLabels[
			'group-label-title-case'
			]
			}}.</mat-checkbox>
	</div>

	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button mat-button type="button" (click)="this.dialogRef.close()">
			Cancel
		</button>
		<progress-button color="warn" [disabled]="!confirm" [state]="state" class="ctw-ml-3">Remove {{
			data.membership.account
			? data.membership.account.name
			: data.membership.invite_email
			}}</progress-button>
	</div>
</form>
