<h2 mat-dialog-title class="notranslate">Are You Sure?</h2>
<mat-dialog-content class="ctw-mb-4">
	<p>
		This will permanently delete all DNS records associated with <strong>{{ data.thirdPartyDomain.domainName
			}}</strong>.
	</p>
</mat-dialog-content>
<mat-dialog-actions class="ctw-mt-10 ctw-pb-5">
	<div class="ctw-text-right ctw-w-full">
		<button mat-button [mat-dialog-close]="false">Cancel</button>
		<button mat-raised-button class="ctw-ml-3" [mat-dialog-close]="true" color="warn">
			Yes, Continue
		</button>
	</div>
</mat-dialog-actions>