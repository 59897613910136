import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Options, ChangeContext } from 'ngx-slider-v2';
import { ApiService, ConfigurationService, AuthService, ProfileService } from '@central/ng-shared';
import { HubspotTrackingService } from 'libs/ng-shared/src/lib/tracking/hubspot/hubspot.service';
import { Router, ActivatedRoute } from '@angular/router';
import config from './config';
import { AppService } from 'apps/central/src/app/app.service';

@Component({
	selector: 'plan-selector',
	templateUrl: './plan-selector.component.html',
	styleUrls: ['./plan-selector.component.scss'],
})
export class PlanSelectorComponent implements OnInit {
	@Input() altDisplay = false;
	@Input() value = 5;
	@Input() couponData = null;
	@Input() itemCodeArray = null;
	@Input() isUpdatePlan;
	@Input() currentPlan;
	@Input() subscriptionUuid: string;
	@Input() termType = 'yearly';
	@Input() server = null;
	@Input() public dataCenter = '';

	@Output() handleSingleSelectionCoupon = new EventEmitter();
	@Output() requirePlanSelection = new EventEmitter();
	@Output() enableNext = new EventEmitter();
	@Output() planSelectorReady = new EventEmitter();

	public stats;
	public license = 'standard';
	public showDetails = false;
	public premiumKeyPriceMonthly = 5;
	public premiumKeyPriceYearly = 60;
	public currencyFormatter;
	public catalog;
	public config = config;
	public status = 'loading';
	public currentPlanValue: number;
	public currentStats;
	public projectId;
	public partialPrice;
	public dataCenters = [];
	public disallowed = [];
	public notAllowed = false;
	public noneAvailable = true;
	public currentSize = 0;
	public poolInfo = {};
	public hubspotDeal;
	public todaysPrice = 0;
	public coupon;
	public discount;
	public locale = 'en-US';
	public currency = 'USD';

	public options: Options = {
		floor: 0,
		ceil: 8,
		step: 1,
		showTicks: true,
		showTicksValues: false,
		stepsArray: [
			{ value: 1, legend: '2 GB' },
			{ value: 2, legend: '3 GB' },
			{ value: 3, legend: '4 GB' },
			{ value: 4, legend: '6 GB' },
			{ value: 5, legend: '8 GB' },
			{ value: 6, legend: '12 GB' },
			{ value: 7, legend: '16 GB' },
			{ value: 8, legend: '24 GB' },
		],
	};

	constructor(
		private appService: AppService,
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
		private route: ActivatedRoute,
		private router: Router,
		public configurationService: ConfigurationService,
		private hubspotService: HubspotTrackingService,
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			if (
				params?.selected &&
				!isNaN(params?.selected) &&
				params?.selected > this.options.floor &&
				params?.selected <= this.options.ceil
			) {
				this.value = params?.selected;
			}

			// Clear query string after setting in passed values.
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: {
					selected: null,
				},
				queryParamsHandling: 'merge',
			});
		});

		this.locale = this.profileService.data?.locale || 'en-US';
		this.currency = this.profileService.data?.currency || 'USD';

		this.currencyFormatter = new Intl.NumberFormat(this.locale, {
			style: 'currency',
			currency: this.currency,
		});

		this.fetchCatalog();

		if (this.isUpdatePlan) {
			const currentSize = parseInt(this.server['diskused'], 10)
			this.config.plans.forEach((plan) => {
				// Exclude plans with disk smaller than current usage
				if (currentSize > parseInt(plan.storage, 10) * 1000000) {
					this.disallowed.push(parseInt(plan.code.split('_')[2], 10));
				}
			});
			this.currentSize = Math.round(currentSize / 1000000);
		}

		this.currentPlan = this.currentPlan || this.value;

		this.setCurrentPlanValue();
		this.setCurrentPlanLegend();
		this.setCurrentPlanSliderValue();

		this.currentStats = this.config.plans.find(
			(plan) => plan.code === 'wphosting_tier_' + this.currentPlan
		);
	}

	onUserChange(changeContext: ChangeContext): void {
		if (this.isUpdatePlan) {
			this.notAllowed = false;
			if (changeContext.value === this.currentPlanValue) {
				this.enableNext.emit(false);
			} else if (this.disallowed.includes(changeContext.value)) {
				this.enableNext.emit(false);
				this.notAllowed = true;
			} else {
				this.enableNext.emit(true);
			}
		}
	}

	setCurrentPlanValue() {
		this.currentPlanValue = parseInt( this.currentPlan, 10);
		if (this.isUpdatePlan) {
			this.notAllowed = false;
			if (parseInt( this.currentPlan, 10) === this.currentPlanValue) {
				this.enableNext.emit(false);
			} else if (this.disallowed.includes(parseInt( this.currentPlan, 10))) {
				this.enableNext.emit(false);
				this.notAllowed = true;
			} else {
				this.enableNext.emit(true);
			}
		} else {
			this.enableNext.emit(true);
		}
	}

	setCurrentPlanLegend() {
		if ( this.isUpdatePlan && this.currentPlan ) {

			// Sets text string for Current Plan.
			this.options.stepsArray[ this.currentPlanValue - 1 ].legend = '<strong class="ctw-text-red-700">Current Plan</strong>';

			// This is used to color the current plan tick mark differently from the selected plan.
			this.options = {
				...this.options,
				getTickColor: (value:number): string => {
					if ( value === parseInt( this.currentPlan, 10 ) - 1 ) {
						return '#242424';
					}
				},
			};
		}
	}

	setCurrentPlanSliderValue() {
		if ( this.isUpdatePlan ) {
			this.value = this.currentPlanValue || this.value;

			// If we have a current plan set the default value to the next item for upgrade.
			if ( this.currentPlan && this.currentPlanValue < this.options.ceil) {
				this.value++;
			}
		}
	}

	public fetchCatalog() {
		this.status = 'loading';

		this.appService.getCatalog().subscribe(
			(catalog) => {
				this.catalog = catalog;
				const monthlyPlans = this.catalog.filter(
					(plan) => plan.code === 'wpc_project_monthly'
				)[0].items;
				const yearlyPlans = this.catalog.filter(
					(plan) => plan.code === 'wpc_project_yearly'
				)[0].items;
				this.config.plans.forEach((el) => {
					el.priceMonthly =
						monthlyPlans.filter((plan) => plan.code === el.code)[0]
							.price_in_cents[this.currency] / 100;
					el.priceYearly =
						yearlyPlans.filter((plan) => plan.code === el.code)[0]
							.price_in_cents[this.currency] / 100;
				});
				this.fetchPoolInfo();
			},
			() => {
				this.status = 'failed';
			}
		);
	}

	public onDataCenterChange(dc: string) {
		this.dataCenter = dc;
	}

	public setStats() {
		clearTimeout(this.hubspotDeal);
		this.stats = this.config.plans[this.value - 1];

		if (!this.isUpdatePlan) {
			if (this.dataCenter === '' && this.poolInfo?.[this?.stats?.code] !== undefined) {
				this.noneAvailable = Object.values(this.poolInfo[this.stats.code]).every(dc => dc === false);
			}
			if (this.dataCenter === '' && this.noneAvailable) {
				this.dataCenter = '';
				this.enableNext.emit(this.dataCenter !== '')
			} else {
				this.dataCenters = Object.keys(this.poolInfo[this.stats.code]).filter(key => this.poolInfo[this.stats.code][key] === true) || [];
				if (this.dataCenter === '' || !this.dataCenters.includes(this.dataCenter)) {
					this.dataCenter = Object.keys(this.poolInfo[this.stats.code]).find(key => this.poolInfo[this.stats.code][key] === true);
				}
			}

			this.enableNext.emit(this.dataCenter && this.dataCenter !== '')
		} else {
			if (this.value !== this.currentPlan) {
				this.enableNext.emit(true);
			}
		}

		if (this.hubspotService.getDeal() === '') {
			this.hubspotService.setDeal(
				this.authService.getAccountId(),
				this.profileService.data.options['public_hubspot_deal'] ?? '');
		}

		this.hubspotDeal = setTimeout(() => {
			let label = this.stats.name;
			let term = { label: '1 Month' };
			if (this.isUpdatePlan) {
				label = `Resize ${this.currentStats.name} → ${this.stats.name}`
			}
			term = { label: this.termType === 'monthly' ? '1 Month' : '1 Year' };

			this.hubspotService.updateCart({
				items: JSON.stringify([{ label, term }]),
				amount: this.getServerPrice(this.termType),
			}).subscribe((dealId) => {
				if (dealId) {
					this.profileService
						.update({
							public_hubspot_deal: dealId
						});
				}
			});
		}, 2000);

		this.status = 'success';
		this.planSelectorReady.emit();

		if (this.dataCenter && this.dataCenter !== '') {
			this.enableNext.emit(true);
			this.handleSingleSelectionCoupon.emit();
		} else {
			this.requirePlanSelection.emit(true);
		}
	}

	public isProvisionAvailable(datacenter) {
		return this.poolInfo[this.stats.code]?.[datacenter] === true;
	}

	public fetchPoolInfo() {
		this.apiService
		.get('/v1/wpcr/available-data-centers', { })
			.subscribe(
				(response) => {
					this.poolInfo = response;
					if (this.itemCodeArray) {
						this.limitSelections();
					}
					this.setStats();
				},
				(error) => {
					setTimeout(() => this.fetchPoolInfo(), 3000)
				}
			);
	}

	public calcPrice() {
		const licensePrice = this.calcLicensePrice();
		const serverPrice = this.getServerPrice();
		return this.currencyFormatter.format(serverPrice + licensePrice);
	}

	/**
	 * Get price of server for display.
	 *
	 * @param term string The term length of plan - 'monthly' or 'yearly'.
	 * @param plan string Whether to get selected plan or current plan price - 'selected' or 'currentServer'.
	 * @returns
	 */
	public getServerPrice(term = 'monthly', plan = 'selected') {
		let price = 0;
		if ( plan === 'selected' ) {
			price = term === 'monthly'
				? this.stats.priceMonthly
				: this.stats.priceYearly;
		} else {
			price = term === 'monthly'
				? this.currentStats.priceMonthly
				: this.currentStats.priceYearly;
		}

		return this.currencyFormatter.format(price);
	}

	public getTodaysPrice() {
		let price = 0;
		const monthlyPrice = this.stats.priceMonthly;
		const monthlyAunnualPrice = this.stats.priceYearly / 12;

		price = monthlyAunnualPrice <= monthlyPrice ? monthlyAunnualPrice : monthlyPrice;

		if (this.couponData) {
			this.coupon = this.couponData.name.split(' - ')[0];
			if (this.couponData.discount_type === 'percent') {
				this.discount = `${this.couponData.discount_percent}%`;
				price = price * (1 - (this.couponData.discount_percent / 100));
			} else if (this.couponData.discount_type === 'dollars') {
				this.discount = `${this.couponData.discount_in_cents.amount / 100}`;
				price = price - (this.couponData.discount_in_cents.amount / 100);
				if (price < 0) {
					price = 0;
				}
			}
		}

		return this.currencyFormatter.format(price);
	}

	public calcLicensePrice() {
		const premiumPrice =
			'monthly' === this.termType
				? this.premiumKeyPriceMonthly
				: this.premiumKeyPriceYearly;
		const licensePrice = 'premium' === this.license ? premiumPrice : 0;
		return licensePrice;
	}

	public getServerName() {
		return this.value.toString();
	}

	public getPackage() {
		return {
			planCode: 'wphosting_tier_' + this.getServerName(),
			options: {
				allows_multiple: false,
				data_center: this.dataCenter,
			},
		};
	}

	public removePackageType(packages) {
		return packages.filter((val) => !val.planCode.startsWith('wphosting_tier_'));
	}

	public getSelections() {
		return [
			{
				type: 'hosting',
				label: 'Cloud VPS ' + this.getServerName(),
				name: 'cVPS-' + this.getServerName(),
				price: this.getServerPrice(),
				allows_multiple: false,
				is_recurring: true,
				term_label: 'monthly' === this.termType ? '1 Month' : '1 Year',
				product_term_id: 15,
				service_type: 'account',
			},
			{
				type: 'service',
				label:
					'premium' === this.license
						? 'Premium Project'
						: 'Basic Project',
				name:
					'premium' !== this.license
						? 'premium-connect-key'
						: 'free-connect-key',
				price: this.calcLicensePrice(),
				allows_multiple: false,
				is_recurring: true,
				term_label: 'monthly' === this.termType ? '1 Month' : '1 Year',
				product_term_id: 15,
				service_type: 'account',
			},
		];
	}

	public limitSelections() {
		const selections = JSON.parse(this.itemCodeArray);

		if (selections) {
			//const lowestLegendText = this.options.stepsArray[0].legend;
			//const highestLegendText = this.options.stepsArray[this.options.stepsArray.length - 1].legend;
			const itemCodes = selections.map((selection) =>
				parseInt(selection.itemCode, 10)
			);

			// Create array of all steps not found in itemCodes
			const removeSteps = this.options.stepsArray.filter((step) => !itemCodes.includes(step.value));

			// Remove steps from stepsArray
			removeSteps.forEach((step) => {
				this.options.stepsArray.splice(this.options.stepsArray.indexOf(step), 1);
			});

			// Re-attach legend values to steps
			//this.options.stepsArray[0].legend = lowestLegendText;
			//this.options.stepsArray[this.options.stepsArray.length - 1].legend = highestLegendText;

			this.value = itemCodes[0];
		}
	}

	public getStat( current, selected ) {
		let formattedStat = selected;

		// Format for the 'update plan' view.
		if ( this.isUpdatePlan ) {
			if ( current !== selected ) {
				if ( current === 'Unlimited' || selected === 'Unlimited' ) {
					formattedStat = current + ' → ' + '<span class="ctw-font-bold ctw-text-imh-blue">' + selected + '</span>';
				// If current plan doesn't have phone support, but selected plan does...
				} else if ( current === false && selected === true ) {
					formattedStat = 'Chat, Ticket, <span class="ctw-font-bold ctw-text-imh-blue">Phone</span>';

				// If current plan does have phone support, but select plan does not...
				} else if ( current === true && selected === false ) {
					formattedStat = 'Chat, Ticket, <span class="ctw-font-bold ctw-text-imh-red ctw-line-through">Phone</span>';
				} else {
					const parsedStat = parseInt( current, 10 ) || current;
					formattedStat = parsedStat + ' → ' + '<span class="ctw-font-bold ctw-text-imh-blue">' + selected + '</span>';
				}
			} else {
				// Current plan doesn't have phone support, and selected plan doesn't either...
				if ( current === false && selected === false ) {
					formattedStat = 'Chat, Ticket';

				// Both current plan and selected plan already have phone support...
				} else if ( current === true && selected === true ) {
					formattedStat = 'Chat, Ticket, Phone';
				}
			}

		// Format for the 'new plan' view.
		} else {
			// Format 24/7 Support section...
			if ( selected === true ) {
				formattedStat = 'Chat, Ticket, Phone';
			} else if ( selected === false ) {
				formattedStat = 'Chat, Ticket';
			}
		}

		return formattedStat;
	}
}
