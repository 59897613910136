<div *ngIf="state !== 'success'" class="card-content">
	<p *ngIf="errorMessage" class="error animated fadeInUp">
		Error: {{ errorMessage }}
	</p>
	<form novalidate (ngSubmit)="onSubmit(passwordWithValidation.value)" #updatePassword="ngForm">
		<div data-private>
			<mat-form-field [appearance]="this.appearance" style="width: 100%"
				[class.ctw-mb-0]="this.configService.config.brandConfig.id === 'imh'">
				<mat-label>Current Password</mat-label>
				<input matInput name="oldPassword" #oldPassword="ngModel" [(ngModel)]="model.oldPassword"
					placeholder="Current Password" type="password" ngDefaultControl required />
				<mat-error>
					<span>Password is required.</span>
				</mat-error>
			</mat-form-field>
			<mat-form-field [appearance]="this.appearance" style="width: 100%" [class.ctw-mt-0]="this.configService.config.brandConfig.id === 'imh'">
				<mat-label>New Password</mat-label>
				<input matInput #passwordWithValidation autocomplete="off" name="password"
					[type]="'submitted' === this.state ? 'password' : inputType" required [formControl]="
						passwordComponentWithValidation.passwordFormControl
					" placeholder="New Password" />
				<button type="button" mat-button matSuffix mat-icon-button aria-label="Show Password" (click)="
						inputType =
							'password' === inputType ? 'text' : 'password'
					">
					<mat-icon>remove_red_eye</mat-icon>
				</button>
				<mat-hint align="end" aria-live="polite">
					{{ passwordWithValidation.value.length }} / 32
				</mat-hint>
				<mat-error *ngIf="
						passwordComponentWithValidation.passwordFormControl.hasError(
							'required'
						)
					">
					Password is required
				</mat-error>
				<mat-error *ngIf="
						passwordComponentWithValidation.passwordFormControl.hasError(
							'pattern'
						)
					">
					Password is not valid
				</mat-error>
			</mat-form-field>

			<!--Password's strength info-->
			<div class="password-hint" [hidden]="!passwordWithValidation.value">
				<mat-password-strength #passwordComponentWithValidation [min]="8" [max]="32"
					[enableSpecialCharRule]="false" [password]="passwordWithValidation.value">
				</mat-password-strength>
				<mat-password-strength-info digitsCriteriaMsg="contains at least one numeric character"
					lowerCaseCriteriaMsg="contains at least one lowercase character"
					upperCaseCriteriaMsg="contains at least one uppercase character"
					[passwordComponent]="passwordComponentWithValidation"></mat-password-strength-info>
			</div>
		</div>

		<div class="action-buttons">
			<progress-button color="primary" [state]="state">Change Password</progress-button>
		</div>
	</form>
</div>
<div *ngIf="state === 'success'" class="animated fadeIn card-content">
	<h4>Updated Successfully</h4>
	<p>
		You can now use your new password to log in to
		{{ brandingService.getAppName() }} with the email:
		<strong>{{ this.email }}</strong>
	</p>
	<div class="right-align action-buttons">
		<button mat-raised-button mat-raised-button mat-ripple type="button" color="primary" (click)="reset()">
			Okay, Got It!
		</button>
	</div>
</div>
