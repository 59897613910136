<h2 class="ctw-capitalize">Create Snapshot</h2>
<p class="ctw-mb-8">Enter a name for your new snapshot to easily identify it later.</p>
<mat-form-field>
	<mat-label>{{this.placeholder}}</mat-label>
	<input [formControl]="snapshotFormControl" name="project-name" matInput [(ngModel)]="this.snapshotName" required
		pattern="^[a-zA-Z0-9]+$" />
	<mat-error *ngIf="snapshotFormControl.hasError('pattern')">Only alphanumeric(A-Z, 0-9) characters are
		allowed!</mat-error>
</mat-form-field>
<div class="ctw-text-right ctw-mt-10">
	<button mat-button (click)="this.closeDialog('cancel')">
		Cancel
	</button>
	<button [mat-dialog-close]="this.snapshotName" mat-raised-button color="primary" class="ctw-ml-3">
		Update
	</button>
</div>
