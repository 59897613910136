<div class="ctw-flex ctw-mb-4">
	<mat-icon *ngIf="this.data.icon" class="ctw-mr-2 ctw-mb-5 dash-card-icon ctw-text-5xl ctw-h-12 ctw-w-12">{{
		this.data.icon !== 'ultrastack' ? this.data.icon : ''
		}}
		<span *ngIf="this.data.icon === 'ultrastack'" inlineSVG="assets/img/ultrastack.svg"></span>
	</mat-icon>
	<h2 mat-dialog-title class="ctw-mb-auto ctw-capitalize">
		{{ this.data.title }}
	</h2>
</div>
<mat-dialog-content class="ctw-flex ctw-flex-col">
	<div *ngIf="this.data.before_video" [innerHTML]="this.data.before_video"></div>
	<div *ngIf="this.data.youtube" class="ctw-aspect-w-16 ctw-aspect-h-9 ctw-my-4">
		<iframe [src]="this.sanitizeUrl(this.data.youtube)" frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen></iframe>
	</div>
	<div *ngIf="this.data.after_video" class="ctw-mb-4" [innerHTML]="this.updateLinks(this.data.after_video)"></div>
</mat-dialog-content>
<div mat-dialog-actions class="ctw-justify-end ctw-text-right dialog-footer action-buttons ctw-pb-5">
	<button mat-raised-button color="primary" mat-dialog-close>OK</button>
</div>
