<ng-container>
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-my-16">
		<h2>Cache Profile</h2>
		<dash-card icon="manage_accounts" cardTitle="Cache Profile" class="dash-card profile" disabled="true"
			[transparent]="true">
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn ">
				<p>
					A Cache profile is a configuration for that is custom
					tailored to match your website's needs.
				</p>
				<form #f="ngForm" (ngSubmit)="updateProfile(f)" novalidate>
					<mat-radio-group [(ngModel)]="selectedProfile" (ngModelChange)="onProfileSelect($event)"
						name="profile" aria-label="Select your Nginx profile" class="ctw-flex ctw-flex-col ctw-py-4">
						<mat-radio-button *ngFor="let op of profiles" [checked]="selectedProfile === op.value"
							[disabled]="!this.available" value="{{ op.value }}" name="profile">
							<div class="ctw-inline-flex" [class.ctw-font-bold]="setProfile === op.value">
								{{ op.viewValue }}
							</div>
							<span class="ctw-text-sm mat-flat-button mat-accent ctw-font-bold ctw-ml-8"
								*ngIf="setProfile === op.value">Active</span>
						</mat-radio-button>
					</mat-radio-group>
					<p class="ctw-py-4">
						<mat-card appearance="outlined">
							<mat-card-content class="ctw-flex ctw-text-imh-gray-200">
								<mat-icon class="ctw-mr-1">info</mat-icon>
								<i class="ctw-w-full">{{ selectedDescription}}</i>
							</mat-card-content>
						</mat-card>
					</p>
					<div class="ctw-text-right ctw-mt-4">
						<central-progress-button *ngIf="this.available" [state]="requestState" type="submit"
							mat-raised-button color="primary" [disabled]="
								this.requestState === 'disabled' ||
								selectedProfile === setProfile
							">
							Switch to{{
							selectedProfile === setProfile
							? '...'
							: ' ' + this.getSeleted()
							}}
						</central-progress-button>
						<central-progress-button *ngIf="!this.available" [state]="requestState" type="submit"
							mat-raised-button color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During
							Update</central-progress-button>
					</div>
				</form>
			</div>
		</dash-card>
	</div>
</ng-container>
