<div class="ctw-cursor-pointer">
	<button
		class="ctw-col-span-2 button {{ this.manage ? 'manage mat-raised-button mat-accent' : 'mat-stroked-button' }}"
		[matMenuTriggerFor]="subMenu" matBadge="{{ this.manage ? null : null }}" matBadgePosition="before"
		[disabled]="!this.profileService.data?.display_name"><span *ngIf="!this.manage"
			class="ctw-align-middle ctw-mb-1 material-icons notranslate">groups</span>
		<div *ngIf="manage" style="margin-top: 1px;margin-right: 1px;">My Teams</div>
	</button>
</div>
<mat-menu #subMenu="matMenu" class="team-selector-menu">
	<button mat-menu-item class="selected-team ctw-bg-gray-200">
		<strong>{{ this.activeTeamName }}</strong>
		<button mat-stroked-button
			class="ctw-relative ctw-ml-8 ctw-px-2 ctw-text-sm ctw-font-semibold ctw-cursor-default selected-team ctw-bg-black ctw-text-white"
			(click)="$event.preventDefault()">
			Active Team</button>
	</button>
	<mat-divider></mat-divider>
	<div *ngIf="this.teams.length > 1"
		class="ctw-text-sm ctw-text-center ctw-bg-neutral-100 ctw-text-imh-gray-200 ctw-py-2">Switch To...
	</div>
	<div class="team-list ctw-max-h-96 notranslate">
		<ng-container *ngFor="let team of this.teams">
			<button mat-menu-item *ngIf="team.account_id !== this.activeTeam?.account_id" (click)="setTeam(team)">
				{{ team.display_name }}
			</button>
		</ng-container>
	</div>
	<div class="ctw-p-4">
		<button class="ctw-text-center ctw-w-full" mat-raised-button color="primary" routerLink="/account/memberships">
			Manage Teams
		</button>
	</div>
</mat-menu>
