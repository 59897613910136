import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

interface Brand {
	id: string;
	applicationName: string;
	logo: string;
	reseller: string;
	marketingSite: string;
	supportUrl: string;
	privacyPolicy: string;
	termsOfService: string;
}

@Injectable()
export class BrandingService {
	private brand: Brand;

	public constructor(private configService: ConfigurationService) {
		this.brand = this.configService.config.brandConfig;
	}

	public isDefault() {
		return this.brand.id === 'default';
	}

	public getId() {
		return this.brand.id;
	}

	public getAssetUrl(assetName: string): string {
		return './assets/branding/' + assetName;
	}

	public getThemedAssetUrl(assetName: string): string {
		let theme = 'default';

		const date = new Date();

		if (date.getMonth() === 12) {
			theme = 'xmas';
		}

		if (date.getMonth() === 5 && date.getDay() === 4) {
			theme = 'starwars';
		}

		// Assets are cached for a year
		// Bust cached theme assets on entry into the next year
		// eg. This december's themed logo may be different from last december
		return this.getAssetUrl(`${theme}/${assetName}?${date.getFullYear()}`);
	}

	public getBrandConfig() {
		return this.brand;
	}

	public getBrandOpt(option: string) {
		return this.brand[option];
	}

	public getAppName() {
		return this.brand.applicationName;
	}
}
