<mat-sidenav-container class="sidenav-container" [class.organization-view]="this.accountService.isOrganization()"
	*ngIf="state === 'success' || this.configService.config.brandConfig.id === 'imh'">
	<mat-sidenav [mode]="isMobile ? 'over' : 'side'" opened class="{{ this.configService.config.brandConfig.id }}" disableClose>
		<mat-nav-list>
			<mat-list-item routerLink="/" class="home">
				<mat-icon mat-list-icon>keyboard_arrow_left</mat-icon>
				<span matLine>Home</span>
			</mat-list-item>
			<div class="nav-items helper-nav">
				<mat-list-item routerLinkActive="router-link-active" *ngIf="this.accountService.isOrganization()"
					[routerLink]="[
					'/account/',
					this.authService.getAccountId(),
					'settings'
				]">
					<mat-icon mat-list-icon>info</mat-icon>
					<span matLine>Personal Profile</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item routerLinkActive="router-link-active" *ngIf="this.accountService.isOrganization()"
					[routerLink]="this.getMixedRoute('organizations')">
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine>My
						{{
						this.configService.config.groupLabels[
						'group-label-title-case'
						]
						}}s</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
			</div>

			<div class="identity {{this.configService.config.brandConfig.id === 'imh' ? 'ctw-flex ctw-relative ctw-z-10' : ''}}"
				[class.organization]="this.accountService.isOrganization()">
				<img [src]="profileService.data.image"
					class="{{this.configService.config.brandConfig.id !== 'imh' ? 'ctw-text-center' : 'avatar' }}"
					*ngIf="this.accountService.isUserAccountOwner() || this.configService.config.brandConfig.id === 'imh'"
					[class.ctw-text-center]="this.configService.config.brandConfig.id !== 'imh'" />
				<central-locale-settings
					*ngIf="this.configService.config.brandConfig.id === 'imh' && !this.accountService.isOrganization() "
					mode="language"></central-locale-settings>
				<central-team-selector [manage]="true"
					*ngIf="this.configService.config.brandConfig.id === 'imh' && this.accountService.isOrganization()"
					class="team-selector ctw-mx-auto ctw-mt-5 ctw-mr-5"
					(teamChange)="this.switchToAccount($event)"></central-team-selector>
				<div class="label" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
					<p *ngIf="!this.accountService.isOrganization()">
						{{
						this.accountService.isOrganization()
						? this.configService.config.groupLabels[
						'group-label-title-case'
						] + ' Account'
						: 'Your Personal Account'
						}}
					</p>
					<h3 class="notranslate">{{ this.currentAccount.display_name }}</h3>
				</div>
			</div>

			<div class="nav-items" [class.sidebar-disabled]="state === 'loading'"
				*ngIf="this.configService.config.brandConfig.id === 'imh'">
				<mat-list-item routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('notifications', this.accountService.isOrganization() ? 'team' : 'account')">
					<mat-icon mat-list-icon [attr.data-icon-count]="
								this.profileService.data.notifications
							">notifications</mat-icon>
					<span matLine>Notifications</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item routerLinkActive="router-link-active" *ngIf="this.accountService.isOrganization() "
					[routerLink]="this.getMixedRoute('members', this.accountService.isOrganization() ? 'team' : 'account')">
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine>Members</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<ng-container *ngIf="state === 'success'">
					<ng-container *ngFor="let item of this.projectMenuItems">
						<mat-list-item routerLinkActive="router-link-active" *ngIf="
								(this.accountService.isOrganization() &&
									item.organizationRoute &&
									this.hasAccess(item)) || (!this.accountService.isOrganization() &&
									!item.organizationRoute &&
									this.hasAccess(item))
							" [routerLink]="this.getMixedRoute(item.path, this.accountService.isOrganization() ? 'team' : 'account')"
							[class.router-link-active]="item.path === 'subscriptions' && (this.router.url.match('subscriptions') || this.router.url.match('receipts'))">
							<mat-icon mat-list-icon *ngIf="item.icon !== 'groups'">{{ item.icon }}</mat-icon>
							<span mat-list-icon *ngIf="item.icon === 'groups'"
								class="mat-icon material-icons notranslate">{{ item.icon
								}}</span>
							<span matLine>{{ item.label }}</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
					</ng-container>
				</ng-container>
				<mat-list-item *ngIf="state !== 'success'">
					<mat-icon mat-list-icon>{{ !this.accountService.isOrganization() ? 'fingerprint' :
						'credit_card_gear' }}</mat-icon>
					<span matLine>Preferences</span>
				</mat-list-item>
				<mat-list-item *ngIf="state === 'success'" routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('preferences', this.accountService.isOrganization() ? 'team' : 'account')">
					<mat-icon mat-list-icon>{{ !this.accountService.isOrganization() ? 'fingerprint' :
						'credit_card_gear' }}</mat-icon>
					<span matLine>Preferences</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: false }"
					[routerLink]="this.getMixedRoute('help/requests', this.accountService.isOrganization() ? 'team' : 'account')"
					*ngIf="state === 'success' && this.hasAccess({permission: 'support'}) && this.accountService.isOrganization() ">
					<mat-icon mat-list-icon>support_agent</mat-icon>
					<span matLine>Support</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item *ngIf="!this.accountService.isOrganization() " routerLinkActive="router-link-active"
					routerLink="/logout">
					<mat-icon mat-list-icon>logout</mat-icon>
					<span matLine class="ctw-text-red-700">Sign Out</span>
				</mat-list-item>
			</div>
			<div class="nav-items" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
				<mat-list-item routerLinkActive="router-link-active" [routerLink]="this.getMixedRoute('settings')">
					<mat-icon mat-list-icon>info</mat-icon>
					<span matLine>Profile</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item routerLinkActive="router-link-active" *ngIf="
						this.accountService.isOrganization() &&
						!this.accountService.isUserAccountOwner()
					" [routerLink]="this.getMixedRoute('members')">
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine>Members</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item class="organizations" routerLinkActive="router-link-active"
					*ngIf="!this.accountService.isOrganization()" [routerLink]="this.getMixedRoute('organizations')">
					<mat-icon mat-list-icon>people</mat-icon>
					<span matLine>{{
						this.configService.config.groupLabels[
						'group-label-title-case'
						]
						}}s</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item class="notifications" routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('notifications')" *ngIf="this.hasAccess({permission: 'support'})">
					<mat-icon mat-list-icon>notifications</mat-icon>
					<span matLine>Notifications</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item class="support" routerLinkActive="router-link-active"
					[routerLink]="this.getMixedRoute('help/requests')" *ngIf="
						!this.profileService.data.requires_email_verification &&
						this.hasAccess({permission: 'support'})
					">
					<mat-icon mat-list-icon>confirmation_number</mat-icon>
					<span matLine>Requests</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<mat-list-item routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: true }"
					[routerLink]="this.getMixedRoute('help')" *ngIf="this.hasAccess({permission: 'support'})">
					<mat-icon mat-list-icon>help</mat-icon>
					<span matLine>Support</span>
					<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
				</mat-list-item>
				<ng-container *ngFor="let item of this.projectMenuItems">
					<mat-list-item routerLinkActive="router-link-active" *ngIf="
							(this.accountService.isOrganization() &&
								item.organizationRoute &&
								this.hasAccess(item)) ||
							(!this.accountService.isOrganization() &&
								!this.configService.config.brandConfig.applicationName.includes(
									'InMotion'
								) &&
								item.accountRoute != false) ||
							this.accountService.isUserOrganization()
						" [routerLink]="this.getMixedRoute(item.path)">
						<mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
						<span matLine>{{ item.label }}</span>
						<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
					</mat-list-item>
				</ng-container>
			</div>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content class="ctw-p-8">
		<div class="mobileBar" *ngIf="this.isMobile">
			<button mat-raised-button color="primary" (click)="sidenav.open()">
				<mat-icon>menu</mat-icon> Menu
			</button>
		</div>
		<router-outlet
			*ngIf="this.configService.config.brandConfig.id !== 'imh' || (this.configService.config.brandConfig.id === 'imh' && this.state === 'success')"></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="this.isSwitchingAccounts" class="team-switching-overlay">
	<mat-spinner class="component-loader" diameter="50" color="primary"></mat-spinner>
	<div class="ctw-text-center ctw-text-xl">
		Switching Teams<br /><br />
	</div>
</div>
