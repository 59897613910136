<div>
	<h3 *ngIf="this.configService.config.brandConfig.id !== 'imh'" class="ctw-capitalize">Two Factor Authentication</h3>
	<h3 *ngIf="this.configService.config.brandConfig.id === 'imh'" class="ctw-capitalize">Two Factor Authentication
		(2FA)</h3>
	<p>
		Enable devices for Two Factor Authentication to increase the
		security of your account. Upon login, you will be prompted for verification on an enabled device.
	</p>
	<mat-spinner class="ctw-mx-auto ctw-mt-10" *ngIf="this.fetchSettingsState === 'loading'" [diameter]="30"
		[strokeWidth]="4"></mat-spinner>
	<div class="error" *ngIf="this.fetchSettingsState === 'failed'">
		Unable to load 2FA settings. Please try again later.
	</div>
	<table mat-table [dataSource]="this.dataSource" *ngIf="'success' === this.fetchSettingsState"
		[class.imh]="this.configService.config.brandConfig.id === 'imh'">
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Type</th>
			<td mat-cell *matCellDef="let device">
				<mat-icon *ngIf="device.name === 'email'">email</mat-icon>
				<mat-icon *ngIf="device.name === 'sms'">{{ this.configService.config.brandConfig.id !== 'imh' ? 'phone'
					: 'phone_iphone'}}</mat-icon>
			</td>
		</ng-container>

		<ng-container matColumnDef="message">
			<th mat-header-cell *matHeaderCellDef>Device</th>
			<td mat-cell *matCellDef="let device">
				<span *ngIf="device.message">{{ device.message }}</span>
				<span *ngIf="!device.message" class="error">Device not enabled</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="action" stickyEnd>
			<th mat-header-cell *matHeaderCellDef aria-label="row actions">
				&nbsp;
			</th>
			<td mat-cell *matCellDef="let device" class="ctw-text-right">
				<button mat-stroked-button *ngIf="!device.message" (click)="this.updateDevice(device, 'enable-device')"
					[class.mat-primary]="
						this.configService.config.brandConfig.id === 'imh'
					">
					<mat-icon
						[class]="this.configService.config.brandConfig.id === 'imh' ? 'ctw-text-2xl ctw-mb-1 ctw-mr-1' : ''">add</mat-icon>
					<span>Enable Device</span>
				</button>
				<button mat-stroked-button color="warn" *ngIf="device.message"
					(click)="this.deleteDevice(device, 'enable-device')">
					<span>Disable</span>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>

<ng-template #twoFaModal>
	<div class="two-fa-modal">
		<div [hidden]="!(this.state === 'enableDevice' && this.deviceToUpdate)"
			[class.update-device-prompt]="this.configService.config.brandConfig.id !== 'imh'">
			<h3 *ngIf="this.configService.config.brandConfig.id !== 'imh'" class="ctw-capitalize">Enable Two Factor
				Authentication</h3>
			<h2 *ngIf="this.configService.config.brandConfig.id === 'imh'" class="ctw-capitalize">Enable 2FA</h2>
			<p
				[class.ctw-pb-3]="this.configService.config.brandConfig.id === 'imh' && this.deviceToUpdate?.name === 'sms'">
				Enter the
				{{
				this.getDeviceDescription(this.deviceToUpdate?.name) ||
				'device'
				}}
				to use for two factor authentication below.
			</p>
			<div *ngIf="this.deviceToUpdate?.name === 'sms'" class="phone-input">
				<central-phone-number value="" [isRequired]="true" [inContactForm]="true"
					#phoneNumber></central-phone-number>
			</div>
			<mat-form-field data-private
				appearance="{{ this.configService.config.brandConfig.id === 'imh' ? 'outline' : 'fill' }}"
				*ngIf="this.deviceToUpdate?.name === 'email'">
				<mat-label *ngIf="this.configService.config.brandConfig.id === 'imh'">
					{{ this.deviceToUpdate?.name === 'sms' ? 'Mobile Number' : 'Email Address' }}
				</mat-label>
				<input [(ngModel)]="this.emailInput" email type="email" #emailField="ngModel" required matInput
					name="primaryEmail" placeholder="jdoe@company.com" />
				<mat-error>
					<ng-container *ngIf="emailField.errors?.required">This field is required.</ng-container>
					<ng-container *ngIf="emailField.errors?.email">Invalid Format. Please use the following format
						user@domain.tld</ng-container>
				</mat-error>
			</mat-form-field>

			<div class="update-message ctw-text-center ctw-mt-5">
				<div class="error" [class.visible]="this.sendCodeState === 'failed'">
					{{ this.sendError }}
				</div>
			</div>

			<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
				<button mat-ripple mat-raised-button color="accent" class="btn-spinner send-code-button"
					(click)="this.handleSendCodeSubmit()" [disabled]="this.sendCodeState === 'submitted'">
					<mat-spinner diameter="20" *ngIf="this.sendCodeState == 'submitted'"></mat-spinner>
					Send Code
				</button>
				<button type="button" (click)="this.handletwoFaCancel()" class="cancel-button" mat-raised-button>
					<mat-icon>arrow_back</mat-icon> Cancel
				</button>
			</ng-container>
			<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
				<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-pb-5"
					[class.ctw-mt-10]="this.deviceToUpdate?.name !== 'email'">
					<button mat-button (click)="this.handletwoFaCancel()"
						class="cancel-button {{this.configService.config.brandConfig.id}}">
						Cancel
					</button>
					<button mat-ripple mat-raised-button color="primary"
						class="btn-spinner send-code-button ctw-ml-3 {{this.configService.config.brandConfig.id}}"
						(click)="this.handleSendCodeSubmit()" [disabled]="this.sendCodeState === 'submitted'">
						<mat-spinner diameter="20" *ngIf="this.sendCodeState === 'submitted'"></mat-spinner>
						<mat-icon *ngIf="this.sendCodeState !== 'submitted'">{{this.deviceToUpdate?.name === 'email' ?
							'forward_to_inbox' : 'send_to_mobile'}}</mat-icon> Send Code
					</button>
				</mat-dialog-actions>
			</ng-container>
		</div>

		<ng-container *ngIf="this.state === 'validateCode'">
			<central-two-factor-auth [mode]="setup" [twoFaUserData]="this.twoFaUserData" [action]="'update-twofactor'"
				inputAppearance="outline" [showDeviceSelection]="false" [showRememberMe]="false"
				[codeFailure]="this.sendCodeError" [state]="this.verifyState" [selection]="this.deviceToUpdate?.name"
				[customRetry]="true" (retry)="this.handleSendCodeSubmit()" (twoFaCancel)="this.handletwoFaCancel()"
				(twoFaSubmit)="this.handleVerifyCodeSubmit($event)"></central-two-factor-auth>
		</ng-container>

		<ng-container *ngIf="this.state === 'recoveryCodes'">
			<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
				<h3 class="ctw-capitalize">Recovery Codes</h3>

				<p>
					Recovery codes are used to access your account in the event that
					you cannot access your device to receive a two-factor
					authentication code.
				</p>
				<div class="alert">
					<mat-icon>report_problem</mat-icon>Save your recovery codes
					before continuing.
				</div>

				<div class="recovery-codes">
					<ul>
						<li *ngFor="let code of this.recoveryCodes">{{ code }}</li>
					</ul>
				</div>
				<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
					<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-4">
						<button type="button" color="primary" (click)="this.handletwoFaCancel()" class="cancel-button"
							mat-raised-button>
							Complete
						</button>
					</mat-dialog-actions>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
				<h2 class="ctw-capitalize">2FA Backup Codes</h2>
				<p>
					2FA backup codes are used to recover access to your account in the event that
					you cannot access your device(s).
				</p>
				<div class="recovery-codes">
					<mat-form-field appearance="outline" class="ctw-w-full ctw-mt-8">
						<mat-label>Backup Codes</mat-label>
						<textarea matInput readonly rows="5" value='{{this.recoveryCodes.join("\n")}}'></textarea>
						<div class="copy ctw-absolute ctw-right-0">
							<button class="ctw-ml-1" mat-stroked-button mat-button (click)="copyRecoveryCodes()">
								Copy
							</button>
						</div>
						<mat-hint class="ctw-flex ctw-text-red-700 ctw-text-sm" id="mat-hint-3"><span
								class="ctw-mr-1 ctw-mt-1 mat-icon material-icons notranslate">error</span> Please save your recovery
							codes before continuing.</mat-hint>
					</mat-form-field>
				</div>
				<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
					<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-p-0 ctw-pb-5">
						<button type="button" color="primary" (click)="this.handletwoFaCancel()" mat-raised-button>
							OK, I Saved Them
						</button>
					</mat-dialog-actions>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</ng-template>
