<central-header *ngIf="this.configurationService.config.brandConfig.id === 'imh'" title="Team Members"
	[status]="fetchStatus" actions="membership" (action)="this.header($event)"></central-header>

<mat-progress-bar class="loader" mode="indeterminate"
	*ngIf="this.configurationService.config.brandConfig.id !== 'imh' && this.fetchStatus === 'loading'"></mat-progress-bar>

<div class="members" [class]="
		this.configurationService.config.brandConfig.id === 'imh'
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16'
			: ''
	">
	<h2>{{ resourceTitle }}</h2>
	<p>Invite team members to collaborate.</p>
	<mat-spinner *ngIf="
			this.configurationService.config.brandConfig.id !== 'imh' &&
			this.fetchStatus === 'loading'
		" class="ctw-mt-20 ctw-mx-auto" diameter="50"></mat-spinner>
	<central-failed-request *ngIf="this.fetchStatus === 'failed'"></central-failed-request>
	<ng-container *ngIf="this.fetchStatus === 'success'">
		<div class="invite-member" *ngIf="
		this.configurationService.config.brandConfig.id !== 'imh' && ((this.hasEditAccess() && !this.isReadOnly) ||
				this.isOwner)
			">
			<button mat-raised-button color="{{
					this.configurationService.config.buttonColor || 'primary'
				}}" (click)="this.openAddDialog()">
				Invite Member
			</button>
		</div>

		<contextual-help-card *ngIf="this.configurationService.config.brandConfig.id === 'imh'" cardTitle="Team Members"
			icon="group" disabled="true" [transparent]="true">
			<mat-table [dataSource]="this.members" class="team-members animate__animated animate__fadeIn">
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef="let member">
						<div class="member-identity" *ngIf="member.account">
							<div mat-line class="name notranslate">
								{{ member.account.name }}
							</div>
							<div mat-line class="email notranslate"
								[class.mask-pii]="this.isDemo && member.account.email !== this.profileService.data.email">
								{{ member.account.email }}
							</div>
						</div>
						<div class="member-identity" *ngIf="!member.account" [class.mask-pii]="this.isDemo">
							<div mat-line class="name">
								{{ member.invite_email }}
							</div>
							<div>
								<div class="badge {{ this.configurationService.config.brandConfig.id }}-{{member.invite_status.state ===
									'pending' ? 'ok' : 'warn'}} ctw-capitalize">
									<ng-container *ngIf="this.configurationService.config.brandConfig.id === 'imh'">
										{{ member.invite_status.state === 'pending' ? 'Membership' : 'Invitation' }}
										{{ member.invite_status.state }}
									</ng-container>
									<ng-container *ngIf="this.configurationService.config.brandConfig.id !== 'imh'">
										Invite {{ member.invite_status.state
										}}
									</ng-container>
									<ng-container *ngIf="
											member.invite_status.state ===
											'pending'
										"></ng-container>
								</div>
								<div class="badge ctw-ml-3 ctw-capitalize" *ngIf="
										member.invite_status.state === 'pending'
									">
									Expires
									{{
									member.invite_status.expires
									| amDateFormat : 'LLL'
									}}
								</div>
							</div>
						</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="role">
					<mat-header-cell class="flex-end" *matHeaderCellDef>Role</mat-header-cell>
					<mat-cell class="flex-end role" *matCellDef="let member">{{
						this.getRoleLabel(member.role)
						}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="has2fa" *ngIf="feature2fa">
					<mat-header-cell class="flex-end" *matHeaderCellDef>2FA</mat-header-cell>
					<mat-cell class="flex-end role {{
							member?.account?.has2fa ? '' : 'warning'
						}}" *matCellDef="let member">
						<span *ngIf="member.account">{{
							this.get2faLabel(member?.account?.has2fa)
							}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="options">
					<ng-container *ngIf="
							this.configurationService.config.brandConfig.id ===
							'imh'
						">
						<mat-header-cell class="flex-end" *matHeaderCellDef></mat-header-cell>
					</ng-container>
					<ng-container *ngIf="
							this.configurationService.config.brandConfig.id !==
							'imh'
						">
						<mat-header-cell class="flex-end" *matHeaderCellDef>Options</mat-header-cell>
					</ng-container>
					<mat-cell class="flex-end" *matCellDef="let member">
						<ng-container *ngIf="
								(this.canChangeAccess(member) &&
									!this.isReadOnly) ||
								this.isOwner
							">
							<ng-container *ngIf="
									this.configurationService.config.brandConfig
										.id === 'imh'
								">
								<button mat-stroked-button [matMenuTriggerFor]="memberOptions"
									[class.ctw-border-none]="this.configurationService.config.brandConfig.id === 'imh'">
									<mat-icon class="settings-icon">more_vert</mat-icon>
								</button>
							</ng-container>
							<ng-container *ngIf="
									this.configurationService.config.brandConfig
										.id !== 'imh'
								">
								<button mat-raised-button [matMenuTriggerFor]="memberOptions">
									<mat-icon class="settings-icon">settings</mat-icon>
								</button>
							</ng-container>
							<mat-menu #memberOptions="matMenu" xPosition="before">
								<button *ngIf="!this.isOwner" (click)="this.permissionsDialog(member)" mat-menu-item>
									<mat-icon>{{ this.configurationService.config.brandConfig.id === 'imh' ? 'security'
										: 'edit'}}</mat-icon>{{ this.configurationService.config.brandConfig.id !==
									'imh' ? 'Edit' : '' }} Permissions
								</button>
								<button *ngIf="!member.account" (click)="this.openAddDialog(member)" mat-menu-item>
									<mat-icon>{{ this.configurationService.config.brandConfig.id === 'imh' ?
										'forward_to_inbox' : 'refresh'}}</mat-icon>Resend Invite
								</button>
								<mat-divider
									*ngIf="this.configurationService.config.brandConfig.id === 'imh'"></mat-divider>
								<button (click)="this.openDeleteDialog(member)" mat-menu-item
									[class.ctw-text-red-700]="this.configurationService.config.brandConfig.id === 'imh'">
									<mat-icon
										[class.ctw-text-red-700]="this.configurationService.config.brandConfig.id === 'imh'">{{
										this.configurationService.config.brandConfig.id === 'imh' ?
										'person_off' : 'delete_forever'}}</mat-icon>{{
									!member.account
									? 'Revoke Invite'
									: 'Remove Member'
									}}
								</button>
							</mat-menu>
						</ng-container>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
		</contextual-help-card>

		<mat-table *ngIf="this.configurationService.config.brandConfig.id !== 'imh'" [dataSource]="this.members">
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
				<mat-cell *matCellDef="let member">
					<div class="member-identity" *ngIf="member.account">
						<div mat-line class="name">
							{{ member.account.name }}
						</div>
						<div mat-line class="email">
							{{ member.account.email }}
						</div>
					</div>
					<div class="member-identity" *ngIf="!member.account">
						<div mat-line class="name">
							{{ member.invite_email }}
						</div>
						<div>
							<div class="badge">
								Invite {{ member.invite_status.state
								}}<ng-container *ngIf="
										member.invite_status.state === 'pending'
									"></ng-container>
							</div>
							<div class="badge ctw-ml-3" *ngIf="member.invite_status.state === 'pending'">
								Expires
								{{
								member.invite_status.expires
								| amDateFormat : 'LLL'
								}}
							</div>
						</div>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="role">
				<mat-header-cell class="flex-end" *matHeaderCellDef>Role</mat-header-cell>
				<mat-cell class="flex-end role" *matCellDef="let member">{{
					this.getRoleLabel(member.role)
					}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="has2fa" *ngIf="feature2fa">
				<mat-header-cell class="flex-end" *matHeaderCellDef>2FA</mat-header-cell>
				<mat-cell class="flex-end role {{
						member?.account?.has2fa ? '' : 'warning'
					}}" *matCellDef="let member">
					<span *ngIf="member.account">{{
						this.get2faLabel(member?.account?.has2fa)
						}}</span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="options">
				<mat-header-cell class="flex-end" *matHeaderCellDef>Options</mat-header-cell>
				<mat-cell class="flex-end" *matCellDef="let member">
					<ng-container *ngIf="
							(this.canChangeAccess(member) &&
								!this.isReadOnly) ||
							this.isOwner
						">
						<button mat-raised-button [matMenuTriggerFor]="memberOptions">
							<mat-icon class="settings-icon">settings</mat-icon>
						</button>
						<mat-menu #memberOptions="matMenu" xPosition="before">
							<button *ngIf="!this.isOwner" (click)="this.permissionsDialog(member)" mat-menu-item>
								<mat-icon>edit</mat-icon>Edit Permissions
							</button>
							<button *ngIf="!member.account" (click)="this.openAddDialog(member)" mat-menu-item>
								<mat-icon>refresh</mat-icon>Resend Invite
							</button>
							<button (click)="this.openDeleteDialog(member)" mat-menu-item>
								<mat-icon>delete_forever</mat-icon>{{
								!member.account
								? 'Revoke Invite'
								: 'Remove Member'
								}}
							</button>
						</mat-menu>
					</ng-container>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
	</ng-container>
</div>
