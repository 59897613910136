import { Input, Component, OnInit, OnDestroy } from '@angular/core';

@Component({
	selector: 'central-server-software',
	templateUrl: './server-software.component.html',
	styleUrls: ['./server-software.component.scss'],
})
export class ServerSoftwareComponent implements OnInit {

	public hasUpdates;
	public projectService;
	public ansibleService;

	constructor() {	}

	ngOnInit() {
		this.hasUpdates =
			this.ansibleService.latestVersion !==
				this.projectService.environment.fields.playbook_values.version;
	}
}
