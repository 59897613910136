<!-- <p>create works!</p> -->
<div class="ctw-mx-auto ctw-max-w-screen-lg ctw-mt-10">
	<h2>
		New
		{{
			this.configurationService.config.groupLabels[
				'group-label-title-case'
			]
		}}
	</h2>
	<div class="ctw-mb-10">
		Creating
		{{
			this.configurationService.config.groupLabels[
				'group-label-title-case'
			]
		}}s allows you to share resources between your teammates.
	</div>
	<form #form="ngForm" (ngSubmit)="form.valid && submit()">
		<mat-form-field appearance="fill">
			<mat-label
				>{{
					this.configurationService.config.groupLabels[
						'group-label-title-case'
					]
				}}
				Name</mat-label
			>
			<input
				matInput
				[disabled]="this.state === 'submitted'"
				name="organizationName"
				placeholder="Your {{
					this.configurationService.config.groupLabels[
						'group-label-title-case'
					]
				}} Name"
				required
				[(ngModel)]="organizationName"
				#name="ngModel"
			/>
			<mat-error>
				<ng-container *ngIf="name.errors?.required"
					>This field is required.</ng-container
				>
			</mat-error>
		</mat-form-field>
		<ng-container *ngIf="!this.websiteOptional">
			<mat-form-field appearance="fill">
				<mat-label>Website</mat-label>
				<input
					matInput
					required
					(blur)="this.fixUrls()"
					type="url"
					[(ngModel)]="orgWebsite"
					required
					#website="ngModel"
					centralValidationUrl
					name="orgWebsite"
					placeholder="www.corporate-website.com"
				/>
				<mat-error>
					<ng-container *ngIf="website.errors?.required"
						>This field is required.</ng-container
					>
					<ng-container *ngIf="!website.errors?.required"
						>Invalid URL format.</ng-container
					>
				</mat-error>
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="this.websiteOptional">
			<mat-form-field appearance="fill">
				<mat-label>Website</mat-label>
				<input
					matInput
					(blur)="this.fixUrls()"
					type="url"
					[(ngModel)]="orgWebsite"
					#website="ngModel"
					centralValidationUrlOptional
					name="orgWebsite"
					placeholder="www.corporate-website.com"
				/>
				<mat-error>
					<ng-container *ngIf="website.errors?.required"
						>This field is required.</ng-container
					>
					<ng-container *ngIf="!website.errors?.required"
						>Invalid URL format.</ng-container
					>
				</mat-error>
			</mat-form-field>
		</ng-container>

		<mat-form-field appearance="fill">
			<mat-icon
				class="help-icon"
				matTooltip="This is typically a department email address. Ticket updates & deployment notifications will go here."
				>help_outline</mat-icon
			>
			<mat-label>Contact Email</mat-label>
			<input
				matInput
				type="email"
				autocomplete="off"
				[disabled]="this.state === 'submitted'"
				name="contactEmail"
				placeholder="engineering@company.com"
				required
				[(ngModel)]="contactEmail"
				#contact="ngModel"
				email
			/>
			<mat-error>
				<ng-container *ngIf="contact.errors?.required"
					>This field is required.</ng-container
				>
				<ng-container *ngIf="contact.errors?.email"
					>Invalid Format. Please use the following format
					user@domain.tld</ng-container
				>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="fill">
			<mat-icon
				class="help-icon"
				matTooltip="Your receipts, invoices, etc. will be sent to the Billing Email. If not set, billing items will be sent to the Contact Email."
				>help_outline</mat-icon
			>
			<mat-label>Billing Email</mat-label>
			<input
				matInput
				type="email"
				autocomplete="off"
				[disabled]="this.state === 'submitted'"
				name="billingEmail"
				placeholder="billing@company.com"
				[(ngModel)]="billingEmail"
				#billing="ngModel"
				email
			/>
			<mat-error>
				<ng-container *ngIf="billing.errors?.email"
					>Invalid Format. Please use the following format
					user@domain.tld</ng-container
				>
			</mat-error>
		</mat-form-field>

		<div class="update-message ctw-mt-5">
			<div class="error" *ngIf="this.state === 'failed'">
				An error occurred while processing your request.
			</div>
		</div>

		<div class="navigation">
			<progress-button [state]="state" color="primary"
				>Create</progress-button
			>
		</div>
	</form>
</div>
