import { Injectable, Optional } from "@angular/core";
import { TrackingService } from "../tracking.service";
import { ScriptService } from "../../core/script/script.service";

export interface SubTrackingService {
    name: string;
    script: string;
    onEvent(event: string, data: object): void;
    onSetUser(userId: number, email: string): void;
    appendScript(): void;
}
export interface AfterAppend {
    afterAppend(): void;
}

export interface OnSetUser {
    setUser(userId: number, email: string): void;
}

@Injectable({
    providedIn: 'root',
})
export class SubTrackingService implements SubTrackingService {

    name: string;
    script: string;

    constructor(
        protected trackingService: TrackingService,
        @Optional() protected scriptService?: ScriptService,
    ) {
        this.name = this.constructor.name;
        this.trackingService.onAppendScript$.subscribe( () => this.init() );
    }


    init() {
        this.appendScript().then(() => {
            this['afterAppend']?.();
            this.trackingService.onEvent$.subscribe(({event, data }) => {
                this.onEvent(event, data);
            });
            this.trackingService.onSetUser$.subscribe(([user, email]) => {
                this.onSetUser(user, email);
            });
        });
    }

    async appendScript(): Promise<any> {
        return new Promise((resolve) => {
            if( this.script ) {
                this.scriptService.load(this.script).then(() => resolve({}));
            } else {
                resolve({});
            }
        });
    }

    onEvent(event: string, data: object) {
        try {
            this?.[event]?.(data);
        } catch (error) {
            console.error(`${this.name}: Error in tracking event`, error);
        }
    }

    onSetUser(userId: number, email: string) {
        try {
            this?.['setUser']?.(userId, email);
        } catch (error) {
            console.error(`${this.name}: Error in setting user'`, error);
        }
    }
    [event: string]: any;
}