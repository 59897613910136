<central-header title="{{this.domainName || '&nbsp;'}}" [status]="verificationStatus" actions="registrar"
	breakpoint="6xl"></central-header>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16">
	<failed-request *ngIf="'failed' === requestState"></failed-request>

	<ng-container *ngIf="'succeeded' === requestState">
		<h2>
			Registration
		</h2>
		<dash-card cardTitle="Registration Details" icon="language" [transparent]="true" class="ctw-mb-16">
			<div class="">
				<div class="grid-container">
					<div>
						<p>
							Domain:
							<span class="value">{{ this.domainName }}</span>
						</p>

						<p>
							Registered:
							<span class="value">{{ domainCreateDate }}</span>
						</p>
						<p>
							Expires:
							<span class="value">{{ domainExpireDate }}</span>
						</p>

						<p *ngIf="verificationStatus === 'verified'">
							Status:
							<mat-progress-spinner *ngIf="'loading' === domainStatusRequestState" class="inline-spinner"
								mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<span *ngIf="'succeeded' === domainStatusRequestState" class="value ctw-capitalize"
								[class.ctw-text-red-700]="
									domainStatus !== 'Active'
								" [class.ctw-text-green-700]="
									domainStatus === 'Active'
								">{{ domainStatus }}</span>

							<span *ngIf="'failed' === domainStatusRequestState"
								class="error-label ctw-text-red-700">Error retrieving domain status</span>
						</p>

						<p *ngIf="verificationStatus !== 'verified'">
							<span>
								Status:
							</span>

							<mat-progress-spinner *ngIf="
									'loading' === verificationStatusRequestState
								" class="inline-spinner" mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<span *ngIf="
									'succeeded' ===
									verificationStatusRequestState
								" class="value ctw-capitalize" [class.tooltip]="verificationStatus === 'Suspended'" [class.ctw-text-red-700]="
									verificationStatus === 'Suspended' ||
									verificationStatus === 'Awaiting Verification'
								" [class.ctw-text-green-700]="
									verificationStatus !== 'Suspended' &&
									verificationStatus !== 'Awaiting Verification'
								" [matTooltipDisabled]="verificationStatus !== 'Suspended'"
								matTooltip="It appears that the domain was suspended due to lack of verification. To fix this, you must resend the verification email to a valid address and confirm verification via the email.">{{
								verificationStatus }}</span>

							<a class="simple-button spacer" *ngIf="
									'succeeded' === verificationStatusRequestState &&
									'inactive' === resendRequestState &&
									(
										verificationStatus === 'Suspended' ||
										verificationStatus === 'Awaiting Verification'
									)
								" (click)="resendVerification()">[re-send email]</a>

							<mat-progress-spinner *ngIf="
									'succeeded' === verificationStatusRequestState &&
									'loading' === resendRequestState &&
									(
										verificationStatus === 'Suspended' ||
										verificationStatus === 'Awaiting Verification'
									)
								" class="inline-spinner" mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>
							<mat-icon class="resend" *ngIf="
									'succeeded' === verificationStatusRequestState &&
									'succeeded' === resendRequestState &&
									(
										verificationStatus === 'Suspended' ||
										verificationStatus === 'Awaiting Verification'
									)
								">check</mat-icon>


							<ng-container *ngIf="
									'failed' === verificationStatusRequestState
								">
								<span class="error-label ctw-text-red-700">Error retrieving verification status</span>
								<button class="simple-button spacer" (click)="getVerificationStatus()">
									[try again]
								</button>
							</ng-container>

							<mat-icon
								matTooltip="When a domain is registered or transferred, or when the registrant contact information is changed, the registrant must reply to an email confirming the submitted contact information is correct."
								class="ctw-text-base ctw-absolute ctw-ml-1" style="margin-top: 2px;">help</mat-icon>
						</p>

						<!-- <p>Auto Renew:
							<mat-progress-spinner *ngIf="'loading' === autoRenewRequestState || 'loading' === domainStatusRequestState" class="inline-spinner" mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<ng-container *ngIf="'succeeded' === autoRenewRequestState || 'inactive' === autoRenewRequestState && 'succeeded' === domainStatusRequestState">
								<span class="value">{{ autoRenewEnabled ? 'On' : 'Off' }}</span>
								<button class="simple-button spacer" (click)="toggleAutoRenew()">[{{ autoRenewEnabled ? 'disable' : 'enable' }}]</button>
							</ng-container>

							<ng-container *ngIf="'failed' === domainStatusRequestState || 'update-failed' === autoRenewRequestState">
								<span class="error-label">Error {{ 'failed' === domainStatusRequestState ? 'retrieving' : 'updating' }} auto renew</span>
								<button class="simple-button spacer" (click)="toggleAutoRenew()">[try again]</button>
							</ng-container>
						</p> -->

						<!-- <button mat-raised-button color="primary" (click)="renewDomain()">Renew</button> -->

						<!-- <p>
							<span class="tooltip" matTooltip="Replaces your information with that of our forwarding service">Domain Privacy: </span>

							<ng-container *ngIf="domainName.endsWith( '.us' )">
								<span class="value">Prohibited <a href="https://www.about.us/policies/us-privacy-services-policy" target="_blank">[?]</a></span>
							</ng-container>

							<ng-container *ngIf="! domainName.endsWith( '.us' )">
								<span class="value">{{ domainPrivacyEnabled ? 'On' : 'Off' }}</span>
								<button class="simple-button spacer" (click)="toggleDomainPrivacy()">[{{ domainPrivacyEnabled ? 'disable' : 'enable' }}]</button>
							</ng-container>
						</p> -->

						<p>
							<span>Auth-Code: <span *ngIf="'inactive' === authCodeRequestState"
									class="ctw-font-bold">&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
							</span>

							<button class="simple-button spacer" *ngIf="'inactive' === authCodeRequestState"
								(click)="getAuthCode()">
								[retrieve]
							</button>

							<mat-progress-spinner *ngIf="'loading' === authCodeRequestState" class="inline-spinner"
								mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<central-text-copy *ngIf="'succeeded' === authCodeRequestState" class="notranslate">{{
								domainAuthCode
								}}</central-text-copy>

							<ng-container *ngIf="'failed' === authCodeRequestState">
								<span class="error-label">Error retrieving auth-code</span>
								<button class="simple-button spacer" (click)="getAuthCode()">
									[try again]
								</button>
							</ng-container>

							<mat-icon matTooltip="Required for transferring the domain to another registrar"
								class="ctw-text-base ctw-absolute ctw-ml-1" style="margin-top: 2px;">help</mat-icon>
						</p>
					</div>
					<dash-card cardTitle="NS" icon="dns" disabled="true" [subTitle]="[
				'The authoritative source of the domain\'s DNS records'
			]" [transparent]="true" class="ctw-m-0">

						<p>
							<span>Domain Lock: </span>

							<mat-progress-spinner *ngIf="
						'loading' ===
							domainLockStatusRequestState ||
						'loading' === domainStatusRequestState
					" class="inline-spinner" mode="indeterminate" color="primary" diameter="16"></mat-progress-spinner>

							<ng-container *ngIf="
						'succeeded' ===
							domainLockStatusRequestState ||
						('inactive' ===
							domainLockStatusRequestState &&
							'succeeded' ===
								domainStatusRequestState)
					">
								<span *ngIf="!domainLockSupported" class="value">Unsupported</span>

								<ng-container *ngIf="domainLockSupported">
									<span class="value ctw-capitalize">{{
										domainLockEnabled ? 'On' : 'Off'
										}}</span>
									<button class="simple-button spacer" (click)="toggleDomainLock()">
										[{{
										domainLockEnabled
										? 'disable'
										: 'enable'
										}}]
									</button>
								</ng-container>
							</ng-container>

							<ng-container *ngIf="
						'failed' === domainStatusRequestState ||
						'update-failed' ===
							domainLockStatusRequestState
					">
								<span class="error-label">Error
									{{
									'failed' === domainStatusRequestState
									? 'retrieving'
									: 'updating'
									}}
									lock status</span>
								<button class="simple-button spacer" (click)="getDomainLock()">
									[try again]
								</button>
							</ng-container>

							<mat-icon matTooltip="Helps prevent unwanted changes to the domain"
								class="ctw-text-base ctw-absolute ctw-ml-1" style="margin-top: 2px;">help</mat-icon>
						</p>


						Name Servers<span *ngIf="domainLockEnabled">:</span>
						<span *ngIf="!domainLockEnabled && !editingNameServers">
							<button class="simple-button spacer" (click)="editingNameServers = true">
								[edit]</button>
						</span>
						<div class="ctw-mb-8">
							<ng-container *ngIf="!editingNameServers">
								<ul
									[matTooltip]="domainLockEnabled ? 'Disable domain lock to edit name servers' : null">
									<li *ngFor="let nameServer of nameServers" class="monospace">
										{{ nameServer }}
									</li>
								</ul>
								<div class="ctw-py-4" *ngIf="domainLockEnabled && 0">
									<span>(Disable domain lock to edit
										name servers)</span>
								</div>
							</ng-container>

							<div *ngIf="editingNameServers" class="ctw-mt-4">
								<ng-container *ngFor="
							let nameServer of editedNameServers;
							index as i;
							trackBy: trackByIndex
						">
									<div>
										<mat-form-field class="name-server-input" appearance="outline">
											<mat-label>Name Server {{ i + 1 }}</mat-label>
											<input matInput [(ngModel)]="editedNameServers[i]"
												placeholder="{{ nameServer }}" value="{{ nameServer }}"
												pattern="^((?!-)([-0-9a-z]{0,62})?[0-9a-z](\.(?!$)|$)){3,}" />
											<button *ngIf="i > 1" class="delete-name-server-button" mat-icon-button
												color="warn" (click)="editedNameServers.splice(i, 1)"
												class="ctw-absolute ctw-right-0 ctw-top-0" style="margin: -3px;">
												<mat-icon>delete_forever</mat-icon>
											</button>
										</mat-form-field>
									</div>
								</ng-container>

								<p *ngIf="'update-failed' === nameServerRequestState" class="error-label inline-error">
									Error updating name servers
								</p>

								<div class="name-server-buttons">
									<div class="expand">

										<button mat-stroked-button color="warn" (click)="
									editedNameServers = [
										'ns1.inmotionhosting.com',
										'ns2.inmotionhosting.com'
									]
								">
											<mat-icon>settings_backup_restore</mat-icon>
											Defaults
										</button>
										<button *ngIf="editedNameServers.length < 13" mat-stroked-button color="primary"
											(click)="editedNameServers.push('')" class="ctw-ml-3">
											<mat-icon>add</mat-icon> Add
										</button>
									</div>
									<div class="ctw-text-right">
										<button mat-button (click)="revertNameServerChanges()">
											Cancel
										</button>
										<central-progress-button color="primary" [state]="nameServerProgressButtonState"
											(click)="saveNameServers()" class="ctw-ml-3">{{
											nameServerProgressButtonTitle
											}}</central-progress-button>
									</div>
								</div>
							</div>
						</div>
					</dash-card>
				</div>
			</div>
		</dash-card>


		<h2>
			WHOIS Information
		</h2>
		<dash-card cardTitle="WHOIS Information" icon="contact_page" [transparent]="true">
			<div>
				<mat-tab-group>
					<mat-tab label="Owner" class="ctw-w-1/4">
						<central-contact-set *ngIf="!editingContactSets"
							[(contactSet)]="contactSets.owner"></central-contact-set>
						<central-contact-set-edit *ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.owner"
							[disableAll]="'loading' === contactSetRequestState"></central-contact-set-edit>
					</mat-tab>
					<mat-tab *ngIf="contactSets.hasOwnProperty('admin')" label="Administrator" class="ctw-w-1/4">
						<central-contact-set *ngIf="!editingContactSets"
							[(contactSet)]="contactSets.admin"></central-contact-set>
						<central-contact-set-edit *ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.admin"
							[disableAll]="'loading' === contactSetRequestState"></central-contact-set-edit>
					</mat-tab>
					<mat-tab *ngIf="contactSets.hasOwnProperty('billing')" label="Billing" class="ctw-w-1/4">
						<central-contact-set *ngIf="!editingContactSets"
							[(contactSet)]="contactSets.billing"></central-contact-set>
						<central-contact-set-edit *ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.billing"
							[disableAll]="'loading' === contactSetRequestState"></central-contact-set-edit>
					</mat-tab>
					<mat-tab *ngIf="contactSets.hasOwnProperty('tech')" label="Technical" class="ctw-w-1/4">
						<central-contact-set *ngIf="!editingContactSets"
							[(contactSet)]="contactSets.tech"></central-contact-set>
						<central-contact-set-edit *ngIf="editingContactSets"
							[(editedContactSet)]="editedContactSets.tech"
							[disableAll]="'loading' === contactSetRequestState"></central-contact-set-edit>
					</mat-tab>
				</mat-tab-group>

				<div *ngIf="editingContactSets" class="contact-set-buttons ctw-mt-10">
					<div class="expand">
						<span *ngIf="'update-failed' === contactSetRequestState" class="error-label inline-error">Error
							updating WHOIS information</span>
					</div>
					<div>
						<button mat-button (click)="revertContactSetsChanges()"
							[disabled]="'loading' === contactSetRequestState">
							Cancel
						</button>
						<central-progress-button color="primary" [state]="contactSetProgressButtonState"
							(click)="saveContactSets()" class="ctw-ml-3">{{
							contactSetProgressButtonTitle
							}}</central-progress-button>
					</div>
				</div>
			</div>
			<button mat-stroked-button color="primary" *ngIf="!editingContactSets" (click)="editingContactSets = true">
				Edit
			</button>
		</dash-card>
	</ng-container>
</div>
