import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';

import { ApiService, ProfileService } from '@central/ng-shared';
import { Config } from '../../../shared/config/env.config';
import { ResourceRecord, ContactSet, ContactSets } from './contact-set/contact-set.types';

@Component({
	selector: 'central-domain-registration',
	templateUrl: './domain-registration.component.html',
	styleUrls: ['./domain-registration.component.scss'],
})
export class DomainRegistrationComponent implements OnInit, OnDestroy {
	public requestState = 'loading';

	public domainName: string;

	public domainStatus = 'Active';
	public domainStatusRequestState = 'loading';
	public domainLockSupported: boolean;
	public domainLockCanModify: boolean;
	public domainLockEnabled: boolean;
	public domainLockStatusRequestState = 'inactive';

	public verificationStatus: string;
	public verificationStatusRequestState = 'loading';
	public resendRequestState = 'inactive';

	public domainCreateDate: string;
	public domainExpireDate: string;
	public autoRenewEnabled: boolean;
	public autoRenewRequestState = 'inactive';

	// public domainPrivacyEnabled: boolean;
	// public domainPrivacyRequestState = 'loading';

	public domainAuthCode: string;
	public authCodeRequestState = 'inactive';

	public nameServers: string[];
	public editedNameServers: string[];
	public editingNameServers = false;
	public nameServerRequestState = 'inactive';
	public nameServerProgressButtonState = '';
	public nameServerProgressButtonTitle = 'Save';

	public contactSets: ContactSets;
	public editedContactSets: ContactSets;
	public editingContactSets = false;
	public contactSetRequestState: string;
	public contactSetProgressButtonState = '';
	public contactSetProgressButtonTitle = 'Save';

    private subscriptions = [];

	constructor(
		private route: ActivatedRoute,
		public dialog: MatDialog,
		private httpClient: HttpClient,
		private clipboard: Clipboard,
		private apiService: ApiService,
		private profileService: ProfileService,
	) {}

	ngOnInit(): void {
        this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
                this.domainName = this.route.snapshot.paramMap.get('domainName');

                const url =
                    Config['host'] + '/v1/domain-service/domain/' + this.domainName;
                const headers = this.apiService.getHeaders({
                    contentType: 'application/json',
                });
                this.httpClient.get(url, { headers }).subscribe(
                    (response) => {
                        if (response['is_success'] === '1') {
                            const expire =
                                response['attributes']['registry_expiredate'];
                            const isoDate =
                                expire.slice(0, 10) + 'T' + expire.slice(11) + '.000Z';
                            if (new Date(isoDate) < new Date()) {
                                this.domainStatus = 'Expired';
                            }
                            this.getDomainStatus();
                            this.getVerificationStatus();

                            this.domainCreateDate = response['attributes'][
                                'registry_createdate'
                            ].substr(0, 10);
                            this.domainExpireDate = response['attributes'][
                                'registry_expiredate'
                            ].substr(0, 10);
                            this.autoRenewEnabled =
                                response['attributes']['auto_renew'] === '1';

                            // this.getDomainPrivacy();

                            this.nameServers = Object.values(
                                response['attributes']['nameserver_list']
                            ).map((nameServer) => nameServer['name']);
                            this.editedNameServers = [...this.nameServers];

                            this.contactSets = response['attributes']['contact_set'];
                            this.editedContactSets = JSON.parse(
                                JSON.stringify(this.contactSets)
                            );

                            this.requestState = 'succeeded';
                        } else {
                            this.requestState = 'failed';
                        }
                    },
                    () => {
                        this.requestState = 'failed';
                    }
                );
            })
        )
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	private getDomainStatus() {
		this.domainStatusRequestState = 'loading';

		const url =
			Config['host'] +
			'/v1/domain-service/domain/status/' +
			this.domainName;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.get(url, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					this.domainLockSupported =
						response['attributes']['domain_supports'] === '1';
					this.domainLockCanModify =
						response['attributes']['can_modify'] === '1';
					this.domainLockEnabled =
						response['attributes']['lock_state'] === '1';
					if (
						response['attributes'].hasOwnProperty(
							'transfer_away_in_progress'
						)
					) {
						this.domainStatus = 'Transfer Away Pending';
					}

					this.domainStatusRequestState = 'succeeded';
				} else {
					this.domainStatusRequestState = 'failed';
				}
			},
			() => {
				this.domainStatusRequestState = 'failed';
			}
		);
	}

	public getVerificationStatus() {
		this.verificationStatusRequestState = 'loading';

		const url =
			Config['host'] +
			'/v1/domain-service/domain/verification-status/' +
			this.domainName;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.get(url, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					// response['attributes']['days_to_suspend']
					// response['attributes']['email_bounced']
					switch (
						response['attributes']['registrant_verification_status']
					) {
						case 'admin_reviewing':
							this.verificationStatus = 'Manual Review';
							break;
						case 'pending':
							this.verificationStatus = 'Pending';
							break;
						case 'suspended':
							this.verificationStatus = 'Suspended';
							break;
						case 'verified':
							this.verificationStatus = 'Verified';
							break;
						case 'verifying':
							this.verificationStatus = 'Awaiting Verification';
							break;
						case 'unverified':
							this.verificationStatus = 'Unverified';
							break;
						default:
							this.verificationStatus = 'Unknown';
					}
					// response['attributes']['verification_deadline']
					this.verificationStatusRequestState = 'succeeded';
				} else {
					this.verificationStatusRequestState = 'failed';
				}
			},
			() => {
				this.verificationStatusRequestState = 'failed';
			}
		);
	}

	/*public toggleAutoRenew() {
		this.autoRenewRequestState = 'loading';

		// TODO: make call to Recurly

		const url = Config['host'] + '/v1/domain-service/domain/expire-action';
		const headers = this.apiService.getHeaders( { contentType: 'application/json' } );
		const body = {
			domainName: this.domainName,
			expireAction: this.autoRenewEnabled ? 'expire' : 'renew',
		};

		this.httpClient.patch( url, body, { headers } ).subscribe(
			( response ) => {
				if (response['is_success'] === '1') {
					this.autoRenewEnabled = ! this.autoRenewEnabled;
					this.autoRenewRequestState = 'succeeded';
				} else {
					this.autoRenewRequestState = 'update-failed';
				}
			},
			() => {
				this.autoRenewRequestState = 'update-failed';
			}
		);
	}*/

	/*public renewDomain() {
		const url = Config['host'] + '/v1/domain-service/domain/renew/' + this.domainName;
		const headers = this.apiService.getHeaders( { contentType: 'application/json' } );

		this.httpClient.patch( url, { headers } ).subscribe(
			( response ) => {
				if (response['is_success'] === '1') {
					this.domainExpireDate = response['attributes']['registration expiration date'].substr(0, 10);
					// this.renewRequestState = 'succeeded';
				} else {
					// this.renewRequestState = 'update-failed';
				}
			},
			( error ) => {
				console.log(error);
			}
		);
	}*/

	/*public getDomainPrivacy() {
		// code here
	}*/

	/*public toggleDomainPrivacy() {
		// code here
	}*/

	public toggleDomainLock() {
		this.domainLockStatusRequestState = 'loading';

		const url = Config['host'] + '/v1/domain-service/domain/lock-state';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const body = {
			domainName: this.domainName,
			lockState: this.domainLockEnabled ? '0' : '1',
		};
		this.httpClient.patch(url, body, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					if (!this.domainLockEnabled) {
						this.editingNameServers = false
					}
					this.domainLockEnabled = !this.domainLockEnabled;
					this.domainLockStatusRequestState = 'succeeded';
				} else {
					this.domainLockStatusRequestState = 'update-failed';
				}
			},
			() => {
				this.domainLockStatusRequestState = 'update-failed';
			}
		);
	}

	public resendVerification() {
		this.resendRequestState = 'loading';
		const url =
			Config['host'] +
			'/v1/domain-service/domain/verification/' +
			this.domainName;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.post(url, {}, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					this.resendRequestState = 'succeeded';
					setTimeout(() => {
						this.resendRequestState = 'inactive'
					}, 5000);
				} else {
					this.resendRequestState = 'failed';
				}
			},
			() => {
				this.resendRequestState = 'failed';
			}
		);
	}

	public getAuthCode() {
		this.authCodeRequestState = 'loading';
		const url =
			Config['host'] +
			'/v1/domain-service/domain/auth-code/' +
			this.domainName;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.httpClient.get(url, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					this.domainAuthCode =
						response['attributes']['domain_auth_info'];
					this.authCodeRequestState = 'succeeded';
				} else {
					this.authCodeRequestState = 'failed';
				}
			},
			() => {
				this.authCodeRequestState = 'failed';
			}
		);
	}

	public trackByIndex(index: number, item: any): any {
		return index;
	}

	public revertNameServerChanges() {
		this.editedNameServers = [...this.nameServers];
		this.editingNameServers = false;
	}

	public saveNameServers() {
		this.nameServerRequestState = 'loading';
		this.nameServerProgressButtonState = 'submitted';
		this.nameServerProgressButtonTitle = 'Saving...';

		this.editedNameServers.forEach(function (nameServer, index, array) {
			array[index] = nameServer.trim();
		});

		// remove empty strings
		this.editedNameServers = this.editedNameServers.filter(
			(nameServer) => nameServer
		);

		const url = Config['host'] + '/v1/domain-service/domain/name-servers';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const body = {
			domainName: this.domainName,
			nameServers: [...this.editedNameServers],
		};
		this.httpClient.patch(url, body, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					this.nameServers = [...this.editedNameServers];
					this.editingNameServers = false;
					this.nameServerRequestState = 'succeeded';
				} else {
					this.nameServerRequestState = 'update-failed';
				}
				this.nameServerProgressButtonState = '';
				this.nameServerProgressButtonTitle = 'Save';
			},
			(error) => {
				console.log(error);
				this.nameServerRequestState = 'update-failed';
				this.nameServerProgressButtonState = '';
				this.nameServerProgressButtonTitle = 'Save';
			}
		);
	}

	public revertContactSetsChanges() {
		this.editedContactSets = JSON.parse(JSON.stringify(this.contactSets));
		this.editingContactSets = false;
	}

	public saveContactSets() {
		this.contactSetRequestState = 'loading';
		this.contactSetProgressButtonState = 'submitted';
		this.contactSetProgressButtonTitle = 'Saving...';

		const url = Config['host'] + '/v1/domain-service/domain/contact-sets';
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const body = {
			domainName: this.domainName,
			contactSets: { ...this.editedContactSets },
		};

		this.httpClient.patch(url, body, { headers }).subscribe(
			(response) => {
				if (response['is_success'] === '1') {
					this.contactSets = JSON.parse(
						JSON.stringify(this.editedContactSets)
					);
					this.editingContactSets = false;
					this.contactSetRequestState = 'succeeded';
				} else {
					this.contactSetRequestState = 'update-failed';
				}
				this.contactSetProgressButtonState = '';
				this.contactSetProgressButtonTitle = 'Save';
			},
			(error) => {
				this.contactSetRequestState = 'update-failed';
				this.contactSetProgressButtonState = '';
				this.contactSetProgressButtonTitle = 'Save';
			}
		);
	}
}
