import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-email-subscription-setup',
	templateUrl: './email-subscription-setup.component.html',
	styleUrls: ['./email-subscription-setup.component.scss'],
})
export class EmailSubscriptionSetupComponent implements OnInit {
	@Input() private accountId: string;
	@Input() private purchaseId: string;
	@Input() private subscriptionUuid: string;
	@Output() cpanelProvisionSuccessEvent = new EventEmitter<{
		purchaseId: string;
		domainName: string;
		cpanelUsername: string;
	}>();

	public organizationRole: string;
	public progressButtonState = '';
	public progressButtonTitle = 'Save Configuration';
	public provisionError = false;

	constructor(
		private apiService: ApiService,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === this.accountId
		);
		if (access) {
			this.organizationRole = access.role;
		}
	}

	public provisionCpanel(domainName) {
		this.provisionError = false;
		this.progressButtonState = 'submitted';
		this.progressButtonTitle = 'Saving Configuration...';

		this.apiService
			.post(`/v1/accounts/${this.accountId}/email`, {
				subscription_uuid: this.subscriptionUuid,
				domain_name: domainName,
			})
			.subscribe(
				(response: any[]) => {
					this.cpanelProvisionSuccessEvent.emit({
						purchaseId: this.purchaseId,
						domainName: domainName,
						cpanelUsername: response[0],
					});
				},
				(error) => {
					this.progressButtonState = '';
					this.progressButtonTitle = 'Save Configuration';
					this.provisionError = true;
				}
			);
	}
}
