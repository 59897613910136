// eslint-disable-next-line
import { HttpUrlEncodingCodec } from '@angular/common/http';

export class BgQueryEncoder extends HttpUrlEncodingCodec {
	public encoder = new HttpUrlEncodingCodec();

	/**
	 * Encode the value of a param. Also changes + signs to %2B.
	 *
	 * By default the following characters are not encoded. ! $ \' ( ) * + , ; A 9 - . _ ~ ? /
	 * We are overriding so that + signs are interpreted litterally when they exist inside values.
	 * Otherwise they would be interperited as spaces.
	 *
	 * For more information see:
	 * https://angular.io/docs/ts/latest/api/http/index/URLSearchParams-class.html
	 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/encodeURIComponent
	 * http://php.net/manual/en/function.rawurldecode.php
	 * https://angular.io/docs/ts/latest/api/http/index/QueryEncoder-class.html
	 *
	 * @param   v Value to be encoded.
	 * @return    Value encoded.
	 */
	public encodeValue(v: string): string {
		const valueEncoded = this.encoder.encodeValue(v);
		return valueEncoded.replace(/\+/g, '%2B');
	}
}
