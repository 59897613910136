import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../branding/branding.service';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	constructor(
		private titleService: Title,
		private brandingService: BrandingService
	) {}

	public setTitle(title: string): void {
		this.titleService.setTitle(
			title + ' | ' + this.brandingService.getAppName()
		);
	}
}
