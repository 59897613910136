import { Component, HostBinding } from '@angular/core';
import { Animations } from '../../shared/animation/animations';
import { BrandingService } from '@central/ng-shared';

@Component({
	selector: 'reset-sent',
	templateUrl: 'reset-sent.component.html',
	styleUrls: ['reset-sent.component.scss'],
	animations: Animations.unauth,
})
export class ResetSentComponent {
	@HostBinding('class.collpased-logo') public collpasedLogo = true;
	@HostBinding('@routeAnimation') public animation = 'true';

	public constructor(public brandingService: BrandingService) {}
}
