<div class="row">
	<div class="col m6 s12">
		<span class="loading-info" *ngIf="
				status === 'fetching' &&
				this.configurationService.config.brandConfig.id !== 'imh'
			"><mat-spinner diameter="20"></mat-spinner>Retreiving Contact
			Information...</span>
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput maxlength="40" class="validated" name="firstName"
				#firstName="ngModel" [(ngModel)]="this.model.firstName" placeholder="First Name" type="text" required
				[class.mask-pii]="this.isDemo" />
			<mat-hint class="animate__animated error" align="start"
				[class.animate__fadeIn]="invalidInputState(firstName)">
				<span *ngIf="!model.firstName">First name is required.</span>
				<span *ngIf="model.firstName && !firstName.valid">First name is not valid.</span>
			</mat-hint>
		</mat-form-field>
	</div>
	<div class="col m6 s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput maxlength="40" #lastName="ngModel" required class="validated"
				[(ngModel)]="this.model.lastName" name="lastName" placeholder="Last Name" type="text"
				[class.mask-pii]="this.isDemo" />
			<mat-error>
				<span *ngIf="lastName.errors?.required">Last name is required.</span>
			</mat-error>
		</mat-form-field>
	</div>
</div>
<div class="row" [class.clearfix]="this.minimalForm && this.isDisabled('organization') && this.isDisabled('email')">
	<div class="col m6 s12">
		<central-phone-number *ngIf="!this.isDisabled('phone')" [isRequired]="true" [inContactForm]="true"
			[readOnlyForm]="readOnlyForm" (change)="this.updatePhone()" [value]="model.phone"
			[showLabel]="this.minimalForm" #phoneNumber></central-phone-number>
	</div>
	<div class="col m6 s12">
		<mat-form-field *ngIf="!this.isDisabled('organization')">
			<input [readonly]="readOnlyForm" matInput class="validated" maxlength="100" autocomplete="organization"
				name="organization" [(ngModel)]="this.model.companyName" placeholder="Company Name" type="text" />
		</mat-form-field>
		<email-input #emailInput *ngIf="!this.isDisabled('email')" (change)="this.model.email = emailInput.input.value"
			[forceLogin]="this.forceLogin" [loginModalTitle]="this.loginModalTitle"
			[loginModalSubTitle]="this.loginModalSubTitle" [organizationEmail]="this.organizationEmail"></email-input>
	</div>
</div>
<div class="row" *ngIf="!this.minimalForm">
	<div class="col s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput class="validated" maxlength="200" name="address1"
				placeholder="Address Line 1" type="text" required #address1="ngModel" [(ngModel)]="this.model.address1"
				[class.mask-pii]="this.isDemo" />
			<mat-hint align="start" class="animate__animated error"
				[class.animate__fadeIn]="invalidInputState(address1)">
				<span *ngIf="!model.address1">Address line 1 is required.</span>
			</mat-hint>
		</mat-form-field>
	</div>
</div>
<div class="row" [hidden]="this.minimalForm">
	<div class="col s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput maxlength="100" class="validated" name="address2"
				[(ngModel)]="this.model.address2" placeholder="Address Line 2" type="text" />
		</mat-form-field>
	</div>
</div>
<div class="row" *ngIf="!this.minimalForm">
	<div class="col m6 s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput maxlength="100" class="validated" name="city" #city="ngModel"
				placeholder="City" [(ngModel)]="this.model.city" type="text" required [class.mask-pii]="this.isDemo" />
			<mat-hint align="start" class="animate__animated error" [class.animate__fadeIn]="invalidInputState(city)">
				<span *ngIf="!model.city">City is required.</span>
				<span *ngIf="model.city">City is not valid.</span>
			</mat-hint>
		</mat-form-field>
	</div>
	<div class="col m6 s12">
		<div>
			<mat-form-field>
				<input [readonly]="readOnlyForm" matInput aria-label="State" [required]="!!regionOptions.length"
					(click)="autoCompleteOpened('state')" [matAutocomplete]="stateAuto"
					[formControl]="autofillControls['state'].control" name="state"
					[placeholder]="this.getStateLabel()" [class.mask-pii]="this.isDemo" />
				<mat-autocomplete #stateAuto="matAutocomplete">
					<mat-option value="{{ state.id }}" *ngFor="
							let state of autofillControls['state']
								.filteredOptions | async
						" class="ctw-text-lg">
						{{ state.display }}
					</mat-option>
				</mat-autocomplete>
				<mat-hint align="start" class="animate__animated error" [class.animate__fadeIn]="
						invalidInputState(autofillControls['state'].control)
					">
					<span>{{ this.getStateLabel() }} is required.</span>
				</mat-hint>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="row">
	<div class="col m6 s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput class="validated" name="zip" #postalCode="ngModel"
				[(ngModel)]="this.model.postalCode" placeholder="Postal Code" type="text" required
				[class.mask-pii]="this.isDemo" />
			<mat-hint align="start" class="animate__animated error"
				[class.animate__fadeIn]="invalidZipInput(postalCode)">
				<span *ngIf="!model.postalCode">Postal code is required.</span>
				<span *ngIf="model.postalCode">Postal code is not valid.</span>
			</mat-hint>
		</mat-form-field>
	</div>
	<div class="col m6 s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput aria-label="Country" [matAutocomplete]="countryAuto"
				(click)="autoCompleteOpened('country')" (blur)="autoCompleteClosed('country')" 
				[formControl]="autofillControls['country'].control" name="country" 
				placeholder="Country" required [class.mask-pii]="this.isDemo" />
			<mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="displayCountry">
				<mat-option value="{{ country.iso }}" *ngFor="
						let country of autofillControls['country']
							.filteredOptions | async
					" class="ctw-text-lg">
					{{ country.name }}
				</mat-option>
			</mat-autocomplete>
			<mat-hint align="start" class="animate__animated error" [class.animate__fadeIn]="!isValidCountry()">
				<span>Country is invalid.</span>
			</mat-hint>
		</mat-form-field>
	</div>
</div>
<div class="row" *ngIf="this.minimalForm && (this.isZipValid() || this.unrecognizedCountry)">
	<div class="col s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput class="validated" maxlength="200" name="address1"
				placeholder="Street Address" type="text" required #address1="ngModel" [(ngModel)]="this.model.address1"
				[class.mask-pii]="this.isDemo" />
			<mat-hint align="start" class="animate__animated error"
				[class.animate__fadeIn]="invalidInputState(address1)">
				<span *ngIf="!model.address1">Street Address is required.</span>
			</mat-hint>
		</mat-form-field>
	</div>
</div>
<div class="row" *ngIf="this.minimalForm && this.unrecognizedCountry">
	<div class="col m6 s12">
		<mat-form-field>
			<input [readonly]="readOnlyForm" matInput maxlength="100" class="validated" name="city" #city="ngModel"
				placeholder="City" [(ngModel)]="this.model.city" type="text" required [class.mask-pii]="this.isDemo" />
			<mat-hint align="start" class="animate__animated error" [class.animate__fadeIn]="invalidInputState(city)">
				<span *ngIf="!model.city">City is required.</span>
				<span *ngIf="model.city">City is not valid.</span>
			</mat-hint>
		</mat-form-field>
	</div>
	<div class="col m6 s12">
		<div>
			<mat-form-field>
				<input [readonly]="readOnlyForm" matInput aria-label="State" [required]="!!regionOptions.length"
					[matAutocomplete]="stateAuto" [formControl]="autofillControls['state'].control" name="state"
					[placeholder]="this.getStateLabel()" [class.mask-pii]="this.isDemo" />
				<mat-autocomplete #stateAuto="matAutocomplete">
					<mat-option value="{{ state.id }}" *ngFor="
							let state of autofillControls['state']
								.filteredOptions | async
						">
						{{ state.display }}
					</mat-option>
				</mat-autocomplete>
				<mat-hint align="start" class="animate__animated error" [class.animate__fadeIn]="
						invalidInputState(autofillControls['state'].control)
					">
					<span>{{ this.getStateLabel() }} is required.</span>
				</mat-hint>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="row" *ngIf="this.vatTax === true">
	<div class="col m6 s12 ctw-mt-3">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="this.isBusiness">
			<div class="ctw-flex">
				<div class="ctw-w-full">Is the purchaser a business?</div>
				<div class="ctw-flex ctw-flex-col sm:ctw-flex-row">
					<mat-radio-button class="ctw-ml-1 ctw-mb-2" [value]="true">
						<span>Yes</span>
					</mat-radio-button>
					<mat-radio-button class="ctw-ml-1 sm:ctw-ml-3 ctw-mb-2" [value]="false">
						<span>No</span>
					</mat-radio-button>
				</div>
			</div>
		</mat-radio-group>
	</div>
	<div class="col m6 s12">
		<div *ngIf="this.isBusiness === true">
			<mat-form-field>
				<input [(ngModel)]="this.model.taxId" name="vattax" [placeholder]="'VAT/Tax ID'"
					type="text" #vatInfo="ngModel" matInput required class="validated" />
				<mat-hint align="start" class="animate__animated error"
					[class.animate__fadeIn]="invalidInputState(vatInfo)">
					<span *ngIf="!model.taxId">VAT/Tax ID is required.</span>
				</mat-hint>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="clearfix"></div>

<ng-content></ng-content>
