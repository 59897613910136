import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { merge } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService, ProfileService } from '../authentication/services';
import { ConfigurationService } from '../core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
	// eslint-disable-next-line
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['../../sidebar.scss', './account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(MatSidenav) public sidenav;
	public accountId;
	public profileSub;
	public state = 'loading';
	public currentAccount;
	public projectMenuItems = [];
	public isMobile = false;
	public isOrganization = false;
	public isSwitchingAccounts = false;
	public sidenavIsOpen = false;

	private routerSub;
	private subscriptions = [];

	public capabilities = {
		owner: ['manage', 'write', 'read', 'support', 'billing'],
		maintainer: ['write', 'read', 'support'],
		member: ['read', 'support'],
		orgSupport: ['support'],
		orgBilling: ['billing'],
	};

	constructor(
		public accountService: AccountService,
		public authService: AuthService,
		public profileService: ProfileService,
		private mediaQuery: BreakpointObserver,
		public configService: ConfigurationService,
		public router: Router,
		private activeRoute: ActivatedRoute,
		public snackBar: MatSnackBar,
	) {
		this.setAccount();
		this.projectMenuItems = this.getProjectMenuItems();
	}

	ngOnInit(): void {
		this.profileSub = this.profileService.profileChange.subscribe(() => {
			this.applyAuthAccounts();
		});

		this.routerSub = this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				if (this.isMobile) {
					this.sidenav.close();
				}
			}
		});
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.mediaQuery.observe('(max-width: 1000px)').subscribe((res) => {
				this.isMobile = res.matches;
				this.applyResponsiveness();
			})
		)

		// Fix for sidebars in sidebars.
		setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
	}

	ngOnDestroy(): void {
		if (this.profileSub) {
			this.profileSub.unsubscribe(); // Causing speed on reload issues + subscriber error !
		}

		if (this.routerSub) {
			this.routerSub.unsubscribe();
		}

		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	public hasAccess(item) {
		let hasAccess = this.currentAccount ? true : false;
		if (hasAccess && item.permission) {
			hasAccess = this.capabilities[this.currentAccount.role].includes(
				item.permission
			);
		}

		return hasAccess;
	}

	/**
	 * Find additional routes with an account prefix.
	 *
	 * @returns array
	 */
	public getProjectMenuItems(): any[] {
		let projectMenuItems = [];
		this.router.config.find((val) => {
			if ('account' === val.path) {
				// InMotion Central
				projectMenuItems = val.children.filter(
					(child) => !!child['icon']
				);
			} else if ('' === val.path && val.children) {
				// OpenMetal Central
				val.children.find((item) => {
					if (
						item.path.startsWith('account') &&
						!item.path.includes('checkout')
					) {
						projectMenuItems = item.children.filter(
							(child) => !!child['icon']
						);
					}
				});
			}
		});

		return projectMenuItems;
	}

	public applyResponsiveness() {
		if (this.sidenav) {
			if (this.isMobile && this.sidenavIsOpen) {
				this.sidenav.close();
			} else {
				if (!this.sidenavIsOpen) {
					this.sidenav.open().then((sidenavIsOpen: boolean) => {
						this.sidenavIsOpen = sidenavIsOpen
					});
				}
			}
		}
	}

	setCurrentAccount(currentId) {
		this.currentAccount = this.profileService.data.account_access.find(
			(account) => account.account_id === currentId
		);
	}

	public navigate(account) {
		const defaultRoute = ['settings'];
		if (this.currentAccount.account_id !== account.account_id) {
			this.currentAccount = account;
			let curRoute = this.router.url.split('/').slice(3);
			switch (curRoute[0]) {
				case 'members':
					if ('organization' !== this.currentAccount.type) {
						curRoute = defaultRoute;
					}
					break;
				case 'billing':
					if (
						-1 ===
						this.accountService.roles['manage'].indexOf(
							this.currentAccount.role
						)
					) {
						curRoute = defaultRoute;
					}
					break;
			}
			this.router.navigate(
				['/account/', this.currentAccount.account_id].concat(curRoute)
			);
		}
	}

	public switchToAccount(event) {
		if (!this.isSwitchingAccounts) {
			switch (event) {
				case 'pending':
					this.isSwitchingAccounts = true;
					break;
				case 'complete':
					//
					break;
				case 'failed':
					this.isSwitchingAccounts = false
					break;
				default:
					const curRoute = this.router.url.split('/').slice(3);
					this.isSwitchingAccounts = true;
					this.router.navigate(
						[(this.configService.config.brandConfig.id === 'imh' ? '/team/' : '/account/'), event].concat(curRoute)
					);
					break;
			}
		} else {
			console.log(event);
		}
	}

	public applyAuthAccounts() {
		// Preselect the current account from the drop down.
		this.setCurrentAccount(this.accountId);
		this.isOrganization = !!this.accountService.account?.organization_id;
		this.accountService.isUser = !this.isOrganization;
	}

	public getMixedRoute(name, prefix = 'account') {
		const segments = name.split('/');
		let route = [`/${prefix}`, this.accountService.getResourceId()];
		if (!this.activeRoute.snapshot.params.account_id) {
			route = [`/${prefix}`];
		}

		return [...route, ...segments];
	}

	public setAccount() {
		this.subscriptions.push(
			this.activeRoute.params.subscribe((routeParams) => {
				this.accountId =
					routeParams.account_id || this.authService.getAccountId();

				if (this.configService.config.brandConfig.id === 'imh') {
					if (!this.accountId) {
						// Customer reloaded one of their logged-in user's account pages, or naviated directly to one
						// from outside of the application. this.accountId will be undefined when this components's
						// setAccount method is called, rendering a non-functioning view. We need to try again once
						// auth completes.
						this.profileService.onReady().subscribe(() => {
							this.accountId = this.profileService.data.account_access[0].account_id;
							this.isOrganization = !!routeParams.account_id;
							this.accountService.isUser = !this.isOrganization;
						})
					} else {
						this.isOrganization = !!routeParams.account_id;
						this.accountService.isUser = !this.isOrganization;
					}

					if (this.isOrganization) {
						window['Weglot'].off("languageChanged");
						const weglotContainer = document.querySelector('.weglot-container');
						const hiddenContainer = document.querySelector('.ctw-weglot-container');
						if (weglotContainer) {
							hiddenContainer.appendChild(weglotContainer);
						}
					}
				}

				this.subscriptions.push(
					merge(
						...[
							this.accountService.fetch(this.accountId),
							this.profileService.onReady(),
						]
					).subscribe({
						complete: () => {
							this.applyAuthAccounts();
							this.isSwitchingAccounts = false
							this.state = 'success';
							setTimeout(() => this.applyResponsiveness());

							if (this.isOrganization &&
								this.configService.config.brandConfig.id === 'imh' &&
								this.accountId !== this.profileService.data.options['public_selected_team']) {

								this.profileService.update({ public_selected_team: this.accountId }).subscribe(() => {
									const teamName = this.profileService.data.account_access.filter((team) => team.account_id === this.profileService.data.options.public_selected_team)[0]?.display_name;
									this.snackBar.open(`You are now working inside of ${teamName}!`, '', {
										...this.configService.config.snackBar,
									})
								});
							}
						},
						error: () => {
							if (this.configService.config.brandConfig.id === 'imh') {
								this.accountService.authAccountUpdate.subscribe(() => {
									this.setAccount();
								}).unsubscribe()
							} else {
								this.router.navigateByUrl('/404', { replaceUrl: true });
							}
						},
					})
				)
			})
		)
	}
}
