<div *ngIf="!isMenuItem" class="option ctw-mt-5 notranslate">
	<div>
		<h4 class="ctw-mb-0 ctw-font-bold">Account Suspension</h4>
		<p>Suspend this account and ban the user from logging into Central.</p>
		<p>Current State: <span class="{{ this.isSuspended ? 'ctw-text-red-700' : 'ctw-text-green-700' }}">
				{{ this.isSuspended ? 'Suspended' : 'Active' }}</span></p>
	</div>
	<div class="ctw-mt-2">
		<progress-button [state]="this.state" (click)="toggleState()"
			[type]="this.isSuspended ? 'ctw-bg-green-200' : 'mat-stroked-button ctw-text-imh-red'">
			{{ this.isSuspended ? 'Unsuspend' : 'Suspend' }} Account
		</progress-button>
	</div>
</div>
<button mat-menu-item *ngIf="isMenuItem" (click)="toggleState()">
	<mat-icon *ngIf="!this.isSuspended" class="ctw-text-red-700">no_accounts</mat-icon>
	<span *ngIf="!this.isSuspended" class="notranslate ctw-text-red-700">Suspend User</span>
	<mat-icon *ngIf="this.isSuspended" class="ctw-text-green-700">account_circle</mat-icon>
	<span *ngIf="this.isSuspended" class="notranslate ctw-text-green-700">Rectivate User</span>
</button>