<h3 mat-dialog-title>Unable to Transfer Automatically</h3>
<mat-dialog-content>
	<p>
		There was a problem migrating your site. The following issues were
		detected:
	</p>
	<ul>
		<li *ngFor="let failure of getAllErrors()">
			<mat-icon>warning</mat-icon>{{ failure }}
		</li>
	</ul>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-end">
	<button
		mat-raised-button
		mat-ripple
		type="button"
		color="primary"
		[mat-dialog-close]="true"
	>
		Close
	</button>
	<button
		*ngIf="failures.length <= 0 && warnings.length > 0"
		mat-raised-button
		mat-ripple
		type="button"
		color="primary"
		[mat-dialog-close]="true"
		(click)="skipWarnings()"
	>
		Continue
	</button>
</mat-dialog-actions>
