<central-header *ngIf="this.configService.config.brandConfig.id === 'imh'"
	title="{{ this.accountService.isOrganization() ? 'Team' : 'My' }} Notifications"
	[status]="this.status"></central-header>

<div [class]="
		this.configService.config.brandConfig.id === 'imh'
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16'
			: 'wrap'
	">
	<mat-progress-bar *ngIf="
			this.configService.config.brandConfig.id !== 'imh' &&
			this.status === 'pending'
		" mode="indeterminate" color="primary"></mat-progress-bar>
	<mat-card *ngIf="status === 'success' && notifications?.length === 0" class="no-content center-align">
		<div class="info ctw-text-center">
			<mat-icon class="ctw-text-imh-gray-200">notifications_off</mat-icon>
			<h2 *ngIf="this.configService.config.brandConfig.id !== 'imh'">No Notifications</h2>
			<p *ngIf="this.configService.config.brandConfig.id !== 'imh'">Notifications that you receive will appear
				here.</p>
			<p *ngIf="this.configService.config.brandConfig.id === 'imh'">Notifications meant for {{
				this.accountService.isOrganization() ? 'this Team' : 'you' }} will soon appear
				here...</p>
		</div>
	</mat-card>
	<mat-card class="ctw-flex team-content" *ngIf="status === 'success' && notifications?.length">
		<div class="list-options {{ this.configService.config.brandConfig.id }}">
			<ul>
				<li *ngFor="let message of notifications; let i = index" mat-ripple
					[class.active]="i == this.activeIndex" [class.unread]="message?.has_read === false"
					[ngClass]="message.display_type" (click)="readNotification(i)">
					<p class="title">{{ message.subject }}</p>
					<p>To: {{ getToName(message.account_id) }}</p>
					<p>
						Date:
						{{ message.date_sent | amDateFormat : 'LL HH:mm' }}
					</p>
				</li>
			</ul>
		</div>
		<div class="notification">
			<mat-progress-bar *ngIf="
					this.configService.config.brandConfig.id !== 'imh' &&
					showLoader
				" color="primary" mode="indeterminate">
			</mat-progress-bar>
			<h4 class="notification-title">
				{{ notifications[activeIndex]?.subject }}
			</h4>
			<hr />
			<div class="notification-details">
				<span>{{
					notifications[activeIndex]?.date_sent
					| amDateFormat : 'LL HH:mm:ss'
					}}</span>
			</div>
			<div class="notification-body" [innerHTML]="notifications[activeIndex]?.body"></div>
		</div>
	</mat-card>
</div>
