import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocketService } from './socket.service';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [SocketService],
})
export class SocketModule {
	// Load the socket service once the module is imported.
	public constructor(public socketService: SocketService) {}
}
