<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success'">
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<ng-container *ngIf="this.projectService.environment.fields.environment_usage !== 'unknown'">
			<h2>Site URL</h2>
			<dash-card icon="language" cardTitle="Site URL" disabled="true" [transparent]="true">
				<central-site-url></central-site-url>
			</dash-card>
			<central-nginx-profile></central-nginx-profile>
		</ng-container>
		<central-php-version></central-php-version>
	</div>
</ng-container>
<ng-container *ngIf="this.state === 'unavailable'">
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32">
		<h2 class="ctw-text-center ctw-capitalize">Feature Disabled</h2>
		<p *ngIf="this.overriddenBy !== 'Agent'">
			This feature was disabled by your
			<strong>
				<a (click)="this.openPlaybookDialog()">Server Software</a></strong>
			settings to prevent <span class="notranslate">Platform I</span> from reverting any custom modifications that
			you or a team member may have been performed on the server.
		</p>
	</div>
</ng-container>
