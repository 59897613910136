<ng-container *ngIf="this.data.notice">
	<h2 mat-dialog-title>Upgrade Required</h2>
	<p>
		Your team <b>{{ this.teamName }}</b>, has reached it's current limit of
		<b>{{ this.data?.limit }}</b> Playground environment{{ this.data?.limit > 1 ? 's' : '' }}.
		<ng-container *ngIf="!this.data?.hasVps">
			To add more Playground environments to this project, you can <strong><a
					routerLink="/projects/{{ this.data.projectId }}/add/server" (click)="this.matDialogRef.close()">Add
					a
					Server</a></strong>
			which will automatically increase your limit by as much as 15, or you
			can purchase more instances separately below.
		</ng-container>
		<ng-container *ngIf="this.data?.hasVps">
			To add a Playground environment to this project, you can delete one of your existing Playgrounds or purchase
			more instances
			below.
		</ng-container>
	</p>
	<mat-divider class="ctw-my-8"></mat-divider>
</ng-container>

<form (ngSubmit)="select(catalog.selection)">
	<div>
		<h3 class="ctw-text-center ctw-font-semibold">
			{{ this.data.title || 'Choose Package' }}
		</h3>
	</div>
	<div class="dialog-content ctw-mb-4">
		<product-catalog #catalog [tags]="this.data.tags" display="radio"></product-catalog>
	</div>
	<div class="dialog-footer action-buttons ctw-text-right ctw-mt-10">
		<button type="button" mat-button mat-button (click)="this.matDialogRef.close()">
			Maybe Later
		</button>
		<button type="submit" mat-button mat-raised-button color="primary" class="ctw-ml-3">
			<mat-icon>shopping_cart</mat-icon>
			{{ catalog.selection?.terms ? 'Checkout' : 'Continue' }}
		</button>
	</div>
</form>