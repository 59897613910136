import {
	Component,
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';
import { ApiService, AuthService, ProfileService } from '@central/ng-shared';
import { Router, ActivatedRoute } from '@angular/router';
import { LicenseService } from '../../shared/license/license.service';
import { ProjectService } from '../project.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewProjectDialogComponent } from './new-project-dialog/new-project-dialog.component';
import { filter } from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from "libs/ng-shared/src/lib/core/local-storage/local-storage.service";
import { AppService } from '../../app.service';
@Directive({
	selector: '[centralCachedSrc]',
})
export class CachedSrcDirective {
	@Input()
	public get centralCachedSrc(): string {
		return this.elRef.nativeElement.src;
	}
	public set centralCachedSrc(src: string) {
		if (this.elRef.nativeElement.src !== src) {
			this.renderer.setAttribute(this.elRef.nativeElement, 'src', src);
		}
	}

	constructor(private elRef: ElementRef, private renderer: Renderer2) {}
}

@Component({
	selector: 'central-projects',
	templateUrl: './collection.component.html',
	styleUrls: [ './collection.component.scss' ],
	providers: [ProjectService],
})
export class ListComponent implements OnInit, OnDestroy {

	public displayedColumns: string[] = ['type', 'name', 'age', 'servers', 'playgrounds', 'environments'];
	public status = 'pending';
	public view = 'grid';
	public projects = [];
	public environmentId = null;
	public newProjectState = '';
	public newProjectTitle = 'My First Project';
	public teams = [];
	public offer;
	public showHostnames = false;

	private subscriptions = [];

	constructor(
		public router: Router,
		public apiService: ApiService,
		public appService: AppService,
		public authService: AuthService,
		public profileService: ProfileService,
		public projectService: ProjectService,
		public licenseService: LicenseService,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.showHostnames = this.authService.jwtHelper.getTokenVal('admin_login');
				this.status = 'loading';
				this.environmentId = null;
				this.subscriptions.push(
					this.route.queryParams
						.pipe(filter((params) => params.environment_id))
						.subscribe((params) => {
							this.environmentId = params.environment_id;
						})
				);

				this.subscriptions.push(
					this.profileService.onProfileUpdate.subscribe(() => {
						this.fetchProjects()
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'add':
				this.newProject();
				break;
			case 'view':
				this.toggleView();
				break;
			case 'reload':
				this.status = 'loading';
				this.fetchProjects();
				break;
		}
	}

	public fetchProjects() {
		this.projectService.fetchProjects(false).subscribe(
			(response: any[]) => {
				this.projects = response;
				this.projects = this.projects.filter((project) =>
					project.fields.organization_id === this.profileService.data.options.public_selected_team
				);
				this.status = 'success';
				/*
				if (null !== this.environmentId) {
					this.projects.forEach((project) => {
						project.children.forEach((environment) => {
							if (environment.id === this.environmentId) {
								this.router.navigate(
									['/projects/', project.id, 'overview'],
									{
										queryParams: {
											environment_id: environment.id,
										},
									}
								);
							}
						});
					})
				}
					*/
			},
			() => {
				this.status = 'failed';
			},
			() => {
				this.createProjectTitle();
				this.view = this.profileService.data?.options?.public_project_view || 'grid';
				this.status = 'success';
			}
		);
	}

	public toggleView() {
		this.view = 'grid' === this.view ? 'table' : 'grid';
		this.profileService.update({ public_project_view: this.view }).subscribe();
	}

	public getStatus(project) {
		const env = this.projectService.getDefaultEnvironment(project);
		if (!env) {
			return 'empty';
		}
		return env.fields?.cloud_id ? 'play' : 'paid';
	}

	public getOwnerName(project) {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === project.fields.organization_id
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	public getUrl(project) {
		let url: string;
		const defaultEnvironment = this.projectService.getDefaultEnvironment(project);

		if (defaultEnvironment?.fields?.has_publish_setup_user || defaultEnvironment?.fields?.cloud_id) {
			url = defaultEnvironment.fields.site_url + '?central=1';
		}

		return url;
	}

	public getScreenshot(project: any) {
		return this.projectService.getScreenshot(project)
	}

	public refreshCollection() {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate(['/projects']));
	}

	/**
	 * Create a new project.
	 */
	public newProject(selectServer = false) {
		this.projectService.organizationId = this.profileService.data.options.public_selected_team;
		this.createProject(selectServer);
	}

	public createProject(selectServer = false) {
		// Set state of progress button to be spinning.
		this.newProjectState = 'submitted';
		this.projectService.createProject({
				label: this.newProjectTitle,
				state: 'live',
			})
			.subscribe(
				(response: any) => {
					this.newProjectState = 'pending';
					this.router.navigate(['/projects', response.id]).then(() => {
						if (selectServer) {
							this.router.navigate(['/projects', response.id, 'add', 'server']);
						}
					});

				},
				() => {
					this.status = 'failed';
				}
			);
	}

	/**
	 * Prepares a new reandom title for projects.
	 * Defaults to "My First Project" if no projects exist
	 */
	public createProjectTitle() {
		if (this.projects.length > 1) {
			this.newProjectTitle = this.appService.generateName('project');
		} else {
			this.newProjectTitle = 'My First Project';
		}
		return;

		let title = this.newProjectTitle;

		const projects = this.projects
			.map(({ label }) => label)
			.filter((label) => label.includes(title));

		// Loop through all titles for matches, and append N + 1
		for (let i = 0; i < projects.length; i++) {
			if (projects.includes(title)) {
				// regexr: https://regexr.com/77qo7  - match My Project 1, My Project 38748, ect.
				title =
					title.replace(/(\s\d+)(?!.*\s\d)/, '') +
					' ' +
					Math.abs(i + 1);
				this.newProjectTitle = title;
			}
		}
	}

	teamCount() {
		this.apiService.get('/v1/organizations/memberships', {}).subscribe({
			next: (teams: any[]) => {
				this.teams = teams || [];
			},
			error: () => {},
		});
	}

	isWordpress(project) {
		if (project.fields.project_type === 'wordpress') {
			return true;
		}
	}

	getEnvironments(project) {
		return {
			servers: project.children.filter(env => env.fields.environment_type === 'vps').length,
			playgrounds: project.children.filter(env => env.fields.environment_type !== 'vps').length
		}
	}
}
