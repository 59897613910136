import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { PurchaseComponent } from './project-create/purchase/purchase.component';
import { SignupComponent } from './project-create/signup/signup.component';
import { SizeComponent } from './project-create/size/size.component';


const routes: Routes = [
	{
		path: 'project',
		component: ProjectCreateComponent,
		children: [
			{ path: '', redirectTo: 'size', pathMatch: 'full' },
			{
				path: 'signup',
				component: SignupComponent,
			},
			{
				path: 'payment',
				component: PurchaseComponent,
			},
			{
				path: 'size',
				component: SizeComponent,
			},
			{
				path: '**',
				component: SizeComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class GuideRoutingModule {}
