<bgc-header-logo></bgc-header-logo>
<div class="login valign submission-form" *ngIf="state !== 'success'">
	<h1>Forgot Password?</h1>
	<p>
		Enter your email address &amp; we'll send you a link to reset your
		password.
	</p>
	<form (ngSubmit)="onSubmit(emailField.valid)" #forgotPassword="ngForm">
		<mat-form-field>
			<input matInput class="validated forgot-email" name="emailField" email [(ngModel)]="model.email"
				placeholder="e.g. youremail@gmail.com" dividerColor="accent" type="email" ngDefaultControl required
				#emailField="ngModel" />
			<mat-hint align="start" class="animate__animated" [class.animate__slideInUp]="
					(!emailField.valid &&
						emailField.dirty &&
						emailField.touched) ||
					state === 'fail'
				">
				You must provide a valid email.
			</mat-hint>
		</mat-form-field>
		<div class="action-buttons ctw-text-right">
			<button type="button" mat-ripple mat-raised-button (click)="this.location.back()">
				Back
			</button>
			<button type="submit" [disabled]="state === 'submitted'" class="btn-spinner" mat-ripple mat-raised-button
				mat-raised-button color="accent">
				<mat-spinner diameter="20" *ngIf="state === 'submitted'"></mat-spinner>Next
			</button>
		</div>
	</form>
</div>
<div class="login valign email-sent" *ngIf="state === 'success'">
	<h1>Sent! Check Your Email</h1>
	<p>
		Head over to your mailbox to get your reset link and create your brand
		new password.
	</p>
	<div class="action-buttons">
		<button mat-raised-button mat-raised-button color="accent" [routerLink]="['/login']">
			Done
		</button>
	</div>
</div>
