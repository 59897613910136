<bgc-header-logo></bgc-header-logo>
<div class="login valign email-sent">
	<h1>Account Setup Needed</h1>
	<p>
		We noticed you haven't logged into
		{{ brandingService.getAppName() }} before. We've sent you a link to
		setup your password. Clicking on the link in your email will allow you
		to complete the setup process and log in.
	</p>
	<div class="action-buttons">
		<button mat-raised-button color="accent" [routerLink]="['/login']">
			Okay, Got It!
		</button>
	</div>
</div>
