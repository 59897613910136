import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../project.service';

@Component({
	selector: 'central-agent',
	templateUrl: './agent.component.html',
	styleUrls: ['./agent.component.scss'],
})
export class AgentComponent implements OnInit {
	public isAnsibleControled = false;

	constructor(public projectService: ProjectService) {}

	ngOnInit(): void {
		this.projectService.onEnvironmentChange.subscribe(() => {
			console.log(this.projectService.environment?.fields);
			if (!this.projectService.environment?.fields?.cloud_id) {
				this.isAnsibleControled = true;
			}
		});
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'clear':
				this.clearPlaybookRunning();
				break;
		}
	}

	public clearPlaybookRunning() {
		this.projectService.updateEnvironmentField({
			playbook_running: 'complete',
		}).subscribe()
	}
}
