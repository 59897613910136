import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ConfigurationService } from '../../core/configuration.service';
import { AuthService } from '../../authentication/services/auth.service';
import { ReplaySubject } from 'rxjs';
import { ApiService } from '../../core/api/api.service';
import { first } from 'rxjs/operators';
import { PublicStorageService } from '../../core/local-storage/public-storage.service';

@Injectable()
export class FingerprintService {
	// create new rjxs subject
	private _fingerprint = new ReplaySubject<any>(1);

	constructor(
		public configurationService: ConfigurationService,
		public apiService: ApiService,
		public publicLocalStorage: PublicStorageService,
		public authService: AuthService
	) {
		this.identify();
		this.saveOnLogin();
	}

	private saveOnLogin() {
		this.authService.onLogin.subscribe(() => {
			this._fingerprint.pipe(first()).subscribe((vistorId) => {
				this.saveFingerPrint(vistorId);
			});
		});
	}

	private saveFingerPrint(visitorId: string) {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl('/v1/auth/fingerprint');

		this.apiService.http
			.post(
				url,
				{
					visitorId,
				},
				{ headers }
			)
			.subscribe({
				error: (error) => {
					console.log('Unable to save fingerprint');
					console.log(error);
				},
			});
	}

	private identify() {
		// Initialize an agent at application startup.
		const fpPromise = FingerprintJS.load();

		// Get the visitor identifier when you need it.
		fpPromise
			.then((fp) => fp.get())
			.then((result) => {
				this._fingerprint.next(result.visitorId);
				this.publicLocalStorage.setItem('visitorId', result.visitorId);
				console.log('Visitor ID: ' + result.visitorId);
			});
	}
}
