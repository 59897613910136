<div #renderWeglot class="{{ this.positioning }}">
	<ng-container *ngIf="this.mode === 'language'">
		<div *ngIf="this.configService.config.i18n.lang"
			class="animate__animated animate__fadeIn ctw-flex ctw-items-center ctw-justify-between ctw-language-selector ctw-mr-5">
		</div>
	</ng-container>
	<ng-container *ngIf="this.mode === 'currency'">
		<div *ngIf="this.configService.config.i18n.currency" class="ctw-flex ctw-items-center ctw-justify-between">
			<mat-button-toggle-group [(ngModel)]="currency" [value]="this.currency" aria-label="Default Currency">
				<mat-button-toggle *ngFor="let currency of this.currencies"
					value={{currency}}>{{currency}}</mat-button-toggle>
			</mat-button-toggle-group>
			<progress-button (click)="submit('currency')" [state]="currencySubmit"
				color="{{ this.configService.config.buttonColor || 'primary' }}">
				Update Currency
			</progress-button>
		</div>
	</ng-container>
	<ng-container *ngIf="this.mode === 'full'">
		<div class="ctw-mb-10">
			<ng-container [ngTemplateOutlet]="
				this.configService.config.i18n.lang && this.configService.config.i18n.currency
					? langAndCurrency
					: this.configService.config.i18n.lang && !this.configService.config.i18n.currency
					? langOnly
					: currencyOnly
			"></ng-container>

			<ng-template #langAndCurrency>
				<h2>Language & Currency Preference</h2>
				<p>Select your preferred language for this device and preferred currency for future purchases.</p>
			</ng-template>
			<ng-template #langOnly>
				<h2>Language</h2>
				<p>Select your preferred language for this device.</p>
			</ng-template>
			<ng-template #currencyOnly>
				<h2>Currency Preference</h2>
				<p>Select your preferred currency for future purchases.</p>
			</ng-template>

		</div>
		<div *ngIf="this.configService.config.i18n.lang"
			class="ctw-flex ctw-items-center ctw-justify-between ctw-language-selector ctw-mr-5">
		</div>
		<div *ngIf="this.configService.config.i18n.currency" class="ctw-flex ctw-items-center ctw-justify-between">
			<mat-button-toggle-group [(ngModel)]="currency" [value]="this.currency" aria-label="Default Currency">
				<mat-button-toggle *ngFor="let currency of this.currencies"
					value={{currency}}>{{currency}}</mat-button-toggle>
			</mat-button-toggle-group>
			<progress-button (click)="submit('currency')" [state]="currencySubmit"
				color="{{ this.configService.config.buttonColor || 'primary' }}">
				Update Currency
			</progress-button>
		</div>
	</ng-container>
</div>