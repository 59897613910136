import { Injectable } from '@angular/core';
import { AuthService } from '@central/ng-shared';

@Injectable()
export class AccountService {
	constructor(public authService: AuthService) {}

	public getAccountId() {
		return this.authService.getAccountId();
	}
}
