import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../api/api.service';

import isEqual from 'lodash/isEqual';

@Injectable()
export class ApiCacheService {
	/**
	 * Api Data.
	 */
	public cachedApiData: any[] = [];

	/**
	 * @param public apiService
	 */
	constructor(public apiService: ApiService) {}

	/**
	 * Clear the cache.
	 *
	 * @since 1.6.0
	 */
	public clear() {
		this.cachedApiData = [];
	}

	/**
	 * Get the data, cached or otherwise.
	 *
	 * @since 1.6.0
	 *
	 * @param   apiCall API call name.
	 * @param   query   Query paramters.
	 */
	public getData(apiCall: string, query: object) {
		const subject = new Subject();

		/*
		 * This set timeout is a hack.
		 * This timeout essentially allows the subject to be returned so that the subscribers can attach.
		 * otherwise the cached response will never finish.
		 */
		setTimeout(() => {
			const cachedData = this.findFromCache(apiCall, query);

			// From observer cache.
			if (cachedData && cachedData['subject']) {
				cachedData['subject'].subscribe(
					(response: any) => {
						subject.next(response);
						subject.complete();
					},
					(err: any) => subject.error(err)
				);

				// From Object Cache.
			} else if (cachedData) {
				subject.next(cachedData['data']);
				subject.complete();

				// From API.
			} else {
				this.fetch(apiCall, query, subject);
			}
		});

		return subject;
	}

	/**
	 * Find the value from the cache.
	 *
	 * @since 1.6.0
	 *
	 * @param   apiCall API call.
	 * @param   query   Query Params.
	 * @return          Api data.
	 */
	private findFromCache(apiCall: string, query: object): object {
		return this.cachedApiData.find(
			(value: any) =>
				apiCall === value.call && isEqual(value.query, query)
		);
	}

	/**
	 * Fetch teh data from the server.
	 *
	 * @since 1.6.0
	 *
	 * @param   apiCall  Name of API Call.
	 * @param   query    Query to send to APi call.
	 */
	private fetch(apiCall: string, query: object, subject: any) {
		const request = {
			call: apiCall,
			query,
			subject,
		};

		this.cachedApiData.push(request);

		this.apiService.get(apiCall, query).subscribe(
			(response: any) => {
				request['data'] = response;
				delete request.subject;

				// Complete the subscriber.
				subject.next(response);
				subject.complete();
			},
			(err: any) => subject.error(err)
		);
	}
}
