export default {
	production: false,
	host: 'https://api-dev.boldgrid.com',
	useMocks: false,
	google: {},

	apiData: {
		apiCalls: {
			// Signup
			createAccount: '/v1/accounts',
			authEmail: '/v1/accounts/preauth',
			// Signup
			signupReseller: '/account/v1/reseller/create',
			providerAuth: '/v1/accounts/provider-auth',
			getProfile: '/v1/accounts/profile',

			// Dashboard
			getApiKeys: '/account/v1/key/list',
			getResellerKeys: '/account/v1/reseller/keys',

			// Pre Authentication.
			getToken: '/account/v1/auth/get-token',
			resetPassword: '/account/v1/auth/reset-password',
			updatePassword: '/account/v1/auth/update-password',
			changePassword: '/account/v1/auth/change-password',
			userConfirmation: '/account/v1/auth/confirm-account',
			userConfirmationResend: '/v1/accounts/confirmation',

			// Key
			keyAdd: '/account/v1/key/add',
			keyRename: '/v1/connect-keys/::connect_id/label',
			keyReplace: '/account/v1/key/reissue',

			// Rate Limit
			checkRateLimit: '/account/v1/auth/rate-limit',

			// Get Package Calls, Duplicated for testing.
			getCoinPackages: '/account/v1/product/get-packages',
			productPackageKey: '/account/v1/product/get-packages',
			productOfferings: '/v1/products',
			productCatalog: '/v1/products/catalog',

			// Payment Processing.
			productAddCoin: '/v1/accounts/products',
			productAddKey: '/v1/accounts/products',
			productAddFree: '/v1/accounts/products/free',
			productGetOfferings: '/v1/products/',
			getDiscount: '/v1/products/discounts',
			getAutoDiscount: '/v1/products/discounts/auto',
			logPaypage: '/account/v1/product/log-paypage',

			// Receipts
			receiptList: '/account/v1/billing/get-receipt-list',
			receiptGet: '/account/v1/billing/get-receipt',

			// Surveys.
			submitNewAccount: '/v1/accounts/surveys/setup',

			// Websites
			siteList: '/account/v1/site/list',

			// Notifications.
			notificationList: '/account/v1/notification/list',
			notificationCount: '/account/v1/notification/unread-count',
			notificationUpdate: '/account/v1/notification/mark-as-read',

			// User updates.
			billingUpdate: '/account/v1/billing/update-address',
			billingGetDefault: '/account/v1/billing/get-address',
			emailUpdate: '/account/v1/auth/update-email',

			// Speed Coach
			speedCoachAnalyze: '/v1/speedcoach',
			speedCoachJobStatus: '/v1/speedcoach/::job_id',
			speedCoachSchedule: '/v1/speedcoach/schedule',
			speedCoachScheduleList: '/v1/speedcoach',
			speedCoachJobDelete: '/v1/speedcoach/::job_id',
			speedCoachList: '/v1/speedcoach/reports/::job_id',
			speedCoachFetch: '/v1/speedcoach/report/::report_id',
			speedCoachRerun: '/v1/speedcoach/rerun',
			speedCoachReschedule: '/v1/speedcoach/schedule/reschedule',
			speedCoachDeschedule: '/v1/speedcoach/schedule/::job_id',
			speedCoachScheduleAllowance: '/v1/speedcoach/schedule/allowance',

			// Reseller

			// Reseller Settings
			updateResellerSettings: '/reseller/v2/user/update-reseller',
			getResellerSettings: '/reseller/v2/user/get',
			getResellerInfo: '/v1/resellers-info',

			keyTransferValidate: '/account/v1/key/transfer',
			keyTransferProcess: '/account/v1/key/transfer',

			// Licences.
			getLicenses: '/v1/licenses',

			// Oauth.
			saveOauthToken: '/v1/oauth2/external-token',

			// Promo Codes
			validatePromoCode: '/v1/promo/::provider',
			redeemPromoCode: '/v1/promo/::provider',

			// Clouds.
			installCloud: '/v1/cloud-wordpress',
			installCloudCrio: '/v1/cloud-wordpress/demo',
			listClouds: '/v1/cloud-wordpress',
			confirmCloud: '/v1/cloud-wordpress/activate',
			deleteCloud: '/v1/cloud-wordpress/::cloud_id',
			getCloud: '/v1/cloud-wordpress/::cloud_id',
			editCloud: '/v1/cloud-wordpress/::cloud_id',
			receateCloud: '/v1/cloud-wordpress/::cloud_id/recreate',
			checkCloudEligibility: '/v1/cloud-wordpress/eligibility',

			// Snaps
			listSnaps: '/v1/snaps',
			getSnap: '/v1/snaps/::snap_id',
			snapDelete: '/v1/snaps/::snap_id',
			snapEdit: '/v1/snaps/::snap_id',
			snapCreate: '/v1/snaps',

			listPlugins: '/v1/plugins',
			getPlugin: '/v1/plugins/::slug',
			listThemes: '/api/theme/get-wordpress-info',

			// rewards
			getRewardOffers: '/v1/rewards/offers',
			redeemOffer: '/v1/rewards/offers/::id',

			// Projects
			createProject: '/v1/projects',
			createEnvironment: '/v1/environments',
			createEnvironmentToken: '/v1/environments/::environment_id/auth',
		},
	},
};
