<mat-card class="ctw-max-w-2xl ctw-mx-auto ctw-mt-32 ctw-mb-16 notranslate">
	<h2>Agent Controls</h2>
	<p>
		This section is only visible to agents
		who can access accounts through zendesk.
	</p>
	<hr>
	<ng-container *ngIf="this.allowBetaTesting">
		<central-beta-tester></central-beta-tester>
		<hr class="ctw-mt-6">
	</ng-container>
	<central-suspension></central-suspension>
</mat-card>
