import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DefaultOrgActionComponent } from './default-org-action/default-org-action.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [DefaultOrgActionComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [DefaultOrgActionComponent]
})
export class DefaultOrgActionModule { }
