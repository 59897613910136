<dash-card disabled="1" cardTitle="Account" icon="person">
    <div class="ctw-flex ctw-px-8 ctw-justify-between ctw-mt-5" *ngIf="isAuthenticated === false && showTwoFactor === false">
        <div class="ctw-flex ctw-flex-wrap">
            <div class="ctw-flex ctw-flex-col sm:ctw-justify-center">
                <div class="ctw-font-semibold ctw-mt-1" *ngIf="accountType === 'new'">Create Your New Account</div>
                <div class="ctw-font-semibold ctw-mt-1" *ngIf="accountType === 'existing'">Login To Your Account</div>
            </div>
            <div class="ctw-flex ctw-mt-2" *ngIf="checkingEmail">
                <mat-progress-spinner
                    class="inline-spinner sm:ctw-ml-3 ctw-mt-1"
                    mode="indeterminate"
                    color="primary"
                    diameter="16"
                ></mat-progress-spinner>
                <span class="ctw-pl-3 ctw-text-blue-800" color="primary">Checking Email...</span>
            </div>
        </div>
        <div class="ctw-ml-3 ctw-flex ctw-flex-wrap ctw-justify-end" *ngIf="accountType === 'new'">
            <div class="ctw-ml-3 ctw-mt-1 sm:ctw-mt-0 ctw-flex ctw-flex-col ctw-justify-center">
                <div>Have an account?</div>
            </div>
            <div class="ctw-ml-5">
                <button color="primary" (click)="setAccountType('existing')"
                    type="button" mat-button mat-stroked-button>Login</button>
            </div>
        </div>
        <div class="ctw-ml-5" *ngIf="accountType === 'existing'">
            <button color="primary" (click)="setAccountType('new')"
                type="button" mat-button mat-stroked-button>Create New Account</button>
        </div>
    </div>
    <div class="ctw-flex ctw-ml-10 ctw-mt-5" *ngIf="isAuthenticated === false && showTwoFactor === true">
        <div class="ctw-text-xl ctw-font-semibold">Enter Authentication Code</div>
    </div>
    <div data-private [class]="isAuthenticated === false ? 'ctw-p-8' : ''">
        <form>
            <div [class]="showTwoFactor === true || this.isAuthenticated === true ? 'ctw-hidden' : ''">
                <mat-form-field>
                    <input [readonly]="readOnlyForm" matInput class="validated" type="email" name="contactEmail" [autocomplete]="this.accountType==='new' ? 'off' : 'email'"
                        placeholder="Email" [(ngModel)]="this.contactEmail" #email="ngModel" required (change)="this.checkEmail(true)" />
                    <mat-hint class="animate__animated error" align="start"
                        [class.animate__fadeIn]="invalidInputState(email) || (this.contactEmail && this.emailExists && this.accountType === 'new')">
                        <span *ngIf="!this.contactEmail">Email is required. </span>
                        <span *ngIf="this.contactEmail && !email.valid && this.emailExists === false && this.accountType === 'new'">Email is not valid. </span>
                        <span *ngIf="this.contactEmail && this.emailExists === true && this.existingEmailPrevention === true && this.accountType === 'new'">
                            This email is already in use. To continue with this email, please login.
                        </span>
                        <span *ngIf="this.incorrectCredentials">Your credentials are incorrect. </span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div *ngIf="accountType === 'existing' && isAuthenticated === false && showForgotPassword === false && showTwoFactor === false">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        name="loginPass"
                        autocomplete="off"
                        [type]="inputType"
                        [(ngModel)]="password"
                        placeholder="Password"
                        (keyup.enter)="handleAuthentication()"
                    />
                    <button
                        type="button"
                        mat-button
                        matSuffix
                        mat-icon-button
                        aria-label="Show Password"
                        (click)="
                            inputType =
                                'password' === inputType ? 'text' : 'password'
                        "
                    >
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </form>
        <form autocomplete="off">
            <div *ngIf="accountType === 'new' && isAuthenticated === false">
                <amp-password-create #newPassword></amp-password-create>
            </div>
        </form>
        <div *ngIf="accountType === 'existing' && showForgotPassword === true">
            <mat-form-field>
                <input matInput class="validated" type="text" name="username"
                    placeholder="Domain or cPanel Username" [(ngModel)]="this.username" #domain="ngModel" required />
                <mat-hint class="animate__animated error" align="start"
                    [class.animate__fadeIn]="invalidInputState(domain)">
                    <span *ngIf="!this.domain">Domain or cPanel Username is required.</span>
                    <span *ngIf="this.forgotPasswordError">{{forgotPasswordError}}</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div *ngIf="accountType === 'existing' && showTwoFactor === true">
            <div class="ctw-flex ctw-flex-col">
				<div *ngIf="['text','Google Authenticator'].includes(this.twoFaType)" class="ctw-pb-5">
                    Two Factor Authentication has been enabled on your account, requiring a verification code to be entered, as well as your normal login credentials.
                </div>
                <div *ngIf="twoFaType === 'text'">
                    We just sent you a code via text message to your phone number ending in {{this.twoFaLast}}. Enter the code below to verify your identity.
                </div>
                <div *ngIf="twoFaType === 'Google Authenticator'">
				    Please enter the one time authentication code shown in your Google Authenticator app to verify your identity.
                </div>
                <div *ngIf="twoFaType === 'dormant'">
                    We just emailed a code to the primary email address. Please enter the one time authentication code to verify your identity.
                </div>
                
            </div>
            <mat-form-field>
                <input matInput class="validated" type="text" name="code" (keyup.enter)="verifyTwoFactor()"
                    placeholder="Authentication Code" [(ngModel)]="this.code" #twoFaCode="ngModel" />
                <mat-hint class="animate__animated error" align="start"
                    [class.animate__fadeIn]="invalidInputState(twoFaCode)">
                    <span *ngIf="this.twoFaError">{{twoFaError}}</span>
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="ctw-w-full ctw-flex ctw-justify-between ctw-pb-8 ctw-pr-8" *ngIf="accountType === 'existing' && isAuthenticated === false">
        <div class="ctw-ml-10">
            <button mat-button mat-stroked-button color="primary" mat-ripple type="button" (click)="this.sendNewCode()"
                [disabled]="disableNewCode" *ngIf="this.showTwoFactor === true && ['text','dormant'].includes(this.twoFaType)">
                Send New Code?
            </button>
            <button *ngIf="this.showBackButton() && includeBackButton" mat-button mat-ripple
                type="button" (click)="this.goBack.emit()">
                Back
            </button>
        </div>
        <div>
            <button mat-button color="primary" mat-ripple type="button" (click)="this.showForgotPassword = true"
                *ngIf="this.showForgotPassword === false && this.showTwoFactor === false">
                Forgot Password?
            </button>
            <button mat-button color="primary" mat-ripple type="button" *ngIf="this.showForgotPassword === true || this.showTwoFactor === true" 
                (click)="this.showForgotPassword = false; this.clearTwoFactorInfo()">
                Cancel
            </button>
            <central-progress-button [state]="status" mat-raised-button color="primary" (click)="handleAuthentication()"
                *ngIf="this.showForgotPassword === false && this.showTwoFactor === false" class="animate__animated animate__fadeIn ctw-ml-3">
                Login
            </central-progress-button>
            <central-progress-button [state]="status" mat-raised-button color="primary"
                *ngIf="this.showForgotPassword === true" (click)="forgotPassword()"
                class="animate__animated animate__fadeIn ctw-ml-3">Submit
            </central-progress-button>
            <central-progress-button [state]="status" mat-raised-button color="primary"
                *ngIf="this.showTwoFactor === true" (click)="verifyTwoFactor()"
                class="animate__animated animate__fadeIn ctw-ml-3">Submit
            </central-progress-button>

        </div>
    </div>
    <div class="ctw-w-full ctw-flex ctw-justify-between ctw-p-8 sm:ctw-pl-10 ctw-flex-wrap"
        [hidden]="!this.contactInputs.hasOwnProperty('user')" *ngIf="accountType === 'existing' && isAuthenticated">
        <div *ngIf="this.contactInputs.hasOwnProperty('user')" class="ctw-leading-snug" data-private>
            <span class="ctw-font-bold">{{this.contactInputs.user.first_name}} {{this.contactInputs.user.last_name}}</span><br />
            <span *ngIf="this.contactInputs.user.company_name">
                {{this.contactInputs.user.company_name}}<br />
            </span>
            <span>{{this.contactInputs.user.address1}}</span><br />
            <span *ngIf="this.contactInputs.user.address2">
                {{this.contactInputs.user.address2}}<br />
            </span>
            <span>{{this.contactInputs.user.city}} {{this.contactInputs.user.state}}, {{this.contactInputs.user.zip}}</span><br />
            <span>{{ this.getCountryName(this.contactInputs.user.country)}}</span><br />
            <span>{{this.contactInputs.user.phone}}</span><br />
            <span>{{trimText(this.contactInputs.user.email)}}</span><br />
        </div>
        <div class="ctw-flex ctw-w-full ctw-mt-3 sm:ctw-mt-0 md:ctw-w-auto md:ctw-flex-col ctw-justify-end">
            <central-progress-button [state]="status" color="primary" (click)="logout()"
                class="animate__animated animate__fadeIn">Logout
            </central-progress-button>
        </div>
    </div>
</dash-card>