<div>
	<mat-form-field appearance="standard" class="ctw-mb-[-1.25em]">
		<mat-label>Organization</mat-label>
		<input matInput [(ngModel)]="editedContactSet.org_name" [disabled]="disableAll" [maxlength]="64" required />
	</mat-form-field>
	<div class="flex-name ctw-mb-[-1.25em]">
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>First Name</mat-label>
			<input matInput [(ngModel)]="editedContactSet.first_name" [disabled]="disableAll" [maxlength]="64"
				required />
		</mat-form-field>
		<mat-form-field appearance="standard">
			<mat-label>Last Name</mat-label>
			<input matInput [(ngModel)]="editedContactSet.last_name" [disabled]="disableAll" [maxlength]="64"
				required />
		</mat-form-field>
	</div>
	<div class="flex-name">
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>Address 1</mat-label>
			<input matInput [(ngModel)]="editedContactSet.address1" [disabled]="disableAll" [maxlength]="64" required />
		</mat-form-field>
		<mat-form-field appearance="standard">
			<mat-label>Address 2</mat-label>
			<input matInput [(ngModel)]="editedContactSet.address2" [disabled]="disableAll" [maxlength]="64" />
		</mat-form-field>
	</div>
	<mat-form-field appearance="standard" class="ctw-mb-[-1.25em]">
		<mat-label>Address 3</mat-label>
		<input matInput [(ngModel)]="editedContactSet.address3" [disabled]="disableAll" [maxlength]="64" />
	</mat-form-field>
	<div class="flex-state-country-zip ctw-mb-[-1.25em]">
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>City</mat-label>
			<input matInput [(ngModel)]="editedContactSet.city" [disabled]="disableAll" [maxlength]="64" required />
		</mat-form-field>
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>State</mat-label>
			<input matInput [(ngModel)]="editedContactSet.state" [disabled]="disableAll" [maxlength]="32" required />
		</mat-form-field>
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>Country</mat-label>
			<input matInput [(ngModel)]="editedContactSet.country" [disabled]="disableAll" [maxlength]="2" required />
		</mat-form-field>
		<mat-form-field appearance="standard">
			<mat-label>Zip/Postal Code</mat-label>
			<input matInput [(ngModel)]="editedContactSet.postal_code" [disabled]="disableAll" [maxlength]="16"
				required />
		</mat-form-field>
	</div>
	<div class="flex-contacts">
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>Phone</mat-label>
			<input matInput [(ngModel)]="editedContactSet.phone" [disabled]="disableAll" [maxlength]="20" required />
		</mat-form-field>
		<mat-form-field class="padding-right" appearance="standard">
			<mat-label>Fax</mat-label>
			<input matInput [(ngModel)]="editedContactSet.fax" [disabled]="disableAll" [maxlength]="20" />
		</mat-form-field>
		<mat-form-field appearance="standard">
			<mat-label>Email</mat-label>
			<input matInput [(ngModel)]="editedContactSet.email" [disabled]="disableAll" [maxlength]="128" required />
		</mat-form-field>
	</div>
</div>
