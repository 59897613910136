import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@central/ng-shared';

// tslint:disable-next-line:no-submodule-imports
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmationService {
	constructor(public apiService: ApiService) {}

	public confirmAccount(email: string, code: string) {
		const params = { email, code };

		return this.apiService.post('userConfirmation', params).pipe(
			map((res) => {
				let success = false;
				let token = '';
				const result = res['result'];

				if (result && result.data) {
					if (result.data.success && result.data.token) {
						token = result.data.token;
						success = result.data.success;
					}
				}

				return { success, token };
			})
		);
	}
}
