import { Component, Inject, OnInit } from '@angular/core';
import { COMMA, SPACE, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ApiService } from '../../../core';
import { TrackingService } from '../../../tracking/tracking.service';
import { ConfigurationService } from '../../../core';

@Component({
	selector: 'central-add-member-dialog',
	templateUrl: './add-member-dialog.component.html',
	styleUrls: ['./add-member-dialog.component.scss'],
})
export class AddMemberDialogComponent implements OnInit {
	public email = '';
	public state = 'pending';
	public role = 'member';
	public permissions = [];
	selectable = true;
	removable = true;
	addOnBlur = true;
	readonly separatorKeysCodes = [ENTER, SPACE, COMMA] as const;
	emails = [];
	emailLimit = 20;
	rulesForm: FormGroup;
	fb: FormBuilder = new FormBuilder();

	constructor(
		private apiService: ApiService,
		private trackingService: TrackingService,
		public configurationService: ConfigurationService,
		public dialogRef: MatDialogRef<AddMemberDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		if (this.data.email) {
			this.email = this.data.email;
		}

		// Setup email chip input.
		this.rulesForm = this.fb.group({
			emailsList: this.fb.array([], [this.validateArrayNotEmpty]),
		});

		this.permissions = this.sortPermissions();
		this.filterPermissions();
	}

	filterPermissions() {
		// if the user is not an owner, remove the owner permission
		if (this.data.currentRole !== 'owner') {
			this.permissions = this.permissions.filter(
				(permission) => permission.role !== 'owner'
			);
		}
	}

	sortPermissions() {
		const permissions = [];
		const defaultPermission = ['owner', 'maintainer', 'member'];
		defaultPermission.forEach((role) => {
			if (this.configurationService.config.groupPermissions[role]) {
				permissions.push({
					role,
					...this.configurationService.config.groupPermissions[role],
				});
			}
		} );

		const customPermissions = [];
		for (const role in this.configurationService.config.groupPermissions) {
			if (!defaultPermission.includes(role)) {
				customPermissions.push({
					role,
					...this.configurationService.config.groupPermissions[role],
				});
			}
		}

		return [ ...permissions,  ...customPermissions ];
	}

	addMember() {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			`/v1/${this.data.resourceType}/` +
				this.data.resourceId +
				'/memberships'
		);

		this.state = 'submitted';

		this.apiService.http
			.post(
				url,
				{
					email: this.email || this.emails.map( ({ value }) => value ),
					role: this.role,
				},
				{ headers }
			)
			.subscribe({
				next: () => {
					this.state = 'success';
					this.dialogRef.close();
					this.trackingService.track('memberInvite');
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}

	/**
	 * What to do when e-mail chip is added.
	 *
	 * @param event MatChipInputEvent
	 */
	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		if (value && this.emails.length < this.emailLimit) {
			if (this.validateEmail(event.value)) {
				this.emails.push({ value: event.value, invalid: false });

				// Clear previous errors.
				this.rulesForm.controls['emailsList'].setErrors({'incorrectEmail': false});

				// Clear the input value
				event.chipInput?.clear();
			} else {
				// this.emails.push({ value: event.value, invalid: true });
				this.rulesForm.controls['emailsList'].setErrors({'incorrectEmail': true});
			}
		}


	}

	/**
	 * What to do when an e-mail chip is removed.
	 *
	 * @param email email to remove
	 */
	remove(email): void {
		const index = this.emails.indexOf(email);

		if (index >= 0) {
			this.emails.splice(index, 1);
		}
	}

	/**
	 * Validate form is not empty.
	 * @param c FormControl
	 * @returns
	 */
	private validateArrayNotEmpty(c: FormControl) {
		if (c.value && c.value.length === 0) {
			return {
				validateArrayNotEmpty: { valid: false }
			};
		}

		return null;
	}

	/**
	 * Frontend e-mail form validation.
	 *
	 * @param   email   E-mail to validate
	 * @returns boolean Is the provided e-mail valid?
	 */
	private validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
}
