import { OrganizationComponent } from './organization.component';
import { OrganizationCreateComponent } from './create/create.component';
import { OrganizationMembershipComponent } from './membership/membership.component';

export const organizationRoutes = [
	{
		path: 'members',
		component: OrganizationMembershipComponent,
	},
	{
		path: 'organization',
		redirectTo: 'organizations',
	},
	{
		path: 'organizations',
		component: OrganizationComponent,
	},
	{
		path: 'organizations/create',
		component: OrganizationCreateComponent,
	},
];
