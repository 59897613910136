<div class="ctw-text-base ctw-pt-4" *ngIf="this.hasCoreUpdate('wordpress')">
	<span class="ctw-font-bold">WordPress<br></span>
	<span>WordPress -
		{{
		this.stats['debug']['wp-core']['fields']['version'][
		'value'
		]
		}}</span>
</div>
<div class="ctw-text-base ctw-pt-4" *ngIf="this.updateThemes.length">
	<span class="ctw-font-bold">Themes<br></span>
	<ng-container *ngFor="let theme of this.updateThemes">
		<span *ngIf="theme.version"
			[inneHTML]="stripSlug(theme.name.value) + ' - ' + cleanVersion(theme.version.value.split('| ')[0])">
		</span>
		<span *ngIf="!theme.version"
			[innerHTML]="stripSlug(theme.label) + ' - ' + cleanVersion(theme.value.split('| ')[0])">
		</span>
	</ng-container>
</div>
<div class="ctw-text-base ctw-pt-4" *ngIf="this.updatePlugins.length">
	<span class="ctw-font-bold">Plugins<br></span>
	<ng-container *ngFor="let plugin of this.updatePlugins">
		<span *ngIf="plugin.version"
			[innerHTML]="stripSlug(plugin.name.value) + ' - ' + cleanVersion(plugin.version.value.split(' | ')[0])">
		</span>
		<span *ngIf="!plugin.version"
			[innerHTML]="stripSlug(plugin.label) + ' - ' + cleanVersion(plugin.value.split('| ')[0])">
		</span>
	</ng-container>
</div>
<div class="ctw-flex ctw-justify-end ctw-mt-10">
	<button mat-raised-button color="primary" (click)="
			this.projectService.login('wp-admin/update-core.php')
		">Update In WordPress <mat-icon class="ctw-text-base open_in_new">open_in_new</mat-icon></button>
</div>
