import { Component, ViewChild } from '@angular/core';
import moment from 'moment';

@Component({
	selector: 'amp-cc',
	templateUrl: 'amp-cc.component.html',
	styleUrls: [],
})
export class AmpCcComponent {
    @ViewChild('ccnum') public ccnum: any;
    @ViewChild('expiration') public expiration: any;

    public validCc;
    public validExp;
	public info = {
        method: 'Credit Card',
		card_number: null,
		expiration: null,
		cvv: null
	}

	public getInfo() {
		return this.info;
	}

    public isValid() {
        return this.validCc && this.validExp && this.info.cvv !== null;
    }
	
	public expChange(exp: string) {
		this.info.expiration = exp.replace(
			/^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
		  ).replace(
			/^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
		  ).replace(
			/^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
		  ).replace(
			/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
		  ).replace(
			/^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
		  ).replace(
			/[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
		  ).replace(
			/\/\//g, '/' // Prevent entering more than 1 `/`
		  );
	}

    public ccChange(ccnum) {
        
        const v = ccnum.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        const matches = v.match(/\d{4,16}/g);
        const match = matches && matches[0] || ''
        const parts = []
        for (let i=0, len=match.length; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
        }
        if (parts.length) {
            this.info.card_number = parts.join(' ')
        } else {
            this.info.card_number = ccnum
        }
      }

	public validateCardNumber() {
        const regex      = new RegExp("^[0-9]{15,16}$");
        let isValid;
        if(this.info.card_number != null) {
            const ccnum = this.info.card_number.replace(/\s+/g, '')
            if (regex.test(ccnum)) {
                isValid = this.luhnCheck(ccnum);
            } else {
                isValid = false;    
            }
            this.validCc = isValid;
        }
    }

    public validateExpirationDate() {
        const todayMonth = moment().month();
        const todayYear  = moment().year();
        let validDate  = false;
        if(this.info.expiration != null) {
            const exp = this.info.expiration.split('/')

            const month = parseInt(exp[0],10);
            const year  = parseInt(exp[1],10);

            let monthAndYearError = false;
            let monthError        = false;
            let yearError         = false;
            

            if ( (year === todayYear && month < todayMonth) || todayMonth == null ) {
                validDate =  false;
                monthError = true;
            } else if ( (year == null) && (month == null) ) {
                validDate =  false;
                monthAndYearError = true;
            } else if ( (year == null && month >= todayMonth) || year == null ) {
                validDate = false;
                yearError = true;
            } else {
                validDate =  true;       
            }
        }

        this.validExp = validDate;
    }

    public luhnCheck(num) {
        let digit, j, len, odd, sum;
        odd = true;
        sum = 0;
        const digits = (num + '').split('').reverse();

        for (j = 0, len = digits.length; j < len; j++) {
            digit = digits[j];
            digit = parseInt(digit, 10);
            if ((odd = !odd)) {
                digit *= 2;
            }
            if (digit > 9) {
                digit -= 9;
            }
            sum += digit;
        }
        
        return sum % 10 === 0;
    }

    /**
	 * Is an input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInput(input: any): boolean {
		const dirty =
			(input.dirty && input.touched);
		return dirty && !input.valid;
	}

	/**
	 * Is a generic input in an invalid state?
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidInputState(input: any): boolean {
		return !input.valid && this.invalidInput(input);
	}

    /**
	 * Validate the cc input. This will determine if the input should show an error message.
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidCcInput(input: any): boolean {
		return !!(
			this.invalidInput(input) ||
			(!this.validCc && this.info.card_number)
		);
	}
    /**
	 * Validate the exp input. This will determine if the input should show an error message.
	 *
	 * @since 1.0
	 *
	 * @param  input Input from the form.
	 * @return       Is the input invalid?
	 */
	public invalidExpInput(input: any): boolean {
		return !!(
			this.invalidInput(input) ||
			(!this.validExp && this.info.expiration)
		);
	}

}
