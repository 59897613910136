import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthService, ProfileService } from '@central/ng-shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'central-email-new',
	templateUrl: './email-new.component.html',
	styleUrls: ['./email-new.component.scss'],
})
export class EmailNewComponent implements OnInit, OnDestroy {
	public state = 'pending';
	public isReady = false;
	public checkoutComplete = false;
	public ownerAccountId = '';
	public packages = [
		{
			planCode: 'email_account',
			quantity: 1,
			options: {
				allows_multiple: true,
				allows_term_discount: false,
			},
		},
	];

	message: string;
	subscription: Subscription;

    private subscriptions = [];

	constructor(
		public router: Router,
		public accountService: AccountService,
		public authService: AuthService,
		public profileService: ProfileService,
	) { }

	ngOnInit(): void {
        this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
                this.subscription = this.accountService.currentMessage.subscribe(
                    (message) => {
                        this.message = message;
                    }
                );
            })
        )
	}

    ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscription.unsubscribe()
	}

	public accountChanged($event) {
		this.ownerAccountId = $event['account_id'];
		this.accountService.updateAccount(this.profileService.data.account_access[1]);
		this.isReady = true;
	}

	public paymentSucceeded() {
		this.checkoutComplete = true;
	}
}
