import { Component } from '@angular/core';
import { BrandingService } from '../branding/branding.service';

@Component({
	// eslint-disable-next-line
	selector: 'bgc-header-logo',
	template: `
		<a
			class="bg-logo-small"
			href="{{ this.brandingService.getBrandOpt('marketingSite') }}"
		>
			<img [src]="this.brandingService.getBrandOpt('logo')" />
		</a>
	`,
	styles: [
		`
			:host {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				text-align: center;
			}

			img {
				margin: 0 auto;
				width: 150px;
				margin-top: 20px;
			}
		`,
	],
})
export class HeaderLogoComponent {
	constructor(public brandingService: BrandingService) {}
}
