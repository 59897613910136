import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ApiService } from '../../../../core/api/api.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfigurationService } from '../../../../core';

@Component({
	selector: 'central-remove-device',
	templateUrl: './remove-device.component.html',
	styleUrls: ['./remove-device.component.scss'],
})
export class RemoveDeviceComponent {
	public state = 'pending';

	public confirmation = '';

	constructor(
		public configService: ConfigurationService,
		public httpClient: HttpClient,
		public apiService: ApiService,
		public dialogRef: MatDialogRef<RemoveDeviceComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private snackBar: MatSnackBar,
	) {}

	public deleteDevice() {
		this.state = 'submitted';

		const url = this.apiService.formatter.getUrl(
			`/v1/twofactor/device/${this.data.device.name}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient.delete(url, { headers }).subscribe({
			next: () => {
				this.state = 'success';
				this.dialogRef.close();
			},
			error: () => {
				this.state = 'failed';
			},
		});
	}

	public doDelete() {
		if (
			this.confirmation.toLowerCase().trim() !== this.data.device.message
		) {
			return;
		}

		this.state = 'submitted';

		const url = this.apiService.formatter.getUrl(
			`/v1/twofactor/device/${this.data.device.name}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient.delete(url, { headers }).subscribe(
			() => {
				this.state = 'success';
				this.dialogRef.close();
				this.snackBar.open('Device Removed', '', {
					duration: 5000,
				});
			},
			() => (this.state = 'failed')
		);
	}
}
