// Modules.
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { CoreModule } from '@central/ng-shared';
import { SharedModule } from '../shared/shared.module';

// Components.
import { TeamComponent } from './team.component';
import { TeamCreateDialogComponent } from './dialogs/create-team-dialog/create-team-dialog.component';
import { LeaveTeamDialogComponent } from './dialogs/leave-team-dialog/leave-team-dialog.component';
import { ArchiveTeamDialogComponent } from './dialogs/archive-team-dialog/archive-team-dialog.component';

import { SubscriptionComponent } from './billing/subscriptions';
import { InvoiceViewComponent } from './billing/invoice/view/view.component';
import { InvoiceListComponent } from './billing/invoice/list/list.component';

@NgModule({
	declarations: [
		TeamComponent,
		TeamCreateDialogComponent,
		LeaveTeamDialogComponent,
        ArchiveTeamDialogComponent,
	],
	imports: [
		SharedModule,
		CoreModule,
	],
	exports: [],
	providers: [],
})
export class TeamModule {
	public routes = [{
		path: 'memberships',
		component: TeamComponent,
		icon: 'groups',
		organizationRoute: false,
		permission: 'manage',
		label: 'Teams',
		orgOnly: false,
	},{
		path: 'subscriptions',
		component: SubscriptionComponent,
		icon: 'event_repeat',
		organizationRoute: true,
		permission: 'manage',
		label: 'Subscriptions',
		orgOnly: true,
	},{
		path: 'receipts',
		component: InvoiceListComponent,
	},{
		path: 'receipts/:receiptId',
		component: InvoiceViewComponent,
	}];

	public constructor(public router: Router) {
		this.mergeAccountRoutes();
	}

	public mergeAccountRoutes() {
		this.router.config.find((val) => {
			if ((val.path.startsWith('account') && val.children) ||
				val.path.startsWith('team')) {
				const children = val.children.filter((value) => {
					if (
						value.path === 'organizations' ||
						value.path === 'organization' ||
						// Routes stack with each module import.
						value.path.match(/(memberships|subscriptions|receipts)/)
					) {
						return false;
					}

					return true;
				});

				val.children = [...this.routes, ...children];
			}
		});
	}
}
