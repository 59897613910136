import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListTicketsComponent } from './list-tickets/list-tickets.component';
import { SupportComponent } from './support.component';
import { supportResolver } from './support.resolver';
import { TicketComponent } from './ticket/ticket.component';

export const childRoutes = [
	{
		path: '',
		component: SupportComponent,
		resolve: {
			supportOptions: supportResolver,
		}
	},
	{
		path: 'requests/:id',
		component: TicketComponent,
	},
	{
		path: 'requests',
		component: ListTicketsComponent,
		resolve: {
			supportOptions: supportResolver,
		}
	},
];

const routes: Routes = [
	{
		path: 'support',
		children: childRoutes,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SupportRoutingModule {}
