import { Component, OnInit } from '@angular/core';
import { AppService } from 'apps/central/src/app/app.service';
import { ApiService } from '@central/ng-shared';
import { ProjectService } from '../../../project.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DialogComponent } from 'apps/central/src/app/shared/dialog/dialog.component';
import { CatalogDialogComponent } from 'apps/central/src/app/checkout/catalog-dialog/catalog-dialog.component';
@Component({
	selector: 'central-backup-manager',
	templateUrl: './backup-manager.component.html',
	styleUrls: [
		'./backup-manager.component.scss',
	],
})
export class BackupManagerComponent implements OnInit {
	isChecked = false;
	accountId: string;
	state: string;

	constructor(
		public appService: AppService,
		public projectService: ProjectService,
		public apiService: ApiService,
	) {}

	ngOnInit(): void {
		this.accountId = this.projectService.getAccountId();

		// Set toggle state based on having active bkmgr subscription.
		this.appService.getSubscriptions(this.accountId, false).subscribe(
			(response: any[]) => {
				const backupManager = response.findIndex(
					(temp) => temp['plan_code'] === 'bakmgr' && temp['state'] === 'active'
				);

				this.isChecked = !!response[backupManager];
			},
			() => {
				this.state = 'failed';
			}
		);
	}

	toggleBackupManager(event: MatSlideToggleChange) {
		const dialogRef = this.isChecked ?

		this.appService.openDialog( DialogComponent, {
			title: 'Disable Backup Manager',
			content:
				'Disable Backup Manager Content',
			warning: true,
		}) :

		this.appService.openDialog(CatalogDialogComponent, {
			tags: ['bkmgr'],
			title: 'Enable Backup Manager',
		});

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if ( this.isChecked ) {
				this.disableBackup();
			} else {
				this.enableBackup();
			}
			if (confirmed) {
				console.log('confirmed')
			}
		});
	}

	enableBackup() {
		console.log( 'backup enabled' );
		this.projectService
			.updateEnvironmentField({
				backup_manager: {
					quota: 25, "multi-region": false, subscription_uuid: "lknlaskjncalksjncascas"
				}
			})
			.subscribe();
	}

	disableBackup() {
		console.log( 'backup disabled' );
		this.projectService
			.updateEnvironmentField({
				backup_manager: {
					quota: 0, "multi-region": false, subscription_uuid: ""
				}
			})
			.subscribe();
	}
}
