<failed-request *ngIf="'failed' === this.state"></failed-request>
<mat-card class="invoice animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16"
	*ngIf="'success' === this.state">
	<mat-icon *ngIf="this.fetched"
		class="ctw-text-imh-gray-200 card-back-top ctw-cursor-pointer ctw-absolute ctw-text-right"
		routerLink="/account/{{ this.accountService.id }}/receipts">close</mat-icon>
	<div class="ctw-flex">
		<div class="ctw-w-1/2">
			<div class="from-address address">
				<div class="notranslate">{{ this.invoice['from_address'].name }}</div>
				<ul>
					<li class="notranslate">{{ this.invoice['from_address'].address1 }}</li>
					<li class="notranslate">{{ this.invoice['from_address'].address2 }}</li>
					<li class="notranslate">
						{{ this.invoice['from_address'].city }},
						{{ this.invoice['from_address'].state }}
						{{ this.invoice['from_address'].zip }}
					</li>
					<li class="notranslate">{{ this.invoice['from_address'].country }}</li>
					<li class="notranslate"><a href="tel:{{ this.invoice['from_address'].phone.replace('-','') }}">{{
							this.invoice['from_address'].phone }}</a><mat-icon
							class="ctw-text-base ctw-absolute ctw-ml-2">open_in_new</mat-icon>
					</li>
					<li class="notranslate"><a href="mailto:{{ this.invoice['from_address'].email }}">{{
							this.invoice['from_address'].email }}</a><mat-icon
							class="ctw-text-base ctw-absolute ctw-ml-2">open_in_new</mat-icon>
					</li>
				</ul>
			</div>
			<div class="to-address address ctw-w-1/2">
				<div>Bill To</div>
				<ul>
					<li class="notranslate">{{ this.invoice['to_address'].name }}</li>
					<li class="notranslate">{{ this.invoice['to_address'].address1 }}</li>
					<li *ngIf="this.invoice['to_address'].address2" class="notranslate">
						{{ this.invoice['to_address'].address2 }}
					</li>
					<li class="notranslate">
						{{ this.invoice['to_address'].city }},
						{{ this.invoice['to_address'].state }}
						{{ this.invoice['to_address'].zip }}
					</li>
					<li class="notranslate">{{ this.invoice['to_address'].country }}</li>
				</ul>
			</div>
		</div>
		<div class="ctw-w-1/2">
			<dl class="invoice-info">
				<dt>Invoice #</dt>
				<dd class="notranslate">{{ this.invoice['invoice_number'] }}</dd>
				<dt>Billed On</dt>
				<dd class="ctw-capitalize">{{ this.invoice['created_at'] | amDateFormat : 'LL' }}</dd>
				<dt>Due On</dt>
				<dd class="ctw-capitalize">{{ this.invoice['due_on'] | amDateFormat : 'LL' }}</dd>
			</dl>

			<div class="amount ctw-mt-10">
				<div class="ctw-flex info">
					<div class="status ctw-uppercase ctw-w-4/12 ctw-text-center ctw-p-2">
						{{ this.invoice['state'].replace('_', ' ') }}
					</div>
				</div>
				<div class="ctw-text-3xl ctw-text-center ctw-p-5 notranslate">
					{{ moneyFormat(this.invoice.total_in_cents, this.invoice.currency) }}
				</div>
			</div>
		</div>
	</div>
	<div class="line-items ctw-mt-5">
		<table mat-table [dataSource]="this.invoice.line_items">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>DATE</th>
				<td mat-cell *matCellDef="let element" class="ctw-capitalize">
					{{ element.created_at | amDateFormat : 'LL' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
				<td mat-cell *matCellDef="let element" class="notranslate">
					{{ element.description.replace('WordPress Hosting', 'UltraStack VPS') }}<br />
					<ng-container *ngFor="let discount of this.invoice.redemptions">
						<div *ngIf="
								discount.amount_in_cents ===
								element.discount_in_cents
							" class="ctw-text-sm">
							<strong><span class="notranslate">{{ discount.name }}</span> Discount Applied</strong>:
							<span class="notranslate">{{
								moneyFormat(discount.amount_in_cents, invoice.currency)
								}}</span><br />
						</div>
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>QTY</th>
				<td mat-cell *matCellDef="let element">
					{{ element.quantity }}
				</td>
			</ng-container>

			<ng-container matColumnDef="unit_amount_in_cents">
				<th mat-header-cell *matHeaderCellDef>PRICE</th>
				<td mat-cell *matCellDef="let element" class="notranslate">
					{{ moneyFormat(element.unit_amount_in_cents, invoice.currency) }}
				</td>
			</ng-container>
			<ng-container matColumnDef="discount_in_cents">
				<th mat-header-cell *matHeaderCellDef>DISCOUNT</th>
				<td mat-cell *matCellDef="let element" class="notranslate">
					<ng-container *ngIf="element.discount_in_cents">
						({{ moneyFormat(element.discount_in_cents, invoice.currency) }})
					</ng-container>
				</td>
			</ng-container>
			<ng-container matColumnDef="total_in_cents">
				<th mat-header-cell *matHeaderCellDef>SUBTOTAL</th>
				<td mat-cell *matCellDef="let element" class="notranslate">
					{{ moneyFormat(element.total_in_cents, invoice.currency) }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns"></tr>
		</table>
	</div>
	<div class="totals">
		<div>
			<span>Subtotal</span>
			<div class="notranslate">{{ moneyFormat(invoice.subtotal_in_cents, invoice.currency) }}</div>
		</div>
		<div *ngIf="invoice.tax_in_cents">
			<span>Tax</span>
			<div class="notranslate">{{ moneyFormat(invoice.tax_in_cents, invoice.currency) }}</div>
		</div>
		<div>
			<span>Total</span>
			<div class="notranslate">{{ moneyFormat(invoice.total_in_cents, invoice.currency) }}</div>
		</div>
		<div *ngFor="let creditPayment of invoice.credit_payments">
			<span>Credit Applied</span>
			<div class="notranslate">({{ moneyFormat(creditPayment.amount_in_cents, invoice.currency) }})</div>
		</div>
		<div *ngFor="let transaction of invoice.transactions">
			<span>Paid</span>
			<div class="notranslate">({{ moneyFormat(transaction.amount_in_cents, this.invoice.currency) }})</div>
		</div>
	</div>
	<div class="ctw-mt-10 ctw-text-sm">
		<div>Payments</div>
		<ul>
			<li class="ctw-text-sm " *ngFor="let transaction of this.invoice.transactions">
				<span class="ctw-capitalize">{{ transaction.collected_date }}</span> &nbsp;&middot;&nbsp; <span
					class="notranslate">{{
					moneyFormat(transaction.amount_in_cents, invoice.currency)
					}}</span>
				applied to {{ transaction.card_type }} &middot;&middot;&middot;
				{{ transaction.last_four }}
			</li>
		</ul>
	</div>
	<div class="ctw-mt-10 ctw-text-sm">
		<div>Notes</div>
		<ul>
			<li class="ctw-text-sm">
				Any past due amounts can be paid by updating your payment
				method.
			</li>
			<li class="ctw-text-sm">All amounts listed in {{ invoice.currency }}.</li>
		</ul>
	</div>
</mat-card>
