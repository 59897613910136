import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ConfigurationService } from '@central/ng-shared';
import { Animations } from '../../shared/animation/animations';
import { ApiService } from '@central/ng-shared';
import { LocalStorageService } from '@central/ng-shared';
import { FormModel } from './update-password.model';

@Component({
	selector: 'update-password',
	templateUrl: 'update-password.component.html',
	styleUrls: ['update-password.component.scss'],
	animations: Animations.unauth,
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
	@HostBinding('class.collpased-logo') public collpasedLogo = true;
	@HostBinding('@routeAnimation') public animation = 'true';

	public model = new FormModel('', '');
	public inputType = 'text';
	public state = 'pending';
	public code = '';
	public email = '';
	public sub: any;
	public loginRoute: string;

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public localStorage: LocalStorageService,
		private configService: ConfigurationService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.loginRoute = this.configService.config.routes.login;
	}

	public ngOnInit() {
		this.setQueryParams();
		this.sub = this.route.params.subscribe((params) => {
			this.code = params['code'];
			try {
				this.email = atob(decodeURIComponent(params['email']));
			} catch (err) {
				this.email = '';
			}
		});
	}

	public ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	/**
	 * Set query parameters for page to redirect to.
	 *
	 * The auth service looks in query params for a return URL
	 * to redirect the user to.  In order to not mess up any
	 * existing logic for redirects check if the return value
	 * is set first, and if no return is present we will set
	 * the URL to the projects route.
	 */
	public setQueryParams() {
		// Check if route has query parameter called 'return'.
		if (!this.route.snapshot.queryParams['return']) {
			// Add the return param to the current route.
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: { return: '/projects' },
				queryParamsHandling: 'merge', // Preserve current params that may be passed.
			});
		}
	}

	public onSubmit(value: any) {
		if (this.state !== 'submitted') {
			if (
				value &&
				value.match(/(?=.*?[A-Z])(?=.*\d)(?=.*?[a-z]).{8,}$/g)
			) {
				this.model.password = value;
				this.updatePassword();
			}
		}
	}

	public success(token: string) {
		this.authService.setLogin(token);
	}

	public fail() {
		this.state = 'fail';
	}

	public updatePassword() {
		this.state = 'submitted';

		const params = {
			email: this.email,
			code: this.code,
			password: this.model.password,
		};

		this.apiService.post('updatePassword', params).subscribe(
			(response) => {
				const result = response['result'];

				if (result && result.data && result.data.success) {
					if (result.data.token) {
						this.success(result.data.token);
					}
				} else {
					this.fail();
				}
			},
			(err) => this.fail()
		);
	}
}
