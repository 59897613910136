import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@central/ng-shared';
import { Animations } from '../../shared/animation/animations';
import { LocalStorageService } from '@central/ng-shared';
import { ConfirmationService } from './confirmation.service';

@Component({
	selector: 'account-confirmation',
	templateUrl: 'confirmation.component.html',
	styleUrls: ['confirmation.component.scss'],
	animations: Animations.unauth,
})
export class ConfirmationComponent implements OnInit, OnDestroy {
	@HostBinding('class.collpased-logo') public collpasedLogo = true;
	@HostBinding('@routeAnimation') public animation = 'true';

	public email = '';
	public code = '';
	public requestStatus = 'progress';
	private sub: any;

	constructor(
		public confirmationService: ConfirmationService,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService,
		public router: Router,
		public authService: AuthService
	) {}

	public ngOnInit() {
		this.authService.silentLogout();

		this.sub = this.route.params.subscribe((params) => {
			this.code = params['code'];
			try {
				this.email = atob(decodeURIComponent(params['email']));
			} catch (err) {
				this.email = '';
			}
			this.confirmAccount();
		});
	}

	public ngOnDestroy() {
		this.sub.unsubscribe();
	}

	/**
	 * Success process, log the user in.
	 *
	 * These timeouts are purely animation related.
	 *
	 * @since 1.3.0
	 *
	 * @param   token JWT Token.
	 */
	public success(token: string) {
		setTimeout(() => {
			this.requestStatus = 'success';
		}, 1000);

		setTimeout(() => {
			const redirectUrl = this.getRedirectUrl();

			// If a redirect url was found, do not redirect within the login process.
			this.authService.setLogin(token, !!redirectUrl);

			// Send the user to the redirect route.
			if (redirectUrl) {
				this.localStorage.setItem('confirmationCode', this.code);
				this.router.navigate([redirectUrl]);
			}
		}, 2000);
	}

	public fail() {
		setTimeout(() => {
			this.requestStatus = 'fail';
		}, 1000);
	}

	public confirmAccount() {
		this.confirmationService
			.confirmAccount(this.email, this.code)
			.subscribe(
				(response) => {
					if (response && response.success) {
						this.success(response.token);
					} else {
						this.fail();
					}
				},
				() => {
					this.fail();
				}
			);
	}

	/**
	 * Get a redirect URL from the confirmation code.
	 *
	 * @since 1.3.0
	 *
	 * @return Confirmation Code.
	 */
	private getRedirectUrl() {
		let redirectUrl = '';

		if (-1 !== this.code.indexOf('::')) {
			const confirmationData = this.code.split('::');

			redirectUrl = JSON.parse(
				atob(decodeURIComponent(confirmationData[1]))
			);
			redirectUrl = redirectUrl['redirect_url'] || '';
		}

		return redirectUrl;
	}
}
