import { Injectable } from '@angular/core';
import { ApiService } from '../core';
import { AuthService, ProfileService } from '../authentication/services';

@Injectable({
	providedIn: 'root',
})
export class CouponService {
	public couponData = {};
	public discountTypes = ['server', 'domain'];

	constructor(
		public authService: AuthService,
		public apiService: ApiService,
		private profileService: ProfileService
	) { }

	public validateCoupon(code: string, type: string) {
		this.apiService
			.get(`/v1/wpcr/coupons/${code}`, {})
			.subscribe({
				next: (data: any) => {
					if (data.state === 'redeemable') {
						this.couponData[type] = data;
					} else {
						this.couponData[type] = false;
					}
				},
				error: (error) => {
					this.couponData[type] = false;
					console.log(error);
				},
			});
	}

	public getOffers() {
		const period = new Date().toLocaleString('en-US', {
			month: 'short',
			year: 'numeric'
		}).replace(' 20', '-').toLocaleLowerCase();

		this.discountTypes.forEach((type) => {
			if (type === 'server' && this.profileService.data.trial_available) {
				this.validateCoupon(this.profileService.data.trial_available.toString(), type);
			} else {
				this.validateCoupon(`upsell-${type}-${period}`, type);
			}
		});
	}
}
