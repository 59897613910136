import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'central-help-dialog',
	templateUrl: './help-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public sanitizer: DomSanitizer
	) {

	}

	sanitizeUrl(url): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	updateLinks(text) {
		if (text) {
			return this.sanitizer.bypassSecurityTrustHtml(text.replace(/<\/a>/g, ' <mat-icon class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined" style="margin-top: 1px">open_in_new</mat-icon></a>'));
		}
		return text;
	}
}
