import { Component, EventEmitter, Inject, Injectable, Output, ViewChild } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';

@Component({
	selector: 'billing-address',
	templateUrl: './billing-address.component.html',
})
@Injectable()
export class BillingAddressComponent {
    @ViewChild('newcc') public newcc: any;
    @ViewChild('billingInputs') public billingInputs: any;
	public status = 'pending';
	public errorMessage: string;
    public newBillingInfo = false;
    public cardId = null;
    public disableForm = true;
    public countries = [];
    public selectedCountry = {};

    @Output() public addCardSuccess = new EventEmitter();

	constructor(
		public dialogRef: MatDialogRef<BillingAddressComponent>,
		public snackBar: MatSnackBar,
        public ampOpApiService: AmpOpApiService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
        this.getCountries();
        this.getCountries(1);
        this.billingInputs?.autofillControls['country'].control.valueChanges.subscribe(
            (value: string) => {
                const countryFilter = this.countries.find(e => e.Code === value);
                if(countryFilter) {
                    this.billingInputs.model.countryCode = countryFilter.Id;
                    this.getCountries(countryFilter.Id)
                }
            }
        );
        this.billingInputs?.autofillControls['state'].control.valueChanges.subscribe(
            (value: string) => {
                if(this.selectedCountry.hasOwnProperty('States')) {
                    const stateFilter = this.selectedCountry['States'].find(e => e.Code === value);
                    if(stateFilter) {
                        this.billingInputs.model.stateCode = stateFilter.Id;
                    }
                    
                }
            }
        );
    }

    public getCountries(code = null) {
        this.ampOpApiService.countryLookup(code)
			.subscribe(resp => {
                if(resp.hasOwnProperty('countries')) {
					this.countries = resp['countries'];
				}
                if(resp.hasOwnProperty('States')) {
                    this.selectedCountry = resp;
                }
			}
        )
    }

    public getCountryName(code) {
		const country = this.countries?.find(c => c.Code === code);
		return (country && country.hasOwnProperty('FullName')) ? country.FullName : code;
	}

    public closeDialogue(ref) {
		this.dialogRef.close(ref);
	}

    public addNewCard() {
        this.errorMessage = null;
        const cardValid = this.newcc.isValid();
        const infoValid = this.newBillingInfo === false || this.billingInputs.isFormValid();
        if(cardValid && infoValid) {
            this.status = 'submitted'
            
            
            const billingData = this.buildBillingObject();

            const request = {
                ccData: {
                    creditCardValue: this.newcc.info.card_number,
                    cvsValue: this.newcc.info.cvv,
                    expiryValue: this.newcc.info.expiration,
                },
                addressData: billingData,
                accountId: this.data.accountInfo.contactInputs.accountId
            }
           
            this.ampOpApiService.addNewCard(request).subscribe( 
                (resp) => {
                    this.cardId = resp['id'];
                    this.addCardSuccess.emit();
                    
                },
                (err) => {
                    this.errorMessage = "Something went wrong. Please try again.";
                    this.status = 'failed';
                })
        } else {
            if([false,undefined,null].includes(cardValid)) {
                this.errorMessage = 'Credit card information is required.'
            }
            if(cardValid === false) {
                this.errorMessage = 'Credit card information is not valid.'
            }
            if(infoValid === false && this.newBillingInfo === true) {
                this.errorMessage = 'The billing information is not valid.'
            }
            this.status = 'failed';
        }
    }

    private buildBillingObject() {
        let billingDataSource = { ...this.data.accountInfo.contactInputs.user};
        let billingData;
        if(this.newBillingInfo === true) {
            billingDataSource = {...this.billingInputs.model}
            billingData = {
                address1:   billingDataSource.address1,
                address2:   billingDataSource.address2,
                city:   billingDataSource.city,
                firstName:  billingDataSource.firstName,
                lastName:	billingDataSource.lastName,
                companyName: billingDataSource.companyName,
                postal:	billingDataSource.postalCode,
                email: this.data.accountInfo.contactInputs.user.email,
                confirmEmail: this.data.accountInfo.contactInputs.user.email,
                state: "",
                stateOther: "",
                country: "",
                countryOther: "",
            }
        } else {
            billingData = {
                address1:   billingDataSource.address1,
                address2:   billingDataSource.address2,
                city:   billingDataSource.city,
                firstName:  billingDataSource.first_name,
                lastName:	billingDataSource.last_name,
                companyName: billingDataSource.company_name,
                postal:	billingDataSource.postal_code,
                email: billingDataSource.email,
                confirmEmail: billingDataSource.email,
                state: "",
                stateOther: "",
                country: "",
                countryOther: "",
            }
        }
        if(this.selectedCountry.hasOwnProperty('States')){
            const stateFilter = this.selectedCountry['States'].find(e => e.Code === billingDataSource.state);
            if(stateFilter) {
                billingDataSource.stateCode = stateFilter.Id;
            }
        }
        if(billingDataSource.hasOwnProperty('stateCode')) {
            billingData.state = billingDataSource.stateCode + ":" + billingDataSource.state;
        } else {
            billingData.state = "Other";
            billingData.stateOther = billingDataSource.state;
        }

        const countryFilter = this.countries.find(e => e.Code === billingDataSource.country);
        if(countryFilter) {
            billingDataSource.countryCode = countryFilter.Id;
        }
        if(billingDataSource.hasOwnProperty('countryCode')) {
            billingData.country = billingDataSource.countryCode + ":" + billingDataSource.country;
        } else {
            billingData.country = "Other";
            billingData.countryOther = billingDataSource.country;
        }
        return billingData;
    }

}