import { Component, Input } from '@angular/core';

@Component({
	selector: 'central-progress-button',
	templateUrl: 'progress-button.component.html',
})
export class ProgressButtonComponent {
	@Input() public type: string;
	@Input() public color = 'primary';
	@Input() public state: string;
	@Input() public disabled: boolean;
}
