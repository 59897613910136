import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
	ApiService,
	AuthService,
	ProfileService,
	ConfigurationService,
} from '@central/ng-shared';
import { TeamCreateDialogComponent } from './dialogs/create-team-dialog/create-team-dialog.component';
import { LeaveTeamDialogComponent } from './dialogs/leave-team-dialog/leave-team-dialog.component';
import { ArchiveTeamDialogComponent } from './dialogs/archive-team-dialog/archive-team-dialog.component';

@Component({
	selector: 'central-team',
	templateUrl: './team.component.html',
	styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
	public status = 'loading';
	public teams: any[] = [];
	public inactiveTeams: any[] = [];
	public hasInactive = false;
	public showInactive = false;

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public configurationService: ConfigurationService
	) {}

	ngOnInit(): void {
		this.fetchTeams(this.showInactive);
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
            case 'create':
                this.openCreateDialog();
                break;
			case 'toggleArchived':
				this.fetchTeams(true);
				break;
		}
	}

	public fetchTeams(toggle = false) {
		this.apiService.get('/v1/organizations/memberships?getInactive=' + (toggle || this.status === 'loading' ? !this.showInactive : this.showInactive), {}).subscribe({
			next: (teams: any[]) => {
				this.teams = teams || [];

				teams.map((team) => {
					team.role = team.members.find(
						(element) =>
							element.account.account_id ===
							this.authService.getAccountId()
					).role;
				});

				this.showInactive = toggle && this.status !== 'loading' ? !this.showInactive : this.showInactive;
				this.inactiveTeams = teams.filter((team) => team.active === false);
				this.teams = teams.filter((team) => team.active === true);

				if (this.inactiveTeams.length) {
					this.hasInactive = true;
				}

				if (this.teams.length <= 0 && this.showInactive === false) {
					this.showInactive = true;
					this.fetchTeams();
				}

				this.status = 'success';
			},
			error: (error) => {
				this.status = 'failed';
			},
		});
	}

	public openCreateDialog() {
		const dialogRef = this.dialog.open(TeamCreateDialogComponent, {
            disableClose: true,
            width: '500px',
			maxWidth: '500px'
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchTeams();

                    this.snackBar.open('Team Created', '', {
                        duration: 4000,
                    });
				}
			},
		});
    }

    public openLeaveDialog(team) {
		const dialogRef = this.dialog.open(LeaveTeamDialogComponent, {
            disableClose: true,
            width: '500px',
			maxWidth: '500px',
			data: {
				teamId: team.organization_id || this.getPrivateTeam(team),
				teamName: team.name,
				membershipId: this.getMembershipId(team),
				canLeave: this.isSoleOwner(team),
				isPrivate: team.account_id ? true : false,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchTeams();

					this.snackBar.open('Left Team', '', {
						duration: 4000,
					});
				}
			},
		});
	}

    public openArchiveDialog(team) {
		const dialogRef = this.dialog.open(ArchiveTeamDialogComponent, {
            disableClose: true,
            width: '500px',
			maxWidth: '500px',
			data: {
				teamId: team.organization_id || this.getPrivateTeam(team),
				teamName: team.name,
				membershipId: this.getMembershipId(team),
				hasSubscriptions: false,
				isPrivate: team.account_id ? true : false,
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchTeams();

					this.snackBar.open('Team Archived', '', {
						duration: 4000,
					});
				}
			},
		});
	}

    public restoreTeam(team) {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			'/v1/organizations/' + team.organization_id
		);

		this.apiService.http
			.patch(
				url,
				{
					active: true,
				},
				{ headers }
			)
			.subscribe({
				next: (account) => {
					this.profileService.fetch();
					this.fetchTeams();
				},
				error: () => {
				},
			});
	}

	getRoleLabel(role: string) {
		return (
			this.configurationService.config.groupPermissions[role]?.label || ''
		);
	}

	private getPrivateTeam(team): string {
		return this.profileService.data.account_access.filter(
			(account) => (
					account.type === 'user' &&
					account.role === 'maintainer' &&
					account.display_name === team.name &&
					team.account_id
				)
		)[0].account_id;
	}

	private getMembershipId(team): string {
		return team.members.filter(
			(member) =>
				member.account.account_id === this.authService.getAccountId()
		)[0].membership_id;
	}

	private isSoleOwner(team): boolean {
		const ownerCount = team.members.filter(
			(member) => member.role === 'owner'
		).length;
		if (team.role === 'owner' && ownerCount === 1) {
			return false;
		}
		return true;
	}

}
