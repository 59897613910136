<bgc-header-logo></bgc-header-logo>

<div class="login valign" *ngIf="'success' !== this.state">
	<h3>Select your new password:</h3>
	<p
		[class.animate__bounce]="state === 'fail'"
		[hidden]="state !== 'fail'"
		class="error animate__animated"
	>
		Oops! Unable to set your password. Please try again.
	</p>
	<form
		(ngSubmit)="onSubmit(passwordWithValidation.value)"
		#updatePassword="ngForm"
	>
		<input matInput hidden name="email" disabled [value]="this.email" />

		<div>
			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>Password</mat-label>
				<input
					data-private
					matInput
					#passwordWithValidation
					autofocus
					autocomplete="off"
					name="password"
					[type]="'submitted' === this.state ? 'password' : inputType"
					required
					[formControl]="
						passwordComponentWithValidation.passwordFormControl
					"
					placeholder="Password"
				/>
				<button
					type="button"
					mat-button
					matSuffix
					mat-icon-button
					aria-label="Show Password"
					(click)="
						inputType =
							'password' === inputType ? 'text' : 'password'
					"
				>
					<mat-icon>remove_red_eye</mat-icon>
				</button>
				<mat-hint align="end" aria-live="polite">
					{{ passwordWithValidation.value.length }} / 32
				</mat-hint>
				<mat-error
					*ngIf="
						passwordComponentWithValidation.passwordFormControl.hasError(
							'required'
						)
					"
				>
					Password is required
				</mat-error>
				<mat-error
					*ngIf="
						passwordComponentWithValidation.passwordFormControl.hasError(
							'pattern'
						)
					"
				>
					Password is not valid
				</mat-error>
			</mat-form-field>

			<mat-password-strength
				#passwordComponentWithValidation
				[min]="8"
				[max]="32"
				[enableSpecialCharRule]="false"
				[password]="passwordWithValidation.value"
			>
			</mat-password-strength>

			<!--Password's strength info-->
			<mat-password-strength-info
				digitsCriteriaMsg="contains at least one numeric character"
				lowerCaseCriteriaMsg="contains at least one lowercase character"
				upperCaseCriteriaMsg="contains at least one uppercase character"
				[passwordComponent]="passwordComponentWithValidation"
			></mat-password-strength-info>
		</div>

		<div class="action-buttons ctw-text-right">
			<button
				*ngIf="!this.localStorage.getItem('confirmationCode')"
				mat-raised-button
				mat-raised-button
				mat-ripple
				type="button"
				[routerLink]="['/login']"
			>
				Cancel
			</button>
			<button
				mat-raised-button
				mat-raised-button
				mat-ripple
				color="accent"
				type="submit"
				[disabled]="state === 'submitted'"
				class="btn-spinner"
			>
				<mat-spinner
					diameter="20"
					*ngIf="state === 'submitted'"
				></mat-spinner
				>Set Password
			</button>
		</div>
	</form>
</div>
<div class="login valign ctw-text-center" *ngIf="'success' === this.state">
	<div class="ctw-max-w-xs ctw-mx-auto ctw-mb-16">
		<img
			class="svg"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/security.svg"
		/>
	</div>
	<h2>Password Updated</h2>
	<p>Please use your new password to login.</p>
	<button
		mat-raised-button
		color="primary"
		type="button"
		[routerLink]="[this.loginRoute]"
	>
		Login
	</button>
</div>
