import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppService } from '../../app.service';

@Component({
	selector: 'central-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

	public state = 'pending';

	public copyText = 'Copy to Clipboard';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public appService: AppService
	) {}

	public copy() {
		this.appService.copyToClipboard(this.data.itemToCopy, this.data.itemToCopyName);
	}
}
