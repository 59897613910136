// eslint-disable-next-line
import { Md5 } from 'ts-md5/dist/md5';

export class Gravitar {
	/**
	 * Get the url to the users gravitar icon.
	 *
	 * @since 1.3
	 *
	 * @param   email Email to override.
	 * @return        URL.
	 */
	public md5Email(email?: string) {
		return email ? Md5.hashStr(email) : 'default';
	}

	/**
	 * Get the default profile image.
	 *
	 * @since 1.18.0
	 *
	 * @return Default image.
	 */
	public getDefaultUrl(): string {
		return '//www.gravatar.com/avatar/default?d=mm';
	}

	/**
	 * Get the url to the users gravitar icon.
	 *
	 * @since 1.3
	 *
	 * @param   email Email to override.
	 * @return        URL.
	 */
	public getUrl(email?: string): string {
		const updatedEmail = this.md5Email(email);
		return '//www.gravatar.com/avatar/' + updatedEmail + '?d=mm';
	}
}
