import { Injectable } from '@angular/core';
import { AuthService, ProfileService } from '@central/ng-shared';
import { ApiService } from '@central/ng-shared';

@Injectable()
export class NotificationService {
	public unreadCount = 0;
	public unreadMax = 99;

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
	) {}

	public updateCount(count: number) {
		this.profileService.data.notifications = Math.max(0, count);
		this.profileService.data.notifications = Math.min(
			this.profileService.data.notifications,
			this.unreadMax
		);
	}

	public decrementUnreadCount() {
		this.updateCount(this.profileService.data.notifications - 1);
	}

	/**
	 * Mark notifcation as read.
	 *
	 * @since 1.0.0
	 * @param  notificationId Notification ID.
	 */
	public updateNotification(notificationId: number) {
		this.apiService
			.post('notificationUpdate', { id: notificationId })
			.subscribe();
	}
}

/*
* When do we get the count?
In the toolbar compoenent.

Count.
Messages

Where should Count be stored?
window.localStorage - persistance between pages laoded, not important to me
variable - wel will be updating this frequently

When is count updated?
On Dashboard visit or new toolbar render.
Must check expired

When are messages updated?
When you go to the notifcations Page.

*/
