import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AccountService, ApiService, ProfileService } from '@central/ng-shared';

import { DomainThirdPartyDeleteDialogComponent } from './domain-third-party-delete-dialog/domain-third-party-delete-dialog.component';
import { DomainThirdPartyAddDialogComponent } from './domain-third-party-add-dialog/domain-third-party-add-dialog.component';
import { AppService } from '../../../app.service';
import { interval } from 'rxjs/internal/observable/interval';

@Component({
	selector: 'central-domain-collection',
	templateUrl: './domain-collection.component.html',
	styleUrls: ['./domain-collection.component.scss'],
})
export class DomainCollectionComponent implements OnInit, OnDestroy {

	public state = 'loading';

	public firstPartyRequestState = 'loading';
	public thirdPartyRequestState = 'loading';

	public deletingRecordIndex = -1;
	public thirdPartyDeleteRequestState = 'inactive';

	private adminAccountIds = [];

	public firstPartyDomains = [];
	public thirdPartyDomains = [];

	public allDomains = [];

	public displayedColumns = ['domain', 'expires', 'manage'];

	private subscriptions = [];

	constructor(
		public accountService: AccountService,
		private apiService: ApiService,
		private appService: AppService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private profileService: ProfileService,
		public router: Router
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.profileService.data.account_access.forEach((account) => {
					if (account.role === 'owner' || account.role === 'maintainer') {
						this.adminAccountIds.push(account.account_id);
					}
				});
				this.fetchDomains(false);
				this.subscriptions.push(
					interval(10000).subscribe(() => {
						this.fetchDomains(false);
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'add':
				this.addThirdPartyDomain();
				break;
			case 'reload':
				this.firstPartyRequestState = 'loading';
				this.thirdPartyRequestState = 'loading';
				this.firstPartyDomains = [];
				this.thirdPartyDomains = [];
				this.allDomains = [];
				this.fetchDomains(false);
				break;
		}
	}

	fetchDomains(fromCache = true) {
		this.firstPartyRequestState = 'loading';
		this.thirdPartyRequestState = 'loading';

		// Load first-party domains
		this.appService.getFirstPartyDomains(fromCache).subscribe(
			async (response: any[]) => {
				response.sort().forEach((domain) => {
					if (this.profileService.data.options.public_selected_team === domain.account_id) {
						this.firstPartyDomains.push(domain);
					}
				});

				await Promise.all(this.firstPartyDomains.map(async (domain) => {
					await this.appService.getDomainInfo(domain.name).then(info => {
						this.allDomains.push({
							id: null,
							domainName: domain.name,
							accountId: domain.account_id,
							info
						});
					})
				})
				);

				this.thirdPartyDomains.sort(function (
					firstDomain,
					secondDomain
				) {
					if (firstDomain.domainName > secondDomain.domainName) {
						return 1;
					}

					if (firstDomain.domainName < secondDomain.domainName) {
						return -1;
					}

					return 0;
				});

				this.firstPartyRequestState = 'succeeded';
				this.sortAllDomains();
			},
			() => {
				this.firstPartyRequestState = 'failed';
			}
		);

		// Load third-party domains
		this.appService.getThirdPartyDomains(fromCache).subscribe(
			(response: any[]) => {
				// const currentAccount = this.accountService.account.organization_id || null;
				// const userAccountId = this.accountService.authService.getAccountId();

				// // Filter out third-party domains that are not owned by the current account.
				// response = response.filter( ( domain ) => {
				// 	if ( currentAccount ) {
				// 		return currentAccount === domain.fields.organization_id;
				// 	} else {
				// 		return userAccountId === domain.fields.organization_id || ! domain.fields.organization_id;
				// 	}
				// } );

				response
					.sort(function (firstDomain, secondDomain) {
						if (firstDomain.label > secondDomain.label) {
							return 1;
						}

						if (firstDomain.label < secondDomain.label) {
							return -1;
						}

						return 0;
					})
					.forEach((domain) => {
						if (
							this.profileService.data.options.public_selected_team === domain.fields.organization_id
						) {
							this.thirdPartyDomains.push(domain);
						}
					});

				this.thirdPartyDomains.forEach((domain) => {
					this.allDomains.push({
						id: domain.id,
						domainName: domain.label,
						accountId: domain.fields.organization_id,
						info: {},
					});
				});

				this.thirdPartyRequestState = 'succeeded';
				this.state = this.firstPartyRequestState = 'succeeded' ? 'success' : this.state;
				this.sortAllDomains();
			},
			(error) => {
				this.thirdPartyRequestState = 'failed';
			}
		);
	}

	private sortAllDomains() {
		// Set to get unique objects
		this.firstPartyDomains = this.firstPartyDomains.filter(
			(obj, index, arr) => arr.findIndex(o => JSON.stringify(o) === JSON.stringify(obj)) === index);
		this.thirdPartyDomains = this.thirdPartyDomains.filter(
			(obj, index, arr) => arr.findIndex(o => JSON.stringify(o) === JSON.stringify(obj)) === index);
		this.allDomains = this.allDomains.filter(
			(obj, index, arr) => arr.findIndex(o => JSON.stringify(o) === JSON.stringify(obj)) === index);

		this.allDomains.sort(function (firstDomain, secondDomain) {
			if (firstDomain.info.domainExpireDate > secondDomain.info.domainExpireDate) {
				return 1;
			}

			if (firstDomain.info.domainExpireDate < secondDomain.info.domainExpireDate) {
				return -1;
			}

			return 0;
		});
	}

	public getAccountNameFromId(accountId) {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === accountId
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	addThirdPartyDomain() {
		const dialogRef = this.dialog.open(
			DomainThirdPartyAddDialogComponent,
			{
				disableClose: true,
				width: '500px',
			}
		);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.appService.getThirdPartyDomains(true);
				console.log(result)
			}
		});
    }

    deleteThirdPartyDomain(thirdPartyDomain, index) {
		const dialogRef = this.dialog.open(
			DomainThirdPartyDeleteDialogComponent,
			{
				disableClose: true,
				width: '500px',
				data: { thirdPartyDomain: thirdPartyDomain },
			}
		);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deletingRecordIndex = index;
				this.thirdPartyDeleteRequestState = 'loading';

				this.apiService
					.delete('/v1/domains/' + thirdPartyDomain.id, {})
					.subscribe(
						(response: any[]) => {
							this.thirdPartyDomains =
								this.thirdPartyDomains.filter(
									(domain) =>
										domain.id !== thirdPartyDomain.id
								);
							this.allDomains = this.allDomains.filter(
								(domain) => domain.id !== thirdPartyDomain.id
							);
							this.appService.getThirdPartyDomains(true);
							this.thirdPartyDeleteRequestState = 'inactive';
						},
						(error) => {
							this.thirdPartyDeleteRequestState = 'inactive';
							this.snackBar.open('Error deleting domain', '', {
								duration: 3000,
							});
						}
					);
			}
		});
	}
}
