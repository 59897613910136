<div class="wrap animate__animated animate__fadeIn ctw-mt-8 ctw-mx-auto ctw-max-w-4xl ctw-mb-8">
	<dash-card [disabled]="true" class="ctw-cursor-default" [transparent]="true">
		<mat-progress-bar
			*ngIf="state === 'loading' || (env === 'vps' && !containerStatus) || (env !== 'vps' && this.siteHealthRequestState !== 'success')"
			mode="indeterminate" color="primary"></mat-progress-bar>
		<div class="dash-card-content" *ngIf="state === 'success'">
			<mat-list *ngIf="(containerStatus || (env !== 'vps' && this.siteHealthRequestState === 'success'))"
				class="ctw-grid ctw-grid-cols-3 ctw-p-0 ctw-border-b ctw-border-gray-300 ctw-border-solid ctw-mb-8 ctw-pb-6 animate__animated animate__fadeIn">
				<mat-list-item>
					<span mat-list-icon class="indicator running"
						*ngIf="containerStatus === 'running' || env !== 'vps'"></span>
					<span mat-list-icon class="indicator" *ngIf="containerStatus === 'stopping'"></span>
					<span mat-list-icon class="indicator" *ngIf="containerStatus === 'starting'"></span>
					<span mat-list-icon class="indicator" *ngIf="containerStatus === 'restarting'"></span>
					<span mat-list-icon class="indicator stopped" *ngIf="containerStatus === 'stopped'"></span>
					<span mat-list-icon class="indicator stopped" *ngIf="containerStatus === 'reclaimed'"></span>
					<h4 mat-line class="ctw-capitalize">{{ this.env === 'vps' ? this.containerStatus : 'Running' }}</h4>
					<p mat-line class="ctw-text-imh-gray-200">
						Server Status
					</p>
				</mat-list-item>
				<mat-list-item *ngIf="this.keyStats[0]">
					<span mat-list-icon inlineSVG="assets/img/wordpress.svg"></span>
					<h4 mat-line>{{ this.keyStats[0]?.value }}</h4>
					<p mat-line class="ctw-text-imh-gray-200">
						{{ this.keyStats[0]?.name }}
					</p>
				</mat-list-item>
				<mat-list-item *ngIf="!this.keyStats[0]">
					<span mat-list-icon inlineSVG="assets/img/wordpress.svg"></span>
					<h4 mat-line class="ctw-text-yellow-700">{{ this.siteHealthRequestState === 'success' ||
						containerStatus !== 'running' ? 'Unreachable'
						: 'Connecting...' }}</h4>
					<p mat-line class="ctw-text-imh-gray-200">
						Central Connect
					</p>
				</mat-list-item>
				<mat-list-item *ngIf="env !== 'vps'">
					<mat-icon mat-list-icon>code</mat-icon>
					<h4 mat-line>{{ this.keyStats[1]?.value }}</h4>
					<p mat-line class="ctw-text-imh-gray-200">
						{{ this.keyStats[1]?.name }}
					</p>
				</mat-list-item>
				<mat-list-item *ngIf="env === 'vps'">
					<span class="ultrastack" mat-list-icon inlineSVG="assets/img/ultrastack.svg"></span>
					<h4 mat-line [class.ctw-text-red-700]="this.latestPlaybookVersion !== this.currentPlaybookVersion"
						[class.ctw-text-yellow-700]="this.ansibleService.override">
						{{ this.ansibleService.override ? 'Unmanaged' :
						this.currentPlaybookVersion }}</h4>
					<p *ngIf="containerStatus === 'running' && this.ansibleService.state === 'pending'" mat-line
						class="ctw-text-imh-blue ctw-cursor-pointer" (click)="this.openPlaybookDialog()">
						UltraStack Version
					</p>
					<p *ngIf="this.ansibleService.state !== 'pending' && !this.ansibleService.override" mat-line>
						Updating Software...
					</p>
					<p *ngIf="containerStatus !== 'running'" mat-line>
						UltraStack Version
					</p>
				</mat-list-item>
			</mat-list>
			<div
				*ngIf="!this.issue && !this.hasUpdates && this.siteHealthRequestState === 'success' && (containerStatus || this.env !== 'vps')">
				<div class="task-complete ctw-text-center animate__animated animate__fadeIn">
					<span class="status-icon ctw-w-24 ctw-inline-block" inlineSVG="./assets/temp/cloud-done.svg"></span>
					<p>Everything is up to date</p>
					<p>The latest and greatest is up and running!</p>
				</div>
			</div>
			<div class="ctw-mt-12 ctw-mx-10 animate__animated animate__fadeIn"
				*ngIf="containerStatus !== 'running' && env === 'vps'">
				<mat-card *ngIf="containerStatus === 'reclaimed' || containerStatus === 'suspended'"
					appearance="outlined" class="alert alert-caution">
					<mat-card-content>
						<div class="ctw-flex">
							<mat-icon class="ctw-text-red-700">warning</mat-icon>
							<i class="ctw-text-red-700">
								We've detected a potential issue with your site!
							</i>
						</div>
						<div class="ctw-text-base ctw-mt-3">
							<span class="ctw-font-bold">Your server is {{ containerStatus === 'reclaimed' ? 'no longer
								available.' : 'offline and pending deletion.' }}<br></span>
							<span>This is commonly caused by unpaid subscription. Please refer to your email for billing
								notices or reach out to support for more information.</span>
						</div>
						<div class="action ctw-mt-10 ctw-mx-auto ctw-max-w-4xl ctw-w-full ctw-text-right">
							<button routerLink="/team/{{this.projectService.project.organization_id}}/help/requests"
								mat-raised-button color="warn" class="ctw-ml-3">
								Contact Us
							</button>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="ctw-mt-12 ctw-mx-10 animate__animated animate__fadeIn"
				*ngIf="containerStatus === 'running' && this.issue && env === 'vps'">
				<mat-card appearance="outlined" class="alert">
					<mat-card-content>
						<div class="ctw-flex">
							<mat-icon class="ctw-text-red-700">warning</mat-icon>
							<i class="ctw-text-red-700">
								We've detected a potential issue with your site!
							</i>
						</div>
						<central-diagnostic-triage [state]="this.request" [diag]="this.recommended"
							(fix)="this.runDiag($event)" [attempts]="this.attempts"></central-diagnostic-triage>
					</mat-card-content>
				</mat-card>
			</div>
			<div class="ctw-mt-12 ctw-mx-10 animate__animated animate__fadeIn"
				[hidden]="this.siteHealthRequestState !== 'success' ||
				(this.siteHealthRequestState === 'success' && (!this.hasUpdates || (this.hasUpdates && this.issue) || (env === 'vps' && !containerStatus)))">
				<mat-card appearance="outlined" class="alert alert-caution">
					<mat-card-content>
						<div class="ctw-flex">
							<mat-icon class="ctw-text-yellow-700">warning</mat-icon>
							<i class="ctw-w-full">
								The following items are out of date!
							</i>
						</div>
						<central-site-health #siteHealth [env]="this.env"
							(updatesPending)="this.updateSiteHealth($event)"></central-site-health>
					</mat-card-content>
				</mat-card>
			</div>

		</div>
	</dash-card>
</div>
