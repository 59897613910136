import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { CustomFormsModule } from 'ngx-custom-validators';
import { AmpOpApiService } from './amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from './amp-local-storage-service';

// Modules.
import { SharedModule } from '../shared/shared.module';
import { AmpComponent } from './amp.component';
import { TitleService, CoreModule } from '@central/ng-shared';
import { CheckoutComponent } from './checkout/checkout.component';
import { AmpDomainComponent } from './amp-domain/amp-domain.component';
import { AmpStepperComponent } from './amp-stepper/amp-stepper.component';
import { CheckoutModule } from '../checkout/checkout.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		CoreModule,
		CustomFormsModule,
		CheckoutModule,
		MomentModule,
		MatPasswordStrengthModule,
	],
	declarations: [
		AmpComponent,
		CheckoutComponent,
		AmpDomainComponent,
		AmpStepperComponent
	],
	providers: [TitleService,AmpOpApiService,AmpLocalStorageService],
})
export class AmpModule {}
