import { Directive } from '@angular/core';
import {
	AbstractControl,
	NG_VALIDATORS,
	ValidationErrors,
	Validator,
	ValidatorFn,
} from '@angular/forms';
import isURL from 'validator/es/lib/isURL';

@Directive({
	selector: '[centralValidationUrl]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CentralValidationUrlDirective,
			multi: true,
		},
	],
})
export class CentralValidationUrlDirective implements Validator {
	public validate(control: AbstractControl): ValidationErrors | null {
		const validator = this.check();
		return validator(control);
	}

	protected check(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const forbidden = !isURL(control.value + '', {
				require_protocol: true,
				protocols: ['http', 'https'],
			});

			return forbidden
				? { centralValidationUrl: { value: control.value } }
				: null;
		};
	}
}
