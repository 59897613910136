import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { ConfigurationService } from '../../../core/configuration.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class FirebaseService {
	public firebaseAuthUI: any;
	public currentUser;
	public authReady = new ReplaySubject();

	public constructor(
		public angularFireAuth: AngularFireAuth,
		public configService: ConfigurationService
	) {
		this.setCurrentUser();
	}

	public initUI(target: string, uiConfig: object): void {
		this.angularFireAuth.app.then((app) => {
			if (!this.firebaseAuthUI) {
				this.firebaseAuthUI = new firebaseui.auth.AuthUI(app.auth());
			} else {
				this.firebaseAuthUI.reset();
			}

			this.firebaseAuthUI.start(target, uiConfig);
		});
	}

	public async setCurrentUser() {
		const app = await this.angularFireAuth.app;
		app.auth().onAuthStateChanged((user) => {
			this.currentUser = user;
			this.authReady.next(this.currentUser);
			this.authReady.complete();
		});
	}

	public getConfig(): object {
		const signInOptions = this.getSignInOptions();

		return {
			signInFlow: 'popup',
			signInSuccessUrl: '/auth/sign-in',
			callbacks: {},
			// immediateFederatedRedirect: true,
			signInOptions: signInOptions,
			tosUrl: 'https://www.boldgrid.com/tos/',
			privacyPolicyUrl:
				'https://www.boldgrid.com/website-privacy-policy/',
		};
	}

	public getSignInOptions() {
		const signInOptions = [];
		const props = {
			google: {
				provider: auth.GoogleAuthProvider.PROVIDER_ID,
				customParameters: {
					// Forces account selection even when one account is available.
					prompt: 'select_account',
				},
			},
			twitter: auth.TwitterAuthProvider.PROVIDER_ID,
			facebook: {
				scopes: [
					// These require app review
					// 'public_profile',
					// 'user_friends',
					'email',
				],
				provider: auth.FacebookAuthProvider.PROVIDER_ID,
			},
			github: auth.GithubAuthProvider.PROVIDER_ID,
		};

		for (const option of this.configService.config.signInOptions) {
			signInOptions.push(props[option]);
		}

		return signInOptions;
	}
}
