
<div class="ctw-py-4 ctw-w-full" [class]="this.actions === 'amp' ? 'amp-footer' : ''">
    <div *ngIf="this.actions === 'amp'" class="ctw-flex ctw-justify-between ctw-mx-auto ctw-max-w-{{ this.breakpoint }}">
        <div class="ctw-ml-6 ctw-flex ctw-flex-col">
            <div class="ctw-text-white ctw-font-bold">ABOUT US</div>
            <div>
                <a href="{{
                this.configService.config.brandConfig
                    .termsOfService
                }}" target="_blank">Terms of Service</a> 
            </div>
            <div>
                <a href="{{
                this.configService.config.brandConfig
                    .privacyPolicy
                }}" target="_blank">Privacy Policy</a>
            </div>
        </div>
        <div class="ctw-ml-6 ctw-flex ctw-justify-end">
            <div class="ctw-flex ctw-flex-col">
                <div class="ctw-flex ctw-justify-start">
                    <h2 class="ctw-m-0">
                        <a href="https://www.inmotionhosting.com" target="_blank">
                            <img class="amp-brand-logo"
                                src="assets/img/imh-white-logo-red-stripe.png" />
                        </a>
                    </h2>
                </div> 
                <p class="ctw-ml-8 ctw-mr-3 ctw-text-right ctw-leading-tight ctw-flex ctw-flex-col">
                    <span>{{ this.configService.config.powerPanel.brandAddress}}</span>
                    <span>{{ this.configService.config.powerPanel.brandCity}}</span>
                    <span>{{ this.configService.config.powerPanel.phoneNumber}}</span>
                    <span>© {{ copyRightYear }} All Rights Reserved</span>
                </p>
            </div>
        </div>
    </div>
</div>