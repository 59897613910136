import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';

export class Animations {
	public static unauth = [
		trigger('routeAnimation', [
			state('*', style({ opacity: 1 })),
			transition(':enter', [style({ opacity: 0 }), animate('1s ease')]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate(
					'1s ease',
					style({
						opacity: 0,
					})
				),
			]),
		]),
	];
	public static page = [
		trigger('routeAnimation', [
			state(
				'*',
				style({
					opacity: 1,
					transform: 'translateY(0%)',
				})
			),
			transition(':enter', [
				style({
					opacity: 0,
				}),
				animate('400ms 50ms ease-in'),
			]),
		]),
	];
}
