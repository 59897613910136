<p *ngIf="this.state === 'failed'">
	Error preparing search...
	<button class="simple-button" (click)="this.fetchTlds()">[try again]</button>
</p>

<ng-container *ngIf="this.state === 'success'">
	<form #form="ngForm" (ngSubmit)="form.valid && isDomainAvailable()">
		<div class="ctw-flex input-wrap">
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Find Your Team's Next Domain</mat-label>
				<mat-icon matPrefix>search</mat-icon>
				<input matInput type="text" [(ngModel)]="domainName" [formControl]="domainValidator" autofocus
					placeholder="Start your search here..." class="domain-search-box" />
				<mat-error *ngIf="domainValidator?.invalid" [innerHTML]="getErrorMessage()"></mat-error>
				<div class="ctw-absolute ctw-right-0 ctw-top-0">
					<central-progress-button color="primary" [disabled]="!domainName" class="">Check
						Availability</central-progress-button>
					<button mat-raised-button color="accent" class="ctw-ml-3" (click)="this.randomSearch()">I Feel
						Lucky</button>
				</div>
			</mat-form-field>
		</div>
	</form>
</ng-container>
