import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

// Internal.
import { FirebaseService } from './firebase/firebase.service';
import { DecoderService } from '../../core/jwt/decoder';

// Externals.
import { ApiService } from '../../core/api/api.service';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { ConfigurationService } from '../../core/configuration.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AuthService {
	public loginUrl = '';
	public jwt: string;
	public role: string;
	public disableAuthRedirect = false;
	public authStateChange = new EventEmitter();
	public onLogin = new EventEmitter();
	public onLogout = new EventEmitter();
	public onInit = new ReplaySubject(1);

	constructor(
		public router: Router,
		public apiService: ApiService,
		public firebase: FirebaseService,
		public route: ActivatedRoute,
		public jwtHelper: DecoderService,
		public configService: ConfigurationService,
		private localStorage: LocalStorageService
	) {
		this.jwt = this.jwtHelper.getToken();
		this.onInit.next();
		this.updateRole();
		this.watchLoginState();
		this.apiService.silentLogout$.subscribe(() => this.silentLogout());
	}

	public watchLoginState() {
		/**
		 * Poll the localstorage login state.
		 * If the login token becomes different than the application login token.
		 * Refresh the page.
		 */
		setInterval(() => {
			const tokenValue = this.jwtHelper.getToken();

			// Loose type checking needed.
			// eslint-disable-next-line eqeqeq
			if (this.jwt != tokenValue) {
				window.location.reload();
			}
		}, 5000);
	}

	/**
	 * Update the class property role, based on the user type.
	 *
	 * @since 1.3.0
	 */
	public updateRole() {
		this.role = this.isLoggedIn() ? 'user' : 'guest';
	}

	public getToken() {
		return this.jwtHelper.getToken();
	}

	public setUpdatedEmail(email: string) {
		window.localStorage.setItem('updatedEmail', email);
	}

	public getAccountId() {
		return this.jwtHelper.getTokenVal('account_id');
	}
	public getUserId() {
		return this.jwtHelper.getTokenVal('user_id');
	}
	public isAdmin() {
		return !!this.jwtHelper.getTokenVal('admin_login');
	}

	public isImpersonating() {
		return !!this.jwtHelper.getTokenVal('impersonate');
	}

	/**
	 * Returns true if the JWT token has a property called 'has_completed_guide' and it is false.
	 * Returns false if the application is not IMC
	 *
	 * @returns boolean
	 */
	public isGuideLocked() {
		if (
			this.configService.config.brandConfig.applicationName.includes(
				'InMotion'
			)
		) {
			return !this.jwtHelper.getTokenVal('has_completed_guide');
		}
		return false;
	}

	public getEmail() {
		const updatedEmail = window.localStorage.getItem('updatedEmail');
		return updatedEmail
			? updatedEmail
			: this.jwtHelper.getTokenVal('email');
	}

	/**
	 * Update the token with the token saved in local storage.
	 *
	 * This is used to trigger return URL and SSO.
	 *
	 * @since 1.4.1
	 */
	public reloadToken() {
		const token = this.jwtHelper.getToken();
		this.setLogin(token);
	}

	public setLogin(token: string, disableRedirect?: boolean) {
		const returnRoute = this.route.snapshot.queryParams['return'];
		window.localStorage.removeItem('updatedEmail');
		this.jwtHelper.setToken(token);
		this.jwt = this.jwtHelper.getToken();
		this.updateRole();
		// this.license.fetch();

		// Trigger this after all inline updates above.
		this.authStateChange.emit();
		this.onLogin.emit();

		if (disableRedirect || this.disableAuthRedirect) {
			return;
		}

		if (returnRoute) {
			// Go to the url requested.
			window.location.href = returnRoute;
		} else {
			// Go to the dashboard.
			this.navigatePostLogin();
		}
	}

	/**
	 * Navigate to the post login page.
	 *
	 * @since 1.15.0
	 */
	public navigatePostLogin(): void {
		if (this.loginUrl) {
			this.router.navigateByUrl(this.loginUrl);
			this.loginUrl = null;
		} else {
			this.router.navigate([this.configService.config?.routes?.postLogin || '']);
		}
	}

	/**
	 * Log the user out without redirecting them.
	 *
	 * @since 1.3
	 */
	public silentLogout() {
		this.localStorage.clear();
		this.jwtHelper.removeToken();
		this.jwt = null;
		window.localStorage.removeItem('updatedEmail');
		this.updateRole();

		// Info about 3rd party signout: https://groups.google.com/forum/#!topic/firebase-talk/gxBm0WKCuIY
		this.firebase.angularFireAuth.signOut();

		// Trigger this after all inline updates above.
		this.authStateChange.emit();
		this.onLogout.emit();
	}

	/**
	 * Log a user out.
	 *
	 * @since 1.3
	 */
	public logout() {
		const returnRoute = this.route.snapshot.queryParams['return'];

		this.silentLogout();

		if (returnRoute) {
			window.location.href = returnRoute;
		} else {
			setTimeout(() => {
				this.router.navigate([this.configService.config.routes.login], {
					replaceUrl: true,
				});
			});
		}
	}

	/**
	 * Redirect to login if state changed.
	 */
	public isLoggedIn() {
		return this.jwtHelper.validToken();
	}

	public preAuthCheck(name: string) {
		return this.apiService.get('checkRateLimit', { id: name }).pipe(
			map((res) => {
				let validRequest = true;
				const result = res['result'];

				if (result && result.data) {
					validRequest = result.data.validRequest;
				}

				return validRequest;
			})
		);
	}

	public signup() {
		this.router.navigate(['login']);
	}

	public loginAsUser(user_id, redirectUrl = null) {
		return this.apiService
			.get(`/v1/agents/accounts/${user_id}/login-token`, {})
			.pipe(
				tap( (response: any) => {
					const tokenUrl = new URL(response.token);
					const token = tokenUrl.searchParams.get('auth-token');

					if ( redirectUrl ) {
						tokenUrl.searchParams.set('return', redirectUrl);
					}

					// Need to set the token before redirecting to avoid conflicts with the profile
					// being fetched with the old token(pre token update) and new token(post token update) simultaneously on page load
					this.silentLogout();
					this.setLogin(token);

					if (location.hostname.match(/(200.225.44.119|inmotionhosting)/)) {
						// Cross-environment impersonation
						location.href = location.href.split('agent')[0] + 'token' + response.token.split('/token')[1];
					} else {
						window.location.assign(tokenUrl.toString());
					}
				})
			);
	}
}
