<central-header title="New Email Subscription" [status]="this.state" actions="" breakpoint="6xl"></central-header>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16"
	[class.ctw-hidden]="this.state !== 'ready'">
	<ng-container *ngIf="!checkoutComplete && this.state === 'ready'">
		<h4 class="ctw-text-center ctw-mb-4 ctw-flex ctw-justify-center">
			<mat-icon class="ctw-text-imh-gray-200 ctw-mr-3">info</mat-icon> Confirm the quantity of email accounts to
			start with
			before submitting your order.
		</h4>
	</ng-container>
	<payment-form [packages]="packages" [organizationId]="ownerAccountId" [hideGuarantee]="true"
		(paymentBack)="router.navigateByUrl('/email')" (paymentSubmit)="this.state = 'processing'"
		(paymentSuccess)="paymentSucceeded()" (paymentFailure)="this.state = 'ready'"
		(paymentReady)="this.state = 'ready'"></payment-form>
</div>
