<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success'">
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<h2>Professional Services</h2>
		<p class="ctw-mb-6">
			Platform i's Managed Hosting team excels in personalized service, offering comprehensive assistance
			for website migrations, optimization, and server configurations. Our <strong>Launch Assist
				service</strong> simplifies the migration of complex WordPress sites, managing large files,
			databases, backups, and custom setups for CDN, DNS, or SSL.<br><br>Additionally, our
			<strong>PageSpeed Pros
				experts</strong> specialize in diagnosing WordPress performance issues. We'll help you determine
			what's keeping your Core Web Vitals scores down, from inefficient themes and plugins, to
			configuration issues with the WordPress application and server. This dedicated approach ensures
			optimized performance and a superior user experience, leveraging our specialized skills to meet your
			digital needs efficiently — act now to leverage our Managed Hosting team.
		</p>
		<central-managed-hosting></central-managed-hosting>
	</div>
</ng-container>
