import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@central/ng-shared';
import { Animations } from '../../shared/animation/animations';
import { BrandingService } from '@central/ng-shared';
import { ApiService } from '@central/ng-shared';
import { LocalStorageService } from '@central/ng-shared';

/**
 * This class represents the lazy loaded AboutComponent.
 */
@Component({
	selector: 'sd-login',
	templateUrl: 'login.component.html',
	styleUrls: ['login.component.scss'],
	animations: Animations.unauth,
})
export class LoginComponent implements OnInit {
	@HostBinding('@routeAnimation') public animation = 'true';
	public state = 'pending';

	constructor(
		public route: ActivatedRoute,
		public router: Router,
		public auth: AuthService,
		public localStorageService: LocalStorageService,
		public apiService: ApiService,
		public brandingService: BrandingService
	) {}

	/**
	 * If the user lands on this page while they are logged in and they specify a return URL. Trigger the return URL.
	 * Otherwise send them to standard login. The route for this scenario is /sso.
	 *
	 * @since 1.4.1
	 */
	public ngOnInit() {
		if (this.route.snapshot.queryParams['auth_code']) {
			if (this.auth.isLoggedIn()) {
				this.auth.silentLogout();
			}

			this.validateAuthCode(this.route.snapshot.queryParams['auth_code']);
		} else if (this.auth.isLoggedIn()) {
			const returnRoute = this.route.snapshot.queryParams['return'];
			if (returnRoute) {
				this.auth.reloadToken();
			} else {
				this.router.navigate(['login']);
			}
		}
	}

	/**
	 * Validate an authentication code.
	 *
	 */
	private validateAuthCode(authCode: string): void {
		this.state = 'authenticating';
		this.apiService
			.post('/v1/oauth2/auth-code', { code: authCode })
			.subscribe(
				(response) => {
					this.auth.setLogin(response['token']);
					this.localStorageService.setItem(
						'session_data',
						response['session_data']
					);
					this.router.navigate(['/']);
				},
				() => {
					this.state = 'failed';
				}
			);
	}
}
