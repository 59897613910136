<div class="ctw-mb-8 ctw-relative">
	<central-domain-selector label="Use a Team Domain / Enter Manually" [disabled]="this.disabled" #domainSelector
		(domainSelectorReady)="this.isReady()" (domainInputChange)="updateIsDisabled($event)" [allowManual]="true"
		[context]="this.context" [hostname]="this.hostname" [invalid]="this.invalid"
		[validating]="!this.disabled ? this.validating : 'submitted'" [current]="this.current"
		class="ctw-w-full ctw-mt-2 ctw-block"></central-domain-selector>
	<div class="ctw-absolute ctw-top-0 ctw-right-0 ctw-mr-3 ctw-mt-3">
		<central-progress-button *ngIf="!this.disabled && !getIsDisabled(domainSelector.selectedDomainName)"
			color="primary" [state]="this.validating" [disabled]="getIsDisabled(domainSelector.selectedDomainName)"
			(click)="validateSelected(domainSelector.selectedDomainName)">
			<span *ngIf="this.validating === 'pending'">Continue</span>
			<span *ngIf="this.validating === 'submitted'">Preparing...</span>
		</central-progress-button>
		<central-progress-button *ngIf="this.disabled" color="primary" [state]="this.validating"
			[disabled]="this.disabled">
			<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
		</central-progress-button>
	</div>
</div>
