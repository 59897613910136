import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApiService } from '../../../core';
import { ConfigurationService } from '../../../core/configuration.service';

@Component({
	selector: 'central-permissionsdialog',
	templateUrl: './permissionsdialog.component.html',
	styleUrls: ['./permissionsdialog.component.scss'],
})
export class PermissionsdialogComponent {
	public state = 'pending';
	public role;
	public error: string;

	public permissionDescriptions = {
		clouds: {
			owner: 'Has full administrative access to this cloud.',
			maintainer:
				'Can update project and invite members. Cannot update billing.',
			member: 'Can view project details',
		},
		organizations: {
			owner: 'Has full administrative access to the entire organization.',
			maintainer:
				'Can create new cloud projects and edit all organization projects. Cannot update billing.',
			member: 'Can view all project information. Cannot make changes.',
		},
	};

	constructor(
		public dialogRef: MatDialogRef<PermissionsdialogComponent>,
		public apiService: ApiService,
		public configurationService: ConfigurationService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.role = data.membership.role;
	}

	getRoleLabel(role: string) {
		if (this.data.resourceType === 'organizations') {
			return (
				this.configurationService.config.groupPermissions[role]
					?.label || ''
			);
		} else {
			return role;
		}
	}

	getRoleDescription(role: string) {
		if (this.data.resourceType === 'organizations') {
			return (
				this.configurationService.config.groupPermissions[role]
					?.description || ''
			);
		} else {
			return this.permissionDescriptions[this.data.resourceType][role];
		}
	}

	update() {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const endpoint =
			`/v1/${this.data.resourceType}/` +
			this.data.resourceId +
			'/memberships/' +
			this.data.membership.membership_id;
		const url = this.apiService.formatter.getUrl(endpoint);

		this.error = '';
		this.state = 'submitted';

		this.apiService.http
			.patch(
				url,
				{
					role: this.role,
				},
				{ headers }
			)
			.subscribe({
				error: (error) => {
					this.error = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
				complete: () => {
					this.state = 'success';
					this.dialogRef.close();
				},
			});
	}
}
