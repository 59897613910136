<dash-card
	#dashcard
	[expanded]="false"
	cardTitle="Promo Code"
	icon="local_offer"
>
	<div class="content">
		<ng-container *ngIf="this.status !== 'submitted'">
			<ng-container *ngIf="this.requiresSignin">
				<div class="added-discount">
					<span class="animate__tada animate__animated"></span>
					<p class="details">
						We found a discount for you! Please sign in to unlock
						your savings.
					</p>
				</div>
			</ng-container>
			<ng-container
				*ngIf="!this.discounts.length && !this.requiresSignin"
			>
				<form (ngSubmit)="this.code && this.fetch(this.code)">
					<p>Have a special offer to apply?</p>
					<mat-form-field appearance="outline">
						<mat-label>Promo Code</mat-label>
						<input
							matInput
							placeholder="Promo Code (optional)"
							name="code"
							[(ngModel)]="this.code"
							required
						/>
						<button
							class="discount-input"
							color="primary"
							mat-button
							mat-raised-button
							matSuffix
							aria-label="Apply"
						>
							Apply
						</button>
						<mat-hint
							class="error"
							*ngIf="this.status === 'failed'"
						>
							The code you entered is invalid.
						</mat-hint>
					</mat-form-field>
				</form>
			</ng-container>
			<div
				class="added-discount"
				*ngIf="this.discounts.length && !this.requiresSignin"
			>
				<span class="animate__tada animate__animated"></span>
				<div class="details">
					<div class="accent-color subtitle">
						Added Promotional Code
					</div>
					<div
						*ngFor="let discount of this.discounts"
						class="discount"
					>
						{{ discount?.label }}
						<a
							(click)="this.removeDiscount(discount)"
							*ngIf="
								supportManualEntry &&
								discount?.category !== 'prorate'
							"
							><mat-icon
								aria-label="Remove"
								matTooltip="Remove Code"
								>close</mat-icon
							></a
						>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="added-discount" *ngIf="this.status === 'submitted'">
			<mat-spinner color="accent" diameter="35"></mat-spinner>
		</div>
	</div>
</dash-card>
