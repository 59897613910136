<ng-container *ngIf="'member' === organizationRole">
	<p>You are not authorized to configure this subscription.</p>
</ng-container>
<ng-container *ngIf="'member' !== organizationRole">
	<p>Select a domain to use for this subscription:</p>
	<p>
		<span class="username-label">example @ </span>
		<central-domain-selector #centralDomainSelector [context]="accountId"
			[usedDomains]="this.usedDomains"></central-domain-selector>
		<central-progress-button mat-stroked-button color="primary"
			(click)="provisionCpanel(centralDomainSelector.selectedDomainName)"
			[disabled]="undefined === centralDomainSelector.selectedDomainName" [state]="progressButtonState"
			class="ctw-ml-3">{{ progressButtonTitle }}</central-progress-button>
	</p>
	<p *ngIf="provisionError" class="error">
		Error saving configuration, try again
	</p>
	<p>
		Don't see the domain you're looking for?
		<a [routerLink]="['/domains/manage']">Add it here</a>
	</p>
</ng-container>
