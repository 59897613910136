import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../../project.service';
import { Observable, concat } from 'rxjs';
import { AnsibleService } from '../services/ansible.service';
import { ProfileService } from '@central/ng-shared';

@Component({
	selector: 'bgc-w3',
	templateUrl: './w3.component.html',
	styleUrls: ['./w3.component.scss'],
})
export class W3Component implements OnInit, OnDestroy {

	public state = 'loading';
	public available = true;

	public plugins = [];
	public hasPlugin = false;
	public pluginName = 'w3-total-cache.php';
	public enableState = 'pending';
	public deleteState = 'pending';
	public pageCachingEnabled = false;

	private subscriptions = [];

	constructor(
		public projectService: ProjectService,
		private ansibleService: AnsibleService,
		private profileService: ProfileService,
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((info: any) => {
						// TODO: Disallow functionality if container is stopped.
						// ...
						this.subscriptions.push(
							this.ansibleService.playbookStatus.subscribe((data) => {
								if (data === 'pending' || data === 'complete' || data === 'playbook-overridden') {
									this.available = true;
								} else {
									// A playbook is running
									if (this.available === true) {
										this.state = 'loading';
									}
									this.available = false;

								}
								this.subscriptions.push(
									this.projectService.onClientConnected.subscribe((client: any) => {
										if (this.state === 'loading' && this.projectService.client) {
											this.fetchStatus();
										}
									})
								)
							})
						)
					})
				)
			})
		)

		if (this.projectService.environment.fields.cloud_id) {
			this.projectService.onClientConnected.subscribe((msg) => {
				if (this.projectService.client) {
					this.fetchStatus();
				}
			})
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	/**
	 * Get the status of the page caching setting.
	 */
	public fetchStatus(): void {
		concat(this.fetchPluginStatus(), this.getSetting()).subscribe(
			() => (this.state = 'success'),
			() => (this.state = 'failed')
		);
	}

	/**
	 * Enable the page caching setting.
	 */
	public enablePageCaching(): void {
		this.enableState = 'submitted';

		concat(this.activatePlugin()).subscribe(
			() => {},
			() => (this.enableState = 'failed'),
			() => (this.enableState = 'success')
		);
	}

	/**
	 * Delete the plugin.
	 */
	public disableFeature() {
		this.deleteState = 'submitted';

		let pluginFile = '';
		this.plugins.map((val) => {
			if (val['File'].endsWith(this.pluginName) && val['IsActive']) {
				pluginFile = val['File'];
			}
		});

		this.projectService.client
			.namespace('bgc/v1')
			.plugins()
			.update({ active: false, files: [pluginFile] })
			.then(() => this.projectService.client
					.namespace('bgc/v1')
					.plugins()
					.delete({ files: [pluginFile] }))
			.then(() => {
				this.pageCachingEnabled = false;
				this.hasPlugin = false;
				this.deleteState = 'success';
			})
			.catch((error) => {
				if ('restx_logged_out' === error.code) {
					// Re-authenticating.
					this.projectService.wpRestService
						.reloadClient(this.projectService.environment)
						.subscribe(
							(client) => {
								this.projectService.client = client;
								setTimeout(() => this.disableFeature(), 2000);
							},
							() => {
								this.projectService.onClientConnected.next('failed');
								this.deleteState = 'pending';
								this.state = 'failed';
							}
						).unsubscribe();
				} else {
					this.projectService.onClientConnected.next('failed');
					this.deleteState = 'pending';
					this.state = 'failed';
				}
			});
	}

	/**
	 * Active the plugin.
	 */
	private activatePlugin() {
		return new Observable((observer) => {
			if (this.hasPlugin) {
				observer.next();
				observer.complete();
				return;
			}
			console.log('this.hasPlugin');
			this.projectService.client
				.namespace('bgc/v1')
				.plugins()
				.create({
					activate: true,
					plugins: [this.pluginName.replace('.php', '')],
				})
				.then((plugins) => {
					this.plugins = plugins;
					this.hasPlugin = this.checkPlugin(plugins);
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									setTimeout(() => this.activatePlugin(), 2000);
									observer.complete();
								},
								() => {
									this.projectService.onClientConnected.next(
										'failed'
									);
									observer.error();
									observer.complete();
								}
							);
					} else {
						this.projectService.onClientConnected.next('failed');
						observer.error();
						observer.complete();
					}
				});
		});
	}

	/**
	 * Get the status of all plugins on this site.
	 */
	private fetchPluginStatus() {
		return new Observable((observer) => {
			this.projectService.client
				.namespace('bgc/v1')
				.plugins()
				.get()
				.then((plugins) => {
					this.plugins = plugins;
					this.hasPlugin = this.checkPlugin(plugins);
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									setTimeout(() => this.fetchPluginStatus(), 2000);
									observer.complete();
								},
								() => {
									this.projectService.onClientConnected.next(
										'failed'
									);
									observer.error();
									observer.complete();
								}
							);
					} else {
						this.projectService.onClientConnected.next('failed');
						observer.error();
						observer.complete();
					}
				});
		});
	}

	/**
	 * Get the status of all plugins on this site.
	 */
	private getSetting() {
		return new Observable((observer) => {
			if (!this.hasPlugin) {
				observer.next();
				observer.complete();
				return;
			}

			this.projectService.client
				.namespace('bgc/v1')
				.w3cache()
				.get()
				.then((settings) => {
					this.pageCachingEnabled = !!settings['pgcache.enabled'];

					observer.next();
					observer.complete();
				})
				.catch((error) => {
					if ('restx_logged_out' === error.code) {
						// Re-authenticating.
						this.projectService.wpRestService
							.reloadClient(this.projectService.environment)
							.subscribe(
								(client) => {
									this.projectService.client = client;
									setTimeout(() => this.getSetting(), 2000);
									observer.complete();
								},
								() => {
									this.projectService.onClientConnected.next(
										'failed'
									);
									observer.error();
									observer.complete();
								}
							);
					} else {
						this.projectService.onClientConnected.next('failed');
						observer.error();
						observer.complete();
					}
				});
		});
	}

	/**
	 * Check if the current plugin is active.
	 *
	 * @param plugins Is this plugin active.
	 */
	private checkPlugin(plugins: any[]): boolean {
		let hasPlugin = false;
		plugins.map((val) => {
			if (val['File'].endsWith(this.pluginName) && val['IsActive']) {
				hasPlugin = true;
			}
		});

		return hasPlugin;
	}
}
