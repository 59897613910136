import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'progress-message',
	templateUrl: 'progress-message.component.html',
	styleUrls: ['progress-message.component.scss'],
})
export class ProgressMessageComponent implements OnInit {
	@Input() public messageType = 'any'
    @Input() public hideMessages = false;
    @Input() public progressMessages = [
        //{ msg: 'Checking cart info', time: 1000, type: 'any'},
		//{ msg: 'Deploying the Gnomes', time: 2000, type: 'other'},
		//{ msg: 'Loading cart details', time: 4000, type: 'any'},
		//{ msg: 'We did say Gnomes. You read that right.', time: 3000, type: 'any'},
		//{ msg: 'Finishing up...', time: 0, type: 'any'},
    ];
    public progressMessage = {};
    public progressAmount = 0;
    

	public constructor(private cdr :ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.displayProgressMessage();
    }

    public displayProgressMessage(index = 0) {
		const vm = this;
		const totalTime = this.progressMessages.reduce((accumulator, currentValue) => {
			if(['any',vm.messageType].includes(currentValue.type)){
				return accumulator + currentValue.time;
			} else {
				return accumulator;
			}
		},0,)
		if(index < vm.progressMessages.length) {
			vm.progressMessage = {};
			let initialTimeout = 500;
			if(index === 0 || !['any',vm.messageType].includes(vm.progressMessages[index]['type'])) {
				initialTimeout = 0;
			}
			setTimeout(function(){
				if(['any',vm.messageType].includes(vm.progressMessages[index]['type'])) {
					vm.progressMessage = vm.progressMessages[index];
					if(index === 0) {
						vm.progressAmount = 0;
					} else {
						vm.progressAmount += (vm.progressMessage['time'] / totalTime) * 100;
					}
					setTimeout(function(){
						vm.displayProgressMessage(index + 1);
					}, vm.progressMessage['time']);
				} else {
					vm.displayProgressMessage(index + 1);
				}
			}, initialTimeout);
		} else {
			vm.progressAmount = 100;
		}
	}
}