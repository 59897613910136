import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../config/env.config';
import { BrandingService } from '@central/ng-shared';

@Component({
	selector: 'layout-base',
	templateUrl: 'layout-base.component.html',
	styleUrls: ['layout-base.component.scss'],
})
export class LayoutBaseComponent implements OnInit {
	@HostBinding('class.sticky-footer') public stickyFooter = true;

	@Input() public pageTitle = '';
	@Input() public isLoading = false;
	@Input() public titleStyle = 'standard';
	@Input() public failedLoading = false;
	@Input() public beta = false;
	@Input() public container = 'container';

	private pageTitles = Config.pageTitles;

	constructor(
		public brandingService: BrandingService,
		private router: Router
	) {
		this.pageTitle = '';
	}

	/**
	 * Generate the pages title when navigating.
	 *
	 * @since 1.1
	 */
	public ngOnInit(): void {
		if (!this.pageTitle) {
			this.generatePageTitle();
		}
	}

	/**
	 * Check the configuration for matching pattern and name.
	 *
	 * @since 1.1
	 *
	 * @return  Name for page title.
	 */
	public getTitleOverride(): string {
		let pageTitle = '';

		for (const index in this.pageTitles) {
			if (this.router.url && this.router.url.match(this.pageTitles[index].pattern)) {
				pageTitle = this.pageTitles[index].name;
				break;
			}
		}

		return pageTitle;
	}

	/**
	 * Create a page title based on configs.
	 *
	 * @since 1.1
	 */
	private generatePageTitle(): void {
		const title = this.getTitleOverride();
		this.pageTitle = title ? title : '' ;
	}
}
