import { EventEmitter, Injectable, Output } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ApiService } from '@central/ng-shared';

@Injectable()
export class DomainValidator {
    public static _self;

    @Output() domain = new EventEmitter();

	constructor(public apiService: ApiService) {
		DomainValidator._self = this;
	}

	isValid(control: AbstractControl): ValidationErrors | null {
		const domain = control.value.trim().toLowerCase();
		let error: ValidationErrors | null = null;

        if (control.pristine) {
			return null;
		}

		const regex =
			/^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/g;
		const matched = domain && domain.match(regex);

		if (!matched) {
            error = { invalidDomain: control.value };
            DomainValidator._self.domain.emit(error || domain);

			return error;
		} else {
			control.markAsDirty();
			DomainValidator._self.apiService
				.get(`/v1/domain-service/domain/is-valid/${domain}`, {})
				.subscribe((response: any) => {
                    error = response ? null : { invalidDomain: control.value };
                    DomainValidator._self.domain.emit(error || domain);
					return control.setErrors(error);
				});
		}
	}
}
