<mat-progress-bar
	class="loader"
	mode="indeterminate"
	*ngIf="status === 'start'"
></mat-progress-bar>
<central-failed-request *ngIf="'failed' === status"></central-failed-request>
<div
	id="organization-container"
	class="ctw-max-w-screen-lg ctw-mx-auto ctw-mt-10"
	*ngIf="status === 'success'"
>
	<ng-container *ngIf="this.organizations.length">
		<h2 class="ctw-display-flex">
			{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}s
		</h2>
		<div class="ctw-text-right">
			<button
				mat-raised-button
				class="new-button"
				color="primary"
				[routerLink]="[
					'/account/',
					this.authService.getAccountId(),
					'organization',
					'create'
				]"
			>
				<mat-icon>add</mat-icon> New
				{{
					this.configurationService.config.groupLabels[
						'group-label-title-case'
					]
				}}
			</button>
		</div>
	</ng-container>
	<ng-container *ngIf="this.organizations.length === 0">
		<div class="no-organizations ctw-max-w-lg ctw-mx-auto ctw-text-center">
			<img
				src="https://inmotionhosting.github.io/static-assets/illustrations/small/enterprise-large-business.svg"
			/>
			<h3>
				No
				{{
					this.configurationService.config.groupLabels[
						'group-label-title-case'
					]
				}}s Found
			</h3>
			<p>
				{{
					this.configurationService.config.groupLabels[
						'group-label-title-case'
					]
				}}s allow you to invite your team and share access to
				{{
					this.configurationService.config.groupLabels[
						'group-resource-label-lower-case'
					]
				}}s. Create
				{{
					this.configurationService.config.groupLabels[
						'group-label-lower-case-with-article'
					]
				}}
				or have an administrator of an existing
				{{
					this.configurationService.config.groupLabels[
						'group-label-lower-case'
					]
				}}
				send an invite to this account.
			</p>
			<button
				mat-raised-button
				color="primary"
				[routerLink]="[
					'/account/',
					this.authService.getAccountId(),
					'organization',
					'create'
				]"
			>
				Create
				{{
					this.configurationService.config.groupLabels[
						'group-label-title-case-with-article'
					]
				}}
			</button>
		</div>
	</ng-container>

	<table
		class="ctw-mt-8"
		mat-table
		[dataSource]="organizations"
		*ngIf="this.organizations.length > 0"
		id="organization-index"
	>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Name</th>
			<td mat-cell *matCellDef="let organization" class="with-subtitle">
				<p>
					<a
						[routerLink]="[
							'/account',
							organization.organization_id,
							'settings'
						]"
						>{{ organization.name }}</a
					>
					<span *ngIf="this.default_org_id === organization.organization_id && this.hasDefaultOrgFeature" class="ctw-inline-flex ctw-text-lg">
						<mat-icon class="ctw-mx-2 ctw-inline" inline matTooltip="This is your default organization">check_circle_outline</mat-icon>
					</span>
				</p>
				<p>
					{{
						organization.members.length +
							(organization.members.length === 1
								? ' member'
								: ' members')
					}}<ng-container *ngIf="organization.cloud_count"
						>,
						{{
							organization.cloud_count +
								(organization.cloud_count === 1
									? ' cloud'
									: ' clouds')
						}}</ng-container
					>
				</p>
			</td>
		</ng-container>

		<ng-container matColumnDef="billing">
			<th mat-header-cell *matHeaderCellDef>Billing</th>
			<td mat-cell *matCellDef="let organization">
				<p *ngIf="'owner' !== organization.role">
					{{ organization.has_billing ? 'Active' : 'Inactive' }}
				</p>
				<p *ngIf="'owner' === organization.role">
					<a
						[routerLink]="[
							'/account',
							organization.organization_id,
							'billing'
						]"
						>{{
							organization.has_billing ? 'Active' : 'Inactive'
						}}</a
					>
				</p>
			</td>
		</ng-container>

		<ng-container matColumnDef="your-role">
			<th mat-header-cell *matHeaderCellDef>Your Role</th>
			<td mat-cell *matCellDef="let organization">
				{{ this.getRoleLabel(organization.role) | titlecase }}
			</td>
		</ng-container>

		<ng-container matColumnDef="action">
			<th mat-header-cell class="align-right" *matHeaderCellDef>
				Actions
			</th>
			<td mat-cell class="align-right" *matCellDef="let organization">
				<ng-container *ngIf="true">
					<button
						mat-raised-button
						[matMenuTriggerFor]="organizationOptions"
					>
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #organizationOptions="matMenu" xPosition="before">
						<button
							mat-menu-item
							[routerLink]="[
								'/account',
								organization.organization_id,
								'settings'
							]"
						>
							<mat-icon>info</mat-icon>Profile
						</button>
						<button
							mat-menu-item
							[routerLink]="[
								'/account',
								organization.organization_id,
								'members'
							]"
						>
							<mat-icon>people</mat-icon>Members
						</button>
						<mat-divider></mat-divider>
						<button mat-menu-item *ngIf="this.hasDefaultOrgFeature && this.default_org_id !== organization.organization_id" class="ctw-p-0">
							<central-default-org-action
								[organization]="organization"
								(defaultOrgId)="this.default_org_id = $event"
								></central-default-org-action>
						</button>
						<button
							mat-menu-item
							(click)="this.openLeaveDialog(organization)"
						>
							<mat-icon>door_front</mat-icon>Leave
							{{
								this.configurationService.config.groupLabels[
									'group-label-title-case'
								]
							}}...
						</button>
						<!-- <button mat-menu-item (click)="this.openDeleteDialog( organization )" *ngIf="organization.role === 'owner'"><mat-icon>delete_forever</mat-icon>Delete Organization...</button> -->
					</mat-menu>
				</ng-container>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="['name', 'billing', 'your-role', 'action']"
		></tr>
		<tr
			mat-row
			*matRowDef="
				let row;
				columns: ['name', 'billing', 'your-role', 'action']
			"
		></tr>
	</table>
</div>
