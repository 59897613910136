<div *ngIf="this.overrideEligible">
	<p *ngIf="overrideState === 'Disabled'">
		If you use Semaphore to apply a customer setting, disable Semaphore
		Access below to prevent Central from reverting a custom modification.
	</p>
	<p *ngIf="overrideState === 'Enabled'">
		Central's access to Semaphore was previously disabled to apply a custom
		setting. If this is no longer required,re-enable Semaphore Access to
		allow Central to resume control of the server.
	</p>
	<form #f="ngForm" (ngSubmit)="submitForm(f)" class="ctw-mt-8">
		<div class="ctw-mx-auto ctw-text-right ctw-relative">
			<mat-form-field floatLabel appearance="standard">
				<input matInput type="text" [(ngModel)]="comment" name="comment" [formControl]="formControl"
					placeholder="Please enter Zendesk Ticket or Reason." />
				<mat-error *ngIf="formControl.hasError('required')">A Comment is required</mat-error>
			</mat-form-field>
			<div class="ctw-absolute ctw-top-0 ctw-right-0 ctw-mt-2">
				<central-progress-button [state]="this.state" type="submit" color="{{
					this.overrideState === 'Disabled' ? 'warn' : 'accent'
				}}" mat-raised-button class="" (onSubmit)="checkDomainStatus($event)">
					{{ overrideState === 'Disabled' ? 'Disable' : 'Re-Enable' }}
				</central-progress-button>
			</div>
		</div>
	</form>
	<h4 class="ctw-mb-0" *ngIf="0">Management History</h4>
	<table id="agent-overrides" *ngIf="this.overrides.length > 0 && 0">
		<tr>
			<th>State</th>
			<th>Agent</th>
			<th>Time</th>
			<th>Comment</th>
		</tr>
		<tr *ngFor="let override of this.overrides">
			<td>{{ override.State === 'Enabled' ? 'Disabled' : 'Enabled' }}</td>
			<td>{{ override.UserAdmin }}</td>
			<td>{{ override.Timestamp }}</td>
			<td>{{ override.Comment }}</td>
		</tr>
	</table>
</div>