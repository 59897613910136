import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../../../project.service';
import { ApiService, ProfileService } from '@central/ng-shared';
import { NgForm } from '@angular/forms';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AppService } from '../../../../app.service';
import { DialogComponent } from '../../../../shared/dialog/dialog.component';
import { AnsibleService } from '../../services/ansible.service';

interface PhpVersion {
	version: string;
	recommended: boolean;
	eol: boolean;
	allowed: boolean;
	securityFixesOnly: boolean;
}

@Component({
	selector: 'central-php-version',
	templateUrl: './php-version.component.html',
	styleUrls: ['./php-version.component.scss'],
})
export class PhpVersionComponent implements OnInit, OnDestroy {

	public state = 'loading';
	public available = true;

	versions: PhpVersion[] = [
		{
			version: '8.3',
			recommended: true,
			eol: false,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '8.2',
			recommended: false,
			eol: false,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '8.1',
			recommended: false,
			eol: false,
			allowed: true,
			securityFixesOnly: true,
		},
		{
			version: '8.0',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		},
		{
			version: '7.4',
			recommended: false,
			eol: true,
			allowed: true,
			securityFixesOnly: false,
		}
	];

	setVersion: string =
		this.projectService.environment.fields.playbook_values?.php_version ||
		'7.4';
	selectedVersion: string =
		this.projectService.environment.fields.playbook_values?.php_version ||
		'7.4';
	isRunningPlaybook: boolean | null = null;
	hasSubmittedForm = false;
	isOverriden = false;
	setVersionIsEOL = false;
	setVersionIsRecommended = false;

	error: string;
	interval;
	profile: string | null = null;
	nonPublishingStates = ['success', 'fail'];
	ready = false;

	public requestState: string;

	private subscriptions = [];

	constructor(
		public projectService: ProjectService,
		private appService: AppService,
		private apiService: ApiService,
		private profileService: ProfileService,
		private ansibleService: AnsibleService,
		private dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((msg) => {
						// TODO: Disallow if container is stopped.
						if (this.projectService.environmentState !== 'pending') {
							this.subscriptions.push(
								this.ansibleService.playbookStatus.subscribe((data) => {
									if (data === 'complete') {
										// Loaded info needed for view.
										this.projectService.reloadProject(false).subscribe(() => {
											this.setVersion =
												this.projectService.environment.fields.playbook_values
													?.php_version || '7.4';
											this.setVersionIsEOL = this.versions.find(version => version.version === this.setVersion && version.eol)?.eol;
											this.setVersionIsRecommended = this.versions.find(version => version.version === this.setVersion && version.recommended)?.recommended;
											this.state = 'success';
											this.available = true;
										});
									} else {
										if (data === 'pending') {
											this.setVersion =
												this.projectService.environment.fields.playbook_values
													?.php_version || '7.4';
											this.setVersionIsEOL = this.versions.find(version => version.version === this.setVersion && version.eol)?.eol;
											this.setVersionIsRecommended = this.versions.find(version => version.version === this.setVersion && version.recommended)?.recommended;
											this.state = 'success';
											this.available = true;
										} else {
											// Playbook is running or playbooks are disabled.
											this.state = data === 'playbook-overridden' ? 'disabled' : 'unavailable';
											this.available = false;
										}
									}
								})
							)
						}
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	updateProfile(submission: NgForm) {
		this.requestState = 'submitted';
		this.hasSubmittedForm = true;
		this.isRunningPlaybook = true;
		this.profile = submission.form.value.version;
		// If saved value is the same as what's being submitted.
		const storedProfile =
			this.projectService.environment.fields.playbook_values
				?.php_version || '7.4';

		/*
		if (storedProfile === submission.form.value.version) {
			this.requestState = 'success';
			return;
		}
		*/

		this.ansibleService.runPlaybook({
			php_version: submission.form.value.version,
			tags: ['phpversion']
		}).subscribe({
			next: () => {
				this.requestState = 'success';
				this.appService.showConfirmation('Your PHP configuration is being rolled out to your server!')
			},
			error: () => {
				this.requestState = 'failed';
				this.appService.showConfirmation('An error occured applying your PHP configuration. Please try again.')
			}
		});
	}

	openDialog(submission: NgForm) {
		const dialogRef = this.appService.openDialog(DialogComponent, {
			title: 'Are You Sure?',
			content:
				'Changing PHP versions will cause your site to be unavailable for several minutes while your server is being updated!',
			warning: true,
		});

		dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.updateProfile(submission);
			}
		});
	}
}
