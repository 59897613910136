import { Component, Output, EventEmitter } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'central-site-setup-blueprint-dialog',
	templateUrl: './site-setup-blueprint-dialog.component.html',
	styleUrls: ['./site-setup-blueprint-dialog.component.scss'],
})
export class SiteSetupBlueprintDialogComponent {

	@Output() installed = new EventEmitter<string>();

	public blueprint;
	public project;
	public environment;
	public state = '';
	public error = '';
	public attempts = 0;

	constructor(
		private apiService: ApiService,
		public projectService: ProjectService,
		private dialogRef: MatDialogRef<SiteSetupBlueprintDialogComponent>
	) {}

	public getConfigValue(key) {
		return JSON.parse(this.blueprint.configuration)[key];
	}

	public setUsage(usage: string) {
		this.projectService.reloadProject().subscribe(() => {
			this.projectService
				.updateEnvironmentField({ environment_usage: usage + ' Blueprint'})
				.subscribe();
		})
	}

	public installBlueprint() {
		this.state = 'submitted';
		this.attempts++;
		this.apiService.timeout = this.apiService.defaultTimeout * 8;
		this.apiService
			.post('/v1/environments/' + this.environment.id + '/install-blueprint', {
				blueprint_id: this.blueprint.id
			}, 600000)
			.subscribe(
				(response) => {
					this.state = 'installing';
					const failedInstalls = [];
					(response as Array<any>).forEach((result) => {
						if (result['status'] !== 'success') {
							failedInstalls.push(result);
						}
					});
					if (failedInstalls.length === 0) {
						this.state = 'success';
						this.attempts = 0;
						this.installed.emit('true');
						this.dialogRef.close(true);
					} else {
						if (this.attempts === 3) {
							this.state = 'failed';
						} else {
							this.installBlueprint();
						}
					}
					this.apiService.timeout = this.apiService.defaultTimeout;
				},
				(error: any[]) => {
					if (this.attempts === 3) {
						this.error = "There was a problem installing the selected blueprint. Please try again later.";
						this.state = 'failed';
						this.apiService.timeout = this.apiService.defaultTimeout;
						this.projectService.rollbarService.rollbar.error(
							'There was a problem installing Blueprint ID ' + this.blueprint.id
						)
					} else {
						this.installBlueprint();
					}
				}
			);
	}
}
