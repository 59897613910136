import { Component, OnInit, OnDestroy } from '@angular/core';

import { ApiService, ConfigurationService, ProfileService } from '@central/ng-shared';

import { ProjectService } from '../../project.service';
import { AnsibleService } from '../services/ansible.service';

@Component({
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {

	state = 'loading';
	status = 'loading';

	env: string;
	veid: string;
	containerStatus = '';
	containerSpec = '';
	sshUser = 'root';
	sshUserStatus: string;

	private subscriptions = [];

	constructor(
		public configService: ConfigurationService,
		public projectService: ProjectService,
		public ansibleService: AnsibleService,
		private apiService: ApiService,
		private profileService: ProfileService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.setEnvironment();
				this.getUser();
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	private setEnvironment() {
		if (this.projectService.environment.fields.environment_type === 'vps' ||
			this.projectService.environment.fields.hostname) {

			this.env = 'vps'
			this.veid =
				this.projectService.environment.fields?.machine_details?.veid;
			this.subscriptions.push(
				this.projectService.getServerInfo.subscribe(
					(containerInfo) => {
						if (containerInfo) {
							// If container doesn't return 'running' as status it should be stopped.
							if ('running' !== containerInfo['status']) {
								this.containerStatus = 'stopped';

								if (containerInfo['response'] &&
									containerInfo['response'].includes('does not exist')) {
									this.containerStatus = 'reclaimed';
								}
							} else {
								this.projectService.environmentState = 'accessible';
								this.containerStatus = 'running';
							}

							if (this.containerStatus !== 'stopped') {
								this.containerSpec = this.translatePackageName(containerInfo['Package']);
							}
						}
					})
			)

			this.status = 'success';
		} else {
			this.env = 'playground';
			this.status = 'success';
		}
	}

	public translatePackageName(vzpackage) {
		if (vzpackage) {
			const vcpu = vzpackage.match(/([0-9]{1,2}(cpu|core))/gm)[0];
			const ram = vzpackage.match(/([0-9]{1,2}ram)/gm)[0];
			const storage = vzpackage.match(/([0-9]{2,4}disk)/gm)[0];

			return `${parseInt(ram, 10)} GB RAM &middot; ${parseInt(vcpu, 10)} vCPU${parseInt(vcpu, 10) !== 1 ? 's' : ''} &middot; ${parseInt(storage, 10)} GB SSD Storage`;
		} else {
			return 'Unknown';
		}
	}

	public getHostname() {
		let hostname = 'demo3.playgrounds.cloud';

		if (this.env === 'vps') {
			hostname = this.projectService.environment.fields.hostname
		} else if (this.configService.config.environment !== 'production') {
			hostname = 'demo-dev-new.boldgrid.com';
		}

		return hostname;
	}

	public getIp() {
		let ip = '173.231.217.236';

		if (this.env === 'vps') {
			ip = this.projectService.environment.fields.ipAddress;
		} else if (this.configService.config.environment !== 'production') {
			ip = '173.231.254.88';
		}

		return ip;
	}

	public getUser() {
		if (this.env === 'playground' && !this.sshUserStatus) {
			this.sshUserStatus = 'getting-user';
			this.apiService
				.post('/v1/cloud-wordpress/get-ssh-user', { trial_id: this.projectService.environment?.fields?.cloud_id })
				.subscribe(
					(response:any) => {
						this.sshUser = response.message;
						this.sshUserStatus = 'success';
					},
					(error) => {
						this.sshUserStatus = 'failed';
					}
				);
		}

		return this.sshUser;
	}
}
