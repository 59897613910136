<div data-private class="ctw-mb-4">
    <img class="payment-icons ctw-mb-3"src="assets/svg/credit_card_payment_types.svg" />
    <div class="ctw-grid ctw-grid-cols-2 sm:ctw-grid-cols-4 ctw-gap-2">
        <div class="ctw-col-span-2">
            <mat-form-field>
                <input 
                    matInput
                    type="tel"
                    #ccnum="ngModel" 
                    [(ngModel)]="this.info.card_number"
                    name="ccnum"
                    (ngModelChange)="ccChange($event); validateCardNumber()"
                    autocomplete="cc-number"
                    class="validated"
                    placeholder="Credit Card Number"
                    required
                />
                <mat-hint class="animate__animated error" align="start"
                    [class.animate__fadeIn]="invalidCcInput(ccnum)">
                    <span *ngIf="!info.card_number">Credit Card Number is required.</span>
                    <span *ngIf="info.card_number && !validCc">Credit Card Number is not valid.</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="ctw-col-span-1">
            <mat-form-field>
                <mat-label>Expiration</mat-label>
                <input
                    matInput
                    type="text"
                    #expiration="ngModel" 
                    placeholder="MM/YY"
                    name="expiration"
                    [(ngModel)]="this.info.expiration"
                    (ngModelChange)="expChange($event); validateExpirationDate()"
                    autocomplete="cc-exp"
                    required
                />
                <mat-hint class="animate__animated error" align="start"
                    [class.animate__fadeIn]="invalidExpInput(expiration)">
                    <span *ngIf="!info.expiration">Expiration Date is required.</span>
                    <span *ngIf="info.expiration && !validExp">Expiration Date is not valid.</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="ctw-col-span-1">
            <mat-form-field>
                <mat-label>CVV</mat-label>
                <input
                    matInput
                    #cvv="ngModel"
                    type="text"
                    name="cvc"
                    [(ngModel)]="this.info.cvv"
                    required
                />
                <mat-hint class="animate__animated error" align="start"
                    [class.animate__fadeIn]="invalidInputState(cvv)">
                    <span *ngIf="!info.card_number">CVV is required.</span>
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
</div>