<h2 mat-dialog-title class="notranslate">Are You Sure?</h2>
<mat-dialog-content class="ctw-mb-4">
	<p>
		Removing this DNS record may impact services relying on this record for up to 24 hours or longer:
	</p>
	<div class="dns-records-container">
		<div class="dns-records">
			<table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="notranslate">{{ record.name + domain }}</td>
						<td class="notranslate">{{ record.type }}</td>
						<td class="notranslate" *ngIf="'MX' === record.type">
							<span class="label">Priority:</span>
							{{ record.rdata.mxPriority }}
							<br />
							<span class="label">Destination:</span>
							{{ record.rdata.mxDestination }}
						</td>
						<td class="notranslate" *ngIf="'CAA' === record.type">
							<span class="label">Flag:</span>
							{{ record.rdata.caaFlag }}
							<br />
							<span class="label">Tag:</span>
							{{ record.rdata.caaTag }}
							<br />
							<span class="label">Value:</span>
							{{ record.rdata.caaValue }}

						</td>
						<td class="notranslate" *ngIf="'SRV' === record.type">
							<span class="label">Priority:</span>
							{{ record.rdata.srvPriority }}
							<br />
							<span class="label">Weight:</span>
							{{ record.rdata.srvWeight }}
							<br />
							<span class="label">Port:</span>
							{{ record.rdata.srvPort }}
							<br />
							<span class="label">Target:</span>
							{{ record.rdata.srvTarget }}

						</td>
						<td class="notranslate"
							*ngIf="'MX' !== record.type && 'CAA' !== record.type && 'SRV' !== record.type"
						>
							<span>{{ this.getValue() }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-mt-10 ctw-pb-5">
	<div class="ctw-text-right ctw-w-full">
		<button mat-button [mat-dialog-close]="false">Cancel</button>
		<button mat-raised-button class="ctw-ml-3" [mat-dialog-close]="true" color="warn">
			Yes, Continue
		</button>
	</div>
</mat-dialog-actions>
