import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ProfileService } from '@central/ng-shared';
import { ProjectService } from './project.service';
import { AppService } from '../app.service';
import { Location } from '@angular/common';

@Component({
	selector: 'central-project',
	templateUrl: 'project.component.html',
	providers: [ProjectService],
})
export class ProjectComponent implements OnDestroy {

	public status = 'loading';

	public isProjectRoute = false;
	public isEnvironmentRoute = false;

	private projectRoutes =
		// Routes that do not need to wait for a connection.
		/\/projects\/[a-zA-Z\d]+($|\?|\/settings|\/snaps|\/hosting|\/whats\-new)/;
	private environmentRoutes =
		// Routes that require a connection of some sort.
		/\/projects\/[a-zA-Z\d]+($|\?|\/setup|\/overview|\/configure|\/optimize|\/recover|\/services|\/(add|resize)\/)/;

	private queryEnvironmentId: string;
	private subscriptions = [];

	public constructor(
		private appService: AppService,
		private profileService: ProfileService,
		private projectService: ProjectService,
		private activeRoute: ActivatedRoute,
		private location: Location,
		private router: Router,
	) {
		this.isEnvironmentRoute = !!this.location.path().match(this.environmentRoutes);

		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.activeRoute.queryParams.subscribe((params) => {
						if (
							this.queryEnvironmentId !== params.environment_id &&
							this.projectService.project
						) {
							this.queryEnvironmentId = params.environment_id;
							this.setCurrentEnvironment(this.queryEnvironmentId);
						} else {
							this.queryEnvironmentId = params.environment_id;
						}

						this.setProject();
					})
				)

				this.subscriptions.push(
					this.router.events.subscribe((e) => {
						if (e instanceof NavigationEnd) {
							this.isProjectRoute = !!e.url.match(this.projectRoutes);
							this.isEnvironmentRoute = !!e.url.match(this.environmentRoutes) ||
								!!this.location.path().match(this.environmentRoutes);
						}
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	private setProject() {
		this.status = 'loading';
		this.activeRoute.params.subscribe((routeParams) => {
			const projectId = routeParams.project_id;
			const cachedProject = this.appService.sessionStorage.getItem('project_id');
			this.projectService.fetchProject(projectId, true, projectId && projectId !== cachedProject).subscribe(
				(data) => {
					this.setCurrentEnvironment(
						this.projectService.getDefaultEnvironment(data)?.id
					);
					this.appService.sessionStorage.setItem('project_id', projectId);
				},
				(error) => {
					this.router.navigateByUrl('/404', { replaceUrl: true });
				}
			);
		}).unsubscribe()
	}

	private setCurrentEnvironment(requestedEnv) {
		const cachedEnv = this.appService.sessionStorage.getItem('environment_id');
		if (this.queryEnvironmentId) {
			this.projectService.setEnvironment(this.queryEnvironmentId);
			this.appService.sessionStorage.setItem('environment_id', this.queryEnvironmentId);
		} else if (requestedEnv !== cachedEnv && !this.projectService.environment) {
			this.projectService.setEnvironment(requestedEnv);
			this.appService.sessionStorage.setItem('environment_id', this.queryEnvironmentId);
		} else if (!this.projectService.environment ){
			this.projectService.setEnvironment(cachedEnv);
		}
		this.status = 'success';
	}
}
