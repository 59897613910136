<central-header title="{{ this.projectService.project?.label }}" titleIcon="{{ this.projectService.project?.fields?.project_type
}}" status="{{ this.vpsStatus === 'ready' && this.playgroundStatus === 'ready' ? 'success' : 'loading' }}"
	breakpoint="6xl" actions="project" (action)="this.header($event)" [service]="this.projectService"
	[data]="this.billingSubscription"></central-header>

<div class="wrap animate__animated animate__fadeIn"
	*ngIf="this.vpsStatus === 'ready' && this.playgroundStatus === 'ready'">
	<div class="ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16 ctw-grid">

		<h2>UltraStack VPS</h2>

		<!-- VPS -->
		<dash-card *ngIf="!this.projectService.servers?.length && this.isAnOrg" showPopup="true" icon="ultrastack"
			cardTitle="UltraStack VPS" disabled="true" [transparent]="true" class="mat-button">
			<div class="text-center add-server ctw-flex ctw-px-4 ctw-py-2 ctw-mb-16 ctw-justify-center ctw-whitespace-pre-wrap"
				[routerLink]="['/projects', this.projectService.project.id, 'add', 'server']">
				<button mat-raised-button color="primary"><mat-icon class="ctw-mr-2"><span
							inlineSVG="assets/img/ultrastack-white.svg"></span></mat-icon>
					Start Your Cloud Server
				</button>
			</div>
		</dash-card>

		<div *ngFor="let server of this.projectService.servers; let i = index">
			<dash-card showPopup="true" icon="ultrastack" cardTitle="UltraStack VPS" disabled="true"
				class="ctw-mb-12 ctw-w-full" hostname="{{ server.fields?.site_url }}"
				ip="{{ server.fields?.machine_details?.machine_ip }}" [transparent]="true">
				<ng-container subTitleHTML>
					<span
						*ngIf="this.vpsStates[i] === 'suspended' || this.vpsStates[i] === 'expired' || this.vpsStates[i] === 'reclaimed'"
						class="sub-title ctw-flex ctw-ml-2">
						<button mat-raised-button class="suspended ctw-text-sm ctw-mx-2" color="warn"
							(click)="this.openChat()">
							{{ this.vpsStates[i].toUpperCase() }}
						</button>
					</span>
					<span class="sub-title ctw-text-base">
						<span class="notranslate">{{ this.projectService.getEnvironmentName(server) }}</span>
					</span>
				</ng-container>
				<div class="manage ctw-flex ctw-mb-16 ctw-p-8"
					[class.ctw-pb-0]="server.fields?.environment_usage?.includes('Blueprints') && 0">

					<!-- Preview -->
					<div class="at-a-glance">
						<dash-card icon="" cardTitle="" iframe="{{ server.fields?.site_url }}" class="ctw-m-0"
							disabled="true">
							<div class="site preview">
								<img *ngIf="this.projectService.getScreenshot(this.projectService.project, server)"
									[centralCachedSrc]="this.projectService.getScreenshot(this.projectService.project, server)" />
								<div class="preview-placeholder">
									<div class="message">
										<div class="spinner-container"
											*ngIf="this.projectService.getEnvironmentUrl(this.projectService.project) && !this.projectService.getScreenshot(this.projectService.project, server)">
											<mat-progress-spinner mode="indeterminate" color="primary"
												diameter="30"></mat-progress-spinner>
										</div>

										<ng-container
											*ngIf="!this.projectService.getEnvironmentUrl(this.projectService.project)">
											<mat-icon>photo_size_select_actual</mat-icon>
											<div>
												Preview Unavailable
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</dash-card>
					</div>

					<!-- Info -->
					<div class="info ctw-pl-8">
						<h3 class="ctw-flex ctw-cursor-pointer ctw-font-bold" (mouseover)="mouseOveredEdit=true"
							(mouseout)="mouseOveredEdit=false" (click)="this.openEditDialog(server)">
							{{ server.label }}
							<span class="editable ctw-cursor-pointer notranslate"
								[class.ctw-opacity-50]="!mouseOveredEdit" [class.ctw-opacity-100]="mouseOveredEdit"
								(mouseover)="mouseOveredEdit=true" (mouseout)="mouseOveredEdit=false">
								<mat-icon class="ctw-text-xl ctw-ml-3 ctw-absolute ctw-mt-1">edit</mat-icon>
							</span>
						</h3>
						<mat-card>
							Your UltraStack VPS is our in-house, high performance purpose built WordPress server stack
							meant for hosting your Production website. Start with the following guides on how to setup
							and manage your UltraStack VPS environment.
							<br><br>
							<a href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/projects/ultrastack-vps/"
								target="_blank" rel="noopener">Getting Started with Your UltraStack VPS <mat-icon
									class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined"
									style="margin-top: 1px">open_in_new</mat-icon></a><br />

							<a href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/servers/manage-server/"
								target="_blank" rel="noopener">Managing Your UltraStack VPS <mat-icon
									class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined"
									style="margin-top: 1px">open_in_new</mat-icon></a><br />

							<a href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/servers/ultrastack-utilities/"
								target="_blank" rel="noopener">UltraStack Utilities <mat-icon
									class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined"
									style="margin-top: 1px">open_in_new</mat-icon></a><br />
						</mat-card>
						<div class="actions ctw-flex ctw-justify-end ctw-absolute ctw-right-4 ctw-mr-4">
							<button mat-button mat-raised-button *ngIf="server.fields?.has_publish_setup_user" [color]="
							server.fields?.environment_usage === 'unknown'
								? 'primary'
								: 'secondary'
						" [routerLink]="[
							'/projects',
							this.projectService.project.id,
							server.fields?.environment_usage === 'unknown' ? 'setup' : 'overview'
						]" [queryParams]="{ environment_id: server.id }"
								class="animate__animated animate__fadeIn ctw-bg-black ctw-text-white">
								<mat-icon class="ctw-mr-2"
									[class.ctw-mb-1]="server.fields?.environment_usage === 'unknown'">
									<ng-container *ngIf="server.fields?.environment_usage === 'unknown'">
										rocket_launch
									</ng-container>
									<span *ngIf="server.fields?.environment_usage !== 'unknown'"
										inlineSVG="assets/img/ultrastack-white.svg"></span>
								</mat-icon>
								{{ server.fields?.environment_usage === 'unknown' ? 'Get Started' : 'Manage Environment'
								}}
							</button>
							<central-progress-button [state]="submitted"
								*ngIf="this.vpsStates[i] !== 'suspended' && this.vpsStates[i] !== 'expired' && this.vpsStates[i] !== 'error' && this.vpsStates[i] !== 'reclaimed' && (!server.fields?.has_publish_setup_user)"
								mat-raised-button [color]="secondary"
								class="animate__animated animate__fadeIn playbook-status" disabled>
								<mat-spinner role="progressbar" mode="indeterminate" diameter="20" color="primary"
									class="ctw-mr-2"></mat-spinner>
								<span *ngIf="!this.vpsStates[i] || this.vpsStates[i] === 'pending'">Checking
									Status...</span>
								<span *ngIf="this.vpsStates[i] === 'provisioning'">Provisioning...</span>
								<span *ngIf="this.vpsStates[i] === 'installing'">Configuring WordPress...</span>
							</central-progress-button>

							<button mat-stroked-button
								*ngIf="server.fields?.has_publish_setup_user && server.fields?.environment_usage !== 'unknown'"
								(click)="openAdmin(server.id)"
								class="animate__animated animate__fadeIn ctw-ml-3 ctw-border-current ctw-text-white"
								[disabled]="!this.wpRestService.getSite(server)"
								[class.ctw-opacity-50]="!this.wpRestService.getSite(server)">
								<span class="ctw-flex ctw-justify-center">
									<svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="24px"
										viewBox="0 -7 96.24 96.24" xml:space="preserve" stroke="#ffffff"
										class="ctw-relative ctw-mr-2 wordpress-icon">
										<g id="SVGRepo_bgCarrier" stroke-width="0" />
										<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
										<g id="SVGRepo_iconCarrier">
											<g>
												<path
													d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
											</g>
										</g>
									</svg>
									<span matLine
										*ngIf="!this.wpRestService.getSite(server) && !this.wpRestService.sitesUnavailable.includes(server.fields?.connection_url)">Connecting...</span>
									<span matLine
										*ngIf="!this.wpRestService.getSite(server) && this.wpRestService.sitesUnavailable.includes(server.fields?.connection_url)">Unable
										To Connect</span>
									<span matLine *ngIf="this.wpRestService.getSite(server)">Admin</span>
								</span>
							</button>
						</div>
					</div>
				</div>

				<ng-container *ngIf="server.fields?.environment_usage?.includes('Blueprint') && 0">
					<button mat-stroked-button
						class="ctw-h-full ctw-px-2 ctw-text-xs ctw-bg-[#8bbdff] ctw-font-semibold ctw-cursor-default ctw-ml-4 ctw-mb-4">
						{{ server.fields?.environment_usage }}</button>
				</ng-container>
			</dash-card>
		</div>

		<!-- Playgrounds -->
		<dash-card icon="settings_system_daydream" cardTitle="Playgrounds" disabled="true" class="ctw-w-full"
			[transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base ctw-flex" *ngIf="!this.playgroundAvailabilityRequestState">
					<mat-progress-spinner mode="indeterminate" color="primary" diameter="20"></mat-progress-spinner>
				</span>
				<span class="sub-title ctw-text-base ctw-flex"
					*ngIf="this.playgroundAvailabilityRequestState === 'success'">
					<span
						[class]="this.playgroundUsed === this.playgroundLimit ? 'ctw-text-red-700 ctw-font-bold' : ''">
						{{ this.playgroundUsed }} </span> <span class="ctw-px-2">/</span> <span class="ctw-font-bold"
						[class.ctw-text-red-700]="this.playgroundUsed === this.playgroundLimit"
						[class.ctw-text-green-700]="this.playgroundUsed < this.playgroundLimit"> {{ this.playgroundLimit
						}} </span>
				</span>

			</ng-container>
			<div class="">
				<div class="ctw-text-center">
					<div class="ctw-flex ctw-justify-center">
						<mat-divider class="ctw-w-full ctw-mt-4"></mat-divider>
						<h4 class="ctw-px-8 ctw-uppercase ctw-font-bold ctw-m-0">Playground</h4>
						<mat-divider class="ctw-w-full ctw-mt-4"></mat-divider>
					</div>
					<span class="sub-title ctw-text-base ctw-opacity-50">
						Experiment with new ideas or create demo sites for clients!
					</span>
				</div>
				<div class="ctw-flex ctw-justify-between ctw-flex-align-center">

				</div>
				<div>
					<div class="ctw-grid ctw-grid-cols-3 ctw-mt-8">
						<div *ngFor="let environment of this.playgrounds; let i = index"
							class="ctw-grid ctw-grid-cols-1 ctw-my-5 ctw-m-8 ctw-text-center">
							<ng-container *ngIf="environment.create">
								<ng-container *ngIf="i === 0 || this.playgrounds[i-1]?.id">
									<div matTooltip="Add Playground"
										class="add-playground ctw-flex mat-stroked-button ctw-px-4 ctw-py-2 ctw-mb-16 ctw-justify-center ctw-whitespace-pre-wrap"
										(click)="this.addPlayground(i)">
										<div class="text-center">
											<mat-icon *ngIf="this.addPlaygroundState[i] !== 'submitted'"
												class="ctw-text-3xl ctw-opacity-50">add</mat-icon>
											<div *ngIf="this.addPlaygroundState[i] === 'submitted'"
												class="spinner-container">
												<mat-progress-spinner mode="indeterminate" color="primary"
													diameter="30"></mat-progress-spinner>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="i > 0 && this.playgrounds[i-1]?.create">
									<div
										class="add-playground ctw-flex mat-stroked-button ctw-px-4 ctw-py-2 ctw-mb-16 ctw-justify-center ctw-whitespace-pre-wrap ctw-opacity-50 ctw-cursor-default">
										<div class="text-center">
											&nbsp;
										</div>
									</div>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="environment.id">
								<div>
									<dash-card icon="" cardTitle="" iframe="{{ environment.fields?.site_url }}"
										class="ctw-m-0" disabled="true">
										<div class="site preview playground">
											<img *ngIf="this.projectService.getScreenshot(this.projectService.project, environment)"
												[centralCachedSrc]="this.projectService.getScreenshot(this.projectService.project, environment)" />
											<div class="preview-placeholder">
												<div class="message">
													<div class="spinner-container"
														*ngIf="this.projectService.getEnvironmentUrl(this.projectService.project) && !this.projectService.getScreenshot(this.projectService.project, environment)">
														<mat-progress-spinner mode="indeterminate" color="primary"
															diameter="30"></mat-progress-spinner>
													</div>

													<ng-container
														*ngIf="!this.projectService.getEnvironmentUrl(this.projectService.project)">
														<mat-icon>photo_size_select_actual</mat-icon>
														<div>
															Preview Unavailable
														</div>
													</ng-container>
												</div>
											</div>
										</div>
									</dash-card>
									<div class="ctw-mt-2 ctw-flex ctw-cursor-pointer ctw-font-bold ctw-justify-center"
										(mouseover)="mouseOveredEdit=true" (mouseout)="mouseOveredEdit=false"
										(click)="this.openEditDialog(environment)">
										{{ environment.label }}
										<span class="editable ctw-cursor-pointer notranslate"
											[class.ctw-opacity-50]="!mouseOveredEdit"
											[class.ctw-opacity-100]="mouseOveredEdit" (mouseover)="mouseOveredEdit=true"
											(mouseout)="mouseOveredEdit=false">
											<mat-icon class="ctw-text-base ctw-ml-1 ctw-absolute"
												style="margin-top:1px">edit</mat-icon>
										</span>
									</div>
									<mat-divider class="ctw-mx-8 ctw-my-2"></mat-divider>
									<div class="ctw-flex ctw-justify-around ctw-px-8">
										<button
											matTooltip="{{this.wpRestService.getSite(environment) ? 'Admin' : 'Connecting...' }}"
											*ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && environment.fields?.environment_usage !== 'unknown'
											" (click)="openAdmin(environment.id)" [disabled]="!this.wpRestService.getSite(environment)">
											<mat-icon
												class="ctw-text-blue-800 wordpress-icon ctw-flex ctw-flex-align-center"
												[class.ctw-opacity-50]="!this.wpRestService.getSite(environment)"><svg
													fill="#2245c4" version="1.1" id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink" width="22px"
													height="24px" viewBox="0 0 96.24 103.24" xml:space="preserve"
													stroke="#ffffff" class="wordpress-icon"
													[class.ctw-opacity-50]="!this.wpRestService.getSite(environment)">
													<g id="SVGRepo_bgCarrier" stroke-width="0" />
													<g id="SVGRepo_tracerCarrier" stroke-linecap="round"
														stroke-linejoin="round" />
													<g id="SVGRepo_iconCarrier">
														<g>
															<path
																d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
														</g>
													</g>
												</svg> </mat-icon>
										</button>
										<button matTooltip="Manage Environment" *ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && environment.fields?.environment_usage !== 'unknown'
											" (click)="changeEnvironment(environment.id)" class="ctw-flex ctw-flex-align-center">
											<mat-icon class="ctw-mr-0 actions ctw-opacity-50">settings_b_roll</mat-icon>
										</button>
										<button *ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && environment.fields?.environment_usage === 'unknown'
											" class="ctw-cursor-default" class="ctw-flex ctw-flex-align-center">
											<mat-icon class="ctw-mr-0 actions ctw-opacity-50"></mat-icon>
										</button>
										<button matTooltip="Delete Playground" *ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && +environment?.fields?.is_development && environment.fields?.environment_usage === 'unknown'
											" (click)="openDialog(environment.id)" class="ctw-flex ctw-flex-align-center">
											<mat-icon class="ctw-mr-0 actions ctw-opacity-50">delete_forever</mat-icon>
										</button>
										<button matTooltip="Get Started" *ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && environment.fields?.environment_usage === 'unknown'
											" (click)="changeEnvironment(environment.id)" class="ctw-flex ctw-flex-align-center">
											<mat-icon class="ctw-mr-0 actions ctw-opacity-50">start</mat-icon>
										</button>
										<button matTooltip="Delete Playground" *ngIf="
												projectService.hasEnvironmentControl(
													environment
												) && +environment?.fields?.is_development && environment.fields?.environment_usage !== 'unknown'
											" (click)="openDialog(environment.id)" class="ctw-flex ctw-flex-align-center">
											<mat-icon class="ctw-mr-0 actions ctw-opacity-50">delete_forever</mat-icon>
										</button>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<p class="ctw-flex ctw-justify-center ctw-items-center ctw-text-2xl ctw-mb-16 ctw-text-imh-gray-200"
					*ngIf="!this.projectService.hasPlaygrounds() && 0">
					No Active Playgrounds
				</p>
				<table *ngIf="this.projectService.hasPlaygrounds() && 0" mat-table [dataSource]="this.playgrounds"
					class="mat-elevation-z ctw-mt-8">
					<!--- Note that these columns can be defined in any order.
						The actual rendered columns are set as a property on the row definition" -->

					<!-- Position Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>
							Active Playgrounds
						</th>
						<td class="ctw-w-full" mat-cell *matCellDef="let element">
							<span class="sub-title editable ctw-text-base ctw-cursor-pointer"
								(click)="this.openEditDialog(element)">
								<span><span class="notranslate">{{ element.label
										}}</span><a class="ctw-mx-4">[edit]</a>
								</span>
							</span><br><a color="secondary" target="_blank" [href]="element.fields?.site_url">
								<span class="ctw-text-sm">{{ element.fields?.site_url }}<mat-icon
										class="ctw-text-sm ctw-h-3.5 ctw-w-3.5 ctw-ml-1">open_in_new</mat-icon></span>
							</a>
							<ng-container *ngIf="element.fields?.environment_usage?.includes('Blueprint') && 0">
								<button mat-stroked-button
									class="ctw-h-full ctw-px-2 ctw-text-xs ctw-bg-[#8bbdff] ctw-font-semibold ctw-cursor-default ctw-ml-3">
									{{ element.fields?.environment_usage }}</button>
							</ng-container>
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef></th>
						<td class="ctw-flex ctw-py-2" mat-cell *matCellDef="let environment">
							<button mat-menu-item matTooltip="Admin" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && environment.fields?.environment_usage !== 'unknown'
								" (click)="openAdmin(environment.id)" [disabled]="!this.wpRestService.getSite(environment)">
								<mat-icon class="ctw-ml-1 ctw-text-blue-800 wordpress-icon"><svg fill="#2245c4"
										version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink" width="22px" height="24px"
										viewBox="0 0 96.24 103.24" xml:space="preserve" stroke="#ffffff"
										class="wordpress-icon">
										<g id="SVGRepo_bgCarrier" stroke-width="0" />
										<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
										<g id="SVGRepo_iconCarrier">
											<g>
												<path
													d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
											</g>
										</g>
									</svg> </mat-icon>
							</button>
							<button mat-menu-item matTooltip="Manage Environment" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && environment.fields?.environment_usage !== 'unknown'
								" (click)="changeEnvironment(environment.id)" class="ctw-text-center">
								<mat-icon class="ctw-mr-0">settings_b_roll</mat-icon>
							</button>
							<button mat-menu-item *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && environment.fields?.environment_usage === 'unknown'
								" class="ctw-cursor-default" class="ctw-text-center">
								<mat-icon class="ctw-mr-0"></mat-icon>
							</button>
							<button mat-menu-item *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && environment.fields?.environment_usage === 'unknown'
								" class="ctw-cursor-default" class="ctw-text-center">
								<mat-icon class="ctw-mr-0"></mat-icon>
							</button>
							<button mat-menu-item matTooltip="Get Started" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && environment.fields?.environment_usage === 'unknown'
								" (click)="changeEnvironment(environment.id)" class="ctw-text-center">
								<mat-icon class="ctw-mr-0">start</mat-icon>
							</button>
							<button mat-menu-item matTooltip="Delete Playground" *ngIf="
									projectService.hasEnvironmentControl(
										environment
									) && +environment?.fields?.is_development && environment.fields?.environment_usage !== 'unknown'
								" (click)="openDialog(environment.id)" class="ctw-text-center">
								<mat-icon class="ctw-mr-0">delete_forever</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr class="ctw-cursor-pointer hover:ctw-bg-black hover:ctw-bg-opacity-05" mat-row
						*matRowDef="let row; columns: displayedColumns"></tr>
				</table>
			</div>
		</dash-card>
	</div>
</div>
