import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '../../authentication/services';
import { ApiService, ConfigurationService } from '../../core';

@Component({
	selector: 'central-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	public state = 'pending';
	public errorMessage: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private apiService: ApiService,
		private authService: AuthService,
		public configService: ConfigurationService,
	) {}

	public loginAsUser() {
		this.state = 'submitted';
		this.errorMessage = '';
		this.authService.loginAsUser(this.data.user_id)
			.subscribe({
				next: (response: any) => {
					this.state = 'success';
				},
				error: (error: any) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'error';
				},
			});
	}
}
