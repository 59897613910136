<button
	mat-ripple
	mat-button
	[color]="color"
	class="btn-spinner {{this.type}}"
	[disabled]="this.state === 'submitted' || this.disabled"
>
	<mat-spinner
		diameter="20"
		color="accent"
		*ngIf="this.state == 'submitted'"
	></mat-spinner>
	<ng-content></ng-content>
</button>
