import { Component, Injector, ProviderToken } from "@angular/core";
import { ConfigurationService } from "../core";
import { TrackingService } from "./tracking.service";
import { SubTrackingService } from "./base/sub-tracking.service";


@Component({
    selector: 'central-tracking-loader',
    template: `<ng-content></ng-content>`,
})
export class TrackingLoaderComponent {
    constructor(
        private trackingService: TrackingService,
        private configurationService: ConfigurationService,
        injector: Injector,
    ){
        const TrackingProviders: any[] = this.configurationService.config.trackingConfig?.trackers || [] ;
        TrackingProviders.forEach( (provider:ProviderToken<any>) => {
            const service = injector.get(provider);
            if( !(service instanceof SubTrackingService) ) {
                console.log('Invalid tracking provider', service);
                throw new Error(`Tracking provider must be an instance of SubTrackingService`);
            }
        } );
        this.trackingService.appendScripts();
    }

}