import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { SettingsComponent } from './settings/settings.component';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { AccountService } from './account.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { AdminComponent } from './admin/admin.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AccountNotificationService } from './notification/notification.service';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MomentModule } from 'ngx-moment';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CoreModule } from '../core/core.module';
import { MembershipModule } from '../membership';
import { NotificationService, NotificationsModule } from '../notifications';
import { PhoneNumberModule } from '../phone-number';
import { AuthenticationModule } from '../authentication/authentication.module';
import { OrganizationModule } from '../organization';
import { SupportService as BaseSupportService } from '../support/support.service';
import { SupportService } from './support/support.service';
import { RouterModule } from '@angular/router';
import { SuspensionComponent } from './suspension/suspension.component';
import { BetaTesterComponent } from './beta-tester/beta-tester.component';

@NgModule({
	declarations: [
		AccountComponent,
		SettingsComponent,
		UserSettingsComponent,
		AdminComponent,
		//SuspensionComponent,
		//BetaTesterComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		CoreModule,
		MembershipModule,
		MatSelectModule,
		MomentModule,
		MatTooltipModule,
		MatListModule,
		MatCardModule,
		MatFormFieldModule,
		MatTableModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatProgressBarModule,
		RouterModule,
		NotificationsModule,
		PhoneNumberModule,
		AuthenticationModule,
		OrganizationModule,
	],
	exports: [SettingsComponent],
	providers: [
		AccountService,
		{ provide: BaseSupportService, useClass: SupportService },
		{ provide: NotificationService, useClass: AccountNotificationService },
	],
})
export class AccountModule {}
