<central-header *ngIf="this.configService.config.brandConfig.id === 'imh'" title="Support Requests" [status]="status"
	actions="support" (action)="this.header($event)"></central-header>

<div [class.openmetal]="this.configService.config.brandConfig.id !== 'imh'">
	<mat-progress-bar class="loader" mode="indeterminate"
		*ngIf="status === 'loading' && this.configService.config.brandConfig.id !== 'imh'"></mat-progress-bar>
	<central-failed-request *ngIf="'failed' === this.status"></central-failed-request>

	<div class="tab-wrap" *ngIf="status === 'success'" [class]="
			this.configService.config.brandConfig.id === 'imh'
				? 'wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16 imh'
				: ''
		">
		<span class="open-request" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
			<button mat-raised-button *ngIf="this.configService.config?.hasSupportAgentKey"
				(click)="this.openAgentAccessDialog()">
				Support Agent Access
			</button>
			<button mat-raised-button class="support-access" (click)="openDialog()">
				{{
				this.configService.config.brandConfig.id === 'imh'
				? 'Open a Ticket'
				: 'Open Request'
				}}
			</button>
			<button mat-raised-button class="support-access" [disabled]="this.openingChat"
				*ngIf="this.configService.config?.zendesk?.chatIsEnabled" (click)="this.openChat()" [class.mat-primary]="
					this.configService.config.brandConfig.id === 'imh'
				">
				Live Chat
			</button>
		</span>
		<mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
			class="{{ this.configService.config.brandConfig.id }}" color="accent">
			<mat-tab *ngIf="contextTabTickets.label" color="accent">
				<ng-template matTabLabel>
					<span matBadge="{{ contextTabTickets.openTickets }}" matBadgeSize="medium" color="accent">{{
						contextTabTickets.label }}
					</span>
				</ng-template>

				<ng-container *ngIf="contextTabTickets.tickets.length < 1">
					<ng-container *ngTemplateOutlet="
							noRequestsFound;
							context: { ownerLabel: contextTabTickets.label }
						"></ng-container>
				</ng-container>

				<ng-container *ngIf="contextTabTickets.tickets.length > 0">
					<ng-container *ngTemplateOutlet="
							listTickets;
							context: { tickets: contextTabTickets.tickets }
						"></ng-container>
				</ng-container>
			</mat-tab>
			<mat-tab color="accent">
				<ng-template matTabLabel color="accent">
					<span *ngIf="this.configService.config.brandConfig.id === 'imh'"
						matBadge="{{ accountTickets.openTickets }}" matBadgeSize="medium" color="accent"
						class="ctw-capitalize">
						{{ accountTickets.openTickets < 1 ? 'No' : '' }} Open Requests </span>
							<span *ngIf="this.configService.config.brandConfig.id !== 'imh'"
								matBadge="{{ accountTickets.openTickets }}" matBadgeSize="medium" color="accent">
								{{ accountTickets.label }}
							</span>
				</ng-template>
				<ng-container *ngIf="accountTickets.tickets.length < 1">
					<ng-container *ngTemplateOutlet="
							noRequestsFound;
							context: { ownerLabel: accountTickets.label }
						"></ng-container>
				</ng-container>

				<ng-container *ngIf="accountTickets.tickets.length > 0">
					<ng-container *ngTemplateOutlet="
							listTickets;
							context: { tickets: accountTickets.tickets }
						"></ng-container>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

<ng-template #listTickets let-tickets="tickets">
	<table mat-table [dataSource]="tickets" class="team-content">
		<ng-container matColumnDef="subject">
			<th mat-header-cell *matHeaderCellDef>SUBJECT</th>
			<td mat-cell *matCellDef="let element">
				<a routerLink="../requests/{{ element.id }}">
					<mat-icon class="error emergency-ticket" *ngIf="element.priority !== 'normal'"
						matTooltip="Emergency Request">warning</mat-icon>
					{{ element.subject }}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="created_at">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				{{
				this.configService.config.brandConfig.id === 'imh'
				? 'CREATED'
				: 'CREATED AT'
				}}
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				{{ element.created_at | amTimeAgo }}
			</td>
		</ng-container>

		<ng-container matColumnDef="updated_at">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				{{
				this.configService.config.brandConfig.id === 'imh'
				? 'UPDATED'
				: 'LAST ACTIVITY'
				}}
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				{{ element.updated_at | amTimeAgo }}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th class="justify-right" mat-header-cell *matHeaderCellDef>
				STATUS
			</th>
			<td class="justify-right" mat-cell *matCellDef="let element">
				<span class="ticket-status {{ element.status }} {{ this.configService.config.brandConfig.id }}">{{
					element.status
					}}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="['subject', 'created_at', 'updated_at', 'status']"></tr>
		<tr mat-row *matRowDef="
				let row;
				columns: ['subject', 'created_at', 'updated_at', 'status']
			"></tr>
	</table>
</ng-template>

<ng-template #noRequestsFound let-ownerLabel="ownerLabel">
	<div *ngIf="this.configService.config.brandConfig.id === 'imh'"
		class="no-content ctw-max-w-lg ctw-mx-auto ctw-text-center">
		<img class="ctw-mx-auto svg"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg" />
		<h3>No Requests Found</h3>
		<p>
			<button mat-raised-button color="primary" (click)="openDialog()" class="first-ticket">
				Open Ticket
			</button>
		</p>
	</div>
	<div *ngIf="this.configService.config.brandConfig.id !== 'imh'"
		class="no-content ctw-max-w-lg ctw-mx-auto ctw-text-center">
		<img class="ctw-mx-auto svg"
			src="https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg" />
		<h3>No Requests Found</h3>
		<p>
			Any support requests that you create will appear here. You're
			currently viewing the requests for <strong>{{ ownerLabel }}</strong>.
		</p>
	</div>
</ng-template>
