<central-header title="My Teams" [status]="this.status"
	actions="{{ !this.hasInactive ? 'team' : ( !showInactive ? 'team-' : 'team+' ) }}"
	(action)="this.header($event)"></central-header>

<failed-request *ngIf="'failed' === status"></failed-request>

<ng-container *ngIf="status === 'success'">
	<div class="content animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<ng-container *ngIf="teams.length === 0 && inactiveTeams.length === 0">
			<div class="no-teams ctw-max-w-lg ctw-mx-auto ctw-text-center">
				<img
					src="https://inmotionhosting.github.io/static-assets/illustrations/small/enterprise-large-business.svg" />
				<h3>No Teams Found</h3>
				<p>
					Teams allow you to invite your team and share access to
					sites. Create a team or have an administrator of an existing
					team send an invite to this account.
				</p>
				<button mat-raised-button color="primary" [routerLink]="['/teams/new']">
					<mat-icon>add</mat-icon> New Team
				</button>
			</div>
		</ng-container>

		<dash-card *ngIf="teams.length > 0 || inactiveTeams.length > 0"
			cardTitle="Collaboration, Billing & Subscriptions" icon="groups" disabled="true" [subTitle]=""
			[transparent]="true">
			<table mat-table [dataSource]="teams" id="team-index">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>TEAM NAME</th>
					<td mat-cell *matCellDef="let team" class="with-subtitle">
						<p *ngIf="team.account_id" class="notranslate">{{ team.name }}</p>
						<p class="notranslate">
							<a *ngIf="team.organization_id" [routerLink]="[
									'/team',
									team.organization_id,
									'members'
								]" class="notranslate">{{ team.name }}</a>
						</p>
						<p *ngIf="team.organization_id">
							{{
							team.members.length +
							(team.members.length === 1
							? ' member'
							: ' members')
							}}<ng-container *ngIf="team.cloud_count">,
								{{
								team.cloud_count +
								(team.cloud_count === 1
								? ' cloud'
								: '
								clouds')
								}}</ng-container>
						</p>
					</td>
				</ng-container>

				<ng-container matColumnDef="billing">
					<th mat-header-cell *matHeaderCellDef>BILLING ENABLED</th>
					<td mat-cell *matCellDef="let team">
						<p class="ctw-pt-2">
							<mat-icon class="ctw-text-{{ team.has_billing ? 'green' : 'red' }}-700 ctw-ml-12">{{
								team.has_billing ?
								'task_alt' : 'horizontal_rule' }}</mat-icon>
						</p>
					</td>
				</ng-container>

				<ng-container matColumnDef="your-role">
					<th mat-header-cell *matHeaderCellDef>MY ROLE</th>
					<td mat-cell *matCellDef="let team">
						{{ getRoleLabel(team.role) | titlecase }}
					</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell class="align-right" *matHeaderCellDef>

					</th>
					<td mat-cell class="align-right" *matCellDef="let team">
						<ng-container *ngIf="true">
							<button mat-stroked-button [matMenuTriggerFor]="teamOptions" class="ctw-border-none">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #teamOptions="matMenu" xPosition="before">
								<button *ngIf="team.organization_id" mat-menu-item [routerLink]="[
										'/team',
										team.organization_id,
										'notifications'
									]">
									<mat-icon>notifications</mat-icon>Notifications
								</button>
								<button *ngIf="team.organization_id" mat-menu-item [routerLink]="[
										'/team',
										team.organization_id,
										'subscriptions'
									]">
									<mat-icon>event_repeat</mat-icon>Subscriptions
								</button>
								<button *ngIf="team.organization_id" mat-menu-item [routerLink]="[
										'/team',
										team.organization_id,
										'help','requests'
									]">
									<mat-icon>support_agent</mat-icon>Support Requests
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="openLeaveDialog(team)" class="ctw-text-red-700">
									<mat-icon class="ctw-text-red-700">door_front</mat-icon>Leave Team...
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item class="ctw-text-imh-gray-200"
									*ngIf="team.role === 'owner' && teams.length > 1" (click)="openArchiveDialog(team)">
									<mat-icon class="ctw-text-imh-gray-200">archive</mat-icon>Archive...
								</button>
							</mat-menu>
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="[
						'name',
						'billing',
						'your-role',
						'action'
					]"></tr>
				<tr mat-row *matRowDef="
						let row;
						columns: ['name', 'billing', 'your-role', 'action']
					"></tr>
			</table>
			<ng-container *ngIf="this.inactiveTeams.length > 0 && this.showInactive">
				<table mat-table [dataSource]="inactiveTeams" id="team-index">
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let team" class="with-subtitle">
							<p *ngIf="team.account_id" class="notranslate">{{ team.name }}</p>
							<p class="notranslate ctw-text-imh-gray-200">
								{{ team.name }}
							</p>
							<p *ngIf="team.organization_id">
								{{
								team.members.length +
								(team.members.length === 1
								? ' member'
								: ' members')
								}}<ng-container *ngIf="team.cloud_count">,
									{{
									team.cloud_count +
									(team.cloud_count === 1
									? ' cloud'
									: '
									clouds')
									}}</ng-container>
							</p>
						</td>
					</ng-container>
					<ng-container matColumnDef="action">
						<th mat-header-cell class="align-right" *matHeaderCellDef>
						</th>
						<td mat-cell class="align-right" *matCellDef="let team">
							<ng-container *ngIf="team.role === 'owner'">
								<button mat-stroked-button (click)="restoreTeam(team)">
									Restore
								</button>
							</ng-container>
						</td>
					</ng-container>
					<tr mat-row *matRowDef="
							let row;
							columns: ['name', 'action']
						"></tr>
				</table>
			</ng-container>

		</dash-card>
	</div>
</ng-container>
