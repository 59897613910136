import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../core';
import { AccountService } from '../account.service';
import { ProfileService } from '../../authentication/services/profile/profile.service';

@Component({
	selector: 'central-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnDestroy {
	@ViewChild('phoneNumber') public phoneNumber;

	public state = 'pending';
	public name = '';
	public primaryEmail = '';
	public secondaryEmail = '';
	public contactPhone = '';

	private subscriptions = [];

	constructor(
		public accountService: AccountService,
		public configService: ConfigurationService,
		private profileService: ProfileService
	) {
		this.setModels();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	public setModels() {
		if (this.configService.config.brandConfig.id === 'imh') {
			this.subscriptions.push(
				this.accountService.authAccountUpdate.subscribe((account: any) => {
					this.name = account.name;
					this.primaryEmail = account.email;
					this.contactPhone = account.phone;
					this.secondaryEmail = account.secondary_email;
				})
			)
		} else {
			this.name = this.accountService.account.name;
			this.primaryEmail = this.accountService.account.email;
			this.contactPhone = this.accountService.account.phone;
			this.secondaryEmail = this.accountService.account.secondary_email;
		}
	}

	public submit() {
		const headers = this.accountService.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.accountService.apiService.formatter.getUrl(
			'/v1/accounts/' + this.accountService.getResourceId()
		);

		this.state = 'submitted';

		this.accountService.apiService.http
			.patch(
				url,
				{
					name: this.name,
					primary_email: this.primaryEmail,
					secondary_email: this.secondaryEmail,
					phone: this.phoneNumber?.getNumber(),
				},
				{ headers }
			)
			.subscribe({
				next: (account) => {
					this.accountService.updateAccount(account);
					this.phoneNumber.iti.setNumber(account['phone']);
					this.profileService.fetch();
					this.setModels();
					this.state = 'success';
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}
}
