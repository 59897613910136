<h2>Snapshots</h2>
<p>
	You can create a Snapshot of your server that preserves the state of
	your server files, databases, and configuration from a specific
	moment in time. If needed, you will be able to revert your server
	back to a specific point in time.
</p>
<dash-card icon="history" cardTitle="Server Snapshots" class="dash-card snapshot" disabled="true" [transparent]="true">
	<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

	<failed-request *ngIf="state === 'failed'"></failed-request>

	<div *ngIf="this.state === 'success'" class="dash-card-content">
		<div *ngIf="snapshotBusy" class="mat-spinner-wrapped">
			<mat-spinner class="component-loader" diameter="50" color="primary"></mat-spinner>
			<div class="ctw-text-center ctw-text-xl">
				{{ this.action }} Snapshot<br /><br />
				<strong>This can take a couple of minutes...<br />
					Feel free to leave this page and return later</strong>.<br />
			</div>
		</div>
		<table *ngIf="dataSource?.length" mat-table [dataSource]="dataSource" class="ctw-mt-8 ctw-mb-12">
			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
				<td mat-cell *matCellDef="let snapshot">
					{{ snapshot.description }}
				</td>
			</ng-container>
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>CREATED</th>
				<td mat-cell *matCellDef="let snapshot">
					{{ snapshot.date }}
					<span *ngIf="this.available" class="snapshot-controls">
						<mat-icon (click)="useSnapshot($event)" matTooltip="Restore Snapshot" matTooltipPosition="above"
							aria-label="Restore snapshot">history</mat-icon>
						<mat-icon (click)="deleteSnapshot($event)" matTooltip="Delete Snapshot"
							matTooltipPosition="above" aria-label="Delete snapshot">delete_forever</mat-icon>
					</span>
					<span *ngIf="!this.available" class="snapshot-controls">
						<mat-icon matTooltip="Unavailable During Update" matTooltipPosition="above"
							aria-label="Unavailable During Update">history</mat-icon>
						<mat-icon matTooltip="Unavailable During Update" matTooltipPosition="above"
							aria-label="Unavailable During Update">delete_forever</mat-icon>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let snapshot; columns: displayedColumns" [attr.data-snapshot]="snapshot.id"></tr>
		</table>
		<div class="ctw-text-right ctw-mt-4">
			<button *ngIf="this.available" (click)="openSnapshotDialog()" mat-raised-button color="primary">
				Create Snapshot
			</button>
			<button *ngIf="!this.available" [disabled]="true" mat-raised-button color="primary">
				<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
			</button>
		</div>
	</div>
</dash-card>