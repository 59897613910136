import { Component, OnDestroy } from '@angular/core';
import { ProjectCreateService } from './project-create.service';
import { AppComponent } from '../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandingService } from '@central/ng-shared';

@Component({
	templateUrl: './project-create.component.html',
	styleUrls: ['./project-create.component.scss'],
	providers: [ProjectCreateService],
})
export class ProjectCreateComponent implements OnDestroy {
	constructor(
		public projectCreateService: ProjectCreateService,
		public brandingService: BrandingService,
		public router: Router,
		public route: ActivatedRoute,
		private appComponent: AppComponent
	) {
		this.appComponent.forceGuestView = true;
	}

	ngOnDestroy(): void {
		this.appComponent.forceGuestView = false;
	}
}
