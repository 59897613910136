<form (ngSubmit)="this.update()" *ngIf="this.configurationService.config.brandConfig.id !== 'imh'">
	<h3 mat-dialog-title class="ctw-capitalize">
		Edit
		{{
		data.membership.account
		? data.membership.account.name
		: data.membership.invite_email
		}}
	</h3>
	<p class="description">Please select this member's role:</p>
	<p class="error error-message" *ngIf="this.state === 'failed'">
		{{ this.error }}
	</p>
	<mat-dialog-content class="dialog-content">
		<div>
			<mat-radio-group [(ngModel)]="this.role" name="role">
				<mat-radio-button value="owner">
					<div class="role-name">
						<strong>{{ this.getRoleLabel('owner') }}</strong>
					</div>
					<div class="access-description">
						{{ this.getRoleDescription('owner') }}
					</div>
				</mat-radio-button>
				<mat-radio-button value="maintainer">
					<div class="role-name">
						<strong>{{ this.getRoleLabel('maintainer') }}</strong>
					</div>
					<div class="access-description">
						{{ this.getRoleDescription('maintainer') }}
					</div>
				</mat-radio-button>
				<mat-radio-button value="member">
					<div class="role-name">
						<strong>{{ this.getRoleLabel('member') }}</strong>
					</div>
					<div class="access-description">
						{{ this.getRoleDescription('member') }}
					</div>
				</mat-radio-button>
				<mat-radio-button value="orgSupport" *ngIf="this.getRoleLabel('orgSupport')">
					<div class="role-name">
						<strong>{{ this.getRoleLabel('orgSupport') }}</strong>
					</div>
					<div class="access-description">
						{{ this.getRoleDescription('orgSupport') }}
					</div>
				</mat-radio-button>
				<mat-radio-button value="orgBilling" *ngIf="this.getRoleLabel('orgBilling')">
					<div class="role-name">
						<strong>{{ this.getRoleLabel('orgBilling') }}</strong>
					</div>
					<div class="access-description">
						{{ this.getRoleDescription('orgBilling') }}
					</div>
				</mat-radio-button>
			</mat-radio-group>
		</div>
	</mat-dialog-content>

	<div class="action-buttons ctw-text-right ctw-mt-8">
		<button mat-button type="button" (click)="this.dialogRef.close()">
			Close
		</button>
		<progress-button color="{{
				this.configurationService.config.buttonColor || 'primary'
			}}" [state]="state">Update</progress-button>
	</div>
</form>

<form (ngSubmit)="this.update()" class="imh" *ngIf="this.configurationService.config.brandConfig.id === 'imh'">
	<h2>
		User Permissions
	</h2>
	<h4 class="ctw-mt-8">
		{{
		data.membership.account
		? data.membership.account.name
		: data.membership.invite_email
		}}
	</h4>
	<p class="error error-message" *ngIf="this.state === 'failed'">
		{{ this.error }}
	</p>
	<mat-dialog-content class="dialog-content">
		<div class="ctw-pl-6">
			<mat-radio-group [(ngModel)]="this.role" name="role">
				<mat-radio-button value="owner">
					<div class="role-name ctw-ml-2">
						<strong>{{ this.getRoleLabel('owner') }}</strong>
					</div>
					<div class="access-description ctw-ml-2 ctw-text-imh-gray-200">
						{{ this.getRoleDescription('owner') }}
					</div>
				</mat-radio-button>
				<mat-radio-button value="maintainer">
					<div class="role-name ctw-ml-2">
						<strong>{{ this.getRoleLabel('maintainer') }}</strong>
					</div>
					<div class="access-description ctw-ml-2 ctw-text-imh-gray-200">
						{{ this.getRoleDescription('maintainer').split('.')[0]
						}}<br />
						{{
						this.getRoleDescription('maintainer').split('.')[1]
						}}
					</div>
				</mat-radio-button>
				<mat-radio-button value="member">
					<div class="role-name ctw-ml-2">
						<strong>{{ this.getRoleLabel('member') }}</strong>
					</div>
					<div class="access-description ctw-ml-2 ctw-text-imh-gray-200">
						{{ this.getRoleDescription('member').split('.')[0]
						}}<br />
						{{ this.getRoleDescription('member').split('.')[1] }}
					</div>
				</mat-radio-button>
			</mat-radio-group>
		</div>
	</mat-dialog-content>

	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button mat-button type="button" (click)="this.dialogRef.close()">
			Cancel
		</button>
		<progress-button color="{{
				this.configurationService.config.buttonColor || 'primary'
			}}" [state]="state" class="ctw-ml-3">Update Role</progress-button>
	</div>
</form>
