<div *ngIf="this.loadedChat" class="ctw-w-full ctw-text-center ctw-pt-8"><span class="ctw-text-lg">Need help deciding?
	</span>
	<button mat-raised-button color="primary" (click)="this.openChat()" class="ctw-ml-2">
		Chat Now
	</button>
</div>
<mat-spinner *ngIf="!this.isReady" class="component-loader ctw-mt-16 ctw-pt-8" diameter="50"
	color="primary"></mat-spinner>
<plan-selector #planSelector value="{{ this.getInitialValue() }}" [altDisplay]="true"
	[couponData]="this.projectCreateService.couponData" itemCodeArray="{{ this.getItemCodeArray() }}"
	[dataCenter]="this.projectCreateService.getSelections().dataCenter"
	(handleSingleSelectionCoupon)="handleSingleSelectionCoupon()" (enableNext)="this.enableNext = $event"
	(planSelectorReady)="this.isReady = true" (requirePlanSelection)="this.requirePlanSelection($event)"
	class="ctw-mt-16"></plan-selector>
<div *ngIf="this.isReady" class="actions ctw-mt-16 ctw-mx-16 ctw-flex ctw-justify-between">
	<a mat-stroked-button href="{{ this.projectCreateService.pricingPage }}">
		Back
	</a>
	<button mat-button mat-raised-button color="primary" [disabled]="!this.enableNext"
		(click)="this.select()">Next</button>
</div>
