import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
	Input
} from '@angular/core';
import { ProjectService } from '../../../project.service';
import { PlanSelectorComponent } from '../plan-selector/plan-selector.component';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-hosting',
	templateUrl: './hosting.component.html',
	styleUrls: ['./hosting.component.scss'],
})
export class HostingComponent implements OnInit {
	@ViewChild('planSelector') public planSelector: PlanSelectorComponent;
	@Input() isUpdatePlan = false;
	@Input() term = 'yearly';
	@Input() couponData;
	@Input() public dataCenter = 'lax';
	@Output() hostingComponentReady = new EventEmitter();
	@Output() enableNext = new EventEmitter();

	public state = 'loading';
	public hasServer;
	public currentPlan: string;
	public subscriptionUuid;
	public termType = this.term;
	public veid;
	public dataCenters = [];

	constructor(public projectService: ProjectService, private apiService: ApiService) {}

	ngOnInit(): void {
		this.setHasServer();
	}

	public setHasServer() {
		this.projectService.getBillingSubscription.subscribe({
			next: (subscription) => {
				const active = subscription['state'] === 'active' || subscription['state'] === 'canceled';
				const addons = subscription['subscription_add_ons'].find(
					(addon) => {
						if (addon.add_on_code.startsWith('wphosting_tier')) {
							this.currentPlan = addon.add_on_code.replace('wphosting_tier_', '');
							this.subscriptionUuid = subscription['uuid'];
							this.termType = subscription['plan_code'] === 'wpc_project_monthly' ? 'monthly' : 'yearly';
							return true
						}
					}
				);

				if (active && addons) {
					this.hasServer = true;
					this.apiService
						.get('/v1/vps/server/info', {
							veid: this.projectService.environment.fields.machine_details.veid
						})
						.subscribe(
							(response) => {
								this.veid = response;
								this.state = 'success';
								setTimeout(() => { this.isReady(); }, 2000);
							},
							(error) => {
								this.state = 'success';
							}
						);
				}
				else {
					this.state = 'success';
					this.isReady();
				}
			},
			error: (error) => {
				// 403 - doesn't have access to subscription, this will be caught before the user lands on this page.
				// missing subscriotion - the user should select a plan.
                // console.log(error);
				this.state = 'success';
			},
		});
	}

	public isReady() {
		this.hostingComponentReady.emit();
		this.dataCenters = this.planSelector?.dataCenters || [];
	}
}
