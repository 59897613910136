<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16 ctw-mx-auto">
	<h2 class="ctw-text-left">Let's Get Started</h2>
	<div class="ctw-mb-8 ctw-text-left">What would you like to do with this {{ this.environment?.fields.environment_type
		=== 'vps' ? 'Production' : 'Playground' }} Environment?</div>

	<mat-progress-bar *ngIf="this.blueprintsStatus === 'none'" mode="indeterminate" color="primary"></mat-progress-bar>
	<dash-card
		*ngIf="(this.configService.hasFeature('blueprints') && (this.mode === 'new'  || this.mode === 'blueprint')) || this.mode === 'confirm-new'"
		[clean]="true" [showHeader]="this.mode !== 'confirm-new'"
		[cardTitle]="this.mode === 'new' ? 'Deploy a Blueprint?' : 'Build From Scratch?'"
		[icon]="this.mode === 'new' ? 'grid_guides' : 'construction'" disabled="true" class="ctw-mb-16">
		<ng-container *ngIf="this.mode === 'new'">
			<p *ngIf="this.blueprintsStatus !== 'none'" class="ctw-my-4 ctw-px-8">
				Our Blueprints are designed to simplify the configuration process for this new environment. Select from
				one of the site types below and we’ll automatically deploy the themes, plugins, and
				configurations necessary for a successful launch!
			</p>
			<div class="site-setup-option ctw-grid ctw-grid-cols-3 ctw-mb-4">
				<div *ngFor="let blueprint of this.blueprints"
					class="ctw-grid ctw-grid-cols-1 ctw-my-5 ctw-m-8 ctw-text-center"
					(click)="this.openBlueprint(blueprint)">
					<img src="/assets/img/blueprints/blueprint-hero-{{ this.getConfigValue(blueprint.configuration,
						'img') }}-small.png" />
					<strong>{{ blueprint.label }}</strong>
				</div>
			</div>
		</ng-container>
		<div *ngIf="this.mode === 'confirm-new'" class="ctw-grid ctw-grid-cols-1 ctw-mt-4">
			<h3 class="ctw-mt-3">Ok, you're all set!</h3>
			<p class="ctw-mb-8">
				We've already installed a fresh copy of the latest WordPress on
				your server. It’s been fine-tuned for performance and lean
				speed. All you need to do is start creating!
			</p>
		</div>
	</dash-card>
	<dash-card
		*ngIf="(this.mode === 'new' || this.mode === 'confirm-manual') && this.environment?.fields.environment_type === 'vps'"
		[showHeader]="true" cardTitle="Migrate a Site" icon="unarchive" disabled="true" class="ctw-mb-8">
		<div *ngIf="this.mode === 'new'" class="site-setup-option ctw-mb-10" (click)="this.mode = 'confirm-manual'">
			<!--<mat-icon>build</mat-icon>-->
			<h3>Copy Your Content From Another WordPress Yourself</h3>
			<p>Best for large or ecommerce sites</p>
		</div>
		<div *ngIf="this.mode === 'confirm-manual'" class="ctw-grid ctw-grid-cols-1 ctw-mt-4">
			<h4>Awesome, we're looking forward to it!</h4>
			<p class="ctw-mb-0">
				We've already installed a fresh copy of the latest WordPress on
				your server. All you need to do now to migrate your site’s files
				and data is to use your preferred method of transferring. You
				can use <span class="ctw-inline-flex"><a
						href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/projects/migrate-wordpress-manually"
						target="_blank">Plugins, sFTP and other command line options</a><mat-icon
						class="ctw-ml-1 ctw-w-3 ctw-mt-1 ctw-mr-1 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
			</p>
			<p class="alert ctw-text-base ctw-mb-8 ctw-text-left">
				<strong>IMPORTANT:</strong> Your Ultrastack VPS comes
				provisioned with PHP 8.2. If the site you are migrating is not
				running on PHP 8.2 or higher, we recommend that you first
				<a routerLink="configure">change your PHP version</a> to
				match the version where you are migrating from before
				proceeding. You can always switch back to PHP 8.2 or higher
				after you’ve confirmed compatibility with your site.
			</p>
			<div class="ctw-flex ctw-justify-between">
				<div>
					<button mat-stroked-button mat-ripple type="button" routerLink="configure" class="ctw-mr-2">
						Change PHP Version
					</button>
				</div>

			</div>
		</div>
	</dash-card>
	<div *ngIf="this.mode === 'new' && this.blueprintsStatus !== 'none'" class="ctw-text-right">
		<button mat-button mat-stroked-button color="secondary" routerLink="../" class="">
			Do This Later
		</button>
		<span class="ctw-mx-4">OR</span>
		<button mat-button mat-ripple mat-raised-button color="primary" type="button"
			(click)="this.mode = 'confirm-new'">
			<mat-icon class="ctw-mr-2">construction</mat-icon> Build It From Scratch
		</button>
	</div>
	<div *ngIf="this.mode === 'confirm-new'" class="ctw-text-right">
		<button mat-button mat-ripple type="button" (click)="this.mode = 'new'" class="ctw-mr-4">
			Back
		</button>
		<central-progress-button mat-raised-button color="primary" (click)="setUsage('New Site')">Let's
			Go</central-progress-button>
	</div>
	<div *ngIf="this.mode === 'confirm-manual'" class="ctw-text-right">
		<button mat-button mat-ripple type="button" (click)="this.mode = 'new'" class="ctw-mr-4">
			Back
		</button>
		<central-progress-button mat-raised-button color="primary" (click)="setUsage('Manual Migrate')">Ok,
			Continue</central-progress-button>
	</div>
</div>
