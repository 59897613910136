import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProfileService } from '../../../authentication/services/profile/profile.service';
import { merge } from 'rxjs';

export interface Organization {
  organization_id: string;
  [key: string]: any;
}
@Component({
	selector: 'central-default-org-action',
	templateUrl: './default-org-action.component.html',
	styleUrls: ['./default-org-action.component.scss'],
})
export class DefaultOrgActionComponent implements OnInit {
	@Input() organization: Organization;
  @Output() defaultOrgId: EventEmitter<string> = new EventEmitter<string>();

	private _default_org_id: string;

	constructor(
		private profileService: ProfileService,
		private snackbar: MatSnackBar,
	) {}

	public get account_id() {
		return this.profileService.data?.account_access?.find(
			(access) => access.type === 'user',
		)?.account_id;
	}

  public get default_org_id() {
    return this._default_org_id;
  }

  ngOnInit(): void {
    this.fetchDefaultOrg();
  }

  /**
   * Update the user's default organization account option.  Open a snackbar to display success or failure.
   * @param {void} none
   * @returns {void} none
   */
	updateDefaultOrg() {
		this.profileService
			.update({
				public_default_org_id: this.organization.organization_id,
			})
			.subscribe({
				next: () => {
					this.openSnackBar(
						'Default organization updated successfully',
						'Close',
						{ duration: 2000, panelClass: 'ctw-text-white' },
					);
				},
				error: () => {
					this.openSnackBar(
						'Unable to update your default organization at this time',
						'',
						{ duration: 2000 },
					);
				},
			});
	}

  /**
   * Wrapper for the MatSnackBar.open method
   * 
   * @param message 
   * @param action 
   * @param options 
   */
	openSnackBar(message: string, action: string, options?: MatSnackBarConfig) {
		this.snackbar.open(message, action, options);
	}

	/**
	 * Fetch the default organization from the user's account options when profile is ready or updated.
	 *
	 * @param {void} none
	 * @returns {void} none
	 */
	private fetchDefaultOrg() {
		merge(
			this.profileService.onReady(),
			this.profileService.onProfileUpdate,
		).subscribe(() => {
			const fallback = this.profileService.data?.account_access?.find((access) => access.type === 'organization')?.account_id ?? null;
			this._default_org_id =
				this.profileService.data?.options?.public_default_org_id ?? fallback;

      this.defaultOrgId.emit(this._default_org_id);
		});
	}
}
