import { Component, Inject, Injectable } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '@central/ng-shared';
import { AppService } from '../../../../app.service';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss'],
})
@Injectable()
export class ConfirmationDialogComponent {
	public status = 'pending';
	public errorMessage: string;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		public snackBar: MatSnackBar,
		public apiService: ApiService,
		public appService: AppService,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	activateAutoRenewal(): void {
		const observable = this.apiService.post(
			'/v1/wpcr/subscriptions/' +
				this.data.subscription.uuid +
				'/auto-renew',
			{
				headerOptions: {
					xOrgId: this.data.organizationId,
				},
			}
		);
		this.process(observable);
	}

	cancelAutoRenewal(): void {
		const observable = this.apiService.delete(
			'/v1/wpcr/subscriptions/' +
				this.data.subscription.uuid +
				'/auto-renew',
			{
				headerOptions: {
					xOrgId: this.data.organizationId,
				},
			}
		);
		this.process(observable);
	}

	removeAddon(): void {
		const observable = this.apiService.delete(
			'/v1/wpcr/subscriptions/' +
				this.data.subscription.uuid +
				'/addon/' +
				this.data.addon.add_on_code,
			{
				headerOptions: {
					xOrgId: this.data.organizationId,
				},
			}
		);
		this.process(observable);
	}

	process(observable) {
		this.errorMessage = '';
		this.status = 'submitted';

		observable.subscribe({
			next: (subscription: any) => {
				this.status = 'success';
				this.appService.showConfirmation('Subscription Updated Successfully!')
				this.dialogRef.close(subscription);
			},
			error: (error) => {
				this.errorMessage = this.apiService.getPublicError(error);
				this.status = 'failed';
			},
		});
	}

	getRenewalDate(subscription): string {
		const now = new Date();
		const startedAt = new Date(subscription.current_term_started_at);
		return now < startedAt
			? subscription.current_term_started_at
			: subscription.current_term_ends_at;
	}
}
