<central-header *ngIf="'success' !== this.status" title="{{ this.projectService.project?.label }}" titleIcon="{{ this.projectService.project?.fields?.project_type
}}" status="this.status" breakpoint="6xl"></central-header>

<ng-container *ngIf="'success' === this.status">
	<central-projects *ngIf="!this.isEnvironmentRoute"></central-projects>
	<central-environment [isProjectRoute]="this.isEnvironmentRoute"></central-environment>
	<ng-container *ngIf="!this.projectService.hasEnvironments()">
		<router-outlet *ngIf="this.isEnvironmentRoute"></router-outlet>
	</ng-container>
</ng-container>
