import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AuthService, ProfileService } from '@central/ng-shared';
import { BrandingService } from '@central/ng-shared';

@Component({
	selector: 'upgrade-dialog',
	templateUrl: 'upgrade-dialog.component.html',
})
export class UpgradeDialogComponent {
	/**
	 * @param matDialogRef Dialog Component.
	 */
	public constructor(
		public matDialogRef: MatDialogRef<UpgradeDialogComponent>,
		public authService: AuthService,
		public brandingService: BrandingService,
		private router: Router,
		public profileService: ProfileService,
	) {
		this.matDialogRef.disableClose = true;
	}

	/**
	 * Go to the dashboard.
	 *
	 * @since 1.23.0
	 */
	public goToDashboard(): void {
		this.router.navigateByUrl('/connect-key');
		this.matDialogRef.close();
	}
}
