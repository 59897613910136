<ng-container *ngIf="data.canLeave">
	<h2 *ngIf="!data.isPrivate" mat-dialog-title class="ctw-capitalize">
		Are You Sure?
	</h2>
	<mat-dialog-content class="mat-typography">
		<p>
			You will lose access to all Projects, Domains and Email managed by
			this team <strong>{{ data.teamName }}</strong>.
		</p>
		<p class="error" *ngIf="this.errorMessage">{{ this.errorMessage }}</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end" class="ctw-mt-10 ctw-pb-5">
		<button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
		<span class="spacer"></span>
		<central-progress-button color="warn" [disabled]="false" [state]="state" (click)="leaveTeam()">Yes,
			Continue</central-progress-button>
	</mat-dialog-actions>
</ng-container>
<ng-container *ngIf="!data.canLeave">
	<h2 mat-dialog-title class="ctw-capitalize">Unable To Leave</h2>
	<mat-dialog-content class="mat-typography">
		<p>
			You are currently the only Manager of <strong>{{ data.teamName }}</strong>.<br />Every
			{{
			this.configurationService.config.groupLabels[
			'group-label-lower-case'
			]
			}}
			must have at least one Manager.
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end" class="ctw-mt-10 ctw-pb-5">
		<button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>
			OK
		</button>
	</mat-dialog-actions>
</ng-container>
