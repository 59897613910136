import { countries } from 'typed-countries';

export class ContactModel {
	constructor(
		public firstName?: string,
		public lastName?: string,
		public dialingCode: string = '1',
		public phone?: string,
		public companyName?: string,
		public email?: string,
		public address1?: string,
		public address2?: string,
		public city?: string,
		public postalCode?: string,
		public country = 'US',
		public state?: string,
		public taxId?: string
	) {}

	public update(data: object) {
		this.firstName = data['first_name'];
		this.lastName = data['last_name'];
		this.phone = data['phone'];
		this.companyName = data['company_name'];
		this.email = data['email'];
		this.address1 = data['address1'];
		this.address2 = data['address2'];
		this.city = data['city'];
		if(data.hasOwnProperty('dialingCode')){
			this.dialingCode = data['dialingCode'];
		}
		if(data.hasOwnProperty('taxId')){
			this.taxId = data['taxId'];
		}
		this.postalCode = data['postal_code'];
		this.country = data['country'] ? data['country'] : 'US';
		this.state = data['state'];
	}

	public clear() {
		this.firstName = '';
		this.lastName = '';
		this.phone = '';
		this.companyName = '';
		this.email = '';
		this.address1 = '';
		this.address2 = '';
		this.city = '';
		this.dialingCode = '1';
		this.postalCode = '';
		this.country = 'US';
		this.state = '';
		this.taxId = '';
	}

	public getData() {
		return {
			first_name: this.firstName,
			last_name: this.lastName,
			company_name: this.companyName,
			email: this.email,
			dialing_code: this.dialingCode,
			tax_id: this.taxId,
			phone: this.phone,
			address1: this.address1,
			address2: this.address2,
			city: this.city,
			postal_code: this.postalCode,
			country: this.getCountryCode(this.country),
			state: this.state,
		};
	}

	/**
	 * Given a country name, get the country code.
	 *
	 * @since 1.26.0
	 *
	 * @param countryName Full country name.
	 * @return code Country ISO
	 */
	public getCountryCode(countryName: string): string {
		let countryCode = 'GB' === countryName ? 'UK' : '';
		if (countryName && 2 !== countryName.length) {
			const countryObj = countries.find(
				(country: object) => country['name'] === countryName
			);

			if (countryObj) {
				countryCode = countryObj.iso;
			}
		} else {
			countryCode = countryName;
		}

		return countryCode;
	}
}
