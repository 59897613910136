import { Directive } from '@angular/core';
import {
	AbstractControl,
	Validator,
	NG_VALIDATORS,
	ValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import data from './data';

@Directive({
	selector: '[centralFreeEmailValidator]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CheckFreeDirective,
			multi: true,
		},
	],
})
export class CheckFreeDirective implements Validator {
	public validate(control: AbstractControl): ValidationErrors | null {
		const validator = this.check();
		return validator(control);
	}

	protected check(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			let forbidden = false;
			if (control.value) {
				forbidden = data.freeDomains.includes(
					control.value.split('@')[1]
				);
			}
			return forbidden ? { freeEmail: { value: control.value } } : null;
		};
	}
}
