import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'central-guide',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss', './welcome.scss'],
})
export class StepperComponent implements OnChanges {
	@Input() public step = 1;
	@Input() public steps = [];
	@Input() public logoLink = "/";
	@Input() public canExit = false;
	@Input() public enableStepNavigation = false;
	@Input() public stepOneImage =
		'https://inmotionhosting.github.io/static-assets/hero/sidebar/lights.jpg';
	@Input() public alternativeTitleSteps = [];

	@ViewChild('componentWrap') public componentWrap;

	public washout = false;

	public constructor(public router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.triggerOutletAnimation();
			}
		});
	}

	ngOnChanges(changes) {
		if (
			changes.step &&
			changes.step.currentValue === 2 &&
			changes.step.previousValue === 1
		) {
			this.triggerWashout();
		}
	}

	public triggerOutletAnimation() {
		if (this.componentWrap) {
			this.componentWrap.nativeElement.classList.remove('fadeInUpSmall');
			this.componentWrap.nativeElement.classList.add('ctw-hidden');

			setTimeout(() => {
				this.componentWrap.nativeElement.classList.remove('ctw-hidden');
				this.componentWrap.nativeElement.classList.add('fadeInUpSmall');
			});
		}
	}

	onLastStep() {
		return false;
	}

	public triggerWashout() {
		if (this.washout) {
			return;
		}

		this.washout = true;
		setTimeout(() => {
			setTimeout(() => {
				this.washout = false;
			}, 250);
		}, 500);
	}

	public showStepOneSidebar() {
		return this.step === 1 || this.washout;
	}

	public getStepConfig() {
		return this.steps[this.step - 1];
	}

	public isFirstStep() {
		return this.step === 1;
	}

	public isLastStep() {
		return this.step === this.steps.length;
	}

	public activateStep(step) {
		if (step.stepNumber < this.step) {
			this.router.navigateByUrl(step.route);
		}
	}

	public getTitle() {
		let title = this.steps[this.step - 1].title;
		if (this.alternativeTitleSteps?.includes(this.step - 1)) {
			title = this.steps[this.step - 1].alt_title;
		}

		if ( /<\/?[a-z][\s\S]*>/i.test(title) ) {
			title = `<div>${title}</div>`;
		} else {
			title = `
				<h2 class="step__title">
					${title}
				</h2>
				<p class="step__description">
					${this.steps[this.step - 1].description}
				</p>`;
		}
		return title;
	}
}
