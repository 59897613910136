<h2>UltraStack Version</h2>

<div>
	<p *ngIf="!this.ansibleService.override && this.hasUpdates" class="ctw-flex">
		<mat-icon class="ctw-text-red-700 ctw-mr-3">warning</mat-icon>
		Your environment is running an older version of our software stack.
	</p>
	<p *ngIf="this.hasUpdates">
		<strong>The latest version is:
			<span class="ctw-text-green-700">{{
				this.ansibleService.latestVersion
				}}</span></strong>
	</p>
	<p *ngIf="!this.ansibleService.override && !this.hasUpdates" class="ctw-flex ctw-text-xl ctw-my-6">
		<mat-icon class="ctw-text-green-700 ctw-mr-3">task_alt</mat-icon> Your server's software is up to date!
	</p>
	<p *ngIf="this.ansibleService.override && !this.hasUpdates" class="ctw-flex ctw-text-xl ctw-my-6">
		<mat-icon class="ctw-text-yellow-700 ctw-mr-3">warning</mat-icon> Your server's software is Unmanaged!
	</p>
	<p>
		By default, we manage your server's software automatically through our UltraStack configurations, allowing you
		to effortlessly make adjustments on the fly.
	</p>
	<div class="alert">
		<span *ngIf="this.ansibleService.override">You've previously requested for our management of
			your server's software and configurations to be disabled. As a result, some functionality in your Project
			will be
			limited.
		</span>
		<span *ngIf="!this.ansibleService.override">
			If you've made any custom modifications to the server stack (Nginx, Apache, PHP etc...) or plan to, you may
			want to disable management to prevent the possibility of having your custom modifications reverted. This
			will cause some functionality in your Project to be limited.
		</span>
	</div>
</div>

<div class="ctw-flex ctw-justify-{{this.ansibleService.override ? 'end' : 'between'}} ctw-mt-10">
	<central-progress-button type="stroked" *ngIf="!this.ansibleService.override" [state]="state" color="warn"
		[mat-dialog-close]="'toggle-override'">Disable Management</central-progress-button>
	<span>
		<button [mat-dialog-close]="false" mat-button>Cancel</button>
		<central-progress-button *ngIf="this.ansibleService.override" [state]="state" color="primary"
			[mat-dialog-close]="'toggle-override'" class="ctw-ml-3">Resume
			Management</central-progress-button>
		<button *ngIf="!this.ansibleService.override" mat-raised-button color="primary" class="ctw-ml-3"
			[mat-dialog-close]="'run'">
			{{ this.hasUpdates ? 'Update Now' : 'Force Update' }}
		</button>
	</span>
</div>
