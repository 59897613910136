import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { exhaustMap, first, timeout } from 'rxjs/operators';

export interface PollingOptions {
	interval: number;
	timeout: number;
}

const POLLING_OPTIONS_DEFAULT: PollingOptions = {
	interval: 20000,
	timeout: 300000, // 5min
};

@Injectable({
	providedIn: 'root',
})
export class PollingService {
	private abort = false;

	constructor() { }

	startPoll<T>(
		functionToPoll: () => Observable<T>, // function to begin polling.
		stopPollingCondition: (value: T) => boolean, // condition to stop polling.
		options: PollingOptions = POLLING_OPTIONS_DEFAULT // polling options.
	): Observable<T> {
		this.abort = false;
		return interval(options.interval).pipe(
			exhaustMap(() => functionToPoll()),
			first((value) => stopPollingCondition(value) || this.abort),
			timeout(options.timeout)
		);
	}

	stopPoll(): void {
		this.abort = true;
	}
}
