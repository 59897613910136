import { NgModule } from '@angular/core';

import { AuthenticationModule, CoreModule } from '@central/ng-shared';

import { SharedModule } from '../shared/shared.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { DomainModule } from '../domain/domain.module';

import { EmailComponent } from './email.component';
import { EmailNewComponent } from './email-new/email-new.component';
import { EmailSubscriptionManageComponent } from './email-subscription-manage/email-subscription-manage.component';
import { EmailSubscriptionSetupComponent } from './email-subscription-setup/email-subscription-setup.component';

import { EmailRoutingModule } from './email.routes';

@NgModule({
	declarations: [
		EmailComponent,
		EmailNewComponent,
		EmailSubscriptionManageComponent,
		EmailSubscriptionSetupComponent,
	],
	imports: [
		CoreModule,
		SharedModule,
		EmailRoutingModule,
		AuthenticationModule,
		CheckoutModule,
		DomainModule,
	],
})
export class EmailModule {}
