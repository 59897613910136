import { Component, OnInit, ViewChild } from '@angular/core';
import { ScriptService } from '../../core/script/script.service';
import { ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../../core';

@Component({
	selector: 'central-recurly-credit-card',
	templateUrl: './recurly-credit-card.component.html',
	styleUrls: ['./recurly-credit-card.component.scss'],
})
export class RecurlyCreditCardComponent implements OnInit {
	@ViewChild('field') public field;

	public publicKey = 'ewr1-oNfZOxUwJ86yKMiq9ob02V';
	public recurly;
	public elements;
	public cardElement;
	public address;
	public error;

	constructor(
		public configurationService: ConfigurationService,
		public scriptService: ScriptService,
		private ref: ChangeDetectorRef
	) {
		this.publicKey = this.configurationService.config.recurly.publicKey;
	}

	ngOnInit(): void {
		this.loadScript();
	}

	public getInfo() {
		return this.cardElement.state;
	}

	public getToken(address) {
		return new Promise((resolve, reject) => {
			// Create temporary inputs for address info from passed info.
			this.address = address;
			this.ref.markForCheck();

			setTimeout(() => {
				// Exchange cc info for a token.
				this.recurly.token(
					this.elements,
					this.field.nativeElement,
					(error, token) => {
						if (error) {
							reject(error);
						} else {
							resolve(token);
						}
					}
				);
			});
		});
	}

	private loadScript() {
		this.scriptService.load('recurly').then(() => {
			this.recurly = window['recurly'];
			this.recurly.configure(this.publicKey);
			this.elements = this.recurly.Elements();
			if (this.configurationService.config.brandConfig.id === 'imh') {
				this.cardElement = this.elements.CardElement({
					style: {
						backgroundColor: 'transparent',
						fontFamily: 'Montserrat',
						fontSize: '14px',
					}
				});
			} else {
				this.cardElement = this.elements.CardElement();
			}
			this.cardElement.attach('#recurly-elements');
		});
	}
}
