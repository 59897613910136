<ng-container *ngIf="this.status !== 'failed' && !this.data?.error">
	<h2 class="ctw-capitalize">Are You Sure?</h2>
	<p *ngIf="data.warning">{{ data.warning }}</p>
	<ng-container *ngIf="data.requireNameConfirmation">
		<p>
			Confirm this action by typing the {{ data.label || 'item' }}'s name:
			<strong class="notranslate">{{ data.nameConfirmation }}</strong>
		</p>
		<mat-form-field appearance="outline">
			<mat-label>{{ data.label || 'Item' }} Name</mat-label>
			<input autocomplete="off" #confirmationInput="ngModel" matInput name="confirmation"
				[(ngModel)]="this.confirmation" required />
			<mat-hint class="error" *ngIf="
					this.confirmation?.toLowerCase().trim() !==
						data?.nameConfirmation?.toLowerCase().trim() &&
					confirmationInput.dirty &&
					confirmationInput.touched
				">
				The entered value does not match your
				{{ data.label || 'item' }} name.
			</mat-hint>
		</mat-form-field>
	</ng-container>
	<div class="actions ctw-text-right ctw-mt-10">
		<button mat-button class="" [disabled]="status === 'submitted'" (click)="this.matDialogRef.close()">
			Cancel
		</button>
		<central-progress-button class="right ctw-ml-3" [state]="this.status" [disabled]="
				data.requireNameConfirmation &&
				this.confirmation?.toLowerCase().trim() !==
					data?.nameConfirmation?.toLowerCase().trim()
			" (click)="this.doDelete()" color="warn">Yes, Continue</central-progress-button>
	</div>
</ng-container>

<!-- Error is passed into delete dialog. -->
<ng-container *ngIf="this.data?.error">
	<h2 class="ctw-capitalize">Unable To Delete</h2>
	<p [innerHTML]="this.data.error"></p>
	<div class="actions ctw-text-right ctw-mt-10">
		<button mat-raised-button class="right" color="primary" (click)="this.matDialogRef.close()">
			OK
		</button>
	</div>
</ng-container>

<!-- Error performing delete from dialog. -->
<ng-container *ngIf="this.status === 'failed' && !this.data?.error">
	<h2 class="ctw-capitalize">Unable To Delete</h2>
	<p>We were not able to delete your item. Please try again later.</p>
	<div class="actions ctw-text-right ctw-mt-10">
		<button mat-raised-button class="right" color="primary" (click)="this.matDialogRef.close()">
			OK
		</button>
	</div>
</ng-container>