import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
	ApiService,
	ProfileService,
	ConfigurationService,
} from '@central/ng-shared';

@Component({
	selector: 'central-leave-team-dialog',
	templateUrl: './leave-team-dialog.component.html',
	styleUrls: ['./leave-team-dialog.component.scss'],
})
export class LeaveTeamDialogComponent {
	public state = 'pending';
	public errorMessage = '';

	constructor(
		private apiService: ApiService,
		public profileService: ProfileService,
		public configurationService: ConfigurationService,
		public dialogRef: MatDialogRef<LeaveTeamDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	public leaveTeam() {
		this.errorMessage = '';
		this.state = 'submitted';
		this.apiService
			.delete(
				`/v1/organizations/` +
					this.data.teamId +
					'/memberships/' +
					this.data.membershipId,
				{}
			)
			.subscribe({
				next: () => {
					this.state = 'success';
					this.profileService.fetch();
					this.dialogRef.close();
				},
				error: (error) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
			});
	}
}
