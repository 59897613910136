import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../../../project.service';
import { NgForm } from '@angular/forms';
import { AnsibleService } from '../../services/ansible.service';
import { AppService } from 'apps/central/src/app/app.service';
import { ProfileService } from '@central/ng-shared';
interface Profile {
	value: string;
	viewValue: string;
	description: string;
}

@Component({
	selector: 'central-nginx-profile',
	templateUrl: './nginx-profile.component.html',
	styleUrls: ['./nginx-profile.component.scss'],
})
export class NginxProfileComponent implements OnInit, OnDestroy {

	public state = 'loading';
	public available = true;

	profiles: Profile[] = [
		{
			value: 'no_caching',
			viewValue: 'No Caching',
			description:
				'Disables NGINX page caching. Brute-force ratelimit protection, and static content acceleration are still enabled.',
		},
		{
			value: 'wordpress',
			viewValue: 'WordPress',
			description:
				'General-purpose caching profile with wide compatibility for most WordPress and WooCommerce websites. For best results, be sure to use a plugin such nginx-helper to manage cache purging when pages and posts are updated.',
		},
		{
			value: 'wordpress_multisite',
			viewValue: 'WordPress Multisite',
			description:
				'Cache profile for WordPress Multisite or Network installations. Contains the same configuration options as the WordPress profile, but with Accelerate Static Content disabled to allow Apache to perform the required path rewrites.',
		},
		{
			value: 'wordpress_microcache',
			viewValue: 'WordPress Microcache',
			description:
				'Same as the main WordPress profile, but with a 2 second cache time. This is ideal for highly dynamic sites where long-term full page caching may not be viable. However, microcaching can help mitigate the effects of big traffic spikes on specific page.',
		},
		{
			value: 'pass_all',
			viewValue: 'Pass All',
			description:
				'Passes all traffic directly to Apache without any optimizations, caching, or ratelimit protection. If you only wish to disable caching, it is recommended to use the No Caching profile instead.',
		},
		{
			value: 'honor_headers',
			viewValue: 'Honor Headers',
			description:
				'Honors the Cache, Expires, and X-Accel-Expires headers set by the application. If not set, a default 60 second expiration time is used. All other values match the WordPress/Generic profile.',
		},
	];

	setProfile: string =
		this.projectService.environment?.fields?.playbook_values
			?.nginx_cache_profile || 'wordpress';
	selectedProfile: string =
		this.projectService.environment?.fields?.playbook_values
			?.nginx_cache_profile || 'wordpress';
	selectedDescription: string;
	isRunningPlaybook: boolean | null = null;
	hasSubmittedForm = false;
	error: string;
	interval;
	profile: string | null = null;
	projectId: string = this.projectService.project.id;
	nonPublishingStates = ['success', 'fail'];
	ready = false;

	requestState: string;

	private subscriptions = [];

	constructor(
		public projectService: ProjectService,
		private appService: AppService,
		private profileService: ProfileService,
		private ansibleService: AnsibleService
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((msg) => {
						// TODO: Disallow functionality if container is stopped.
						if (this.projectService.environmentState !== 'pending') {
							this.subscriptions.push(
								this.ansibleService.playbookStatus.subscribe((data) => {
									if (data === 'complete') {
										// Loaded info needed for view.
										this.projectService.reloadProject(false).subscribe(() => {
											this.setDescription();
											this.setProfile =
												this.projectService.environment.fields.playbook_values
													?.nginx_cache_profile || 'wordpress';
											this.state = 'success';
											this.available = true;
										});
									} else {
										if (data === 'pending') {
											this.setDescription();
											this.state = 'success';
											this.available = true;
										} else {
											// Playbook is running or playbooks are disabled.
											this.state = data === 'playbook-overridden' ? 'disabled' : 'unavailable';
											this.available = false;
										}
									}
								})
							)
						}
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	getSeleted(): string {
		return this.profiles.find(
			(profile) => profile.value === this.selectedProfile
		).viewValue;
	}

	setDescription(): void {
		this.selectedDescription = this.profiles.find(
			(profile) => profile.value === this.selectedProfile
		).description;
	}

	onProfileSelect(profile): void {
		this.setDescription();
	}

	updateProfile(submission: NgForm) {
		this.requestState = 'submitted';
		this.hasSubmittedForm = true;
		this.isRunningPlaybook = true;
		this.profile = submission.form.value.profile;
		// If saved value is the same as what's being submitted.
		const storedProfile =
			this.projectService.environment.fields.playbook_values
				?.nginx_cache_profile || 'wordpress';

		/*
		if (storedProfile === submission.form.value.profile) {
			this.requestState = 'success';
			this.isRunningPlaybook = false;
			return;
		}*/

		this.ansibleService.runPlaybook({
			nginx_cache_profile: submission.form.value.profile,
			tags: ['profile']
		}).subscribe({
			next: () => {
				this.requestState = 'success';
				this.appService.showConfirmation('Your Cache Profile is being rolled out to your server!')
			},
			error: () => {
				this.requestState = 'failed';
				this.appService.showConfirmation('An error occured applying your Cache Profile configuration. Please try again.')
			}
		});
	}
}
