import { Injectable } from '@angular/core';
import Rollbar from 'rollbar';
import { ConfigurationService } from '../../core/configuration.service';

@Injectable()
export class RollbarService {
	public rollbar;

	constructor(public configurationService: ConfigurationService) {
		const rollbarConfig = this.configurationService.config.rollbar;

		if ( this.configurationService.config.brandConfig.id === 'imh' ) {
			const host = this.configurationService.config.host.match(/(?<=\/\/)(.*?)(?=\.)/gm)[0];
			let env = 'dev-' + host;

			switch ( host ) {
				case 'pdt-wp-api':
					env = 'staging';
					break;
				case 'api-wpc':
					env = 'production';
			}

			rollbarConfig.environment = env;
			rollbarConfig.source_map_enabled = true;
			rollbarConfig.guess_uncaught_frames = true;
			rollbarConfig.payload = {
				environment: env,
				client: {
					javascript: {
						source_map_enabled: true,
						guess_uncaught_frames: true,
						code_version: this.configurationService.config.gitConfig.hash,
					}
				},
				server: {
					root: 'webpack:///./',
					branch: 'main',
				}
			}
		}

		this.rollbar = new Rollbar(rollbarConfig);
	}
}
