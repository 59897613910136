import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectService } from '../../../../project.service';
import { AnsibleService } from '../../../services/ansible.service';
import { AppService } from 'apps/central/src/app/app.service';

@Component({
	selector: 'central-site-health',
	templateUrl: './site-health.component.html',
	styleUrls: ['./site-health.component.scss'],
})
export class SiteHealthComponent implements OnInit, OnDestroy {

	@Input() public env: string;

	@Output() public connect = new EventEmitter();
	@Output() updatesPending = new EventEmitter();

	public state = 'loading';
	public request = 'pending';

	public stats;
	public updates = 0;
	public recommendations = 0;
	public hasUpdates = false;
	public keyStats = []
	public updateThemes = [];
	public updatePlugins = [];
	public summary = '';

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		public appService: AppService,
		public projectService: ProjectService,
		public configService: ConfigurationService,
		private dialog: MatDialog,
		private ansibleService: AnsibleService
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.projectService.onClientConnected.subscribe((client) => {
				if (client === 'ready') {
					if (this.env === 'vps') {
						this.subscriptions.push(
							this.ansibleService.playbookStatus.subscribe((msg: any) => {
								if (msg === 'pending' || msg === 'complete' || msg === 'playbook-overridden') {
									this.checkSite();
								}
							})
						)
					} else {
						this.checkSite();
					}
				}
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	public checkSite() {
		this.subscriptions.push(
			this.projectService.getSiteStats(false).subscribe(
				(stats: any) => {
					this.stats = stats;
					this.state = 'success';
					this.hasUpdates = this.checkHasUpdates(
						this.projectService.project.fields.project_type
					);
					this.updatesPending.emit(this.hasUpdates);
				},
				(error) => { },
			)
		)
	}

	/**
	 * Does this user have pending updates.
	 */
	public checkHasUpdates(application): boolean {
		this.keyStats = [];
		this.updateThemes = [];
		this.updatePlugins = [];

		switch (application) {
			case 'wordpress':
				this.checkThemeUpdates(application);
				this.checkPluginUpdates(application);
				this.getHealthSummary(application);

				this.updates += this.updateThemes.length;
				this.updates += this.updatePlugins.length;

				return !!(
					this.hasCoreUpdate(application) ||
					this.updateThemes.length ||
					this.updatePlugins.length
				);
				break;
		}
	}

	/**
	 * Does the user need a WP update.
	 */
	public hasCoreUpdate(application): boolean {
		if (application === 'wordpress') {
			if (this.stats) {
				this.keyStats = [{
					name: 'WordPress Version',
					value: this.stats['debug']['wp-core'].fields.version.value.split('(')[0]
				}, {
					name: 'PHP Version',
					value: this.stats['debug']['wp-server'].fields.php_version.value.split(' ')[0]
				}]
				return this.stats['debug']['wp-core']['fields']['version'][
					'value'
				].match('Latest version');
			}
		}
	}

	/**
	 * Determine which themes need updates.
	 */
	public checkThemeUpdates(application): void {
		if (application === 'wordpress') {
			const themes = this.getInactiveThemes(application);
			themes.push(this.stats['debug']['wp-active-theme'].fields);

			for (const theme of themes) {
				const test = theme['version']
					? theme['version'].value
					: theme['value'];

				if (test.match('Latest version')) {
					this.updateThemes.push(theme);
				}
			}
		}
	}

	/**
	 * Determine which plugins need updates.
	 */
	public checkPluginUpdates(application): void {
		if (application === 'wordpress') {
			let plugins = Object.values(
				this.stats['debug']['wp-plugins-inactive'].fields
			);
			plugins = plugins.concat(
				Object.values(this.stats['debug']['wp-plugins-active'].fields)
			);

			for (const plugin of plugins) {
				const test = plugin['version']
					? plugin['version'].value
					: plugin['value'];

				if (test.match('Latest version')) {
					this.updatePlugins.push(plugin);
				}
			}
		}
	}

	/**
	 * Get a list of inactive themes.
	 *
	 * @return Themes.
	 */
	public getInactiveThemes(application): any[] {
		if (application === 'wordpress') {
			let themes = [];
			if (this.stats['debug']['wp-themes-inactive']) {
				themes = Object.values(
					this.stats['debug']['wp-themes-inactive'].fields
				);
			}
			return themes;
		}
	}

	/**
	 * Remove theme and plugin slugs from label
	 *
	 * @return clean name
	 */
	public stripSlug(name: string): string {
		return name.replace(/\(.*\)/, '');
	}

	/**
	 * Remove extra metadata from theme and/or plugin label
	 *
	 * @return clean name
	 */
	public cleanVersion(name: string): string {
		return name
			.replace(/ – .*Version /, 'v')
			.replace(/ - Version /, 'v')
			.replace(/ by .*\(/, ' <span class="ctw-mb-1 ctw-ml-2 ctw-text-sm mat-stroked-button ctw-px-2 ctw-bg-green-200">')
			.replace(/Latest version:/, 'Latest:')
			.replace(/\).*$/, '</span><br />');
	}

	/**
	 * Get the number of active plugins.
	 */
	public countPlugins(application) {
		if (application === 'wordpress') {
			return Object.entries(this.stats['debug']['wp-plugins-active'].fields)
				.length;
		}
	}

	/**
	 * Get text for site health summary.
	 *
	 * @return Site health summary.
	 */
	public getHealthSummary(application): string {
		if (application === 'wordpress') {
			const sort = {};
			for (const test of this.stats['health']) {
				sort[test.status] = sort[test.status] || 0;
				sort[test.status]++;
			}

			const getSummary = (type: string) => `${sort[type]} ${type} issue${sort[type] > 1 ? 's' : ''}`;

			let summary: string;

			if (this.projectService.configService.hasFeature('IMC-683')) {
				if (sort['critical']) {
					this.updates += sort['critical'] - (this.hasCoreUpdate(application) ? 1 : 0);
					this.recommendations = sort['critical'];
					this.summary = sort['critical'] + ' Critical';
				} else if (sort['recommended']) {
					this.recommendations = sort['recommended'];
					this.summary = sort['recommended'] + ' Minor';
				} else {
					this.summary = "0 Health";
				}
			} else {
				if (sort['critical']) {
					summary = getSummary('critical');
				} else if (sort['recommended']) {
					summary = getSummary('recommended');
				} else {
					summary = "Everything's looking good!";
				}
			}

			return summary;
		}
	}
}
