<mat-dialog-content>
	<div class="notranslate">
		<h3 *ngIf="this.configService.config.brandConfig.id !== 'imh'">Login as User</h3>
		<h2 *ngIf="this.configService.config.brandConfig.id === 'imh'" class="ctw-flex">
			<mat-icon class="ctw-relative ctw-text-red-700 ctw-mr-2">
				admin_panel_settings
			</mat-icon>
			Login as User
		</h2>
		<p>
			Logging in as this user will allow to access the application as if you were 
			the user. You'll still have administrative control over their account. While 
			logged in as the user, you will be logged out of your own account. All of your
			actions will be recorded.
		</p>
		<p>You will need to use the VPN to log in as a user.</p>
		<p>
			Would you like to log in as <strong>{{ this.data.email }}</strong>?
		</p>
		<p *ngIf="this.errorMessage" class="error">
			Error: {{ this.errorMessage }}
		</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-p-0 ctw-pb-5 notranslate">
	<button mat-button mat-dialog-close>Cancel</button>
	<progress-button [state]="state" (click)="loginAsUser()" class="ctw-ml-3"
		color="{{ this.configService.config.brandConfig.id === 'imh' ? 'primary' : 'accent' }}">
		Continue
	</progress-button>
</mat-dialog-actions>
