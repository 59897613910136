import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'central-contextual-help-dialog',
	templateUrl: './contextual-help-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualHelpDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public sanitizer: DomSanitizer
	) { }

	ngOnInit(): void {
		this.data.after_video = this.sanitizer.bypassSecurityTrustHtml(this.data?.after_video?.replace(/<\/a>/g, ' <mat-icon class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined" style="margin-top: 1px">open_in_new</mat-icon></a>'));
	}

	sanitizeUrl(url): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
