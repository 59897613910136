import { AfterViewInit, Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ScriptService, ApiService, ConfigurationService } from '../core';
import { ProfileService, AuthService, } from '../authentication/services';
import { MessageComponent } from './message/message.component';
import { SupportService } from './support.service';
import team from './data';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '../account';
@Component({
	selector: 'central-main',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, OnDestroy, AfterViewInit {
	public people = team;
	public currentTeam;
	public teamStatus = 'loading';
	public hasPremiumSupport = false;
	public tickets = [];
	public cloudId: string;
	public style = '';
	public dialogRef: MatDialogRef<MessageComponent>;

	@HostBinding('class.inmotion')
	public hasMH = false;

	private subscriptions = [];

	constructor(
		public scriptService: ScriptService,
		public configService: ConfigurationService,
		public supportService: SupportService,
		public profileService: ProfileService,
		public dialog: MatDialog,
		public authService: AuthService,
		public apiService: ApiService,
		public activeRoute: ActivatedRoute,
		public router: Router,
		public accountService: AccountService,
	) {
		this.style =
			this.configService.config.brandConfig.applicationName ===
			'OpenMetal Central'
				? 'openmetal'
				: '';
		this.hasMH = this.configService.hasFeature('managedHosting');
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	ngOnInit(): void {
		if (this.configService.config.brandConfig.id === 'imh') {
			this.subscriptions.push(
				this.accountService.authAccountUpdate.subscribe(() => {
					this.fetchUsers();
					this.cloudId = this.supportService.getCloud()
						? this.supportService.getCloud().id
						: null;
					this.performQueryActions();
					this.hasPremiumSupport = !!(this.supportService.getCloud()
						? this.supportService.getCloud().fields.support_plan
						: null);
				})
			)
		} else {
			this.fetchUsers();
			this.cloudId = this.supportService.getCloud()
				? this.supportService.getCloud().id
				: null;
			this.performQueryActions();
			this.hasPremiumSupport = !!(this.supportService.getCloud()
				? this.supportService.getCloud().fields.support_plan
				: null);
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const element = document.getElementById(
				this.activeRoute.snapshot.fragment
			);
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest',
				});
			}
		}, 1000);
	}

	public performQueryActions() {
		if ('new-ticket' === this.activeRoute.snapshot.queryParams.action) {
			this.openDialog();
		}
	}

	public openDialog(userSetting = null) {
		this.dialogRef = this.dialog.open(MessageComponent, {
			disableClose: true,
			width: this.configService.config.brandConfig.id === 'imh' ? '500px' : '600px',
			data: {
				userSetting,
				cloudId: this.cloudId,
				cloud: this.supportService.getCloud(),
				priorities: this.supportService.ticketPriorities,
				typeOptions: this.configService.config.zendeskTicketTypes,
				selectedType: this.configService.config.zendeskDefaultType,
				inputs: this.configService.config.zendesk.inputs,
				account: this.supportService.getAccount(),
				hasMH: this.configService.hasFeature('managedHosting'),
			},
		});
		this.dialogRef.componentInstance.submitTicket.subscribe((ticket_data) => {
			this.submitTicket(ticket_data);
		});
	}

	public fetchUsers() {
		this.apiService
			.get('/v1/zendesk/user-groups', {})
			.subscribe((users: any[]) => {
				const currentTeam = {};
				users.map((group) => {
					group.group_name = group.group_name.replace(
						'Business Lead',
						'Account Manager'
					);

					currentTeam[group.group_name] = {
						group_id: group.group_id,
						position: group.group_name.replace(/.*-\s/, ''),
						group_name: group.group_name,
						requiresPremium:
							true && this.configService.config.hasPremiumSupport,
						user: group.users[
							Math.floor(Math.random() * group.users.length)
						],
						users: group.users,
					};
				});

				this.teamStatus = 'success';
				this.currentTeam = currentTeam;
			});
	}

	public getIcon() {
		let icon = 'chat';
		if (
			!this.hasPremiumSupport &&
			this.configService.config.hasPremiumSupport
		) {
			icon = 'star_rate';
		}

		return icon;
	}
	public fixImage(group_name) {
		this.currentTeam[group_name].user.photo.content_url =
			'https://central.inmotionhosting.com/assets/icons/user.svg';
		this.currentTeam[group_name].user.photo.failed = true;
	}

	public submitTicket(ticket_data) {
		this.dialogRef.componentInstance.state = 'submitted';
		this.supportService.submitTicket(ticket_data)
			.subscribe({
				next: (val) => {
					if (val.hasOwnProperty('ticket_id')) {
						this.dialogRef.componentInstance.state = 'success';
					} else {
						this.dialogRef.componentInstance.state = 'failed';
						if (400 === val['status']) {
							this.dialogRef.componentInstance.state = 'ratelimited';
						}
					}
				},
				error: () => {
					this.dialogRef.componentInstance.state = 'failed';
				},
			});
	}
}
