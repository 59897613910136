import { Injectable } from '@angular/core';

@Injectable()
export class InvalidInputService {
	/**
	 * Check valid input.
	 *
	 * @since 1.1
	 *
	 * @param    status Current Status of the form.
	 * @return         Should the input display an error.
	 */
	public isInvalid(inputEl: any, status?: string): boolean {
		const dirty =
			((inputEl.dirty && inputEl.touched) || 'failed' === status) &&
			!inputEl.valid;
		return dirty && 'submitted' !== status;
	}
}
