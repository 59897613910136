<div class="ctw-grid ctw-mt-3">
	<div class="ctw-text-base">
		<span *ngIf="diag[0]?.diagResultMessage" class="ctw-font-bold">{{diag[0].diagResultMessage}}<br></span>
		<span *ngIf="diag[0]?.diagFixMessage">{{diag[0].diagFixMessage}}</span>
	</div>
	<div *ngIf="diag[0]?.input" class="action ctw-mt-10 ctw-mx-auto ctw-max-w-4xl ctw-w-full ctw-text-right">
		<ng-container *ngFor="let input of diag[0].input">
			<button *ngIf="
					input.type === 'button' &&
					input.action === 'link' &&
					input.label !== 'Update Site URL' &&
					(input.label.includes('Register') || input.label.includes('DNS'))
				" (click)="this.router.navigate([input.href])" mat-raised-button color="primary" [mat-dialog-close]="true"
				class="ctw-ml-3">
				{{ input.label }}
			</button>
			<button *ngIf="
					input.type === 'button' &&
					input.action === 'link' &&
					input.label !== 'Update Site URL' &&
					!input.label.includes('Register')
				" (click)="this.router.navigate([input.href])" mat-stroked-button color="primary" [mat-dialog-close]="true"
				class="ctw-ml-3">
				{{ input.label }}
			</button>
			<mat-form-field *ngIf="input.type === 'text' && input.action === 'input'" appearance="standard"
				floatLabel="always">
				<mat-label>{{ input.label }}</mat-label>
				<input matInput type="text" />
			</mat-form-field>
			<central-domain-select *ngIf="input.type === 'domain-select'" #centralDomainSelect
				[context]="this.environment.fields.organization_id" [allowManual]="true"
				[hostname]="this.environment.fields.hostname"
				(domainInputChange)="updateDomain($event)"></central-domain-select>

			<central-progress-button *ngIf="input.action === 'submit' && getSubmitInput()"
				class="ctw-ml-4 ctw-mt-5 ctw-text-red-700" type="stroked" color="" (click)="submitFix()"
				[disabled]="disableFix()" [state]="this.state">
				<mat-icon *ngIf="this.state !== 'submitted'" class="ctw-mr-1">troubleshoot</mat-icon>
				{{
				this.state === 'submitted'
				? 'Attempting repairs...'
				: getSubmitInput().label
				}}</central-progress-button>
		</ng-container>
	</div>
</div>
