<mat-progress-bar *ngIf="status === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="status === 'failed'"></failed-request>

<ng-container *ngIf="status === 'success'">

	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<h2>
			<a href="{{ this.projectService.environment.fields.site_url }}" target="_blank">{{
				env === 'vps' ?
				this.projectService.environment.fields.site_url.split('/')[2].replace('.inmotionhosting.com', '') :
				this.projectService.environment.fields.site_url.split('/')[3] }} <mat-icon
					class="ctw-text-base ctw-absolute ctw-ml-2 material-symbols-outlined"
					style="margin-top: 18px">open_in_new</mat-icon></a>
		</h2>

		<div class="details animate__animated animate__fadeIn" *ngIf="containerStatus || env !== 'vps'">
			<p class="status" [hidden]="true">
				Server Status:
				<span class="indicator running" *ngIf="containerStatus === 'running' || env !== 'vps'">Running</span>
				<span class="indicator" *ngIf="containerStatus === 'stopping'">Stopping</span>
				<span class="indicator" *ngIf="containerStatus === 'starting'">Starting</span>
				<span class="indicator" *ngIf="containerStatus === 'restarting'">Restarting</span>
				<span class="indicator stopped" *ngIf="containerStatus === 'stopped'">Stopped</span>
				<span class="indicator stopped" *ngIf="containerStatus === 'reclaimed'">Reclaimed</span>
			</p>
			<p class="domain"
				*ngIf="this.projectService.environment.fields?.package_code && containerStatus !== 'stopped' && containerStatus !== 'reclaimed'">
				Server Size: <strong [innerHTML]="this.containerSpec"></strong> <span
					*ngIf="this.configService.hasFeature('resize') && containerStatus === 'running' && (this.ansibleService.state === 'complete' || this.ansibleService.state === 'playbook-overridden')"
					class="ctw-text-imh-blue ctw-ml-2 ctw-cursor-pointer"
					[routerLink]="[ '/projects', this.projectService.project.id, 'resize', 'server' ]"
					[queryParams]="{ environment_id: this.projectService.environment.id }">[<a>resize</a>]</span>
			</p>
			<p class="domain">
				Hostname: <central-text-copy>{{this.getHostname()}}</central-text-copy>
			</p>
			<p class="domain">
				IP Address: <central-text-copy>{{this.getIp()}}</central-text-copy>
			</p>
			<p class="domain ctw-flex">
				<span>Region: </span>
				<span class="flag" [class.us]="this.projectService.environment.fields?.data_center === 'lax'
						|| this.projectService.environment.fields?.data_center === 'iad'
						|| !this.projectService.environment.fields?.data_center
						|| this.env === 'playground'" [class.eu]="this.projectService.environment.fields?.data_center === 'ams'"></span>
				<strong>
					<span
						*ngIf="this.projectService.environment.fields?.data_center === 'lax' || this.env === 'playground'">US
						West</span>
					<span
						*ngIf="this.projectService.environment.fields?.data_center === 'iad' || (this.env === 'vps' && !this.projectService.environment.fields?.data_center)">US
						East/Central</span>
					<span *ngIf="this.projectService.environment.fields?.data_center === 'ams'">EU Central</span>
				</strong>
			</p>
		</div>

		<central-diagnostic [env]="this.env" [url]="this.projectService.environment?.fields.site_url"
			[containerStatus]="this.containerStatus"></central-diagnostic>
	</div>
</ng-container>
