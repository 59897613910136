<central-header *ngIf="this.configService.config.brandConfig.id === 'imh'" title="All User Accounts" actions="agent"
	[status]="state" (action)="this.header($event)">
</central-header>

<div
	class="{{ this.configService.config.brandConfig.id === 'imh' ? 'content animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16' : '' }}">
	<div class="usage-header" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
		<h2>All Users</h2>
		<button mat-raised-button (click)="this.fetch()">
			<mat-icon>refresh</mat-icon> Refresh
		</button>
	</div>

	<!-- Load State -->
	<mat-progress-bar *ngIf="'submitted' === this.state" mode="indeterminate"></mat-progress-bar>

	<!-- Success State -->
	<div class="main-content">
		<div *ngIf="state === 'fail' && this.configService.config.brandConfig.id !== 'imh'" class="failed">
			<h3 class="error">Failed to load users.</h3>
			<button mat-raised-button (click)="this.fetch()">Reload</button>
		</div>

		<div class="user_table">
			<div class="search">

				<mat-form-field *ngIf="this.configService.config.brandConfig.id === 'imh'" appearance="outline"
					floatLabel="always">
					<mat-label>{{ this.searchLabel }}</mat-label>
					<mat-icon matPrefix>search</mat-icon>
					<input matInput type="text" placeholder="{{ this.searchPlaceholder }}" [(ngModel)]="searchBox"
						(keyup)="search($event)" />
					<mat-error *ngIf="domainValidator?.invalid" [innerHTML]="getErrorMessage()"></mat-error>
					<progress-button color="primary" (click)="this.fetch()" [disabled]="this.state === 'submitted'"
						class="ctw-absolute ctw-right-0 ctw-top-0">{{ this.state
						=== 'submitted' ? 'Searching...' :
						'Search' }}</progress-button>
				</mat-form-field>

				<mat-form-field *ngIf="this.configService.config.brandConfig.id !== 'imh'"
					appearance="{{ this.configService.config.inputAppearance || 'fill' }}" class="ctw-w-full ctw-pt-4">
					<mat-label>{{ this.searchLabel }}</mat-label>
					<mat-icon matPrefix>search</mat-icon>
					<input matInput type="text" placeholder="{{ this.searchPlaceholder }}" [(ngModel)]="searchBox"
						(keyup)="search($event)" />
				</mat-form-field>
			</div>
			<table mat-table [dataSource]="data" matSort
				[hidden]="this.configService.config.brandConfig.id === 'imh' && this.state !== 'success'"
				[class.ctw-mt-8]="this.configService.config.brandConfig.id === 'imh'">
				<ng-container matColumnDef="account_id" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let element">
						{{ element.account_id }}
					</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>NAME</th>
					<td mat-cell *matCellDef="let element" class="ctw-w-4/12">
						<a class="id_link" [routerLink]="['/agent/user', element.account_id]">{{ element.name }}</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
					<td mat-cell *matCellDef="let element">{{ element.email }}</td>
				</ng-container>

				<ng-container matColumnDef="phone" *ngIf="this.configService.config.brandConfig.id !== 'imh'">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>PHONE</th>
					<td mat-cell *matCellDef="let element">{{ element.phone }}</td>
				</ng-container>

				<ng-container matColumnDef="phone" *ngIf="this.configService.config.brandConfig.id === 'imh'">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ONBOARDED</th>
					<td mat-cell *matCellDef="let element">
						<mat-icon
							class="ctw-text-{{ element.paid === 'Yes' ? 'green' : 'red' }}-700 ctw-ml-8 ctw-mt-3">{{
							element.paid === 'Yes' ?
							'task_alt' : 'horizontal_rule' }}</mat-icon>
					</td>
				</ng-container>

				<ng-container matColumnDef="created_at">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						SIGNUP DATE
					</th>
					<td mat-cell *matCellDef="let element" class="ctw-w-1/4">
						<span *ngIf="this.configService.config.brandConfig.id === 'imh'"
							innerHTML="{{ element.created_at | amDateFormat : 'llll' | customCallback: multiLineDate }}"></span>
						<span *ngIf="this.configService.config.brandConfig.id !== 'imh'">{{ element.created_at |
							amDateFormat : 'LLLL' }}</span>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
			</table>
			<mat-paginator [length]="this.paginator_count" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 500]"
				[showFirstLastButtons]="true" (page)="this.fetch()"
				*ngIf="this.configService.config.brandConfig.id !== 'imh' || (this.configService.config.brandConfig.id === 'imh' && this.state === 'success')"></mat-paginator>
		</div>

		<div *ngIf="state === 'fail' && this.configService.config.brandConfig.id === 'imh'" class="failed">
			<h3 class="error">Failed to load users.</h3>
		</div>
	</div>
</div>
