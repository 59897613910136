<div
	class="onboarding"
	[class.exit-washout]="this.onLastStep()"
	[class.intro-animations]="this.showStepOneSidebar()"
	[class.step1-transistion]="washout"
>
	<div
		class="navigation animate__animated"
		[class.alternate]="this.showStepOneSidebar()"
		[style.background-image]="
			this.showStepOneSidebar() ? 'url(' + this.stepOneImage + ')' : ''
		"
	>
		<div
			class="animate__animated animate__fadeIn step-1-sidebar"
			*ngIf="this.showStepOneSidebar()"
		>
			<div class="animate__animated fadeInUpSmall">
				<div class="welcome-message">
					<div class="logo">
						<a [href]="this.logoLink">
							<ng-content select="[logo]"></ng-content>
						</a>
					</div>
					<ng-content select="[name]"></ng-content>
				</div>
			</div>
		</div>
		<div
			*ngIf="!this.showStepOneSidebar()"
			class="animate__animated animate__fadeIn"
		>
			<div class="logo">
				<a [href]="this.logoLink">
					<ng-content select="[logo-dark]"></ng-content>
				</a>
			</div>
			<ul class="navigation__items">
				<ng-container *ngFor="let menuStep of this.steps">
					<li
						class="step-nav-item"
						[class.step-nav-enabled]="this.enableStepNavigation"
						[class.active]="menuStep.stepNumber === step"
						[class.completed]="this.step > menuStep.stepNumber"
						(click)="
							this.enableStepNavigation &&
								this.activateStep(menuStep)
						"
					>
						<span class="step-number">
							{{ menuStep.stepNumber }}</span
						>
						<mat-icon
							class="done-check"
							*ngIf="this.step > menuStep.stepNumber"
							>done</mat-icon
						>
						<span class="step-name">{{ menuStep.label }}</span>
						<span class="step-info" *ngIf="menuStep?.data">
							{{ menuStep?.data || '' }}</span
						>
						<mat-icon
							*ngIf="menuStep.stepNumber === step"
							class="step-pointer animate__animated animate__slideInLeft"
							>keyboard_arrow_right</mat-icon
						>
					</li>
				</ng-container>
			</ul>
		</div>
		<div class="footer-graphic" *ngIf="!this.showStepOneSidebar()">
			<span class="triangle-foreground triangle"></span>
			<span class="triangle-background triangle"></span>
		</div>
		<span class="washout animate__animated"></span>
	</div>
	<div class="top-nav">
		<span class="ctw-flex ctw-items-center">
			<ng-content select="[logo-top]"></ng-content>
		</span>
		<div class="ctw-text-right">
			<div>Step {{ this.step }} of {{ this.steps.length }}</div>
			<div class="ctw-font-bold mobile-step-name">
				{{ this.steps[this.step - 1].label }}
			</div>
		</div>
	</div>
	<div class="steps">
		<div
			#componentWrap
			class="animate__animated fadeInUpSmall"
			[hidden]="this.washout"
		>
			<button
				class="return"
				mat-button
				*ngIf="this.steps.length != this.step && this.canExit"
			>
				<a [routerLink]="['/']" mat-button color="primary">
					<mat-icon>keyboard_arrow_left</mat-icon
					><mat-icon>keyboard_arrow_left</mat-icon>
					<span>Setup Later</span>
				</a>
			</button>
			<ng-content select="[beforeContent]"></ng-content>
			<div class="ctw-text-center ctw-pt-8">
				<div [innerHTML]="this.getTitle()"></div>
			</div>
			<div class="ctw-mt-10 ctw-mb-20 ctw-mx-16">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
