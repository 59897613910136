<central-header title="Transaction History" [status]="this.status" actions="invoices"></central-header>

<layout-base [isLoading]="status === 'pending'" pageTitle="My Purchases" [failedLoading]="status === 'fail'">
	<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<mat-card *ngIf="
				status === 'success' &&
				receipts?.length === 0 &&
				invoices?.length === 0
			" class="no-messages ctw-text-center card-medium success card-back">
			<mat-icon class="ctw-text-imh-gray-200">receipt</mat-icon>
			<h2>No Transactions</h2>
			<p>All purchases for this Team will appear here.</p>
		</mat-card>
		<mat-card *ngIf="
				status === 'success' &&
				(receipts?.length !== 0 || invoices?.length !== 0)
			" class="success card-medium">

			<table class="bordered bg-table">
				<thead>
					<tr>
						<th class="ctw-text-left ctw-pb-4 ctw-capitalize">Invoice Number</th>
						<th class="ctw-text-right ctw-capitalize">Date Of Purchase</th>
						<th class="ctw-text-right ctw-capitalize">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let invoice of invoices">
						<td class="ctw-py-1">
							<a [routerLink]="[
									singleLink,
									invoice['invoice_number']
								]">{{ invoice.invoice_number }}</a>
						</td>
						<td class="ctw-text-right ctw-capitalize">{{ invoice.created_at | amDateFormat : 'LL' }}</td>
						<td>
							{{ this.moneyFormat(invoice.total_in_cents, invoice.currency) }}
						</td>
					</tr>
				</tbody>
			</table>
		</mat-card>
	</div>
</layout-base>
