import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProfileService } from '../../authentication/services';
import { ApiService, ConfigurationService } from '../../core';

@Component({
	selector: 'central-admin-settings',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
	public profileOptions;
	public allowBetaTesting = false;
	public state = 'pending';

	constructor(
		public profileService: ProfileService,
		public apiService: ApiService,
		public snackbar: MatSnackBar,
		public configService: ConfigurationService,
	) {}

	ngOnInit(): void {
		this.profileService.onReady().subscribe(() => {
			this.profileOptions = { ...this.profileService.data.options };
		});

		this.allowBetaTesting = this.configService.config?.beta
			&& JSON.stringify(this.configService.config?.beta) !== '{}';
	}
}
