import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '../core/api/api.service';
import { AuthService } from '../authentication/services/auth.service';
import { ConfigurationService } from '../core/configuration.service';
import { LeaveOrganizationDialogComponent } from './dialogs/leave-organization-dialog/leave-organization-dialog.component';

@Component({
	selector: 'central-organization',
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
	public status = 'start';
	public organizations: any[] = [];
	private _default_org_id: string = null;

	constructor(
		public apiService: ApiService,
		private authService: AuthService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public configurationService: ConfigurationService,
	) {}

	public get default_org_id() {
		return this._default_org_id;
	}

	public set default_org_id(value) {
		this._default_org_id = value;
	}

	public get hasDefaultOrgFeature() {
		return this.configurationService.config.features.default_org_selector ?? false;
	}

	ngOnInit(): void {
		this.fetchOrganizations();
	}

	private fetchOrganizations() {
		this.apiService.get('/v1/organizations/memberships', {}).subscribe({
			next: (organizations: any[]) => {
				this.organizations = organizations || [];

				organizations.map((organization) => {
					organization.role = organization.members.find(
						(element) =>
							element.account.account_id ===
							this.authService.getAccountId()
					).role;
				});

				this.status = 'success';
			},
			error: (error) => {
				this.status = 'failed';
			},
		});
	}

	public openLeaveDialog(organization) {
		const dialogRef = this.dialog.open(LeaveOrganizationDialogComponent, {
			maxWidth: '560px',
			data: {
				organizationId: organization.organization_id,
				organizationName: organization.name,
				membershipId: this.getMembershipId(organization),
				canLeave: this.isSoleOwner(organization),
			},
		});

		dialogRef.afterClosed().subscribe({
			next: () => {
				if ('success' === dialogRef.componentInstance.state) {
					this.fetchOrganizations();

					this.snackBar.open('Left Organization', '', {
						duration: 4000,
					});
				}
			},
		});
	}

	getRoleLabel(role: string) {
		return (
			this.configurationService.config.groupPermissions[role]?.label || ''
		);
	}

	// private openDeleteDialog( organization ) {
	// stub for future implementation
	// }

	private getMembershipId(organization): string {
		return organization.members.filter(
			(member) =>
				member.account.account_id === this.authService.getAccountId()
		)[0].membership_id;
	}

	private isSoleOwner(organization): boolean {
		const ownerCount = organization.members.filter(
			(member) => member.role === 'owner'
		).length;
		if (organization.role === 'owner' && ownerCount === 1) {
			return false;
		}
		return true;
	}
}
