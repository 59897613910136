import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core';
import { SupportService as BaseSupportService } from '../../support/support.service';
import { AccountService } from '../account.service';

@Injectable()
export class SupportService implements BaseSupportService {
	public constructor(
		public accountService: AccountService,
		public apiService: ApiService
	) {}

	public ticketPriorities = [
		{ value: 'normal', label: 'Normal' },
		{ value: 'urgent', label: 'Emergency' },
	];

	public getAccount() {
		return this.accountService.account;
	}

	public getCloud() {
		return;
	}
	public getAvailableClarityHours(hid) {
		return new Observable((observer) => {
			if (hid === undefined) {
				hid = this.getAccount()?.clarity_hid;
				if (hid === undefined) {
					observer.next(-1);
					observer.complete();
				}
			}
			this.apiService
				.get('/v1/clarity/get-available-time', { username: hid })
				.subscribe(
					(response) => {
						if (response['status'] === 'success') {
							observer.next((response['response']['available'] as number));
							observer.complete();
						} else {
							observer.next(-1);
							observer.complete();
						}
					},
					(error) => {
						observer.next(-1);
						observer.complete();
					}
				);
		});
	}

	public getSupportOptions(accountId = null) {
		return new Observable((observer) => {
			observer.next(this.ticketPriorities);
			observer.complete();
		});
	}

	public submitTicket(data) {
		return new Observable((observer) => {
			const account = this.getAccount();
			const accountId = account.organization_id || account.account_id;
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});
			const url = this.apiService.formatter.getUrl(
				`/v1/organizations/${accountId}/zendesk/ticket`
			);

			this.apiService.http.post(url, data, { headers }).subscribe({
				next: (res) => {
					observer.next(res);
				},
				error: (err) => {
					observer.error(err);
				},
			});
		});
	}
}
