<div class="ctw-w-full ctw-flex ctw-justify-center">
    <div class="ctw-w-full ctw-max-w-3xl ctw-flex ctw-justify-center">
        <div class="ctw-flex ctw-justify-around ctw-flex-col sm:ctw-flex-row sm:ctw-w-screen">
            <div *ngFor="let step of this.steps" class="ctw-flex ctw-text-md" (click)="handleStep(step)"
                [class]="getStepperClasses(step)">
                <div>
                    <mat-icon *ngIf="step.completed">check_circle</mat-icon>
                    <mat-icon *ngIf="step.completed === false">counter_{{step.step}}</mat-icon>
                </div>
                <div class="ctw-ml-1 ctw-underlinesemibold">
                    <span>{{step.title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>