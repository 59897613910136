<central-header title="Active Subscriptions" [status]="this.status" actions="subscriptions"
	[actionsEnabled]="billingSubscriptions.length"></central-header>

<layout-base pageTitle="Subscriptions" container="container-lg">

	<failed-request *ngIf="'failed' === this.status"></failed-request>

	<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-4xl ctw-mt-32 ctw-mb-16"
		*ngIf="'success' === this.status">
		<ng-container *ngIf="!billingSubscriptions.length">
			<mat-card class="no-content center-align">
				<div class="info ctw-text-center">
					<mat-icon class="ctw-text-imh-gray-200">inactive_order</mat-icon>
					<h2>No Active Subscriptions</h2>
					<p>All subscriptions for this Team will appear here.</p>
				</div>
			</mat-card>
		</ng-container>

		<ng-container *ngFor="let subscription of billingSubscriptions; index as i">
			<ng-container *ngIf="i === 0">
				<h2 class="ctw-capitalize">Next Renewal</h2>
			</ng-container>
			<ng-container>
				<div [class.ctw-px-8]="i > 0" class="team-content">
					<a name="{{subscription.uuid}}"></a>
					<mat-card [attr.uuid]="subscription.uuid" class="{{ subscription.state }} ctw-p-6">
						<h3 class="ctw-flex ctw-justify-between">
							<span class="notranslate ctw-flex">
								<ng-container *ngIf="subscription.state !== 'canceled'">
									<mat-icon class="ctw-text-imh-gray-200"
										*ngIf="subscription.plan_name === 'Domain Registration'">language</mat-icon>
									<mat-icon class="ctw-text-imh-gray-200"
										*ngIf="subscription.plan_name === 'Email Account'">alternate_email</mat-icon>
									<span *ngIf="subscription.plan_name.match('Connected Website')"><svg fill="#777777"
											version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px"
											viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
											class="ctw-relative wordpress-icon">
											<g id="SVGRepo_bgCarrier" stroke-width="0" />
											<g id="SVGRepo_tracerCarrier" stroke-linecap="round"
												stroke-linejoin="round" />
											<g id="SVGRepo_iconCarrier">
												<g>
													<path
														d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
												</g>
											</g>
										</svg></span>
								</ng-container>
								<mat-icon *ngIf="subscription.state === 'canceled'"
									class="ctw-align-bottom ctw-text-imh-gray-200">warning</mat-icon>
								<span *ngIf="!getProjectLabelById(
							subscription.custom_fields
							.project_id
							)?.label" class="ctw-mx-2 ctw-capitalize">{{
									subscription.plan_name }}</span>
								<span *ngIf="getProjectLabelById(
							subscription.custom_fields
							.project_id
						)?.label" class="ctw-mx-2"><span class="ctw-mr-1">Project</span> &middot; <span class="notranslate ctw-ml-1">{{
										getProjectLabelById(
										subscription.custom_fields
										.project_id
										).label
										}}</span></span>
								<span *ngIf="subscription.custom_fields.domain_name" class="notranslate">
									&middot; &nbsp;
									{{ subscription.custom_fields.domain_name }}</span>
								<span *ngIf="
							subscription.custom_fields.project_id &&
							getProjectLabelById(
								subscription.custom_fields.project_id
							)?.status === 'inactive'" class="ctw-text-red-700 ctw-text-sm ctw-ml-2"> (Project Deleted)</span>
							</span>
							<span class="parent-price"><span class="notranslate">{{
									currencyJs(subscription.total_amount_in_cents, {
									fromCents: true
									}).format()
									}}
									/
								</span>
								{{ this.getFormattedTerm(subscription.plan_interval_length + ' ' +
								subscription.plan_interval_unit_display) }}</span>
						</h3>
						<div class="ctw-flex ctw-justify-between">
							<p *ngIf="subscription.state !== 'canceled'">
								<span>Renews
									<span class="ctw-capitalize">{{
										this.getRenewalDate(subscription) | amDateFormat : 'LL'
										}}</span>
									&bull; auto renewal enabled</span>
								<a (click)="
								openDialog({
									action: 'cancel-auto-renewal',
									subscription: subscription
								})
							" class="ctw-ml-2">[disable]</a>
							</p>
							<p *ngIf="subscription.state === 'canceled' && subscription.custom_fields.project_id &&
								getProjectLabelById(
									subscription.custom_fields.project_id
								)?.status !== 'inactive' ">
								<span class="ctw-text-red-700">Expires
									{{
									this.getRenewalDate(subscription) | amDateFormat : 'LL'
									}}
									&bull; auto renewal disabled</span>
								<a (click)="
								openDialog({
									action: 'enable-auto-renew',
									subscription: subscription
								})
							" class="ctw-ml-2">[enable]</a>
							</p>
							<ng-container *ngIf="
									subscription.custom_fields.project_id &&
							getProjectLabelById(
								subscription.custom_fields.project_id
							).status === 'active'
						">
								<button mat-button mat-stroked-button color="primary"
									routerLink="/projects/{{subscription.custom_fields.project_id}}">
									Manage Project
								</button>
							</ng-container>
						</div>

						<div class="past-due" *ngIf="'past_due' === subscription.state">
							Your subscription is <strong>Past Due.</strong> Please
							update your billing information to keep your services
							active.
							<strong><a routerLink="/account/billing">Update now</a></strong>
						</div>
						<ng-container *ngIf="subscription.subscription_add_ons.length">
							<div class="add-on-container">
								<hr />
								<h4 class="ctw-bg-white ctw-capitalize ctw-relative ctw-w-1/5">Project Addons</h4>
								<ng-container *ngFor="
								let addon of subscription.subscription_add_ons
							">
									<p>
										<span class="notranslate">{{ addon.name.replace('WordPress Hosting',
											'UltraStack
											VPS')
											}}</span> &bull;
										<span class="notranslate">{{
											currencyJs(addon.unit_amount_in_cents, {
											fromCents: true
											}).format()
											}}</span>
										<a *ngIf="
										'active' === addon.state &&
										addon.add_on_code !== 'wpc_basic' &&
										addon.add_on_code !== 'wpc_premium_incl' &&
										!addon.add_on_code.match('wphosting') &&
										'canceled' !== subscription.state
									" (click)="
										openDialog({
											action: 'remove-addon',
											subscription: subscription,
											addon: addon
										})
									" class="ctw-ml-2">[remove]</a>
										<a *ngIf="
										'active' === addon.state &&
										addon.add_on_code.match('wphosting') &&
										'canceled' !== subscription.state
									" class="ctw-ml-2" routerLink="/projects/{{subscription.custom_fields.project_id}}/resize/server">[resize]</a>
										<ng-container *ngIf="
										'canceled' === addon.state ||
										'canceled' === subscription.state
									"><span class="ctw-ml-2">(removal pending)</span></ng-container>
									</p>
								</ng-container>
							</div>
						</ng-container>
					</mat-card>
				</div>
				<ng-container *ngIf="i === 0 && billingSubscriptions.length > 1">
					<div class="ctw-ml-8 ctw-mt-10 ctw-relative ctw-text-2xl">All Other Subscriptions</div>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</layout-base>
