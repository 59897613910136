<button
	mat-ripple
	mat-button
	[color]="color"
	class="btn-spinner"
	[disabled]="this.state === 'submitted' || this.disabled"
	[class.mat-stroked-button]="type === 'stroked'"
	[class.mat-raised-button]="type !== 'stroked'"
>
	<mat-spinner
		diameter="20"
		color="primary"
		*ngIf="this.state === 'submitted'"
	></mat-spinner>
	<ng-content></ng-content>
</button>
