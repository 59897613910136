<ng-container>
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8">
		<h2>Auto SSL</h2>
		<dash-card *ngIf="disabled" cardTitle="Auto SSL" icon="no_encryption" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-red-700">
						Disabled
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn ">
				<p class="ctw-mb-4">
					Auto SSL activation is pending. You have not changed your Site URL from it's pre-assigned
					setting. Once you set your Site URL, you will will have control over Auto SSL.
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form class="disabled" #form="ngForm" (ngSubmit)="setOverrideState(form.form)">
						<central-progress-button color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Pending Site URL Change
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
		<dash-card *ngIf="!disabled && enableSsl === false && playbookError === false" cardTitle="Auto SSL"
			icon="no_encryption" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-red-700">
						Disabled
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4">
					Auto SSL is currently disabled. Your site is currently using a <span class="ctw-inline-flex"><a
							target="_blank"
							href="https://www.inmotionhosting.com/support/website/ssl/self-signed-ssl-certificate-warning/">Self-Signed
							Certificate</a>
						<mat-icon
							class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					Enable Auto SSL to install a signed certifcate from LetsEncrypt and avoid browser warnings.
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form #form="ngForm" (ngSubmit)="setOverrideState(form.form)" [class.disabled]="!this.available">
						<mat-slide-toggle *ngIf="this.available" [(ngModel)]="enableSsl" name="enableSSL"
							color="primary" (toggleChange)="setOverrideState(form.form)"><span
								class="ctw-text-red-700">Disabled</span></mat-slide-toggle>
						<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
		<dash-card
			*ngIf="!disabled && this.projectService.environment.fields.ssl_enabled !== undefined && enableSsl === true && playbookError === false && playbookRunning === false"
			cardTitle="Auto SSL" icon="vpn_lock" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-green-700">
						Enabled
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4">
					Your LetsEncrypt SSL is installed and functioning. It will be automatically renewed every 3 months.
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form #form="ngForm" (ngSubmit)="setOverrideState(form.form)" [class.disabled]="!this.available">
						<mat-slide-toggle *ngIf="this.available" [(ngModel)]="enableSsl" name="enableSSL"
							color="primary" (toggleChange)="openDialogue()"><span
								class="ctw-text-green-700">Enabled</span></mat-slide-toggle>
						<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
		<dash-card
			*ngIf="!disabled && this.projectService.environment.fields.ssl_enabled !== undefined && enableSsl === false && playbookError === false && playbookRunning === true"
			cardTitle="Auto SSL" icon="no_encryption" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-red-700">
						In Progress
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4">
					Your SSL is currently being removed. This process may take a few minutes. You may need to <span
						class="ctw-inline-flex"><a target="_blank"
							href="https://www.inmotionhosting.com/support/website/how-to-clear-browser-cache-for-major-browsers/">clear
							your browser's cache</a>
						<mat-icon
							class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					after it
					has bee
					uninstalled.
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form #form="ngForm" (ngSubmit)="setOverrideState(form.form)" class="disabled">
						<central-progress-button *ngIf="this.available" color="primary" [disabled]="true"
							state="submitted"> Revoking Certificate... </central-progress-button>
						<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
		<dash-card
			*ngIf="!disabled && this.projectService.environment.fields.ssl_enabled !== undefined && enableSsl === true && playbookError === false && playbookRunning === true"
			cardTitle="Auto SSL" icon="lock" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-green-700">
						In Progress
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4">
					Your SSL is currently being issued by LetsEncrypt. This process may take a few minutes. You may need
					to
					<span class="ctw-inline-flex"><a target="_blank"
							href="https://www.inmotionhosting.com/support/website/how-to-clear-browser-cache-for-major-browsers/">clear
							your browser's cache</a><mat-icon
							class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					after it has
					bee
					installed.
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form #form="ngForm" (ngSubmit)="setOverrideState(form.form)" class="disabled">
						<central-progress-button *ngIf="this.available" color="primary" [disabled]="true"
							state="submitted"> Issuing Certificate... </central-progress-button>
						<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
		<dash-card
			*ngIf="!disabled && this.projectService.environment.fields.ssl_enabled !== undefined && playbookError"
			cardTitle="Auto SSL" icon="warning" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML>
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-red-700">
						Problem
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>
			<failed-request *ngIf="this.state === 'failed'"></failed-request>
			<div *ngIf="this.state === 'success' || this.state === 'unavailable'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4">
					A problem occurred while your SSL was being installed. Usually this is due to your DNS A record not
					being
					pointed or fully propagated, or sometimes may be caused by a conflict with a CDN. Learn more about
					<span class="ctw-inline-flex"><a target="_blank"
							href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/using-letsencrypt">troubleshooting
							LetsEncrypt</a>
						<mat-icon
							class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
				</p>
				<div class="ctw-text-right ctw-mt-5">
					<form #form="ngForm" (ngSubmit)="setOverrideState(form.form)" [class.disabled]="!this.available">
						<mat-slide-toggle *ngIf="this.available" [(ngModel)]="enableSsl" name="enableSSL"
							color="primary" (toggleChange)="setOverrideState(form.form)"><span
								class="ctw-text-red-700">Disabled</span></mat-slide-toggle>
						<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
							<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
						</central-progress-button>
					</form>
				</div>
			</div>
		</dash-card>
	</div>
</ng-container>
