// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class UserGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {}

	// A user must not be logged in in order to activate this action.
	// Otherwise: send the user home.
	public canActivate() {
		let canActivate = false;

		// Check to see if a user has a valid JWT
		if (!this.auth.isLoggedIn()) {
			// If they do, return true and allow the user to load the home component
			canActivate = true;
		} else {
			// If not, they redirect them to the login page
			setTimeout(() => {
				this.router.navigate([''], { replaceUrl: true });
			});
		}

		return canActivate;
	}
}
