import { Component, Input } from "@angular/core";
import { ConfigurationService } from "@central/ng-shared";


@Component({
	selector: 'op-receipt',
	templateUrl: 'op-receipt.component.html',
	styleUrls: [],
})
export class OpReceiptComponent{
	@Input() public ampButtonStatus: string;
	public constructor(public configService: ConfigurationService) {}
    public goToAccount() {
		window.location.href=this.configService.config.powerPanel.ampHost;
    }
}