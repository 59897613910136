<mat-dialog-content>
	<div [class.notranslate]="this.data.notranslate">
		<h2 class="ctw-flex">
			<mat-icon *ngIf="this.data.titleIcon"
				class="ctw-relative {{this.data.titleIconClass}} ctw-mr-2">{{this.data.titleIcon
				}}</mat-icon>
			{{this.data.title}}
		</h2>
		<div *ngIf="this.data.html" [innerHTML]="this.data.content" class="{{this.data.contentClass}}">
		</div>
		<p *ngIf="!this.data.html" class="{{this.data.contentClass}}">
			{{this.data.content}}
		</p>
		<mat-form-field appearance="outline" *ngIf="this.data.shouldCopy">
			<mat-label>{{this.data.itemToCopyName}}</mat-label>
			<input class="connect-key" matInput readonly [(ngModel)]="this.data.itemToCopy" />
			<button class="ctw-absolute ctw-top-0 ctw-right-0" mat-raised-button [matTooltip]="this.data.copyText"
				matTooltipPosition="above" (click)="copy()">
				Copy
			</button>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions
	class="ctw-flex ctw-justify-{{this.data.secondaryButton ? 'between' : 'end'}} ctw-mt-10 ctw-p-0 ctw-pb-5"
	[class.notranslate]="this.data.notranslate">
	<button *ngIf="this.data.secondaryButton" mat-ripple type="button" [mat-dialog-close]="false"
		class="mat-button {{this.data.secondaryButtonClass}}">
		{{this.data.secondaryButton}}
	</button>
	<span>
		<button *ngIf="this.data.warning || this.data.cancelButton" mat-ripple type="button" [mat-dialog-close]="false"
			class="mat-button {{this.data.cancelButtonClass}}">
			{{this.data.warning ? 'Cancel' : this.data.cancelButton}}
		</button>
		<button mat-ripple type="button" [mat-dialog-close]="true"
			class="mat-button {{this.data.warning ? 'mat-raised-button mat-warn' : this.data.confirmButtonClass}} ctw-ml-3">
			{{this.data.warning ? 'Yes, Continue' : this.data.confirmButton}}
		</button>
	</span>
</mat-dialog-actions>
