import {
	Component,
	OnDestroy,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { AuthService, ApiService, ProfileService } from '@central/ng-shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	templateUrl: './invite.component.html',
})
export class InviteComponent implements OnDestroy, AfterViewInit {
	@ViewChild('registration', { static: false }) public registration;

	public state = 'pending';
	public subscription;
	public membershipId;
	public membershipCode;
	public errorMessage: string;
	public invitedEmail;
	public teamName;

	constructor(
		public authService: AuthService,
		public profileService: ProfileService,
		protected router: Router,
		private activeRoute: ActivatedRoute,
		public apiService: ApiService
	) {
		//this.authService.silentLogout();

		this.activeRoute.params.subscribe((routeParams) => {
			this.membershipId = routeParams.membershipId;
			this.membershipCode = routeParams.membershipCode;
		});
	}

	ngAfterViewInit(): void {
		this.registration.loginForm.enableNameInput = true;
		this.getInvite();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	public successNavigation(resource) {
		this.profileService.fetch();
		this.profileService.onReady().subscribe(() => {
			this.router.navigateByUrl('/');
			this.state = 'success';
		});
	}

	public getInvite() {
		const headers = this.apiService.getHeaders();
		const url = this.apiService.formatter.getUrl(
			'/v1/memberships/' + this.membershipId
		);

		this.apiService.http
			.get(
				url,
				{ headers: headers }
			)
			.subscribe({
				next: (result) => {
					this.invitedEmail = result['invited_email'];
					this.teamName = result['team_name'];
				},
				error: (error) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
			});
	}


	public acceptInvite() {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			'/v1/memberships/' + this.membershipId
		);

		this.state = 'submitted';
		this.errorMessage = '';

		this.apiService.http
			.patch(
				url,
				{
					code: this.membershipCode,
				},
				{ headers }
			)
			.subscribe({
				next: (resource) => {
					this.successNavigation(resource);
				},
				error: (error) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
			});
	}
}
