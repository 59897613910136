import { Component, Inject, OnInit } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
	ApiService,
	ProfileService,
	ConfigurationService,
	AccountService,
} from '@central/ng-shared';

@Component({
	selector: 'central-archive-team-dialog',
	templateUrl: './archive-team-dialog.component.html',
	styleUrls: ['./archive-team-dialog.component.scss'],
})
export class ArchiveTeamDialogComponent implements OnInit {
	public state = 'pending';
	public subState = 'loading';
	public hasSubscriptions = false;
	public errorMessage = '';

	constructor(
		private apiService: ApiService,
		public profileService: ProfileService,
		public configurationService: ConfigurationService,
		public accountService: AccountService,
		public dialogRef: MatDialogRef<ArchiveTeamDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	public ngOnInit() {
		this.getSubscriptions();
	}

	public archiveTeam() {
		this.errorMessage = '';
		this.state = 'submitted';

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			'/v1/organizations/' + this.data.teamId
		);
		this.apiService.http
			.patch(
				url,
				{
					active: false,
				},
				{ headers }
			)
			.subscribe({
				next: (account) => {
					this.state = 'success';
					this.profileService.fetch();
					this.dialogRef.close();
				},
				error: (error) => {
					this.errorMessage = this.apiService.getPublicError(error);
					this.state = 'failed';
				},
			});
	}

	getSubscriptions(): void {
		this.apiService
			.get(`/v1/wpcr/accounts/${this.data.teamId}/subscriptions`, {})
			.subscribe(
				(subscriptions: any) => {
					this.hasSubscriptions = subscriptions.length > 0;
					this.subState = 'success';
				},
				() => (this.subState = 'failed')
			);
	}

}
