import { Component, ViewChild, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { AccountService } from '../../account/account.service';
import { ProfileService } from '../../authentication/services';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'central-locale-settings',
	templateUrl: './central-locale-settings.component.html',
	styleUrls: ['./central-locale-settings.component.scss'],
})

export class CentralLocaleSettingsComponent implements OnDestroy {
	@ViewChild('phoneNumber') public phoneNumber;
	@ViewChild('renderWeglot') set renderWeglot(element) {
		if (element) {
			this.moveWeglot();

		}
	}

	@Input() mode = 'language'; // language | currency | full
	@Input() positioning = 'ctw-mx-auto ctw-max-w-2xl ctw-mt-20 ctw-mb-16';

	public state = 'pending';
	public status = 'loading';
	public currency = '';
	public language = '';
	public currencies = [];
	public languages = [];

	languageState = 'loading';
	currencyState = 'loading';
	profileState = 'loading';
	languageSubmit = 'pending';
	currencySubmit = 'pending';

    @Output() loaded = new EventEmitter();

	constructor(
		public accountService: AccountService,
		public configService: ConfigurationService,
		public profileService: ProfileService,
		public cookieService: CookieService,
	) {
		this.setModels();
		//this.getCurrencies();
		this.getLanguages();
	}

	/**
	 * When component destroyed move the weglot selector back
	 * to the hidden container so things persist between views.
	 */
	ngOnDestroy(): void {
		window['Weglot'].off("languageChanged");
		const weglotContainer = document.querySelector('.weglot-container');
		const hiddenContainer = document.querySelector('.ctw-weglot-container');
		if (weglotContainer) {
			hiddenContainer.appendChild(weglotContainer);
		}
	}

	getState() {
		let state = 'loading';
		if ( this.languageState === 'success' && this.currencyState === 'success' && this.profileState === 'success' ) {
			state = 'success';
			this.loaded.emit(state);
		}
		return state;
	}

	getLanguages() {
		// Currently using Weglot
		// this.languages = [ 'English' ];
		this.languageState = 'success';
	}

	public getCurrencies() {
		this.currencyState = 'loading';

		this.accountService.apiService.get('/v1/wpcr/catalog', []).subscribe(
			( catalog ) => {
				for ( const property in catalog ) {
					if ( catalog[property].price_in_cents ) {
						for ( const currency in catalog[property].price_in_cents ) {
							if ( currency && this.currencies.indexOf( currency ) === -1 ) {
								this.currencies.push( currency );
							}

						}
					}
				}

				this.currencyState = 'success';
			},
			() => {
				this.currencyState = 'failed';
			}
		);
	}

	public setModels() {
		this.currency = this.profileService.data.currency;
		this.language = this.profileService.data.locale;
		this.profileState = 'success';
	}

	public submit(type: string) {
		const submit = type + 'Submit';
		this[submit] = 'submitted';

		this.profileService.update({ "`public_preferred_${type}`": this[type] }).subscribe(
			(response: any) => {
				this[submit] = 'success';
			},
			(error) => {
				this[submit] = 'error';
			}
		);
	}

	/**
	 * Move weglot container to our custom selector to display it.
	 */
	public moveWeglot() {
		const weglotContainer = document.querySelector('.weglot-container');
		const customContainer = document.querySelector('.ctw-language-selector');
		if (weglotContainer && customContainer) {
			customContainer.appendChild(weglotContainer);
			window['Weglot'].on("languageChanged", function (newLang, prevLang) {
				this.profileService.update({ public_preferred_language: newLang });
				this.cookieService.set('lang', newLang, {
					path: '/',
					domain: '.inmotionhosting.com'
				});
			});
		} else {
			setTimeout(() => this.moveWeglot(), 1000);
		}
	}
}
