import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScriptService } from '../../core';

@Injectable({
	providedIn: 'root',
})
export class CaptchaService {
	public observer;
	public loaded;

	constructor(public scriptService: ScriptService) {
		window['captchaOnload'] = () => {
			this.loaded = true;
			this.observer.next();
			this.observer.complete();
		};
	}

	public load() {
		return new Observable((observer) => {
			this.observer = observer;
			this.scriptService.load('captcha');

			if (this.loaded) {
				this.observer.next();
				this.observer.complete();
			}
		});
	}
}
