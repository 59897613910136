<h3>Support Agent Access</h3>
<p>
	Our support agents are not able to access any OpenMetal Cloud server unless
	the following public key has been added to the server's authorized_keys
	file:
</p>
<ng-container *ngIf="'failed' === this.state">
	<div class="error">
		An error occurred while fetching the public key. Please contact support
		if the problem persists.
	</div>
	<div class="action-buttons ctw-mt-10 ctw-text-right">
		<button mat-button mat-dialog-close>Close</button>
	</div>
</ng-container>
<div *ngIf="'loading' === this.state" class="ctw-mt-8 ctw-mb-8">
	<mat-spinner class="ctw-mx-auto" diameter="25"></mat-spinner>
</div>
<ng-container *ngIf="'success' === this.state">
	<mat-form-field appearance="fill">
		<mat-label>Public Key</mat-label>
		<textarea
			rows="4"
			matInput
			readonly
			[(ngModel)]="this.key"
			onclick="this.select()"
		></textarea>
	</mat-form-field>

	<div class="action-buttons ctw-mt-10 ctw-text-right">
		<button mat-button mat-dialog-close>Close</button>
		<button
			mat-raised-button
			color="primary"
			[cdkCopyToClipboard]="this.key"
			(cdkCopyToClipboardCopied)="this.copyKey()"
		>
			Copy Public Key
		</button>
	</div>
</ng-container>
