import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services';

@Component({
	template: '',
})
export class JwtComponent {
	constructor(
		public route: ActivatedRoute,
		public router: Router,
		public authService: AuthService
	) {
		this.route.queryParams.subscribe((params) => {
			if (params['auth-token']) {
				this.authService.silentLogout();
				this.authService.setLogin(params['auth-token']);
			} else {
				this.router.navigateByUrl('/');
			}
		});
	}
}
