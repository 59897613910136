<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success'">
	<div
		class="plugin-page animate__animated animate__fadeIn wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<div class="plugin-page__image"></div>
		<div class="plugin-page__info">
			<ng-container *ngIf="!this.hasPlugin">
				<h3>Lightning Fast Websites with</h3>
				<h3>W3 Total Cache</h3>
				<p>
					W3 Total Cache improves the SEO and user experience of your site
					by increasing website performance, reducing load times via
					features like content delivery network (CDN) integration and the
					latest best practices.
				</p>
				<div class="action-buttons">
					<a mat-button mat-stroked-button
						href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/projects/caching/"
						target="_blank" [disabled]="this.enableState === 'submitted'">Learn More
						<mat-icon class="ctw-ml-1">open_in_new</mat-icon></a>
					<central-progress-button *ngIf="this.available" [state]="enableState" (click)="enablePageCaching()"
						color="primary">Enable
						Plugin</central-progress-button>
					<central-progress-button *ngIf="!this.available" [state]="enableState" [disabled]="true"
						color="primary"><mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During
						Update</central-progress-button>
				</div>
			</ng-container>
			<ng-container *ngIf="this.hasPlugin">
				<h3>Great! W3 Total Cache is Enabled</h3>
				<p>
					W3 Total Cache improves the SEO and user experience of your site
					by increasing website performance, reducing load times via
					features like content delivery network (CDN) integration and the
					latest best practices.
				</p>
				<div class="action-buttons">
					<central-progress-button *ngIf="this.available" [state]="deleteState" type="stroked"
						(click)="this.disableFeature()">Remove
						Plugin & Disable</central-progress-button>
					<central-progress-button *ngIf="!this.available" [state]="deleteState" color="secondary"><mat-icon
							class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update</central-progress-button>
					<button mat-button mat-raised-button color="primary" [disabled]="this.deleteState === 'submitted'"
						(click)="
							this.projectService.login(
								'wp-admin/admin.php?page=w3tc_setup_guide'
							)
						">
						Setup Guide <mat-icon class="ctw-ml-1">open_in_new</mat-icon>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>
