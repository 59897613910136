<h2 mat-dialog-title class="ctw-capitalize">Are You Sure?</h2>
<mat-dialog-content class="mat-typography">
	<p>
		Disabling SSL will revert your site to a self-signed SSL, and will display a warning to your visitors that your
		site is potentially unsafe. While you can re-enable at any time, proceed with caution!
	</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="ctw-text-right ctw-w-full">
		<button mat-button mat-dialog-close (click)="closeDialogue(false)">Cancel</button>
		<button mat-button mat-raised-button [mat-dialog-close]="true" color="warn" (click)="closeDialogue(true)">Yes,
			Continue</button>
	</div>
</mat-dialog-actions>
