import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/api/api.service';
import { BrandingService } from '../../core';

@Component({
	// eslint-disable-next-line
	selector: 'resend-confirmation',
	templateUrl: 'resend-confirmation.component.html',
	styleUrls: ['resend-confirmation.component.scss'],
})
export class ResendConfirmationComponent implements OnInit {
	@Input() public token: string;
	@Input() public isModal = false;

	// eslint-disable-next-line
	@Output() public onComplete = new EventEmitter();

	public email = '';
	public standalone = false;
	public status = 'pending';

	constructor(
		public apiService: ApiService,
		public brandingService: BrandingService,
		public route: ActivatedRoute,
		public router: Router
	) {
		this.route.queryParams.subscribe((params) => {
			this.email = params['email'] || '';
		});
	}

	public ngOnInit() {
		this.standalone = !this.token;
		this.isModal = this.brandingService.getAppName().includes('InMotion');
	}

	/**
	 * Event to occur on completion.
	 */
	public onCompleteAction(): void {
		if (this.standalone) {
			this.router.navigate(['/']);
		} else {
			this.onComplete.emit();
		}
	}

	/**
	 * Resend the users confirmation email.
	 *
	 * @since 1.5.0
	 */
	public resend() {
		this.status = 'submitted';

		this.apiService
			.get('userConfirmationResend', {
				email: this.email,
				headerOptions: { authenticationToken: this.token },
			})
			.subscribe(
				() => (this.status = 'success'),
				() => (this.status = 'failed')
			);
	}
}
