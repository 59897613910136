import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigurationService } from '../../core/configuration.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { TrackingService } from '../tracking.service';
import { Subscription } from 'rxjs';


@Injectable()
export class MatomoAnalyticsService implements OnDestroy{
	public eventCategory = '';
	public eventActionPrefix = '';
	public paramBlacklist = ['email'];
	public trackingEvent: Subscription;
	constructor(
		private router: Router,
		private configService: ConfigurationService,
		private trackingService: TrackingService,
		private readonly tracker: MatomoTracker,
	) {
		this.trackingEvent = this.trackingService.onEvent.subscribe((event) => {
			try{
				this[event['event']](event['data']);
			} catch ( e ) {
				console.error('Error tracking event', event, e);
			}
		});
		this.setupPageView();
		this.eventCategory =
			this.configService.config['matomoAnalytics'].eventCategory ||
			this.eventCategory;
		this.eventActionPrefix =
			this.configService.config['matomoAnalytics'].eventActionPrefix ||
			this.eventActionPrefix;
	}
	public ngOnDestroy() {
		this.trackingEvent.unsubscribe();
	}
	public appendScript() {
		return;
	}

	/**
	 * Send page view on navigation.
	 *
	 * @since 1.9.0
	 */
	public setupPageView() {
		return;
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const params = new URLSearchParams(window.location.search);
				params.forEach((val, key) => {
					if (this.paramBlacklist.includes(key)) {
						params.delete(key);
					}
				});

				let pagePath =
					event.urlAfterRedirects.split('?')[0] +
					'?' +
					params.toString();
				let pageLocation =
					window.location.origin +
					window.location.pathname +
					'?' +
					params.toString();

				pagePath = pagePath.replace(
					/\/(account|clouds)\/[a-zA-Z\d]+\//,
					'/$1/-/'
				);
				pageLocation = pageLocation.replace(
					/\/(account|clouds)\/[a-zA-Z\d]+\//,
					'/$1/-/'
				);

				this.pageview({
					page_path: pagePath,
				});
			}
		});
	}

	/**
	 * Track a page view explictly.
	 *
	 * @param data Page View data
	 */
	public pageview(data: object) {
		//this.tracker.trackPageView(data['page_path']);
	}

	/**
	 * Each time a step is completed.
	 *
	 * @param data Object.
	 */
	public cwpStepComplete(data: object): void {
		this.send({
			event_category: 'signup_' + data['type'],
			event_action: 'cwp_demo_' + data['step'],
			event_label: data['name'],
		});
	}

	/**
	 * Set the user id.
	 *
	 * @param  data Cloud Install Data.
	 */
	public setUser(accountId: number): void {
		this.tracker.setUserId( accountId.toString() );
	}

	/**
	 * Purchase Code Redeemed.
	 *
	 * @param  data Redemption data.
	 */
	public codeRedeemed(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'purchaseCodeRedeemeed',
			event_label: data['provider'],
		});
	}

	public chat() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'chat',
		});
	}

	/**
	 * Send cloud install data
	 *
	 * @param  data Cloud Install Data.
	 */
	public cloudInstall(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_install',
			event_label: data['choice'],
		});
	}

	/**
	 * When a user lands on the order process.
	 *
	 * @param  data Data.
	 */
	public orderInitiated(data: any): void {
		if (!data.cart) {
			return;
		}

		const items = this.formatCartItems(data.cart.dataSource);

		items.forEach((item: any) => {
			this.tracker.addEcommerceItem(item.name, item.name, 'Platform I', item.price, item.quantity);
		})
		this.tracker.trackEcommerceCartUpdate(+data.cart.total)
	}

	/**
	 * Given the cart datasource, format the items to a matomo format.
	 *
	 */
	public formatCartItems(dataSource: any): object[] {
		this.tracker.clearEcommerceCart();

		const cartItems = [];
		for (const item of dataSource) {
			cartItems.push({
				id: item.product_term_id,
				name: item.product,
				price: item.price,
				quantity: item.quantity,
			});
		}

		return cartItems;
	}

	public gaSend(data) {
		data.event_category = data.event_category || this.eventCategory;
		this.send(data);
	}

	public ticketSubmission() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'ticket_submission',
		});
	}

	public memberInvite() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'member_invite',
		});
	}

	public onboardingComplete() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'onboarding_complete',
		});
	}

	/**
	 * Send add payment info step.
	 *
	 * @param  data Data.
	 */
	public addPaymentInfo(data: any): void {
		this.send({ event_action: 'add_payment_info' });
	}

	/**
	 * Send purchase data to GA.
	 *
	 * @param  data Data.
	 */
	public purchase(data: any): void {
		if (!data.cart) {
			return;
		}

		const items = this.formatCartItems(data.cart.dataSource);

		items.forEach((item: any) => {
			this.tracker.addEcommerceItem(item.name, item.name, 'Platform I', item.price);
		})

		this.tracker.trackEcommerceCartUpdate( +data.cart.total );
		this.tracker.trackEcommerceOrder( data.response.invoice.invoice_number, +data.cart.total )
	}

	/**
	 * Track user signups.
	 *
	 * @param data [description]
	 */
	public signup(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'sign_up',
			event_label: 'central_' + data['method'],
		});
	}

	/**
	 * Track user signups.
	 *
	 * @param data [description]
	 */
	public signin(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'sign_in',
		});
	}

	/**
	 * Send cloud install data
	 *
	 * @param  data Cloud Install Data.
	 */
	public newCloudAccount(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_new_account',
		});
	}

	/**
	 * Cloud activation.
	 *
	 * @param  data Cloud Install Data.
	 */
	public cloudActivated(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_activated',
		});
	}

	/**
	 * Track a exception event.
	 *
	 */
	public exception(data: object): void {
		//if ('undefined' !== typeof gtag) {
			this.error({
				category: 'exception',
				name: 'error',
				data: data['message'],
			});
		//}
	}

	/**
	 * Track a generic error event.
	 *
	 */
	public error(data: object): void {
		const fields = {
			event_category: data['category'],
			event_action: data['name'],
			event_label: data['data'],
		};

		if (data['value']) {
			fields['value'] = parseInt(data['value'], 10);
		}

		this.send(fields, 'development');
	}

	/**
	 * Send the data to matomo.
	 *
	 * Simply a wrapper of the gtag method.
	 * See: https://developer.matomo.org/api-reference/tracking-javascript
	 *
	 * @param   data      Data to pass to matomo.
	 */
	public send(data: object, namespace = 'marketing'): void {
		if ('development' === namespace) {
		}
		this.tracker.trackEvent(
			data['event_category'],
			this.eventActionPrefix + data['event_action'],
			data['event_label'] ?? null,
			data['value'] ?? null
		)
	}

	/**
	 * Get the current time in MYSQL format.
	 *
	 * @return string Current Time
	 */
	private getMysqlTimestamp(): string {
		return new Date().toISOString().slice(0, 19).replace('T', ' ');
	}

	/**
	 * Send conversion event data to GA.
	 *
	 * @param  data Data.
	 */
	public trackConversion(data: any): void {
		const payload = {
			transaction_id: data.orderId,
			value: +data.total,
			currency: 'USD',
		};

		payload['event_action'] = 'conversion';
		this.send(payload);
	}
}
