import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../authentication/services/auth.service';
import { ConfigurationService } from '../../core/configuration.service';

@Component({
	selector: 'central-membership',
	templateUrl: './membership.component.html',
	styleUrls: ['./membership.component.scss'],
})
export class OrganizationMembershipComponent {
	public id;
	public state = 'loading';

	constructor(
		private router: Router,
		public authService: AuthService,
		public configurationService: ConfigurationService
	) {
		const accountId = this.router.url.match(
			/\/account\/([0-9A-Za-z]+)\/members/
		);
		this.id = accountId
			? accountId[1] || this.authService.getAccountId()
			: this.authService.getAccountId();
	}
}
