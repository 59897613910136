<central-header title="Email" [status]="this.state" actions="{{emailSubscriptions?.length ? 'email' : ''}}"
	breakpoint="6xl" (action)="this.header($event)"></central-header>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success'">
	<ng-container *ngIf="emailSubscriptions.length">
		<div class="team-content animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16">
			<mat-card *ngFor="let subscription of emailSubscriptions">
				<div class="flex">
					<h3 *ngIf="!subscription.external_service_id">
						Configure new email subscription
					</h3>
					<h3 *ngIf="subscription.external_service_id">
						<span class="notranslate">@</span>{{ subscription.domain_id }}
					</h3>
					<p [hidden]="true">
						Owned by
						<span class="notranslate">{{ getAccountNameFromId(subscription.account_id) }}</span>
					</p>
				</div>
				<mat-divider></mat-divider>
				<div class="card-content">
					<central-email-subscription-setup *ngIf="!subscription.external_service_id"
						[usedDomains]="usedDomains" [accountId]="subscription.account_id"
						[purchaseId]="subscription.purchase_id" [subscriptionUuid]="subscription.subscription_uuid"
						(cpanelProvisionSuccessEvent)="
							reloadSubscription($event)
						"></central-email-subscription-setup>
					<central-email-subscription-manage *ngIf="subscription.external_service_id"
						[accountId]="subscription.account_id" [cpanelUsername]="subscription.external_service_id"
						[thirdPartyDomainId]="
							subscription.third_party_domain_id
						" [domainName]="subscription.domain_id" [subscriptionUuid]="subscription.subscription_uuid"
						[price]="this.price"></central-email-subscription-manage>
				</div>
			</mat-card>
		</div>
	</ng-container>

	<div *ngIf="!emailSubscriptions.length"
		class="no-content animate__animated animate__fadeIn ctw-container ctw-flex ctw-flex-align-center {{this.appService.isNoticeShowing() ? 'notice' : ''}}">
		<div class="ctw-mx-auto">
			<img class="ctw-mx-auto"
				src="https://inmotionhosting.github.io/static-assets/illustrations/small/unlimited-email.svg" />
			<h2 class="ctw-text-center">Fast and Simple Email</h2>

			<div class="ctw-mx-auto ctw-max-w-xl ctw-text-center">
				<div class="ctw-text-lg ctw-mb-4">
					Our affordable email is the perfect solution to start sending
					and receiving emails from your domain starting at
					<strong class="notranslate">{{ this.price }}</strong> per month.
				</div>

				<central-progress-button routerLink="/email/new" color="primary">
					Start Your Team's First Email Account
					<mat-icon class="next">chevron_right</mat-icon>
				</central-progress-button>
			</div>
		</div>
	</div>
</ng-container>
