export default {
	plans: [
		{
			code: 'wphosting_tier_1',
			name: 'UltraStack 2GB RAM',
			ram: '2 GB',
			cpu: '2',
			storage: '50 GB',
			transfer: '2 TB',
			phoneSupport: false,
			isNVMe: false,
			recommendText:
				'Smaller sites that want all the services of larger plans but don’t handle large traffic volumes.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: false,
		},
		{
			code: 'wphosting_tier_2',
			name: 'UltraStack 3GB RAM',
			ram: '3 GB',
			cpu: '3',
			storage: '100 GB',
			transfer: '3 TB',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Minimum recommended plan for top speed performance.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: false,
		},
		{
			code: 'wphosting_tier_3',
			name: 'UltraStack 4GB RAM',
			ram: '4 GB',
			cpu: '4',
			storage: '150 GB',
			transfer: '4 TB',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU will be leveraged for higher throughput for larger sites that have a mix of cacheable and dynamic content.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: false,
		},
		{
			code: 'wphosting_tier_4',
			name: 'UltraStack 6GB RAM',
			ram: '6 GB',
			cpu: '6',
			storage: '200 GB',
			transfer: 'Unlimited',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU supports very busy sites or sites with the highest performance goals.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: false,
		},
		{
			code: 'wphosting_tier_5',
			name: 'UltraStack 8GB RAM',
			ram: '8 GB',
			cpu: '8',
			storage: '250 GB',
			transfer: 'Unlimited',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU supports very busy sites or sites with the highest performance goals.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: true,
		},
		{
			code: 'wphosting_tier_6',
			name: 'UltraStack 12GB RAM',
			ram: '12 GB',
			cpu: '12',
			storage: '300 GB',
			transfer: 'Unlimited',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU supports very busy sites or sites with the highest performance goals.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: true,
		},
		{
			code: 'wphosting_tier_7',
			name: 'UltraStack 16GB RAM',
			ram: '16 GB',
			cpu: '16',
			storage: '350 GB',
			transfer: 'Unlimited',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU supports very busy sites or sites with the highest performance goals.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: true,
		},
		{
			code: 'wphosting_tier_8',
			name: 'UltraStack 24GB RAM',
			ram: '24 GB',
			cpu: '24',
			storage: '400 GB',
			transfer: 'Unlimited',
			phoneSupport: true,
			isNVMe: true,
			recommendText:
				'Additional RAM and more vCPU supports very busy sites or sites with the highest performance goals.',
			priceMonthly: 0,
			priceYearly: 0,
			includeLaunchAssist: true,
		},
	],
};
