import { Component, OnDestroy, OnInit} from '@angular/core';
import { ProjectService } from '../../project.service';
import { AnsibleService } from '../services/ansible.service';
import { ServerSoftwareComponent } from '../overview/diagnostic/server-software/server-software.component';
import { AppService } from '../../../app.service';
import { ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-server-configuration',
	templateUrl: './configure.component.html',
	styleUrls: ['./configure.component.scss'],
})
export class ServerConfigurationComponent implements OnInit, OnDestroy {

	public state = 'loading';

	private subscriptions = [];

	constructor(
		private appService: AppService,
		private profileService: ProfileService,
		private projectService: ProjectService,
		private ansibleService: AnsibleService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe(() => {
						// TODO: Disallow functionality if container is stopped.
						// ...
						this.subscriptions.push(
							this.projectService.getEnvironmentState.subscribe((msg) => {
								if (msg !== 'pending') {
									this.subscriptions.push(
										this.ansibleService.playbookStatus.subscribe((status) => {
											// Playbook is running or playbooks are disabled.
											this.state = status === 'playbook-overridden' ? 'unavailable' : 'success';
										})
									)
								}
							})
						)
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	public openPlaybookDialog() {
		const dialogRef = this.appService.openDialog(ServerSoftwareComponent);

		dialogRef.afterClosed().subscribe((result) => {
			switch (result) {
				case 'run':
					this.ansibleService.runPlaybook({})
					break;
				case 'toggle-override':
					this.ansibleService.toggleOverride();
					break;
			}
		});
	}
}
