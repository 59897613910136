import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { Observable } from 'rxjs/internal/Observable';

import { ApiService, AuthService, ConfigurationService, CouponService, LocalStorageService, ProfileService, RollbarService, LogrocketService, TrackingService, ZendeskService } from '@central/ng-shared';

import { PublicStorageService } from 'libs/ng-shared/src/lib/core/local-storage/public-storage.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './shared/dialog/dialog.component';


@Injectable()
export class AppService {
	private env;
	private host;

	// Recurly Cache
	private catalog;
	private offers;
	private purchases;

	// Project Cache
	private playbook = {};
	private projects;
	private projectScreenshots;
	private project;
	private licenses;

	// Domains Cache
	private registerableTlds;
	private firstPartyDomains;
	private thirdPartyDomains;
	private allDomains = {};

	// Subscriptions Cache
	private subscriptions;
	private allSubscriptions = {};

	// Content Cache
	private contextualHelp;
	private whatsNew;
	private notice;

	constructor(
		public sessionStorage: LocalStorageService,
		public persistentStorage: PublicStorageService,
		private configService: ConfigurationService,
		private authService: AuthService,
		public profileService: ProfileService,
        private rollbarService: RollbarService,
		private apiService: ApiService,
		private couponService: CouponService,
		private clipboard: Clipboard,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private logrocketService: LogrocketService,
		private zendesService: ZendeskService,
	) {
		this.env = this.configService.config.environment;
		this.host = this.configService.config.host;
	}

	/**
	 * Displays a mesage via matSnackBar
	 *
	 * @param message Confirmation message
	 * @param nag Display message without a TTL
	 */
	public showConfirmation(message: string, nag: boolean = false) {
		this.snackBar.open(message, nag ? 'Dismiss' : '', {
			...this.configService.config.snackBar
		});
	}

	/**
	 * Displays an error message via matSnackBar
	 *
	 * @param message Error message
	 * @param nag Display message without a TTL
	 */
	public showError(message: string, nag: boolean = false) {
		message = `An error occurred was an error processing your request... ${message}`
		this.snackBar.open(message, nag ? 'Dismiss' : '', {
			...this.configService.config.snackBar
		});
	}

	/**
	 * Copies specified text to user's clipbord
	 *
	 * @param content Text to copy
	 * @param name Name of what is to be copied
	 */
	public copyToClipboard(content: any, name: string): void {
		this.clipboard.copy(content);
		this.showConfirmation(`Copied ${name} to Clipboard!`);
	}

	/**
	 * Opens a universal matDialog
	 *
	 * @param component Dialog component ref
	 * @param data Information to be merged into dialog body {
	 * 		panelClass: string - css class to be applied to the dialog card
	 * 		title: string - Dialog title
	 * 		content: string - Dialog conent
	 * 		contentClass: string - Tailwind classes to apply to content
	 * 		html: boolean - Renders content as HTML
	 * 		confirmButton: string - Confirmation button text
	 * 		confirmButtonClass: string - Tailwind classes to apply to content
	 * 		cancelButton: string - Confirmation button text
	 * 		cancelButtonClass: string - Tailwind classes to apply to content
	 * 		shouldCopy: boolean - Renders content as HTML
	 * 		itemToCopy: any - Content that should be copied to user's clipboard
	 * 		itemToCopyName: string - Name of item copied
	 *		warn: boolean - Red confirmationButton
	 * }
	 *
	 * @returns MatDialogRef
	 */
	public openDialog(component: any, data: any = {}): MatDialogRef<any> {
		if (component === true) {
			component = DialogComponent;
		}
		return this.dialog.open(component, {
			...this.configService.config.dialog,
			panelClass: data.panelClass || '' ,
			data,
		});
	}

	/**
	 * Shortcut method to retrieving any cached appdata. The formal methods below
	 * can override and refresh the cache. This is essentially a read only method
	 * that returns data where as the formal methods primarily return an observable.
	 *
	 * @param data Any cacheable item
	 * @returns object array
	 */
	public get(data: string): any[] {
		return this[data] ?? this.sessionStorage.getItem(data);
	}

	public setProject(project) {
		this.project = [project];
	}

	/**
	 * Generates a random friendly default label for resources. Resource labels
	 * are generated from the this application except for VPS environments.
	 *
	 * @param context team | project | environment
	 * @returns string
	 */
	// https://github.com/glitchdotcom/friendly-words
	public generateName(context: string): string {
		const fw = require('friendly-words');
		const collection = fw.collections[Math.floor(Math.random() * fw.collections.length)];
		const predicate = fw.predicates[Math.floor(Math.random() * fw.predicates.length)];
		const object = fw.objects[Math.floor(Math.random() * fw.objects.length)];
		const team = fw.teams[Math.floor(Math.random() * fw.teams.length)];

		const subjects = [collection, object, team];
		const capitalize = (word) =>  word[0].toUpperCase() + word.slice(1);

		switch (context) {
			case 'team':
				return `${capitalize(predicate)} ${capitalize(team)}`;
				break;
			case 'project':
				return `${capitalize(predicate)} ${capitalize(collection)}`;
				break;
			case 'environment':
				return `${capitalize(predicate)} ${capitalize(object)}`;
				break;
			case 'domain':
				const subject = subjects[Math.floor(Math.random() * subjects.length)];
				const tld = this.registerableTlds[
					Math.floor(Math.random() * this.registerableTlds.length)];
				return `${predicate}${subject}.${tld}`;
				break;
		}
	}

	/**
	 * Checks if a notice is present to be displayed. The header will
	 * automatically append a banner if theres content present in localStorage.
	 *
	 * @returns boolean
	 */
	public isNoticeShowing(): boolean {
		if (this.sessionStorage.getItem('notice') &&
			this.sessionStorage.getItem('notice')[0]) {
			return true;
		}
	}

	/**
	 * Returns dollar amount to be displayed in the user's preferred currency or
	 * the provided locale/currency. Defaults to en-US / USD.
	 *
	 * @param price Value to format
	 * @param namedOptions locale | currency
	 * @returns string
	 */
	public getFormattedPrice(price, {locale = 'en-US', currency = 'USD'}): string {
		const currencyFormatter = new Intl.NumberFormat(this.profileService.data?.locale || locale, {
			style: 'currency',
			currency: this.profileService.data?.currency || currency,
		});

		return currencyFormatter.format(price);
	}

	/**
	 * Forces a new scresnshot to be fetched
	 *
	 * @param projectId Project
	 * @param environmentId Environment
	 */
	public refreshScreenshot(projectId, environmentId): void {
		this.projectScreenshots = this.persistentStorage.getItem('projectScreenshots');
		if (this.projectScreenshots[projectId] &&
			this.projectScreenshots[projectId][environmentId]
		) {
			this.projectScreenshots[projectId][environmentId].date = 0;
			if (!this.authService.isImpersonating()) {
				this.persistentStorage.setItem('projectScreenshots', this.projectScreenshots);
			}
		}
		this.getProjectScreenshots();
	}


	// The following methods retrieves and stores data for reuse throught the application.
	// The Storage mechanism is localStorage. There are 2 types of localStorage in play,
	// sessionStorage and persistentStorage as defined in the constructor.
	//
	// sessionStorage will be deleted on logout
	// persistentStorage will persist between user sessions
	//
	// Data can/will be refreshed as needed during a session, such as reloading a project,
	// Purchasing a domain, updating a resource field etc...
	//
	// When Agents impersonate users, storage will NOT be used


	/**
	 * sessionStorage
	 * Retrieves and stores products and pricing available for purchase
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getCatalog(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.catalog && fromCache) {
				observer.next(this.catalog);
				observer.complete();
			}
			else if (this.sessionStorage.getItem('catalog') && fromCache) {
				this.catalog = this.sessionStorage.getItem('catalog');
				observer.next(this.catalog);
				observer.complete();
			} else {
				this.apiService.get('/v1/wpcr/catalog', []).subscribe(
					(catalog: any) => {
						this.catalog = catalog;
						this.sessionStorage.setItem('catalog', this.catalog);
						observer.next(this.catalog);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error);
					}
				);
			}
		});
	}

	/**
	 * sessionStorage
	 * Retrieves and stores coupon data for in-app purchases
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getOffers(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.offers && fromCache) {
				observer.next(this.offers);
				observer.complete();
			}
			else if (this.sessionStorage.getItem('offers') && fromCache) {
				this.offers = this.sessionStorage.getItem('offers');
				observer.next(this.offers);
				observer.complete();
			} else {
				const validateCoupon = (code: string, type: string) => this.apiService
					.get(`/v1/wpcr/coupons/${code}`, {})
					.subscribe({
						next: (data: any) => {
							if (data.state === 'redeemable') {
								this.offers[type] = data;
							} else {
								this.offers[type] = false;
							}
							this.sessionStorage.setItem('offers', this.offers);
							this.offers = this.sessionStorage.getItem('offers');
						},
						error: (error) => {
							// TODO Push to rollbar
							this.offers[type] = false;
						},
					});

				const discountTypes = ['server', 'domain'];
				const period = new Date().toLocaleString('en-US', {
					month: 'short',
					year: 'numeric'
				}).replace(' 20', '-').toLocaleLowerCase();

				this.offers = {}

				discountTypes.forEach((type) => {
					this.offers[type] = {};
					if (type === 'server' && this.profileService.data.trial_available) {
						validateCoupon(this.profileService.data.trial_available.toString(), type)
							.add(() => {
								if (this.offers['server'] && this.offers['domain']) {
									observer.next(this.offers);
									observer.complete();
								}
							})
					} else {
						validateCoupon(`upsell-${type}-${period}`, type)
							.add(() => {
								if (this.offers['server'] && this.offers['domain']) {
									observer.next(this.offers);
									observer.complete();
								}
							})
					}
				})
			}
		});
	}

	/**
	 * sessionStorage
	 * Retrieves and stores currenttly available domain TLDs.
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getRegisterableTlds(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.registerableTlds && fromCache) {
				observer.next(this.registerableTlds);
				observer.complete();
			}
			else if (this.sessionStorage.getItem('registerableTlds') && fromCache) {
				this.registerableTlds = this.sessionStorage.getItem('registerableTlds');
				observer.next(this.registerableTlds);
				observer.complete();
			} else {
				const targetTlds = this.configService.hasFeature('recommendedTlds')
					? 'recommended' : 'default'; // or 'all'

				const url = `${this.host}/v1/domain-service/domain/tlds/${targetTlds}`;
				const headers = this.apiService.getHeaders({
					contentType: 'application/json',
				});

				this.apiService.http.get(url, { headers }).subscribe(
					(response: []) => {
						this.registerableTlds = response;
						this.sessionStorage.setItem('registerableTlds', this.registerableTlds);
						observer.next(this.registerableTlds);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * sessionStorage
	 * Retrieves and stores help documentation
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getContextualHelp(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.contextualHelp && fromCache) {
				observer.next(this.contextualHelp);
				observer.complete();
			}
			else if (this.sessionStorage.getItem('contextualHelp') && fromCache) {
				this.contextualHelp = this.sessionStorage.getItem('contextualHelp');
				observer.next(this.contextualHelp);
				observer.complete();
			} else {
				const endpoint: string =
				'https://www.inmotionhosting.com/support/wp-json/wp/v2/central?per_page=100&' +
					new Date().getTime();

				this.apiService.http.get(endpoint).subscribe(
					(supportArticles: any) => {
						this.contextualHelp = supportArticles.filter(post =>
							(post.acf.enabled && post.acf.environment === 'production') ||
							(post.acf.enabled && this.configService.config.environment !== 'production')
						);
						this.whatsNew = this.contextualHelp.filter(post => post.slug === 'whats-new');
						this.notice = this.contextualHelp.filter(post => post.slug === 'notice');
						this.sessionStorage.setItem('contextualHelp', this.contextualHelp);
						this.sessionStorage.setItem('whatsNew', this.whatsNew);
						this.sessionStorage.setItem('notice', this.notice);
						observer.next(this.contextualHelp);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * sessionStorage
	 * Retrieves and stores the latest playbook
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getPlaybookVersion(type: string, fromCache: boolean = true) {
		return new Observable((observer) => {
			if (this.playbook[type] && fromCache) {
				observer.next(this.playbook[type]);
				observer.complete();
			}
			else if (this.sessionStorage.getItem(`${type}-playbook`) && fromCache) {
				this.playbook[type] = this.sessionStorage.getItem(`${type}-playbook`);
				observer.next(this.playbook[type]);
				observer.complete();
			} else {
				this.apiService.get('/v1/playbook/latest', {}).subscribe(
					(response: any[]) => {
						this.playbook[type] = response[0];
						this.sessionStorage.setItem(`${type}-playbook`, this.playbook[type]);
						observer.next(this.playbook[type]);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores subscriptions available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getPurchases(fromCache: boolean = true) {
		return new Observable((observer) => {
			if (this.purchases && fromCache) {
				observer.next(this.purchases);
				observer.complete();
			}
			else if (this.persistentStorage.getItem('purchases') && fromCache) {
				this.purchases = this.persistentStorage.getItem('purchases');
				observer.next(this.purchases);
				observer.complete();
			} else {
				this.apiService.get('/v1/wpcr/purchases', {}).subscribe(
					(response: any[]) => {
						this.purchases = response;
						this.persistentStorage.setItem('purchases', this.purchases);
						observer.next(this.purchases);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores subscriptions available to the user
	 *
	 * @param accountId Account ID to get subscription for.
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getSubscriptions(accountId: string, fromCache: boolean = true) {
		return new Observable((observer) => {
			if (this.allSubscriptions[accountId] && fromCache) {
				observer.next(this.allSubscriptions[accountId]);
				observer.complete();
			}
			else if (this.persistentStorage.getItem('allSubscriptions')
					&& this.persistentStorage.getItem('allSubscriptions')[accountId]
					&& fromCache
			) {
				this.allSubscriptions[accountId] = this.persistentStorage.getItem('allSubscriptions')[accountId];
				observer.next(this.allSubscriptions[accountId]);
				observer.complete();
			} else {
				this.apiService.get(`/v1/wpcr/accounts/${accountId}/subscriptions`, {}).subscribe(
					(response: any[]) => {
						this.allSubscriptions[accountId] = response;
						this.persistentStorage.setItem('allSubscriptions', this.allSubscriptions);
						observer.next(this.allSubscriptions[accountId]);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores projects available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getProjects(fromCache: boolean = true) {
		return new Observable((observer) => {
			if (this.projects && fromCache) {
				observer.next(this.projects);
				observer.complete();
			}
			else if (this.persistentStorage.getItem('projects') && fromCache) {
				this.projects = this.persistentStorage.getItem('projects');
				observer.next(this.projects);
				observer.complete();
			} else {
				this.apiService.get('/v1/projects', {}).subscribe(
					(response: any[]) => {
						this.projects = response;
						this.persistentStorage.setItem('projects', this.projects);
						this.getProjectScreenshots(fromCache);
						observer.next(this.projects);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores environment screenshots for projects available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns ObjectArray
	 */
	public getProjectScreenshots(fromCache: boolean = true): object[] {
		if (this.projectScreenshots && fromCache) {
			return this.projectScreenshots;
		}
		else if (!this.authService.isImpersonating() &&
			this.persistentStorage.getItem('projectScreenshots') && fromCache) {
			this.projectScreenshots = this.persistentStorage.getItem('projectScreenshots');
			return this.projectScreenshots;
		} else {
			this.generateProjectScreenshots().then(() => {
				if (!this.authService.isImpersonating()) {
					try {
						this.persistentStorage.setItem('projectScreenshots', this.projectScreenshots);
					} catch (e) {
						if (e.message.includes('exceeded the quota')) {
							// Clean up any orphaned screenshots
							this.persistentStorage.removeItem('projectScreenshots');
                            try {
							    this.persistentStorage.setItem('projectScreenshots', this.projectScreenshots);
                            } catch (err) {
                                this.rollbarService.rollbar.warn(err)
                            }
						}
					}
				}
			});
		}
	}

	public async generateProjectScreenshots() {
		const imageUrlToBase64 = async (url) => {
			const data = await fetch(url);
			const blob = await data.blob();
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result;
					resolve(base64data);
				};
				reader.onerror = reject;
			});
		};

		this.projectScreenshots = this.persistentStorage.getItem('projectScreenshots') || {};

		if (this.projects) {
			for (const project of this.projects) {
				if (!Object.keys(this.projectScreenshots).includes(project.id)) {
					this.projectScreenshots[project.id] = {};
				}

				if (project.children.length) {
					await new Promise((resolve, reject) => {
						let checked = 0;
						project.children.forEach((environment) => {
							if (!Object.keys(this.projectScreenshots[project.id]).includes(project.id)) {
								this.projectScreenshots[project.id][environment.id] = {};
							}
							if ((environment?.fields?.has_publish_setup_user || environment?.fields?.cloud_id) && environment.fields.site_url && (!this.projectScreenshots[project.id][environment.id].date || this.projectScreenshots[project.id][environment.id].date < Date.now() - (60000 * (60 * this.configService.config.screenshotsTtl)))) {
								imageUrlToBase64(`${this.configService.config.screenshotsUrl}${environment.fields.site_url}?${new Date().toISOString().split(':')[0]}`).then((data) => {
									try {
										this.projectScreenshots[project.id][environment.id] = {
											url: environment.fields.site_url,
											img: data,
											date: Date.now()
										};
									} catch (e) {
										console.log(e)
									} finally {
										checked += 1
										if (checked === project.children.length) {
											resolve(this.projectScreenshots);
										}
									}
								})
							} else {
								checked += 1
								if (checked === project.children.length) {
									resolve(this.projectScreenshots);
								}
							}
						})
					})
				}
			};
		}

		return this.projectScreenshots;
	}

	/**
	 * persistentStorage
	 * Retrieves and stores licences available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getLicenses(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.licenses && fromCache) {
				observer.next(this.licenses);
				observer.complete();
			}
			else if (!this.authService.isImpersonating() &&
				this.persistentStorage.getItem('licenses') && fromCache) {
				this.licenses = this.persistentStorage.getItem('licenses');
				observer.next(this.licenses);
				observer.complete();
			} else {
				this.apiService.get('getLicenses', {}).subscribe(
					(response: any[]) => {
						this.licenses = response;
						if (!this.authService.isImpersonating()) {
							this.persistentStorage.setItem('licenses', this.licenses);
						}
						observer.next(this.licenses);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores registered domains available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getFirstPartyDomains(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.firstPartyDomains && fromCache) {
				observer.next(this.firstPartyDomains);
				observer.complete();
			}
			else if (!this.authService.isImpersonating() &&
				this.persistentStorage.getItem('firstPartyDomains') && fromCache) {
				this.firstPartyDomains = this.persistentStorage.getItem('firstPartyDomains');
				observer.next(this.firstPartyDomains);
				observer.complete();
			} else {
				this.apiService.get('/v1/domain-service/domain', {}).subscribe(
					(response: any[]) => {
						this.firstPartyDomains = response;
						if (!this.authService.isImpersonating()) {
							this.persistentStorage.setItem('firstPartyDomains', this.firstPartyDomains);
						}
						observer.next(this.firstPartyDomains);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores 3rd party domains available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Observable
	 */
	public getThirdPartyDomains(fromCache: boolean = true): Observable<any> {
		return new Observable((observer) => {
			if (this.thirdPartyDomains && fromCache) {
				observer.next(this.thirdPartyDomains);
				observer.complete();
			}
			else if (!this.authService.isImpersonating() &&
				this.persistentStorage.getItem('thirdPartyDomains') && fromCache) {
				this.thirdPartyDomains = this.persistentStorage.getItem('thirdPartyDomains');
				observer.next(this.thirdPartyDomains);
				observer.complete();
			} else {
				this.apiService.get('/v1/domains', {}).subscribe(
					(response: any[]) => {
						this.thirdPartyDomains = response;
						if (!this.authService.isImpersonating()) {
							this.persistentStorage.setItem('thirdPartyDomains', this.thirdPartyDomains);
						}
						observer.next(this.thirdPartyDomains);
						observer.complete();
					},
					(error) => {
						// TODO Push to rollbar
						observer.error(error)
					});
			}
		});
	}

	/**
	 * persistentStorage
	 * Retrieves and stores registration information about registered
	 * domains available to the user
	 *
	 * @param fromCache Pull from cache or not -- Cache cleared on logout
	 * @returns Promise
	 */
	public async getDomainInfo(domainName, fromCache: boolean = true): Promise<object[]> {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		if (this.allDomains[domainName] && fromCache) {
			return this.allDomains[domainName];
		}
		else if (!this.authService.isImpersonating() &&
			this.persistentStorage.getItem('allDomains') &&
			this.persistentStorage.getItem('allDomains')[domainName] && fromCache) {
			this.allDomains[domainName] = this.persistentStorage.getItem('allDomains')[domainName];
			return this.allDomains[domainName];
		} else {
			return new Promise((resolve, reject) => {
				const domainInfo = {}
				this.apiService.get('/v1/domain-service/domain/' + domainName, { headers })
					.subscribe((response) => {
						if (response['is_success'] === '1') {
							const expire =
								response['attributes']['registry_expiredate'];
							const isoDate =
								expire.slice(0, 10) + 'T' + expire.slice(11) + '.000Z';
							if (new Date(isoDate) < new Date()) {
								domainInfo['domainStatus'] = 'Expired';
							}

							domainInfo['domainCreateDate'] = response['attributes'][
								'registry_createdate'
							].substr(0, 10);
							domainInfo['domainExpireDate'] = response['attributes'][
								'registry_expiredate'
							].substr(0, 10);
							domainInfo['autoRenewEnabled'] =
								response['attributes']['auto_renew'] === '1';
						}

						this.allDomains[domainName] = domainInfo;
						if (!this.authService.isImpersonating()) {
							this.persistentStorage.setItem('allDomains', this.allDomains);
						}
						resolve(this.allDomains[domainName]);
					},
						() => {
							reject(this.allDomains[domainName]);
						}
					);
			})
		}
	}
}
