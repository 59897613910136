import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AccountService, ApiService, ConfigurationService, ProfileService } from '@central/ng-shared';

import { AppService } from '../../app.service';

import { PaymentComponent } from '../../checkout/payment/payment.component';
import { Config } from '../../shared/config/env.config';

@Component({
	selector: 'central-domain-register',
	templateUrl: './domain-register.component.html',
	styleUrls: ['./domain-register.component.scss'],
})
export class DomainRegisterComponent implements OnInit {
	@ViewChild(PaymentComponent) public paymentComponent: PaymentComponent;

	public ownerAccountId = '';
	public status = 'loading';
	public domainName = '';
	public domainAvailability = '';
	public DOMAIN_PRICE = 19.99;
	public domainPrice;
	public domainTldsAllowed = [];
	public cachedDomainTldsAllowed;
	public state = 'pending';
	public searching = 0;
	public selected = [];
	public packages = [];
	public searched;
	public isOnboarding;
	private observer;

	public loginModalTitle = 'Sign-Up';
	public loginModalSubTitle = 'First, let\'s connect you to the Platform';

	public paymentSubmitted = false;
	message: string;
	currencyFormatter;

	private subscriptions = [];

	constructor(
		public accountService: AccountService,
		public profileService: ProfileService,
		public route: ActivatedRoute,
		public router: Router,
		private appService: AppService,
		private httpClient: HttpClient,
		private apiService: ApiService,
		private configService: ConfigurationService,
	) {}

	ngOnInit(): void {
		this.domainName = this.route.snapshot.paramMap.get('domainName') ?? '';
	}

	// Events emitted from header component
	public header(action: any) {
		switch (action) {
			case 'checkout':
				this.domainAvailability = 'available';
				break;
			default:
				if (action.remove !== undefined) {
					this.select(action.remove, false, action.event);
				}
				break;
		}
	}

	public setDomainTldsAllowed(tlds) {
		const pending = this.appService.persistentStorage.getItem('cart') || [];

		tlds.forEach(tld => {
			this.domainTldsAllowed.push({
				tld,
				availability: 'unknown',
				price: '',
				domainName: '',
				selected: pending.filter(domain => domain.tld === tld)?.selected || false
			});
		});

		if (pending.length) {
			this.selected = pending;
			this.prepareCheckout();
		}

		this.status = 'success';
	}

	public accountChanged($event) {
		setTimeout(() => {
			this.ownerAccountId = $event['account_id'];
			this.accountService.updateAccount($event['account_id']);
			this.status = 'success';
		}, 0);
	}

	public select(index, addToCart = true, event = null) {
		if (event && this.selected.length > 1) {
			event.stopPropagation();
		}
		if (addToCart) {
			const domain = this.domainTldsAllowed[index];
			this.domainTldsAllowed[index].selected = domain.selected ? false : true;
			if (this.domainTldsAllowed[index].selected) {
				this.selected.push({
					tld: domain.tld,
					availability: 'available',
					price: domain.price,
					name: domain.domainName,
					selected: true
				});
			} else {
				this.selected = this.selected.filter(tld => tld.tld !== domain.tld);
			}
		} else {
			const item = this.selected[index];
			this.domainTldsAllowed.forEach((tld, idx) => {
				if (tld.domainName === item.name) {
					this.domainTldsAllowed[idx].selected = false;
				}
			});
			this.selected = this.selected.filter(selected => selected.name !== item.name);
		}

		this.appService.persistentStorage.setItem('cart', this.selected);
		this.prepareCheckout()
	}

	public prepareCheckout() {
		this.packages = [];
		this.selected.forEach((tld) => {
			this.packages.push({
				planCode: 'domain-gtld-1',
				options: {
					allows_multiple: false,
					allows_term_discount: false,
					domain_name: tld.name,
					price: tld.price * 100,
				},
			});
		})
	}

	public paymentSuccess() {
		this.selected = [];
		this.appService.persistentStorage.setItem('cart', this.selected);
		setTimeout(() => this.appService.getFirstPartyDomains(false), 10000);
	}

	public getDomainAvailability(data) {
		this.domainAvailability = '';
		if (data?.domainName) {
			this.state = 'submitted';
			this.searched = data;
			this.observer = new IntersectionObserver((tlds, observer) => {
				tlds.map((tld) => {
					if (tld.isIntersecting && !tld.target.classList.contains('checked')) {
						this.search(
							tld.target.querySelector('span').textContent,
							parseInt(tld.target.id.replace('tld', ''), 10)
						);
						tld.target.classList.add('checked');
						observer.unobserve(tld.target);
					}
				})
			});

			this.domainTldsAllowed.forEach((tld, index) => {
				this.domainTldsAllowed[index].availability = 'pending';
				this.domainTldsAllowed[index].selected = false;
				this.domainTldsAllowed[index].domainName =
					data.domainName?.split('.')[0] + tld.tld;

				if (index === this.domainTldsAllowed.length - 1) {
					setTimeout(() => {
						// Check availability
						document.querySelectorAll('.tld').forEach(el => {
							this.observer.observe(el);
						})
					}, 1000);
				}
			});
		}
	}

	private search(domain, index) {
		const currency = this.profileService.data.currency;
		//const url = `${Config['host']}/v1/domain-service/domain/availability/${currency}/${domain}`;
		const url = `${Config['host']}/v1/domain-service/domain/availability/${domain}`;
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		this.searching++;
		setTimeout(() => {
			this.httpClient.get(url, { headers }).subscribe(
				(response) => {
					this.domainTldsAllowed[index].selected = (this.selected.filter(tld => tld.tld === this.domainTldsAllowed[index].tld)[0]?.selected && domain === this.selected.filter(selected => selected.name === domain)[0]?.name) || false;
					if (response['is_success'] === '1') {
						if ('price' in response['attributes']) {
							this.domainTldsAllowed[index].availability = 'available';
							if (this.configService.hasFeature('dynamicTldPricing')) {
								this.domainTldsAllowed[index].price = parseFloat(response['attributes']['price']).toFixed(2);
							} else {
								this.domainTldsAllowed[index].price = this.DOMAIN_PRICE;
							}
						} else {
							if (
								'status' in response['attributes'] &&
								response['attributes']['status'] === 'taken'
							) {
								this.domainTldsAllowed[index].availability = 'taken';
							} else {
								this.domainTldsAllowed[index].availability = 'error';
							}
						}
					} else {
						this.domainTldsAllowed[index].availability = 'error';
					}
					this.searching--;

				},
				(error) => {
					console.log(error);
					this.domainTldsAllowed[index].availability = 'error';
					this.searching--;
				}
			);
		}, this.searching * 200);
	}
}
