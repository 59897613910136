<central-header title="Domains" [status]="this.status" actions="domainsearch" breakpoint="6xl"
	(action)="this.header($event)" [cart]="this.selected"
	[checkout]="'available' === domainAvailability"></central-header>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16 ctw-text-center">

	<central-domain-search *ngIf="this.domainAvailability !== 'available'"
		(domainAvailabilityEvent)="getDomainAvailability($event)"
		(domainTldsAllowedEvent)="setDomainTldsAllowed($event)" [domainName]="this.domainName"
		class="ctw-p-12"></central-domain-search>

	<h4 *ngIf="!paymentSubmitted && 'available' !== domainAvailability" class="ctw-mb-12">
		{{ this.state !== 'submitted' && this.domainTldsAllowed.length ? 'Search any of the following TLDs' : '' }}
		<div class="ctw-mt-8 ctw-grid {{ this.state === 'submitted' ? '' : 'ctw-grid-cols-5' }} ctw-gap-4 ctw-mx-16">
			<ng-container *ngFor="let tld of this.domainTldsAllowed; index as i">
				<span id="tld{{i}}" *ngIf="tld.availability === 'unknown'"
					class="tld ctw-px-5 notranslate">{{tld.tld}}</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'pending'"
					class="tld ctw-px-5 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4 notranslate">{{tld.domainName}}</span>
					<central-progress-button state="submitted" mat-raised-button [color]="secondary"
						class="animate__animated animate__fadeIn" disabled>
					</central-progress-button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'available'"
					class="tld ctw-px-5 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4 notranslate">{{tld.domainName}}</span>
					<button mat-raised-button [color]="tld.selected ? 'secondary' : 'primary'" (click)="this.select(i)"
						class="{{tld.selected ? 'ctw-bg-neutral-200 ctw-text-green-700' : ''}}">
						<span class="ctw-flex">
							{{this.appService.getFormattedPrice(tld.price, {})}}
							<mat-icon class="ctw-text-2xl ctw-m-0 ctw-p-0 ctw-ml-2">
								{{tld.selected ? 'check' : 'shopping_cart'}}
							</mat-icon>
						</span>
					</button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'taken'"
					class="tld ctw-px-5 ctw-text-red-700 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4 notranslate">{{tld.domainName}}</span>
					<button mat-stroked-button color="warn" disabled>Taken</button>
				</span>
				<span id="tld{{i}}" *ngIf="tld.availability === 'error'"
					class="tld ctw-px-5 ctw-text-red-700 ctw-flex ctw-justify-between">
					<span class="ctw-mr-4 notranslate">{{tld.domainName}}</span>
					<button mat-stroked-button color="accent" disabled>Unavailable</button>
				</span>
			</ng-container>
		</div>
	</h4>

	<ng-container *ngIf="'error' === domainAvailability">
		<p class="error">Error checking availability, try again</p>
	</ng-container>
	<ng-container *ngIf="'taken' === domainAvailability">
		<h3 *ngIf="'taken' === domainAvailability">
			<mat-icon color="warn">cancel</mat-icon><span class="bold notranslate">{{ this.domainName }}</span> is
			already
			registered
		</h3>
		<p>
			If you control this domain name, you can add it to your
			<a routerLink="../">DNS Manager</a>.
		</p>
	</ng-container>

	<ng-container *ngIf="'available' === domainAvailability">
		<h3 *ngIf="
				!this.configService.hasFeature('bulkTldSearch') &&
				'available' === domainAvailability &&
				'success' === domainPriceStatus &&
				!paymentSubmitted
			" class="ctw-mb-8">
			<mat-icon class="available">check_circle</mat-icon><span class="bold notranslate">{{ this.domainName
				}}</span> is
			available for
			{{this.appService.getFormattedPrice(this.domainPrice || this.DOMAIN_PRICE, {})}} per year
		</h3>
		<payment-form [organizationId]="ownerAccountId" [packages]="this.packages" [hideGuarantee]="true"
			(paymentSuccess)="paymentSuccess()" (paymentBack)="this.getDomainAvailability(this.searched)"
			(paymentFailure)="paymentSubmitted = false" [excludedInputs]="['organization']"></payment-form>
	</ng-container>
</div>
