<h2 class="ctw-capatalize">{{ this.data.isNew ? 'New Playground' : 'Environment' }} Details </h2>

<p class="ctw-mb-1">
	Type: {{ resource['fields']['cloud_id'] ? 'Playground' : 'Production' }}<br>
</p>
<p class="ctw-mb-6">
	Started: {{ resource['created_at'] | amTimeAgo }}
</p>

<form (ngSubmit)="this.update()">

	<mat-form-field class="ctw-mt-4">
		<input matInput placeholder="Name" name="cloud_name" required [(ngModel)]="resource['label']" maxlength="22"
			#environmentName />
		<mat-hint class=" error" *ngIf="this.state === 'failed'">
			Could not rename. Please refresh and try again.
		</mat-hint>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Description</mat-label>
		<textarea matInput [(ngModel)]="resource['description']" name="environment-description" rows="4"
			type="textarea"></textarea>
	</mat-form-field>

	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button type="button" [disabled]="state === 'submitted'" mat-button (click)="this.reset()">
			Cancel
		</button>
		<central-progress-button color="primary" [state]="this.state" class="ctw-ml-3">Update</central-progress-button>
	</div>
</form>
