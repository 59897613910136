import { Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ApiService, Timer } from '../../core';
import { ConfigurationService } from '../../core';

interface UserData {
	account_id: number;
	name: string;
	email: string;
	phone: string;
	paid: string;
	created_at: string;
}

@Pipe({
	name: 'customCallback'
})
export class CustomCallbackPipe implements PipeTransform {
	transform(value: string, func: any): string {
	  return func(value);
	}
}
@Component({
	selector: 'central-user-page',
	templateUrl: './user-page.component.html',
	styleUrls: ['../agent.component.scss'],
})
export class UserPageComponent implements OnInit, OnDestroy {
	// Status of page (loading)
	public state = 'pending';

	// Table variables
	public data;
	public options: any;
	public isActive: any;
	public columnsToDisplay = [
		'account_id',
		'name',
		'email',
		'phone',
		'created_at',
	];

	// Search variables
	private searchTimeoutId: Timer | any;
	public searchBox = '';
	public searchLabel = 'Search';
	public searchPlaceholder = 'Search Users';

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) public paginator: MatPaginator;
	public paginator_count = 0;

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		private route: ActivatedRoute,
		public configService: ConfigurationService
	) {
		if (
			this.configService.config.brandConfig.applicationName.includes(
				'InMotion'
			)
		) {
			this.searchLabel =
				'Name, Email, Domain, VEID, IP, Project Name, Environment Name or Coupon Code';
			this.searchPlaceholder = '...';
			this.columnsToDisplay = [
				'name',
				'email',
				'phone',
				'created_at',
			];
		}
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.route.queryParams.subscribe((params) => {
				if (params?.email) {
					this.searchBox = params?.email;
				}
			})
		)

		this.fetch();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'refresh':
				this.searchBox = '';
				this.fetch();
				break;
		}
	}

	// Grab data for the table
	public fetch() {
		this.state = 'submitted';

		const data = {
			email: this.searchBox ?? null,
			pageIndex: (this.paginator?.pageIndex
				? this.paginator.pageIndex + 1
				: 1
			).toString(),
			pageSize: (this.paginator?.pageSize ?? 25).toString(),
		};
		this.apiService.get(`/v1/agents/fraud-report`, data).subscribe({
			next: (accounts: any) => {
				this.data = this.generateTableData(accounts.data);
				this.paginator_count = accounts.paginator_count;
				this.data.sort = this.sort;
				this.state = 'success';
			},
			error: (error) => {
				this.state = 'fail';
			},
		});
	}

	// Search box
	public search(event) {
		if (this.configService.config.brandConfig.id !== 'imh') {
			if (this.searchTimeoutId) {
				clearTimeout(this.searchTimeoutId);
				this.searchTimeoutId = null;
			} else {
				this.searchTimeoutId = setTimeout(() => {
					this.searchBox = event.target.value;
					this.fetch();
				}, 400);
			}
		} else {
			if (event.code === 'Enter' || event.keyCode === 13) {
				this.searchBox = event.target.value;
				this.fetch();
			}
		}
	}

	private generateTableData(data = []) {
		const tableData: UserData[] = [];
		data.map((user) => {
			const tableRow: UserData = {
				account_id: user.account_id,
				name: user.display_name ?? 'N/A',
				email: user.email ?? 'N/A',
				phone: user.options?.internal_phone_number ?? 'N/A',
				paid:  user.onboarded ? 'Yes' : 'No',
				created_at: user.created_at ?? false,
			};

			tableData.push(tableRow);
		});

		return new MatTableDataSource(tableData);
	}

	public multiLineDate(value: string) {
		const date = value.split(' ')
		return `${date[0]} ${date[1]} ${date[2]} ${date[3]}<br>${date[4]} ${date[5]}`;
	}
}
