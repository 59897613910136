import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import {
	MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { BreakpointObserver } from '@angular/cdk/layout'; // Angular's observer for media queries

import { ApiService, AuthService, ProfileService, ConfigurationService, SocketService } from '@central/ng-shared';

import { AppService } from '../../app.service';
import { ProjectService } from '../project.service';
import { AnsibleService } from './services/ansible.service';
import { WpRestService } from './services/wordpress.service';

import { AddPublicKeyComponent } from './tools/ssh-keys/add-public-key.component';
import { FileTransferComponent } from './tools/sftp-reset/file-transfer.component';
import { ResetServerComponent } from './tools/reset/reset-server.component';
import { EditDialogComponent } from '../../shared/edit-dialog/edit-dialog.component';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { Post } from '../../shared/forms/post';

@Component({
	selector: 'central-environment',
	templateUrl: 'environment.component.html',
	styleUrls: ['environment.component.scss',],
})
export class EnvironmentComponent implements OnInit, OnDestroy, OnChanges {

	@Input() isProjectRoute = false;

	@ViewChild('sidenav') public sidenav: MatSidenav;

	public state = 'loading';

	public currentRoute;

	public sidenavMode;

	public screenshot = '';

	public loadingClient = true;
	public connecting = false;

	public agentNeedsControl = false;
	public ansibleManaged;
	public playbookStatus: any;

	private socketEvents = ['startComplete', 'stopComplete', 'restartComplete']
	private subscriptions = [];

	public constructor(
		public projectService: ProjectService,
		public wpRestService: WpRestService,
		public authService: AuthService,
		private appService: AppService,
		private apiService: ApiService,
		private configService: ConfigurationService,
		private profileService: ProfileService,
		private ansibleService: AnsibleService,
		private sidebarComponent: SidebarComponent,
		private dialog: MatDialog,
		private mediaQuery: BreakpointObserver,
		private router: Router,
		private httpClient: HttpClient,
		private socketService: SocketService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				setTimeout(() => {
					if (this.sidenav) {
						this.setSidenav();
					}
				});

				this.bindEnvironmentChange();

				this.subscriptions.push(
					this.sidebarComponent.toggleMenu.subscribe(() => {
						this.sidenav.toggle();
					})
				)

				this.sidebarComponent.hasChildMenu = true;

				this.subscriptions.push(
					this.router.events.subscribe((event) => {
						if (event instanceof NavigationEnd) {
							this.agentNeedsControl =
								event.urlAfterRedirects.includes('/agent');
							this.ansibleManaged =
								event.urlAfterRedirects.includes('/configure');
							this.currentRoute = event.urlAfterRedirects;
							this.checkSidenav();
						}
					})
				)

				this.subscriptions.push(
					this.ansibleService.playbookStatus.subscribe((data) => {
						if (data !== 'pending' && data !== 'complete' && data !== 'playbook-overridden') {
							this.playbookStatus = 'pending';
						} else {
							this.playbookStatus = false;
						}
					})
				);

				this.subscriptions.push(
					this.wpRestService.connection.subscribe((msg: any) => {
						if (msg.status === 'connected') {
							this.projectService.onClientConnected.next('ready');
						}
					})
				)

				this.projectService.setProjectAccess();

				this.socketEvents.forEach((event) => {
					this.socketService
						.getSocket()
						.on(event, (message) => {
							if (
								('restartComplete' === message.name && 0 === message.data.status) ||
								'startComplete' === message.name
							) {
								this.appService.showConfirmation('Server Running');
							}
						});
				})

				// Fix for sidebar width bug.
				setTimeout(() => window.dispatchEvent(new Event('resize')), 50);

				this.state = 'success';
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.sidebarComponent.hasChildMenu = false;
	}

	ngOnChanges() {
		this.checkSidenav();
	}

	/**
	 * Check if the router url contains the specified route
	 *
	 * @param  {string}  route
	 * @return {boolean} Does route match?
	 */
	public hasRoute(route: string, partial: boolean = false) {
		if ( !partial ) {
			return this.router.url === route;
		} else {
			return this.router.url.includes(route);
		}
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'login':
				this.wpRestService.login(
					this.projectService.environment,
					''
				)
				break;
			case 'files':
			case 'goaccess':
				this.openApps(action);
				break;
			case 'edit-name':
				this.openEditDialog(this.projectService.environment);
				break;
			case 'add-ssh-key':
				this.openAddSshKeyDialog();
				break;
			case 'access-sftp':
				this.openSftpPasswordDialog();
				break;
			case 'reset-server':
				this.openResetDialog();
				break;
			case 'stop-server':
			case 'start-server':
			case 'restart-server':
				this.apiService
					.post(`/v1/vps/server/${action.split('-')[0]}`,
						{ veid: this.projectService.environment.fields?.machine_details?.veid })
					.subscribe(
						(response) => {
							this.appService.showConfirmation(
								`Server ${action.split('-')[0] === 'stop' ? 'Stopped' : action.split('-')[0] + 'ed'}`);
						},
						(error) => {
							this.appService.showError(`Unable To ${action.split('-')[0]} Server`);
						}
					);
				break;
			case 'cancel-playbook':
				this.projectService.updateEnvironmentField({
					playbook_running: 'complete',
				}).subscribe(() => {
					this.appService.showConfirmation('Playbook Running Status Cleared');
				})
				break;
		}
	}

	public async openApps(appName) {
		await new Promise((resolve) => {
			if (this.projectService.environment?.fields?.cloud_id) {
				this.apiService
					.get(
						'/v1/cloud-wordpress/' +
						this.projectService.environment.fields.cloud_id,
						{}
					)
					.subscribe(
						(cloud) => resolve(cloud),
						() => { }
					);
			} else {
				resolve({
					account_id:
						this.projectService.project.fields
							.connect_id,
					login_url: this.projectService.environment.fields
						.connection_url,
					login_token: this.projectService.environment.token,
					connect_key: this.projectService.environment.hash,
				})
			}
		}).then((site: any) => {
			const getAppUrl = (authUrl: string) => {
				if (this.projectService.environment?.fields?.cloud_id) {
					return authUrl.replace('wp-login.php', 'apps/auth.php');
				} else {
					if (this.projectService.environment.fields.connection_url) {
						return this.projectService.environment.fields.connection_url + '/ultrastack/';
					} else {
						return 'https://' + this.projectService.environment.fields.hostname + '/ultrastack/';
					}
				}
			}

			const post = new Post();
			const req = {
				account_id: site.account_id,
				token: site.login_token,
				path: appName,
				origin: window.location.origin,
			};

			if (!this.projectService.environment.fields.cloud_id) {
				req['path'] = appName.replace(/.php.*$/, '/');
				req['site_id'] = this.projectService.environment.id;
				req['connect_key'] = site.connect_key;
			}

			post.postForm(getAppUrl(site.login_url), req);
		});
	}

	public openEditDialog(environment: any): void {
		const updateAction = (component) => {
			const url = this.apiService.formatter.getUrl(
				'/v1/environments/' + environment.id
			);
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			this.httpClient
				.patch(url, { label: environment.label }, { headers })
				.subscribe(
					(response: any) => {
						this.projectService
							.reloadProject()
							.subscribe(() => {
								environment.label = response.label.trim();
								component.dialogRef.close();
								component.snackBar.open(
									'Rename Successful',
									'',
									{
										duration: 3000,
									}
								);
							});
					},
					() => (component.state = 'failed')
				);
		};

		const dialogRef = this.dialog.open(EditDialogComponent, {
			width: '500px',
			disableClose: true,
			data: {
				label: environment.fields.cloud_id ? 'Playground Environment' : 'Production Environment',
				name: 'Environment',
				resource: environment,
				resourceItem: 'label',
				updateAction,
			},
		});
	}

	public openAddSshKeyDialog() {
		const dialogRef = this.appService.openDialog(AddPublicKeyComponent, {
			veid: this.projectService.environment.fields?.machine_details?.veid,
			trial_id: this.projectService.environment?.fields?.cloud_id,
			organization_id: this.projectService.environment?.fields?.organization_id,
		});

		dialogRef.afterClosed().subscribe((result) => {
			if ('success' === result) {
				this.appService.showConfirmation('Public SSH Key successfully added to server!')
			}
			if ('error' === result) {
				this.appService.showError('Your Public SSH key was NOT added!');
			}
		});
	}

	public openSftpPasswordDialog() {
		const dialogRef = this.appService.openDialog(FileTransferComponent, {
			env_id: this.projectService.environment.id,
			organization_id: this.projectService.environment.fields.organization_id,
		});

		dialogRef.afterClosed().subscribe((result) => {
			if ('error' === result) {
				this.appService.showError('The sFTP Password was NOT reset!');
			}
		});
	}

	public openResetDialog() {
		const ref = this.dialog.open(ResetServerComponent, {
			width: '500px',
			data: {},
		});
		const instance = ref.componentInstance;
		instance.environment = this.projectService.environment;
		instance.project = this.projectService.project.id;
	}

	public updateScreenshot() {
		let cached = { date: 0 }
		if (this.appService.getProjectScreenshots() &&
			this.appService.getProjectScreenshots[this.projectService.project.id]) {
			cached = this.appService.getProjectScreenshots()[this.projectService.project.id][this.projectService.environment?.id]
		}

		if (!cached || cached.date < Date.now() - (60000 * (60 * this.configService.config.screenshotsTtl))) {
			this.appService.refreshScreenshot(
				this.projectService.project.id,
				this.projectService.environment.id
			);
		}
	}

	/**
	 * Each time the environment changes, update the services.
	 */
	private bindEnvironmentChange(): void {
		this.subscriptions.push(
			this.projectService.onEnvironmentChange.subscribe(() => {
				this.ansibleService.bind(this.projectService);
				this.projectService.getServers();
				this.updateScreenshot();
				this.connectClient();
			})
		);
	}

	/**
	 * Connect the client to an environment.
	 *
	 */
	private connectClient(): void {
		this.connecting = true;
		if (
			!this.projectService.hasEnvironmentControl(
				this.projectService.environment
			) ||
			this.projectService.environment?.fields?.playbook_running ===
				'in-progress'
		) {
			this.connecting = false;
			return;
		}

		if (this.currentRoute && this.currentRoute.includes('add/server')) {
			// If user is on the add server page, abandon connection attempt
			this.connecting = false;
			return;
		}
	}

	private reconnectClient() {
		this.projectService.reloadProject(false).subscribe(() => {
			this.projectService.getSiteStats();
		});
	}

	// determine window size and set sidenav style
	private setSidenav() {
		this.subscriptions.push(
			this.mediaQuery.observe('(max-width: 768px)').subscribe((res) => {
				const matched = res.matches;
				if (matched) {
					this.sidenav.close();
					this.sidenavMode = 'over';
				} else if (
					!matched &&
					!this.hasRoute('/projects/' + this.projectService.project.id) &&
					!this.hasRoute('/projects/' + this.projectService.project.id + '/add', true) &&
					!this.hasRoute('/projects/' + this.projectService.project.id + '/resize', true)
				) {
					this.sidenavMode = 'side';
					this.sidenav.open();
				}
			})
		)
	}

	private checkSidenav(hide = false): void {
		if (this.sidenav) {
			if (!this.hasRoute('/projects/' + this.projectService.project.id) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/add', true) &&
				!this.hasRoute('/projects/' + this.projectService.project.id + '/resize', true) &&
				!hide)
			{
				this.sidenavMode = 'side';
				this.sidenav.open();
			} else {
				this.sidenav.close();
				this.sidenavMode = 'over';
			}
		}
	}
}
