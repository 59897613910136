<div class="ctw-flex ctw-mb-4">
	<mat-icon *ngIf="this.data.icon" class="ctw-mr-4 ctw-mb-5 contextual-help-card-icon">{{
		this.data.icon
		}}</mat-icon>
	<h2 mat-dialog-title class="ctw-font-bold ctw-mb-auto ctw-capitalize">
		{{ this.data.title }}
	</h2>
</div>
<div mat-dialog-content class="ctw-flex ctw-flex-col" [class.ctw-text-white]="this.data.panelClass === 'offer'"
	[class.ctw-text-xl]="this.data.panelClass">
	<div *ngIf="this.data.before_video" [innerHTML]="this.data.before_video"></div>
	<div *ngIf="this.data.youtube" class="ctw-aspect-w-16 ctw-aspect-h-9 ctw-my-4">
		<iframe [src]="this.sanitizeUrl(this.data.youtube)" frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen></iframe>
	</div>
	<div *ngIf="this.data.after_video" class="ctw-mb-4" [innerHTML]="this.data.after_video"></div>
</div>
<div *ngIf="this.data.panelClass === 'offer'" mat-dialog-actions
	class="ctw-justify-end ctw-text-right dialog-footer action-buttons ctw-mt-10 ctw-p-0 ctw-pb-5">
	<button mat-stroked-button mat-dialog-close class="ctw-border-current ctw-text-white">OK</button>
</div>
<div *ngIf="this.data.panelClass !== 'offer'" mat-dialog-actions
	class="ctw-justify-end ctw-text-right dialog-footer action-buttons ctw-mt-10 ctw-p-0 ctw-pb-5">
	<button mat-raised-button color="primary" mat-dialog-close>OK</button>
</div>