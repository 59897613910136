import { Component, Output, OnInit, EventEmitter, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { AppService } from '../../app.service';

import { DEFAULT_REGISTERABLE_TLDS } from './domain-search.constants';

@Component({
	selector: 'central-domain-search',
	templateUrl: './domain-search.component.html',
	styleUrls: ['./domain-search.component.scss'],
})

export class DomainSearchComponent implements OnInit, AfterViewInit {
	@Input() public domainName = '';

	@Output() domainAvailabilityEvent = new EventEmitter<object>();
	@Output() domainTldsAllowedEvent = new EventEmitter<object>();

	@ViewChild('form') ngForm: NgForm;

	public state = 'loading';
	public domainValidator;
	public checkedDomainName = '';
	public domainAvailability = '';
	public domainTldsAllowed = DEFAULT_REGISTERABLE_TLDS;

	constructor(
		private appService: AppService,
	) {}

	ngOnInit(): void {
		this.domainValidator = new UntypedFormControl('', [
			Validators.pattern('^(?!-)([-0-9a-z]{0,62})?[0-9a-z](' + this.domainTldsAllowed.join('|') + '|)$'),
		]);

		this.fetchTlds();
	}

	ngAfterViewInit(): void {
		if (this.domainName !== '') {
			this.ngForm.ngSubmit.emit();
		}
	}

	public fetchTlds() {
		this.appService.getRegisterableTlds().subscribe(
			(tlds: any[]) => {
				this.domainTldsAllowedEvent.emit(tlds)
				this.domainValidator = new UntypedFormControl('', [
					Validators.pattern('^(?!-)([-0-9a-z]{0,62})?[0-9a-z](' + tlds.join('|') + '|)$'),
				])
				this.state = 'success';
			},
			(error) => {
				// TODO Push to rollbar
				console.warn('Unable to fetch Registerable TLDs', error);
				this.state = 'failed';
			}
		);
	}

	public isDomainAvailable() {
		if (!this.domainValidator.invalid) {
			this.domainAvailability = '';
			this.domainAvailabilityEvent.emit({
				domainAvailability: '',
			});
			this.checkedDomainName = this.domainName.trim().toLowerCase().split('.')[0];
			this.domainAvailabilityEvent.emit({
				domainName: `${this.checkedDomainName}`,
				domainAvailability: this.domainAvailability,
				tlds: this.domainTldsAllowed,
			});
			this.domainName = '';
		}
	}

	public getErrorMessage() {
		if (this.domainValidator.hasError('required')) {
			return 'Enter a domain name';
		}

		const tld = '.' + this.domainName.split('.').slice(1).join('.');

		if (this.domainName.match(/\.$/)) {
			return 'Not a valid domain name';
		}

		return this.domainValidator.hasError('pattern')
			? 'Unable to register <strong>' + tld.replace(/\.\./g, '') + '</strong> domains at this time'
			: '';
	}

	public randomSearch() {
		this.domainName = this.appService.generateName('domain');
		this.ngForm.ngSubmit.emit();
	}
}
