<central-header *ngIf="this.configService.config.brandConfig.id === 'imh'" title="Product Support"
	[status]="teamStatus"></central-header>

<ng-template #contact let-data="data">
	<div class="support-contact" (click)="this.openDialog(data)" [matTooltip]="this.people[data.group_name].content"
		*ngIf="data && data.user">
		<div class="position">{{ data.position }}</div>
		<div class="name">{{ data.user?.name }}</div>
		<div class="rotator">
			<img class="headshot" [class.svg]="data.user.photo?.failed"
				[src]="'assets/img/agents/' + data.user.photo.content_url" (error)="fixImage(data.group_name)" />
			<span class="icon"><img class="svg" [src]="this.people[data.group_name].icon" /></span>
		</div>
		<div class="buttons">
			<button mat-raised-button mat-mini-fab color="accent">
				<mat-icon class="icon-{{ getIcon(data.group_name) }}">{{
					getIcon(data.group_name)
					}}</mat-icon>
			</button>
		</div>
	</div>
</ng-template>

<ng-template #MHcontact let-data="data">
	<div class="support-contact inmotion" (click)="this.openDialog({department: 'managed_hosting'})" *ngIf="data">
		<div class="position">
			<div class="position-text">
				{{ data.title }}
			</div>
		</div>
		<div class="name notranslate">{{ data.name }}</div>
		<div class="rotator">
			<img class="headshot" [class.svg]="data.photo?.failed" [src]="'assets/img/agents/' + data.photo.content_url"
				(error)="fixImage(data.group_name)" />
			<span class="icon"><img class="svg" [src]="this.people['managed_hosting'].icon" /></span>
		</div>
		<div class="buttons">
		</div>
	</div>
</ng-template>

<div class="hero {{ this.style }}">
	<div class="ctw-max-w-5xl ctw-mx-auto">
		<h1>Help Guides and Support</h1>
		<div class="ctw-text-xl">
			We work 24/7/365 to make sure your service is fast, safe, and
			secure. Our mission is to make sure you are 100% satisfied with our
			products and customer service.
		</div>
	</div>
	<span class="wave" [inlineSVG]="'assets/img/wave.svg'"></span>
</div>

<div *ngIf="this.profileService.data.requires_email_verification"
	class="ctw-text-center ctw-{{ this.hasMH ? 'mt-32' : 'mt-10' }}">
	<h2>Verify Email</h2>
	<div class="ctw-max-w-lg ctw-mx-auto">
		You'll need to verify your email before reaching out for support. Please
		click on the confirmation button in the email we sent.
	</div>
</div>

<ng-container *ngIf="!this.profileService.data.requires_email_verification">
	<div [class]="this.hasMH ? 'animate__animated animate__fadeIn' : ''">
		<div class="start-here ctw-pb-6 ctw-mt-8">
			<div class="ctw-mx-auto" [class.ctw-max-w-4xl]="this.hasMH" [class.ctw-max-w-5xl]="!this.hasMH">
				<div class="ctw-mb-12" [class.ctw-text-center]="!this.hasMH">
					<h2>Start Here</h2>
					<p>
						Take a look at our documentation or open a ticket with our
						support staff for assistance.
					</p>
				</div>
			</div>
			<div class="support-items" [class.ctw-max-w-4xl]="this.hasMH" [class.ctw-m-auto]="this.hasMH"
				[class.ctw-mt-12]="this.hasMH">
				<div class="options">
					<a href="{{ configService.config.brandConfig.supportDocs }}" target="_blank" class="start-option">
						<div class="inset">
							<img class="svg"
								src="https://inmotionhosting.github.io/static-assets/illustrations/small/guide-step-by-step-how-to.svg" />
						</div>
						<h4 *ngIf="hasMH">Documentation</h4>
						<h4 *ngIf="!hasMH">View Documentation</h4>
					</a>
					<a href="#" (click)="$event.preventDefault(); this.openDialog()" class="start-option">
						<div class="inset">
							<img class="svg"
								src="https://inmotionhosting.github.io/static-assets/illustrations/small/ticket-open-create.svg" />
						</div>
						<h4>New Request</h4>
					</a>
					<a routerLink="./requests" class="start-option">
						<div class="inset">
							<img class="svg"
								src="https://inmotionhosting.github.io/static-assets/illustrations/small/ticket-view.svg" />
						</div>
						<h4>View Requests</h4>
					</a>
				</div>
			</div>
		</div>
		<div class="transition-in"></div>

		<div *ngIf="hasMH" class="managed">
			<div class="ctw-pb-6 ctw-pt-12 ctw-bg-white">
				<h2 class="ctw-max-w-4xl ctw-mx-auto">Professional Services</h2>
				<p class="ctw-max-w-4xl ctw-mx-auto ctw-py-8 ctw-mb-0">
					Platform i's Managed Hosting team excels in personalized service, offering comprehensive assistance
					for website migrations, optimization, and server configurations. Our <strong>Launch Assist
						service</strong> simplifies the migration of complex WordPress sites, managing large files,
					databases, backups, and custom setups for CDN, DNS, or SSL.<br><br>Additionally, our
					<strong>PageSpeed Pros
						experts</strong> specialize in diagnosing WordPress performance issues. We'll help you determine
					what's keeping your Core Web Vitals scores down, from inefficient themes and plugins, to
					configuration issues with the WordPress application and server. This dedicated approach ensures
					optimized performance and a superior user experience, leveraging our specialized skills to meet your
					digital needs efficiently — act now to leverage our Managed Hosting team.
				</p>
			</div>
			<div class="transition-out ctw-mb-12"></div>
			<div class="ctw-max-w-4xl ctw-mx-auto">
				<div class="ctw-mb-16">
					<h2>Reach Out</h2>
					<p>
						Meet our team of forward-thinking staff driven by
						innovation. They're available to help if you need them.
					</p>
				</div>
				<ng-container *ngIf="teamStatus === 'failed'">
					<p class="ctw-text-center error">
						An error occurred loading team members.
					</p>
				</ng-container>
				<ng-container *ngIf="teamStatus === 'loading'">
					<mat-spinner [diameter]="20" class="ctw-mx-auto" color="accent"></mat-spinner>
				</ng-container>
				<ng-container *ngIf="teamStatus === 'success'">
					<div class="contacts managed">
						<ng-container *ngFor="let member of currentTeam['managed_hosting']['users']">
							<ng-container *ngTemplateOutlet="
								MHcontact;
								context: {
									data: member
								}
							"></ng-container>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
		<div *ngIf="!hasMH" class="reach" id="the-team">
			<div class="ctw-max-w-5xl ctw-mx-auto">
				<div class="ctw-text-center ctw-mb-12">
					<h2>Reach the Team</h2>
					<p>
						Meet our team of forward-thinking staff driven by
						innovation. They're available to help if you need them.
					</p>
				</div>
				<ng-container *ngIf="teamStatus === 'failed'">
					<p class="ctw-text-center error">
						An error occurred loading team members.
					</p>
				</ng-container>
				<ng-container *ngIf="teamStatus === 'loading'">
					<mat-spinner [diameter]="20" class="ctw-mx-auto" color="accent"></mat-spinner>
				</ng-container>
				<ng-container *ngIf="teamStatus === 'success'">
					<div class="contacts">
						<ng-container *ngTemplateOutlet="
							contact;
							context: {
								data: currentTeam['OpenMetal - Account Manager']
							}
						"></ng-container>
						<ng-container *ngTemplateOutlet="
							contact;
							context: {
								data: currentTeam['OpenMetal - Support Lead']
							}
						"></ng-container>
						<ng-container *ngTemplateOutlet="
							contact;
							context: {
								data: currentTeam['OpenMetal - Technical Lead']
							}
						"></ng-container>
					</div>
					<div class="contacts secondary ctw-mt-12">
						<ng-container *ngTemplateOutlet="
							contact;
							context: {
								data: currentTeam['OpenMetal - Product Lead']
							}
						"></ng-container>
						<ng-container *ngTemplateOutlet="
							contact;
							context: {
								data: currentTeam[
									'OpenMetal - Company President'
								]
							}
						"></ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>