import { Component } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'central-reset-server',
	templateUrl: './reset-server.component.html',
	styleUrls: ['./reset-server.component.scss'],
})
export class ResetServerComponent {

	public envConfirm = "";
	public environment;
	public project;
	public state = 'pending';

	constructor(
		public apiService: ApiService,
		private router: Router,
		public dialogRef: MatDialogRef<ResetServerComponent>,
	) {}

	public submitReset() {
		const url = this.apiService.formatter.getUrl('/v1/environments/' + this.environment.id + '/reset');
		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		headers = headers.append('X-Organization-Id', this.environment.fields.organization_id);

		this.apiService.http
			.post(url, {}, { headers })
			.subscribe(
				(response: any) => {
					this.dialogRef.close();
					this.router.navigate(['/projects', this.project])
				},
				(err) => {
					console.log('err:', err);
					this.state = 'failed';
				}
			);
	}
}
