import { Component, Input } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

@Component({
	selector: 'central-footer',
	templateUrl: 'footer.component.html',
	styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Input() breakpoint = '4xl';
    @Input() actions = '';
    public copyRightYear;

    constructor(public configService: ConfigurationService,) {
        this.copyRightYear = (new Date()).getFullYear();
    }
}