<form #form="ngForm" (ngSubmit)="form.valid && this.addMember()">
	<h3 mat-dialog-title class="ctw-capitalize" *ngIf="this.configurationService.config.brandConfig.id !== 'imh'">
		Invite Member
	</h3>
	<h2 mat-dialog-title class="ctw-capitalize" *ngIf="this.configurationService.config.brandConfig.id === 'imh'">
		Bring The Whole Team
	</h2>
	<ng-container *ngIf="this.data?.project">
		<p>
			Billing, Subscriptions and Collaboration is handled in
			<strong><a href="/teams">Teams</a></strong>.
		</p>
		<p>
			Invite a user now to join this project under
			<strong>
				<a href="/account/{{ this.data.ownerId }}/members" class="notranslate">
					{{this.data.ownerName}}
				</a>
			</strong>.
		</p>
	</ng-container>

	<p>
		<span *ngIf="this.configurationService.config.brandConfig.id === 'imh'">
			Team members can see all Projects assigned to a Team and you can
			assign a separate roles to user now or after sending your invitation.
		</span> <span *ngIf="!this.data?.project">Send a user an invitation to collaborate.</span>
	</p>
	<p class="error error-message" *ngIf="this.state === 'failed'">
		An error occurred inviting team member. Check the provided email(s) and try
		again.
	</p>
	<mat-dialog-content class="dialog-content">
		<form [formGroup]="rulesForm">
			<mat-form-field class="example-chip-list" [class.ctw-w-full]="this.configurationService.config.brandConfig.id !== 'imh'" appearance="{{ this.configurationService.config.inputAppearance || 'fill' }}">
				<mat-label>Member's E-mail</mat-label>
				<mat-chip-list #chipList formArrayName="emailsList" aria-label="Member's E-mail">
				<mat-chip *ngFor="let email of emails" [color]="(email.invalid) ? 'warn' : ''" [selectable]="selectable" [removable]="removable" (removed)="remove(email)">
					{{email.value}}
					<button matChipRemove *ngIf="removable">
						<mat-icon>cancel</mat-icon>
					</button>
				</mat-chip>
				<input [hidden]='this.emails.length >= this.emailLimit' placeholder="Enter e-mail..."
					[matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="addOnBlur"
					(matChipInputTokenEnd)="add($event)">
				</mat-chip-list>
				<p class="ctw-mt-6 mat-error" *ngIf="this.emails.length >= this.emailLimit">Only {{this.emailLimit}} Emails can be invited at once!</p>
				<p class="ctw-mt-6 mat-error" *ngIf="rulesForm.get('emailsList').hasError('incorrectEmail')">Invalid email ID</p>
			</mat-form-field>
		</form>

	</mat-dialog-content>
	<mat-form-field [class.ctw-mt-5]="this.configurationService.config.brandConfig.id !== 'imh'" class="ctw-w-full"
		appearance="{{ this.configurationService.config.inputAppearance || 'fill' }}">
		<mat-label>Permission</mat-label>
		<mat-select name="permission" [(ngModel)]="this.role">
			<ng-container *ngFor="let item of this.permissions">
				<mat-option [value]="item.role">
					{{item.label}}
				</mat-option>
			</ng-container>
		</mat-select>
	</mat-form-field>
	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button type="button" mat-button (click)="this.dialogRef.close()">
			Cancel
		</button>
		<progress-button color="primary" [state]="state" class="ctw-ml-3">Send Invite</progress-button>
	</div>
</form>
