import { Component, Input, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { ApiService, ProfileService } from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { ReplaceForm } from './replace.model';
import { AuthService } from '@central/ng-shared';
import { LicenseService } from '../../../../shared/license/license.service';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'central-connect-key-dialog',
	templateUrl: './connect-key-dialog.component.html',
	styleUrls: ['./connect-key-dialog.component.css'],
})
export class ConnectKeyDialogComponent {
	@Input() public connectId;
	@Input() public connectKey;
	@ViewChild('confirmationForm') ngForm: NgForm;

	public copyText = 'Copy to Clipboard';
	public loading = true;
	public model = new ReplaceForm(false);
	public state = 'pending';
	public replaceKeyView = false;

	public newConnectKey = '';
	public errorMessage = '';
	public genericError = 'Unable to replace Connect Key. Please try again.';

	constructor(
		public apiService: ApiService,
		public appService: AppService,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar,
		private licenseService: LicenseService,
		private authService: AuthService,
		public profileService: ProfileService,
	) {}

	public copy() {
		this.appService.copyToClipboard(this.connectKey, 'Connect Key');
	}

	public success(newConnectKey: string) {
		this.state = 'success';
		this.newConnectKey = newConnectKey;
		this.connectKey = newConnectKey;
	}

	public fail(response?: object) {
		this.state = 'fail';
		this.errorMessage = this.genericError;

		if (
			response &&
			response['result'] &&
			response['result']['data'] &&
			response['result']['data']['error']
		) {
			this.errorMessage = response['result']['data']['error'];
		}
	}
	public onSubmit(isValid: boolean) {
		if (this.state !== 'submitted') {
			if (isValid) {
				this.addKey();
			} else {
				this.state = 'fail';
			}
		}
	}
	public getAccountId() {
		const connectKey = this.licenseService
			.getConnectKeys()
			.find((key) => +key.connect_id === +this.connectId);
		return connectKey?.account_id || null;
	}
	public addKey() {
		this.state = 'submitted';
		this.errorMessage = '';

		// '/account/v1/key/reissue'.
		const url = this.apiService.formatter.getUrl('keyReplace');

		// Add organization id to the header.
		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		headers = headers.append('X-Organization-Id', this.getAccountId());

		this.apiService.http
			.post(url, { connect_id: this.connectId }, { headers })
			.subscribe(
				(response) => {
					const result = response['result'];

					if (result && result.data && result.data.success) {
						this.success(result.data['new_key']);
						this.profileService.fetch();
					} else {
						this.fail(response);
					}
				},
				() => this.fail()
			);
	}

	public replaceKey() {
		this.replaceKeyView = this.replaceKeyView ? false : true;
	}
}
