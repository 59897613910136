import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Subscription } from 'rxjs';
import { SupportService } from './support.service';

export const supportResolver: ResolveFn<Subscription> = (route: ActivatedRouteSnapshot) =>{
    const paramRoute = route.pathFromRoot.find((parent: ActivatedRouteSnapshot) => parent.params['account_id'] );
    const accountId = paramRoute?.paramMap.get('account_id');
    if( ! accountId ) {
        return null;
    }
    return inject(SupportService).getSupportOptions(accountId).subscribe();
}
