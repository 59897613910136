import { Component, OnInit } from '@angular/core';
import { AccountService, ApiService, ProfileService } from '@central/ng-shared';
import { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'receipts-list',
	templateUrl: 'list.component.html',
	styleUrls: ['list.component.scss'],
})
export class InvoiceListComponent implements OnInit {
	public status = 'pending';
	public singleLink = '/account/receipts';
	public receipts = [];
	public invoices = [];
	public currencyFormatter;

	constructor(
		public apiService: ApiService,
		public accountService: AccountService,
		private profileService: ProfileService,
	) {}

	public ngOnInit() {
		this.accountService.authAccountUpdate.subscribe(() => {
			this.singleLink = `/account/${this.accountService.id}/receipts`;

			merge(...[this.getInvoices()]).subscribe({
				next: () => {
					this.status = 'success';
				},
				error: () => {
					this.status = 'fail';
				},
			});
		});
	}

	public moneyFormat(priceInCents, currency) {
		this.currencyFormatter = new Intl.NumberFormat(this.profileService.data.locale, {
			style: 'currency',
			currency: currency,
		});

		return this.currencyFormatter.format((priceInCents / 100).toFixed(2));
	}

	private getInvoices() {
		return this.apiService
			.get(`/v1/wpcr/accounts/${this.accountService.id}/invoices`, {})
			.pipe(
				map((invoices: any[]) => {
					this.invoices = invoices;
				})
			);
	}
}
