import { Injectable } from '@angular/core';
import { DecoderService } from 'libs/ng-shared/src/lib/core/jwt/decoder';

@Injectable()
export class UserEchoService {
	public configuration = {
		host: 'boldgrid.userecho.com',
		script: 'https://cdn.userecho.com/js/widget-1.4.gz.js',
		forumIds: {
			userSupport: 14,
			partnerSupport: 14,
		},
	};

	public scriptReturned = false;
	private fetchingScript = false;

	constructor(public jwtHelper: DecoderService) {}

	/**
	 * Open the feedback prompt. Loads needed resources.
	 *
	 * @since 1.3
	 */
	public openFeedbackPrompt(): void {
		if (this.fetchingScript) {
			return;
		}

		this.setGlobalVariables();

		try {
			/*
			 * Forum id is passed here to force the popup to reinitialize. This prevents a user
			 * from accessing a session that is not theirs.
			 */
			if (this.scriptReturned) {
				window['UE'].Popin.setupOptions(window['_ues']);
				window['UE'].Popin.show(
					this.configuration.forumIds.userSupport
				);
			} else {
				this.getScript().then(() =>
					window['UE'].Popin.show(
						this.configuration.forumIds.userSupport
					)
				);
			}
		} catch (e) {
			alert(
				"We're experiencing issues with our feedback portal. Please try again later."
			);
		}
	}

	/**
	 * Authenticate a user in user echo. Passes over sso token.
	 *
	 * @since 1.3
	 */
	public authenticate() {
		this.setGlobalVariables();

		try {
			if (this.scriptReturned) {
				window['UE'].Popin.setupOptions(window['_ues']);
			} else {
				this.getScript().then(() => window['UE'].Popin.preload());
			}
		} catch (e) {
			console.log('Failed to authenticate userecho.');
		}
	}

	/**
	 * If the return route for sign in is user echo. Add sso token.
	 *
	 * @since 1.3
	 *
	 * @param   returnRoute Route requested.
	 * @return              Updated Route.
	 */
	public formatReturnRoute(returnRoute: string): string {
		if (-1 !== returnRoute.indexOf('ue_social_auth')) {
			returnRoute =
				returnRoute +
				'?sso_token=' +
				this.jwtHelper.getTokenVal('userEchoSso');
		}

		return returnRoute;
	}

	/**
	 * Set the global variables needed by UserEcho.
	 *
	 * @since 1.3
	 */
	public setGlobalVariables(): void {
		window['_ues'] = {
			host: this.configuration.host,
			forum: this.configuration.forumIds.userSupport,
			lang: 'en',
			tab_show: false,
			params: {
				envdata: 'Source: InMotion Central',
				sso_token: this.jwtHelper.getTokenVal('userEchoSso'),
			},
		};
	}

	/**
	 * Get support Request Script.
	 *
	 * @since 1.3
	 *
	 * @return Get the user echo script.
	 */
	public getScript() {
		this.fetchingScript = true;

		const scriptPromise = new Promise((resolve, reject) => {
			const script = document.createElement('script');
			document.body.appendChild(script);
			script.onload = resolve;
			script.onerror = reject;
			script.async = true;
			script.src = this.configuration['script'];
		});

		return scriptPromise.then(() => {
			this.fetchingScript = false;
			this.scriptReturned = true;
		});
	}
}
