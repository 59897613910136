import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [PhoneNumberComponent],
	imports: [CommonModule, FormsModule],
	exports: [PhoneNumberComponent],
})
export class PhoneNumberModule {}
