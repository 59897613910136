<div class="ctw-flex mobile-menu"
	*ngIf="!this.hideMobileHeader && this.isMobile && !this.router.url.includes('/guide/')">
	<span class="ctw-flex ctw-items-center">
		<a routerLink="/"><img [src]="this.brandingService.getThemedAssetUrl('logo2.svg')" /></a>
	</span>
	<button mat-icon-button color="primary" aria-label="Expand Menus" (click)="openMenus()">
		<mat-icon>menu</mat-icon>
	</button>
</div>
<mat-sidenav-container class="primary-sidenav">
	<mat-sidenav [mode]="this.getMode()" [opened]="this.authenticated" disableClose>
		<mat-nav-list class="expanded">
			<div class="nav-groups">
				<div>
					<div class="logo-container dark">
						<a class="logo" routerLink="/">
							<img [src]="this.brandingService.getThemedAssetUrl('logo3.svg')" class="ctw-mx-auto" />
						</a>
					</div>
					<div class="main-nav">
						<ng-container *ngIf="this.profileService?.data?.options.public_selected_team">
							<a class="active-team" mat-list-item routerLink="/team/{{this.activeTeamId}}/members"
								[class.router-link-active]="this.activeMenuItem === 'team'">
								<span class="mat-icon material-icons notranslate">groups</span>
								<span
									class="label notranslate display_name ctw-font-bold">{{this.activeTeamName}}</span>
							</a>
							<mat-divider></mat-divider>
							<a mat-list-item routerLink="/projects" [routerLinkActive]="['router-link-active']">
								<mat-icon>web_asset</mat-icon>
								<span class="label">Projects</span>
							</a>
							<a mat-list-item routerLink="/domains" [routerLinkActive]="['router-link-active']">
								<mat-icon>language</mat-icon>
								<span class="label">Domains</span>
							</a>
							<a mat-list-item routerLink="/email" [routerLinkActive]="['router-link-active']">
								<mat-icon>alternate_email</mat-icon>
								<span class="label">Email</span>
							</a>
						</ng-container>
					</div>
				</div>
				<div class="footer-nav">
					<div *ngIf="this.offer?.description && this.offer?.description.split('|').length === 3"
						class="ctw-flex-col ctw-my-8 ctw-mx-2 ctw-text-center ctw-uppercase offer"
						(click)="this.openOffer()">
						<div class="ctw-pt-2">{{this.offer?.description.split('|')[0]}}</div>
						<div class="ctw-py-2">{{this.offer?.description.split('|')[1]}}
							{{this.offer?.discount_percent}}%</div>
						<div
							[class]="this.offer?.description.split('|')[2].length > 22 ? 'ctw-text-left ctw-p-4' : 'ctw-px-2'">
							{{this.offer?.description.split('|')[2]}}</div>
						<div class="ctw-text-right">Expires: {{this.offer?.redeem_by_date.date.split('
							')[0] | amDateFormat : 'LL' }}</div>
					</div>
					<a *ngIf="this.profileService?.data.display_name" mat-list-item routerLink="/account/preferences"
						[class.router-link-active]="this.activeMenuItem === 'user'">
						<img class="profile-image" [src]="this.profileService.data.image" [attr.data-icon-count]="
						this.profileService.data.notifications
					" />
						<span class="label notranslate ctw-capitalize display_name">{{
							this.profileService.data.display_name }}</span>
					</a>
					<mat-divider></mat-divider>
					<a *ngIf="this.showReadme" mat-list-item routerLink="/readme" target="_blank" class="ctw-bg-white">
						<mat-icon class="ctw-pr-4">menu_book</mat-icon>
						<span class="label ctw-flex ctw-justify-between ctw-w-full">README <mat-icon
								class="open-in-new ctw-text-base" style="margin-top: 1px;">open_in_new</mat-icon></span>
					</a>
					<button *ngIf="this.authService.isAdmin()" color="accent" mat-list-item routerLink="/agent"
						[routerLinkActive]="['router-link-active']" class="ctw-bg-black agent-tools">
						<mat-icon class="ctw-text-red-700">admin_panel_settings</mat-icon>
						<span class="label agent notranslate">Agent Tools</span>
					</button>
				</div>
			</div>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
