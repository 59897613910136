import { Injectable } from '@angular/core';
import { ApiService, ConfigurationService } from '../../core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ZendService {
	public ticket;
	public ticket_list;
	public customFieldCloudId: number;

	constructor(
		public apiService: ApiService,
		public configService: ConfigurationService
	) {
		this.customFieldCloudId =
			this.configService.config['zendesk'].customFields?.cloudId;
	}

	public ticketInfo(organization_id: string, id: number) {
		return new Observable((observer) => {
			this.apiService
				.get(
					`/v1/organizations/${organization_id}/zendesk/ticket/${id}`,
					{}
				)
				.subscribe(
					(ticket_info: any[]) => {
						this.ticket = ticket_info;
						observer.next(this.ticket);
						observer.complete();
					},
					(error) => {
						this.ticket = null;
						observer.error(error);
					}
				);
		});
	}

	public listTickets(organization_id = '') {
		return new Observable((observer) => {
			this.apiService
				.get(`/v1/organizations/${organization_id}/zendesk/tickets`, {})
				.subscribe(
					(ticket_list: any[]) => {
						observer.next(ticket_list);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}
	public addReply(account: any, params: any) {
		return new Observable((observer) => {
			const accountId = account.organization_id || account.account_id;
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});
			const url = this.apiService.formatter.getUrl(
				`/v1/accounts/${accountId}/zendesk/add-comment`
			);

			this.apiService.http
				.post(
					url,
					{
						id: params.ticket_id,
						author_id: accountId,
						comment: params.comment,
					},
					{ headers }
				)
				.subscribe(
					(message) => {
						observer.next(message);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}
}
