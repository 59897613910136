import { Component, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'amp-password-create',
	templateUrl: 'password-create.component.html',
	styleUrls: [],
})
export class PasswordCreateComponent {
	@ViewChild('password') public password: any;
	public isValid;
	public minPasswordChar = 12;
	@Input() public readOnlyForm = false;

	public inputType = 'password';

	public getInfo() {
		return this.password
	}

	public onStrengthChanged(e) {
		this.isValid = 100 === e && this.password.length !== 0;
	}
	public updatePassword(value) {
		this.password = value
	}
}