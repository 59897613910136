<mat-expansion-panel #matPanel class="contextual-help-card card" (opened)="this.opened.emit()"
	[expanded]="this.expanded" [disabled]="this.disabled" [class.transparent]="this.transparent">
	<mat-expansion-panel-header *ngIf="this.showHeader" collapsedHeight="auto" expandedHeight="auto"
		class="ctw-bg-neutral-200">
		<mat-panel-title class="contextual-help-card-title valign-wrapper" [class.ctw-default-cursor]="disabled">
			<span *ngIf="customIcon" class="{{ customIcon }}"></span>
			<mat-icon class="ctw-z-10" *ngIf="icon">{{ icon }}</mat-icon>
			<span class="valign title-text">
				<span class="title ctw-capitalize" *ngIf="cardTitle">{{ cardTitle }}</span>
				<ng-container *ngFor="let sub of subTitle">
					<span class="sub-title ctw-text-base" *ngIf="sub">
						{{ sub }}
					</span>
				</ng-container>
				<ng-content select="[subTitleHTML]"></ng-content>
			</span>
			<ng-container *ngIf="iframe">
				<div class="address-bar ctw-inline-block ctw-text-base" [class]="iframe.includes('https') ? 'ssl' : ''"
					href="{{ iframe }}" target="_blank">
					{{ iframe }}
				</div>
			</ng-container>

			<span help *ngIf="help && this.centralArticle" (click)="this.openHelp()"
				class="ctw-flex ctw-ml-auto ctw-cursor-pointer">
				<mat-icon class="ctw-z-10 ctw-text-imh-gray-200">help</mat-icon>
			</span>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<ng-content class="ctw-p-0"></ng-content>
</mat-expansion-panel>