export default {
	'managed_hosting': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg',
	},
	'OpenMetal - Technical Lead': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/assisted_setup-migration-support.svg',
		content:
			"I'm here to help with onboarding, key questions you may have as you migrate workloads, understanding of how to administer an OpenStack Cloud, and many other questions.  After onboarding, ongoing high touch Support requires a Support Plan.",
	},
	'OpenMetal - Account Manager': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/performance-experts.svg',
		content:
			"I'm here to help with onboarding, understanding common Use Cases, sizing of your deployments, Cost Controls, exploring different Support Levels, and many other questions.  This is also your first contact for any issue that has not been resolved to your satisfaction.",
	},
	'OpenMetal - Product Lead': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/cx-service-support.svg',
		content:
			"I can help with feature requests and bug fixes. I'm  also an expert in OpenStack and can often guide you to documentation or tutorials on solving nearly any infrastructure need with OpenStack.",
	},
	'OpenMetal - Support Lead': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/protect-your-data.svg',
		content:
			"I'm available for escalations of technical or quality of service issues. I  pride myself in being available but also in empowering my staff to be the problem solvers.",
	},
	'OpenMetal - Company President': {
		icon: 'https://inmotionhosting.github.io/static-assets/illustrations/small/reseller.svg',
		content:
			"I'm available for feedback and to help you in case something you need falls outside of what your team has been able to do for you.",
	},
};
