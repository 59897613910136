import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
	selector: 'central-create-snapshot-dialog',
	templateUrl: './create-snapshot-dialog.component.html',
})
export class CreateSnapshotDialogComponent {
	snapshotName = '';
	placeholder = 'Snapshot Name';
	status = 'pending';
	private _originalClose: any;
	public snapshotFormControl = new UntypedFormControl('', Validators.pattern('^[a-zA-Z0-9]+$'));

	constructor(
		private _dialogRef: MatDialogRef<CreateSnapshotDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private _dialogData
	) {
		this._originalClose = this._dialogRef.close;
		this._dialogRef.close = this.closeDialog.bind(this);
	}

	closeDialog(reason = '') {
		if (reason === 'cancel') {
			this._originalClose.bind(this._dialogRef)(reason)
		} else {
			if ( /^[a-zA-Z0-9]+$/.test(this.snapshotName) ) {
				this._originalClose.bind(this._dialogRef)(this.snapshotName);
			}
		}
	}
}
