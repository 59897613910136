import { Component } from '@angular/core';
import { AuthService } from '@central/ng-shared';

/**
 * This class represents the main application component. Within the @Routes annotation is the configuration of the
 * applications routes, configuring the paths for the lazy loaded components (HomeComponent, AboutComponent).
 */
@Component({
	selector: 'bg-logout',
	template: ``,
})
export class LogoutComponent {
	constructor(private authService: AuthService) {
		authService.logout();
	}
}
