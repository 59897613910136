<form (ngSubmit)="this.update()" name="account-options" *ngIf="this.options.length">
	<div *ngFor="let option of this.options">
		<ng-container *ngIf="option.type === 'boolean'">
			<mat-checkbox [(ngModel)]="option.value" [name]="'account-option-' + option.id" (change)="this.onChanges( option.id, option.value )">{{option.name}}</mat-checkbox>
		</ng-container>
	</div>
	<div class="ctw-mt-3">
		<progress-button [state]="this.state" [disabled]="this.hasChanges()">Update Account Options</progress-button>
		<div *ngIf="state !== 'pending'">
			<div class="success" *ngIf="this.state === 'success'">Successfully Updated</div>
			<div class="error" *ngIf="this.state === 'failed'">Unable to update options</div>
		</div>
	</div>
</form>