<ng-container *ngIf="data.canLeave">
	<h4 mat-dialog-title>Leave Organization "{{ data.organizationName }}"?</h4>
	<mat-dialog-content class="mat-typography">
		<p>You will lose access to all clouds managed by this organization.</p>
		<p class="error" *ngIf="this.errorMessage">{{ this.errorMessage }}</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
		<span class="spacer"></span>
		<progress-button
			color="warn"
			[disabled]="false"
			[state]="state"
			(click)="leaveOrganization()"
			>Leave
			{{
				this.configurationService.config.groupLabels[
					'group-label-title-case'
				]
			}}</progress-button
		>
	</mat-dialog-actions>
</ng-container>
<ng-container *ngIf="!data.canLeave">
	<h4 mat-dialog-title>
		Cannot Leave
		{{
			this.configurationService.config.groupLabels[
				'group-label-title-case'
			]
		}}
		"{{ data.organizationName }}"
	</h4>
	<mat-dialog-content class="mat-typography">
		<p>
			You are the sole owner of this
			{{
				this.configurationService.config.groupLabels[
					'group-label-lower-case'
				]
			}}. Every
			{{
				this.configurationService.config.groupLabels[
					'group-label-lower-case'
				]
			}}
			must have at least one member assigned the role of owner.
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close cdkFocusInitial>OK</button>
	</mat-dialog-actions>
</ng-container>
