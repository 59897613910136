import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'central-text-copy',
	templateUrl: './text-copy.component.html',
	styleUrls: ['./text-copy.component.scss'],
})
export class TextCopyComponent {
	public copying = false;
	@Input() cssClasses = 'value';

	constructor(private clipboard: Clipboard) {}

	public copyValue(element) {
		element.classList.add('copy-highlight');
		this.copying = true;

		this.clipboard.copy(element.innerText);

		setTimeout(() => {
			element.classList.remove('copy-highlight');
			this.copying = false;
		}, 1000);
	}
}
