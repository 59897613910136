<p>
	The current Site URL for this environment is <span class="ctw-inline-flex"><a target="_blank" href="{{
		this.projectService.environment.fields.site_url }}" class="ctw-inline-flex notranslate"> {{
			this.projectService.environment.fields.site_url }}</a>
		<mat-icon class="ctw-ml-1 ctw-mt-1 ctw-text-sm ctw-h-3.5 ctw-w-3.5 ctw-mr-1">open_in_new</mat-icon></span> <span
		*ngIf="this.currentDomain && this.currentDomain !== '' && this.projectService.environment.fields.site_url.split('/')[2] !== this.currentDomain">and
		is currenly being updated to http://{{ this.currentDomain }}. </span>
</p>

<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success' || this.state === 'unavailable'">
	<p class="ctw-mt-0" *ngIf="this.available && this.domainState === 'needs-dns'">
		<mat-card appearance="outlined">
			<mat-card-content class="ctw-flex ctw-text-red-700">
				<mat-icon class="ctw-mr-1">warning</mat-icon>
				<i class="ctw-w-full">The DNS for your preferred domain appears to be managed by another
					system and does not point to this environment. It is recommended that you add the following A record
					to where DNS for <span class="notranslate ctw-font-bold">{{ this.currentDomain }}</span> is being
					managed to minimize interruption:</i>
			</mat-card-content>
			<mat-form-field appearance="outline" class="needs-dns">
				<mat-label>DNS Entry</mat-label>
				<input class="dns-entry" matInput readonly value="{{ this.dnsEntry }}" />
				<div class="ctw-absolute ctw-top-0 ctw-right-0">
					<button class="ctw-mr-2" mat-button (click)="copy()">
						Copy
					</button>
					<progress-button *ngIf="0" class="ctw-top-0 ctw-right-0" mat-raised-button color="primary"
						state="submitted" [disabled]="true">
						Waiting for DNS...
					</progress-button>
				</div>
			</mat-form-field>
		</mat-card>
	</p>
	<p class="ctw-mt-0" *ngIf="this.available && this.domainState === 'needs-registration'">
		<mat-card appearance="outlined">
			<mat-card-content class="ctw-flex ctw-text-red-700">
				<mat-icon class="ctw-mr-1">warning</mat-icon>
				<i class="ctw-w-full">Your Site URL's domain name appears to be available for registration! It is
					recommended that you register this domain ASAP if you wish to use it permanetly for this site.</i>
			</mat-card-content>
			<mat-form-field appearance="outline" class="needs-dns">
				<mat-label>Available for Registration</mat-label>
				<input class="dns-entry" matInput readonly value="{{ this.currentDomain }}" />
				<div class="ctw-absolute ctw-top-0 ctw-right-0">
					<button class="ctw-top-0 ctw-right-0" mat-stroked-button color="primary"
						[routerLink]="['/domains']">
						Register Now
					</button>
				</div>
			</mat-form-field>
		</mat-card>
	</p>
	<div *ngIf="this.available && (this.domainState === 'needs-registration' || this.domainState === 'needs-dns')"
		class="ctw-justify-center ctw-text-imh-gray-200 ctw-py-4 ctw-flex"><mat-divider
			class="ctw-mt-3 ctw-w-full ctw-ml-20 ctw-mr-4"></mat-divider>OR<mat-divider
			class="ctw-mt-3 ctw-w-full ctw-mr-20 ctw-ml-4"></mat-divider></div>
	<central-domain-change [disabled]="!this.available" (domainChangeReady)="this.isReady()"
		[current]="this.projectService.environment.fields.site_url"
		[hostname]="this.projectService.environment.fields.hostname" [usedDomains]="this.usedDomains"
		[currentDomain]="this.currentDomain" [state]="this.domainCheckState"
		[context]="this.projectService.owner?.account_id" (domainChangeBack)="this.dnsView = 'state-based'"
		(domainChangeSubmit)="checkDomainStatus($event)" [validating]="this.domainCheckState" [invalid]="this.invalid">
	</central-domain-change>

</ng-container>

<central-auto-ssl></central-auto-ssl>