<mat-spinner *ngIf="'loading' === componentState" class="component-loader" diameter="50" color="accent"></mat-spinner>

<failed-request *ngIf="'failed' === componentState"></failed-request>

<ng-container *ngIf="'signupsEnabled' === componentState">
	<div class="sign-in" [class.ctw-invisible]="this.authService.isLoggedIn()">
		Have an account?<br><a routerLink="/login">Log In</a>
	</div>

	<div class="login-form">
		<bg-registration #registration [isGuide]="true" [hideIntro]="true" [shouldRedirectOnReset]="false"
			[hideSignedInAs]="this.projectCreateService.skipPurchase"></bg-registration>
	</div>
	<div *ngIf="this.authService.isLoggedIn() && !this.projectCreateService.skipPurchase"
		class="ctw-text-center ctw-mt-5">
		<button mat-stroked-button (click)="this.projectCreateService.back()" class="ctw-mr-4">
			Back
		</button>
		<button mat-raised-button color="primary" (click)="this.projectCreateService.next()">
			Next
		</button>
	</div>
</ng-container>
