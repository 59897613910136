import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TitleService, ApiService, AuthService, AccountService, ProfileService } from '@central/ng-shared';
import { Router } from '@angular/router';
import { PaymentComponent } from '../../../checkout';
import { ProjectCreateService } from '../project-create.service';
import { AppService } from '../../../app.service';

@Component({
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit, AfterViewInit {
	@ViewChild('paymentForm') paymentForm: PaymentComponent;

	public packages = [];
	public excludedInputs = ['organization'];
	public forceLogin = true;
	public loginModalTitle = 'Sign-Up';
	public loginModalSubTitle = 'First, let\'s connect you to the Platform';
	public minimalForm = true;
	public skipPurchase = false;

	constructor(
		public projectCreateService: ProjectCreateService,
		public apiService: ApiService,
		public authService: AuthService,
		public profileService: ProfileService,
		public router: Router,
		public titleService: TitleService,
		private appService: AppService,
	) {
		this.titleService.setTitle('Payment');

		// If the user presses the back button, send them back to the first step.
		if (this.projectCreateService.getSelections().mode !== 'purchase') {
			this.projectCreateService.navigateTo(1);
		}
	}

	ngOnInit(): void {
		this.packages = [...this.packages, ...this.getDomainPlan()];
		this.packages = [...this.packages, ...this.getEmailPlan()];
		this.packages = [...this.packages, ...this.getServerPlan()];
		this.packages = [...this.packages, ...this.getAddons()];

		this.authService.onLogin.subscribe(async () => {
			if (!this.profileService.data.onboarded) {
				this.profileService.update({
					public_source: this.appService.sessionStorage.getItem('mktgData')
				}).subscribe();
			}

			if (this.projectCreateService.skipPurchase) {
				this.skipPurchase = this.projectCreateService.skipPurchase;
				this.apiService.post('/v1/guide/override', {
					coupon_code: this.projectCreateService.couponData?.coupon_code
				}).subscribe({
					next: (res: any) => {
						if (res.login_token) {
							this.authService.setLogin(res.login_token, true);
							if (this.projectCreateService.couponData?.coupon_code.match(/^partner-.*-free$/)) {
								this.projectCreateService.projectService.organizationId =
									this.profileService.data.account_access[1]['account_id'];
								this.projectCreateService.projectService.createProject({
									label: 'My First Project',
									fields: {
										'coupon_code': this.projectCreateService.couponData?.coupon_code,
									},
								}).subscribe(
									(response: any) => {
										// this.router.navigate(['/projects']);
									},
									(error) => {
										console.log(error);
									}
								);
							} else {
								this.router.navigate(['/projects']);
							}
						}
					},
					error: (err: any) => {
						console.error(err);
					},
				});
			} else if (this.authService.isAdmin() && !this.profileService.data.onboarded) {
				this.apiService.post('/v1/guide/override', {
					coupon_code: 'employee'
				}).subscribe({
					next: (res: any) => {
						if (res.login_token) {
							this.authService.setLogin(res.login_token, true);
							this.router.navigate(['/projects']);
						}
					},
					error: (err: any) => {
						console.error(err);
					},
				});
			} else {
				// This is called twice upon login and successful purchase
				if (this.projectCreateService.couponData &&
					this.projectCreateService.couponData.coupon_code.match(/-free$/)) {
					// This is a freemium entry coupon and this user is an existing customer
					this.router.navigate(['/']);
				}
			}
		});
	}

	ngAfterViewInit(): void {
		this.paymentForm.organizationId =
			this.projectCreateService.getSelections().organizationId;

		this.paymentForm.cart.cartUpdated.subscribe(() => {
			this.projectCreateService.updateSelections(
				'term',
				this.paymentForm.cart.term
			);
		});

		this.paymentForm.paymentSuccess.subscribe(() => {
			if (!this.getCouponData()) {
				// Hide Payment Title
				document.querySelector('h2').parentElement.style.display = 'none';
			}
			this.paymentForm.receipt.products.find((product) => {
				if (product.code.startsWith('wpc_project')) {
					this.projectCreateService.updateSelections(
						'primarySubscriptionUuid',
						product.resource_ids.subscription_uuid
					);
				}
			});
		});
	}

	private getDomainPlan() {
		const domainPlan = [];
		if (this.projectCreateService.getSelections().domainName) {
			domainPlan.push({
				planCode: 'domain-gtld-1',
				options: {
					allows_multiple: false,
					domain_name:
						this.projectCreateService.getSelections().domainName,
				},
			});
		}

		return domainPlan;
	}

	private getServerPlan() {
		const serverPlan = [];
		if (this.projectCreateService.getSelections().serverTier) {
			const planName =
				'wphosting_tier_' +
				this.projectCreateService.getSelections().serverTier;

			serverPlan.push({
				planCode: planName,
				options: {
					allows_multiple: false,
					data_center: this.projectCreateService.getSelections().dataCenter,
					project_id: this.projectCreateService.getSelections().projectId
				},
			});
		}

		return serverPlan;
	}

	private getAddons(): any[] {
		const addons = [];
		for (const addon of this.projectCreateService.getSelections()
			.projectAddons) {
			addons.push({
				planCode: addon,
				options: {
					allows_multiple: false,
				},
			});
		}

		return addons;
	}

	private getEmailPlan(): any[] {
		const emails = [];
		if (this.projectCreateService.getSelections().numberOfEmails > 0) {
			emails.push({
				planCode: 'email_account',
				quantity:
					this.projectCreateService.getSelections().numberOfEmails,
				options: {
					allows_multiple: true,
				},
			});
		}

		return emails;
	}

	public getCouponData() {
		if (this.projectCreateService.couponData) {
			return this.projectCreateService.couponData;
		}
	}

	public isTrial() {
		if (this.getCouponData()) {
			return this.getCouponData().coupon_code.match(/-trial$/) ? true : false;
		}
		return false;
	}
}
