import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ScriptService } from '../../core/script/script.service';
import { ConfigurationService } from '../../core/configuration.service';
import { AfterAppend, OnSetUser, SubTrackingService } from '../base/sub-tracking.service';
import { TrackingService } from '../tracking.service';
import { AuthService } from '../../authentication/services/auth.service';

// eslint-disable-next-line
declare let gtag: any;
// eslint-disable-next-line
declare let BG_GA_Tags: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService extends SubTrackingService implements AfterAppend, OnSetUser{
	public eventCategory = 'boldgrid_central';
	public eventActionPrefix = '';
	public paramBlacklist = ['email'];
	script = 'googleAnalytics';
	constructor(
		private router: Router,
		scriptService: ScriptService,
		private configService: ConfigurationService,
		private authService: AuthService,
		trackingService: TrackingService,
    private location: Location,
	) {
		super(trackingService,scriptService);
		this.setupPageView();
		this.eventCategory =
			this.configService.config['googleAnalytics'].eventCategory ||
			this.eventCategory;
		this.eventActionPrefix =
			this.configService.config['googleAnalytics'].eventActionPrefix ||
			this.eventActionPrefix;
	}

	public afterAppend() {
		if (this.configService.config['googleAnalytics'].routeOverrides) {
			const override = this.configService.config['googleAnalytics'].routeOverrides.find(
			    item => this.location.path().startsWith(item.route)
			);
			window['BG_GA_Tags'] = {
			    marketing: override?.marketing ?? 
				this.configService.config['googleAnalytics'].marketing
			};
		} else {
			window['BG_GA_Tags'] = {
			    marketing:
				this.configService.config['googleAnalytics'].marketing,
			};
		}

		window['dataLayer'] = window['dataLayer'] || [];
		window['gtag'] = function () {
			window['dataLayer'].push(arguments);
		};

		window['gtag']('js', new Date());
		window['dataLayer'].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});

		// Page views are tracked within the application.
		window['gtag']('config', window['BG_GA_Tags'].marketing, {
			linker: { accept_incoming: true },
			send_page_view: false,
		});

		if (this.configService.config['googleAdwords']?.property) {
			window['gtag'](
				'config',
				this.configService.config['googleAdwords']?.property,
				{ send_page_view: false }
			);
		}
		this.authService.onLogin.subscribe((data) => {
			this.gaSend({
				event_action: 'login',
			});
		});
	}

	/**
	 * Send page view on navigation.
	 *
	 * @since 1.9.0
	 */
	public setupPageView() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd && 'undefined' !== typeof gtag) {
				const params = new URLSearchParams(window.location.search);
				params.forEach((val, key) => {
					if (this.paramBlacklist.includes(key)) {
						params.delete(key);
					}
				});

				let pagePath =
					event.urlAfterRedirects.split('?')[0] +
					'?' +
					params.toString();
				let pageLocation =
					window.location.origin +
					window.location.pathname +
					'?' +
					params.toString();

				pagePath = pagePath.replace(
					/\/(account|clouds|team)\/[a-zA-Z\d]+\//,
					'/$1/-/'
				);
				pageLocation = pageLocation.replace(
					/\/(account|clouds|team)\/[a-zA-Z\d]+\//,
					'/$1/-/'
				);

				gtag('config', BG_GA_Tags.marketing, {
					page_path: pagePath,
					page_location: pageLocation,
				});

				if (this.configService.config['googleAdwords']?.property) {
					gtag(
						'config',
						this.configService.config['googleAdwords'].property,
						{
							page_path: pagePath,
							page_location: pageLocation,
						}
					);
				}
			}
		});
	}

	/**
	 * Track a page view explictly.
	 *
	 * @param data Page View data
	 */
	public pageview(data: object) {
		if ('undefined' !== typeof gtag) {
			gtag('config', BG_GA_Tags.marketing, {
				page_path: data['page_path'],
			});
		}
	}

	/**
	 * Each time a step is completed.
	 *
	 * @since 1.6.0
	 *
	 * @param data Object.
	 */
	public cwpStepComplete(data: object): void {
		this.send({
			event_category: 'signup_' + data['type'],
			event_action: 'cwp_demo_' + data['step'],
			event_label: data['name'],
		});
	}

	/**
	 * Set the user id.
	 *
	 * @since 1.4.0
	 *
	 * @param  data Cloud Install Data.
	 */
	public setUser(accountId: number, email: string): void {
		if ('undefined' !== typeof gtag) {
			gtag('set', BG_GA_Tags.marketing, { userId: accountId });
		}
		if (email && window['gaSetUserId']) {
			window['gaSetUserId'](email);
		}
	}

	/**
	 * Purchase Code Redeemed.
	 *
	 * @since 1.12.0
	 *
	 * @param  data Redemption data.
	 */
	public codeRedeemed(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'purchaseCodeRedeemeed',
			event_label: data['provider'],
		});
	}

	public chat() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'chat',
		});
	}

	/**
	 * Send cloud install data
	 *
	 * @since 1.4.0
	 *
	 * @param  data Cloud Install Data.
	 */
	public cloudInstall(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_install',
			event_label: data['choice'],
		});
	}

	/**
	 * When a user lands on the order process.
	 *
	 * @since 1.14.0
	 *
	 * @param  data Data.
	 */
	public orderInitiated(data: any): void {
		if (!data.cart) {
			return;
		}

		const payload = {
			value: +data.cart.total,
			currency: 'USD',
			items: this.formatCartItems(data.cart.dataSource),
		};

		payload['event_action'] = 'begin_checkout';
		this.send(payload);
	}

	/**
	 * Given the cart datasource, format the items to a google format.
	 *
	 * @since 1.14.0
	 */
	public formatCartItems(dataSource: any): object[] {
		const cartItems = [];
		for (const item of dataSource) {
			cartItems.push({
				id: item.product_term_id,
				name: item.product,
				price: item.price,
				quantity: item.quantity,
			});
		}

		return cartItems;
	}

	public gaSend(data) {
		data.event_category = data.event_category || this.eventCategory;
		this.send(data);
	}

	public ticketSubmission() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'ticket_submission',
		});
	}

	public memberInvite() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'member_invite',
		});
	}

	public onboardingComplete() {
		this.send({
			event_category: this.eventCategory,
			event_action: 'onboarding_complete',
		});
	}

	/**
	 * Send add payment info step.
	 *
	 * @since 1.14.0
	 *
	 * @param  data Data.
	 */
	public addPaymentInfo(data: any): void {
		this.send({ event_action: 'add_payment_info' });
	}

	/**
	 * Send purchase data to GA.
	 *
	 * @since 1.14.0
	 *
	 * @param  data Data.
	 */
	public purchase(data: any): void {
		if (!data.cart) {
			return;
		}

		const payload = {
			transaction_id: data.response.orderId,
			value: +data.cart.total,
			currency: 'USD',
			items: this.formatCartItems(data.cart.dataSource),
		};

		payload['event_action'] = 'purchase';
		this.send(payload);
	}

	/**
	 * Track user signups.
	 *
	 * @since.
	 * @param data [description]
	 */
	public signup(data: object): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'sign_up',
			event_label: 'central_' + data['method'],
		});
		const adwordConfigs = this.configService.config.googleAdwords;
		if (adwordConfigs?.events?.signup) {
			if ('undefined' !== typeof gtag) {
				gtag('event', 'conversion', {
					send_to:
						adwordConfigs.property +
						'/' +
						adwordConfigs.events.signup,
				});
			}
		}
	}

	/**
	 * Track user signups.
	 *
	 * @since.
	 * @param data [description]
	 */
	public signin(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'sign_in',
		});
	}

	/**
	 * Send cloud install data
	 *
	 * @since 1.4.0
	 *
	 * @param  data Cloud Install Data.
	 */
	public newCloudAccount(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_new_account',
		});
	}

	/**
	 * Cloud activation.
	 *
	 * @since 1.4.0
	 *
	 * @param  data Cloud Install Data.
	 */
	public cloudActivated(): void {
		this.send({
			event_category: this.eventCategory,
			event_action: 'cloud_activated',
		});
	}

	/**
	 * Track a exception event.
	 *
	 * @since 1.11.0
	 */
	public exception(data: object): void {
		if ('undefined' !== typeof gtag) {
			this.error({
				category: 'exception',
				name: 'error',
				data: data['message'],
			});
		}
	}

	/**
	 * Track a generic error event.
	 *
	 * @since 1.11.0
	 */
	public error(data: object): void {
		const fields = {
			event_category: data['category'],
			event_action: data['name'],
			event_label: data['data'],
		};

		if (data['value']) {
			fields['value'] = parseInt(data['value'], 10);
		}

		this.send(fields, 'development');
	}

	/**
	 * Send the data to google.
	 *
	 * Simply a wrapper of the gtag method.
	 * See: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
	 *
	 * @since 1.4.0
	 *
	 * @param   data      Data to pass to google.
	 */
	public send(data: object, namespace = 'marketing'): void {
		if ('undefined' !== typeof gtag) {
			const action = this.eventActionPrefix + data['event_action'];
			data['send_to'] = data['send_to'] ?? BG_GA_Tags[namespace];
			delete data['event_action'];

			if ('development' === namespace) {
				data['dimension2'] = this.getMysqlTimestamp();
			}
			gtag('event', action, data);
		}
	}

	/**
	 * Get the current time in MYSQL format.
	 *
	 * @sinced 1.11.0
	 *
	 * @return string Current Time
	 */
	private getMysqlTimestamp(): string {
		return new Date().toISOString().slice(0, 19).replace('T', ' ');
	}

	/**
	 * Send conversion event data to GA.
	 *
	 * @since 1.14.0
	 *
	 * @param  data Data.
	 */
	public trackConversion(data: any): void {
		const payload = {
			transaction_id: data.orderId,
			value: +data.total,
			currency: 'USD',
		};

	        if (data['send_to']) {
	            payload['send_to'] = data['send_to'];
	        }

		payload['event_action'] = 'conversion';
		this.send(payload);
	}
}
