import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';
import { AppService } from '../app.service';

@Component({
	selector: 'central-email',
	templateUrl: './email.component.html',
	styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit, OnDestroy {

	public state = 'loading';
	public requestState = 'loading';

	public price = ''

	public emailSubscriptions: object[];
	public usedDomains = [];

    private subscriptions = [];

	constructor(
		private appService: AppService,
		private apiService: ApiService,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
        this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.fetchSubscriptions();
				this.appService.getCatalog().subscribe((catalog) => {
					const item = catalog.find(product => product.label === 'Email Account');
					const preferredCurrency = this.profileService.data.currency;
					this.price = this.appService.getFormattedPrice(
						item.price_in_cents[preferredCurrency] / 100,
						{}
					);
				})
            })
		)
	}

    ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'reload':
				this.state = 'loading';
				this.fetchSubscriptions();
				break;
		}
	}

	fetchSubscriptions() {
		// get third party domains list
		this.appService.getThirdPartyDomains().subscribe(
			(domains: any[]) => {
				const thirdPartyDomains = domains.filter((domain) =>
					domain.fields.organization_id === this.profileService.data.options.public_selected_team
				);

				this.appService.getPurchases().subscribe(
					(response: any[]) => {
						// find email account subscriptions
						response = response.filter(
							(subscription) =>
								subscription.code === 'email_account' &&
								subscription.account_id === this.profileService.data.options.public_selected_team
						);

						// set third_party_domain_id property on subscription if applicable
						response.forEach((subscription) => {
							const thirdPartyDomain = thirdPartyDomains.find(
								(domain) =>
									domain.label === subscription.domain_id
							);
							subscription.third_party_domain_id =
								thirdPartyDomain?.id;
							if (subscription.domain_id) {
								this.usedDomains.push(subscription.domain_id);
							}
						});

						// sort by domain name
						response.sort(function (
							firstSubscription,
							secondSubscription
						) {
							if (
								firstSubscription.domain_id >
									secondSubscription.domain_id ||
								secondSubscription.domain_id === null
							) {
								return 1;
							}

							if (
								firstSubscription.domain_id <
									secondSubscription.domain_id ||
								firstSubscription.domain_id === null
							) {
								return -1;
							}

							return 0;
						});

						this.emailSubscriptions = response;
						this.state = 'success';
					},
					() => {
						this.state = 'failed';
					}
				);
			},
			() => {
				this.state = 'failed';
			}
		);
	}

	public getAccountNameFromId(accountId) {
		const access = this.profileService.data.account_access.find(
			(acc) => acc.account_id === accountId
		);

		let name = this.profileService.data.display_name || 'You';
		if (access) {
			name = access.display_name;
		}

		return name;
	}

	public reloadSubscription(value) {
		this.apiService
			.get(`/v1/wpcr/purchase/${value.purchaseId}`, {})
			.subscribe(
				(response: any[]) => {
					// swap out the updated subscription
					this.emailSubscriptions = this.emailSubscriptions.map(
						(sub) =>
							response.find(
								(s) => s['purchase_id'] === sub['purchase_id']
							) || sub
					);

					// Refresh caches
					this.appService.getPurchases(false).subscribe();
				},
				(error) => {}
			);

		// get cPanel IP address
		let cpanelIpAddress;
		this.apiService
			.get('/emails/v1/cpanel/ip?username=' + value.cpanelUsername, {})
			.subscribe((cpanelIpAddressResponse) => {
				cpanelIpAddress = cpanelIpAddressResponse;

				// get expected DKIM value
				let dkimValue;
				this.apiService
					.get(
						'/emails/v1/cpanel/dkim?domain=' + value.domainName,
						{}
					)
					.subscribe((dkimResponse) => {
						dkimValue = dkimResponse['data'].payload[0].expected;

						// silently attempt to create relevant DNS records if appropriate
						let url = this.apiService.formatter.getUrl(
							'/v1/domain-service/dns-zone/' + value.domainName
						);
						const headers = this.apiService.getHeaders({
							contentType: 'application/json',
						});
						this.apiService.http
							.get(url, { headers })
							.subscribe((dnsZoneResponse: any[]) => {
								// check for MX record
								let mxRecord = dnsZoneResponse.find(
									(record) =>
										record.name === '@' &&
										record.type === 'MX'
								);
								if (!mxRecord) {
									mxRecord = {
										name: '@',
										type: 'MX',
										ttl: 3600,
										rdata: {
											mxPriority: 0,
											mxDestination: 'mail',
										},
									};
								} else {
									return;
								}

								// check for MX destination A record
								let mxDestinationARecord = dnsZoneResponse.find(
									(record) =>
										record.name === 'mail' &&
										record.type === 'A'
								);
								if (!mxDestinationARecord) {
									mxDestinationARecord = {
										name: 'mail',
										type: 'A',
										ttl: 3600,
										rdata: {
											aValue: cpanelIpAddress,
										},
									};
								}

								// check for SPF record
								let spfRecord = dnsZoneResponse.find(
									(record) =>
										record.name === '@' &&
										record.type === 'TXT' &&
										record.rdata.txtValue.startsWith(
											'"v=spf'
										)
								);
								if (!spfRecord) {
									spfRecord = {
										name: '@',
										type: 'TXT',
										ttl: 3600,
										rdata: {
											txtValue:
												'"v=spf1 +a +mx +ip4:' +
												cpanelIpAddress +
												' +include:smtp.servconfig.com ~all"',
										},
									};
								}

								// check for DKIM record
								let dkimRecord = dnsZoneResponse.find(
									(record) =>
										record.name === '@' &&
										record.type === 'TXT' &&
										record.rdata.txtValue.startsWith(
											'"v=DKIM'
										)
								);
								if (!dkimRecord) {
									const dkimSegments =
										dkimValue.match(/(.{1,255})/g);
									let dkimTxtValue = '';
									dkimSegments.forEach(function (
										dkimSegment
									) {
										dkimTxtValue +=
											'"' + dkimSegment + '" ';
									});

									dkimRecord = {
										name: 'default._domainkey',
										type: 'TXT',
										ttl: 3600,
										rdata: {
											txtValue: dkimTxtValue.trim(),
										},
									};
								}

								// check for DMARC record
								let dmarcRecord = dnsZoneResponse.find(
									(record) =>
										record.name === '@' &&
										record.type === 'TXT' &&
										record.rdata.txtValue.startsWith(
											'"v=DMARC'
										)
								);
								if (!dmarcRecord) {
									dmarcRecord = {
										name: '_dmarc',
										type: 'TXT',
										ttl: 3600,
										rdata: {
											txtValue:
												'"v=DMARC1;p=reject;sp=reject;adkim=s;aspf=s;pct=100;fo=1;rf=afrf;ri=86400"',
										},
									};
								}

								const newZone = dnsZoneResponse.slice(); // create a shallow copy

								// auto-increment SOA Serial
								newZone.forEach((record) => {
									if (record.type === 'SOA') {
										const serial =
											new Date()
												.toISOString()
												.substr(0, 10)
												.replace(/-/g, '') + '01';
										if (
											parseInt(serial, 10) >
											record.rdata.soaSerial
										) {
											record.rdata.soaSerial =
												parseInt(serial, 10);
										} else {
											record.rdata.soaSerial =
												++record.rdata.soaSerial >
												4294967295
													? 0
													: record.rdata.soaSerial;
										}
									}
								});

								newZone.push(
									mxRecord,
									mxDestinationARecord,
									spfRecord,
									dkimRecord,
									dmarcRecord
								);

								// Update records in name servers
								url = this.apiService.formatter.getUrl(
									`/v1/domain-service/dns-zone`
								);
								this.apiService.http
									.patch(
										url,
										{
											domainName: value.domainName,
											zoneData: newZone,
										},
										{ headers }
									)
									.subscribe();
							});
					});
			});
	}
}
