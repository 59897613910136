import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-receipt',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
	@Input() public invoice;

	public fetched = false;
	public state = 'loading';
	public columns = [
		'date',
		'description',
		'quantity',
		'unit_amount_in_cents',
		'total_in_cents',
	];

	currencyFormatter;

	constructor(
		protected apiService: ApiService,
		protected activatedRoute: ActivatedRoute,
		public accountService: AccountService
	) {}

	ngOnInit(): void {
		if (!this.invoice) {
			this.fetch();
		} else {
			this.state = 'success';
		}
	}

	protected fetch() {
		this.state = 'loading';

		this.apiService
			.get(
				`/v1/wpcr/invoices/${this.activatedRoute.snapshot.params.invoice_id}`,
				{}
			)
			.subscribe({
				next: (invoice: any[]) => {
					this.invoice = invoice;
					this.fetched = true;
					this.state = 'success';
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}

	public moneyFormat(priceInCents, currency) {
		this.currencyFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency,
		});

		return this.currencyFormatter.format((priceInCents / 100).toFixed(2));
	}
}
