import { Component, OnInit } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ActivatedRoute } from '@angular/router';
import { BrandingService } from '@central/ng-shared';

@Component({
	selector: 'bgc-app-auth',
	templateUrl: './app-auth.component.html',
	styleUrls: ['../login/login.component.scss', './app-auth.component.scss'],
})
export class AppAuthComponent implements OnInit {
	public status = 'loading';
	public requestAppId: string;
	public redirectUrl = '';
	public application;

	constructor(
		public apiService: ApiService,
		public brandingService: BrandingService,
		private activeRoute: ActivatedRoute
	) {}

	ngOnInit() {
		// Get the application ID.
		this.activeRoute.params.subscribe((routeParams) => {
			this.requestAppId = routeParams['application_id'];
			this.fetchApplication();
		});

		// Get the redirect URL.
		this.activeRoute.queryParams.subscribe((params) => {
			this.redirectUrl = params['redirect_url'];
		});
	}

	/**
	 * If an error occurs, the confirmation button will reload the page.
	 */
	public reset(): void {
		window.location.reload();
	}

	/**
	 * Upon loging in request an authorization code and redirect.
	 *
	 * @param Login Data.
	 */
	public onLogin(data) {
		const request = data ? data.request || {} : {};
		this.apiService
			.post('/v1/oauth2/app/' + this.requestAppId, request)
			.subscribe(
				(response: any) => {
					this.status = 'success';

					const params = new URLSearchParams({
						code: response.authorization_code,
					});

					// Redirect to app.
					window.location.href = this.redirectUrl + '?' + params;
				},
				() => {
					this.status = 'failed';
				}
			);
	}

	/**
	 * Fetch the application data.
	 */
	private fetchApplication(): void {
		this.apiService
			.get('/v1/applications/' + this.requestAppId, {})
			.subscribe(
				(application) => {
					this.status = 'success';
					this.application = application;
					this.setDefaultRedirectUrl();

					// If an invalid redirect url is found, mark as failure.
					if (
						!this.redirectUrl ||
						-1 ===
							this.application.fields.redirect_urls.indexOf(
								this.redirectUrl
							)
					) {
						this.status = 'failed';
					}
				},
				() => {
					this.status = 'failed';
				}
			);
	}

	/**
	 * Where should we redirect the user after an authorization code is fetched.
	 */
	private setDefaultRedirectUrl(): void {
		if (!this.redirectUrl) {
			this.redirectUrl = this.application.fields.redirect_urls[0] || '';
		}
	}
}
