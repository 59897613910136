import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ScriptService } from '../core/script/script.service';
import { AuthService } from '../authentication/services';
import { ApiService, ConfigurationService } from '../core';

@Injectable()
export class ZendeskService {
	public jwt;

	constructor(
		private scriptService: ScriptService,
		private authService: AuthService,
		private configurationService: ConfigurationService,
		private apiService: ApiService
	) {
		// Load the Zendesk Widget
		this.scriptService.loadScript('ze-snippet').then(() => {
			if (window['zE']) {
				// Uncomment the following 2 lines to NOT display on page load
				//window['zE']('webWidget', 'hide');
				//this.hideOnLoad();
			}
		});

		// When a user logs in fetch jwt and save it to local storage.
		this.authService.onLogin.subscribe(() => {
			if (window['zE']) {
				if (window['zE'].widget === 'classic') {
					window['zE']('webWidget', 'chat:reauthenticate');
					window['zE']('webWidget', 'helpCenter:reauthenticate');
				}
			}
		});

		this.authService.onLogout.subscribe(() => {
			if (window['zE']) {
				if (window['zE'].widget === 'classic') {
					window['zE']('webWidget', 'logout');
				}
			}
		});

		// If local storage has a zendesk token, apply it.
		this.configureWidget();
	}

	public hideOnLoad() {
		if (window['zE'].widget === 'classic') {
			window['zE']('webWidget:on', 'chat:connected', () => {
				if (window['zE']('webWidget:get', 'chat:isChatting')) {
					window['zE']('webWidget', 'show');
				}
			});
		}
	}

	public getJwt(callback) {
		if (!this.authService.isLoggedIn()) {
			return;
		}

		this.apiService
			.get('/v1/zendesk/jwt', {})
			.pipe(
				map((response) => {
					this.jwt = response;
					callback(this.jwt['token']);
				})
			)
			.subscribe({
				error: (error) => {
					console.log('Unable to fetch zendesk JWT');
					console.error(error);
				},
			});
	}

	public configureWidget() {
		window['zESettings'] = {
			webWidget: {
				label: 'Chat',
				chat: {
					suppress: false,
					departments: {
						enabled: [
							this.configurationService.config.zendesk.chat
								.departmentsEnabled,
						],
						select: this.configurationService.config.zendesk.chat
							.defaultDepartment,
					},
				},
				contactForm: {
					suppress: true,
				},
				helpCenter: {
					suppress: true,
				},
				talk: {
					suppress: true,
				},
			},
			authenticate: {
				chat: {
					jwtFn: (callback) => this.getJwt(callback),
				},
			},
		};
	}
}
