<h2 class="ctw-capatalize">{{ this.data.isNew ? 'New' : '' }} Project Details</h2>
<form class="project-details" (ngSubmit)="this.save()">

	<p class="ctw-mb-1">
		Type: {{ this.type }}<br>
	</p>
	<p class="ctw-mb-6">
		Started: {{ this.appService.get('project')[0].created_at | amTimeAgo }}
	</p>

	<mat-form-field>
		<mat-label>Name</mat-label>
		<input name="project-name" matInput [(ngModel)]="this.appService.get('project')[0].label" maxlength="25" />
	</mat-form-field>
	<mat-form-field>
		<mat-label>Description</mat-label>
		<textarea matInput [(ngModel)]="this.appService.get('project')[0].description" name="project-description"
			rows="4" type="textarea"></textarea>
	</mat-form-field>
	<div class="action-buttons ctw-text-right ctw-mt-10">
		<button type="button" [mat-dialog-close]="this.status === 'success'" [disabled]="state === 'submitted'"
			mat-button (click)="this.reset()">
			Cancel
		</button>
		<central-progress-button [mat-dialog-close]="this.status === 'success'" [state]="this.status" color="primary"
			class="ctw-ml-3">Update</central-progress-button>
	</div>
</form>
