<central-header title="Domains" [status]="this.state === 'loading'" actions="domains" breakpoint="6xl"
	(action)="this.header($event)"></central-header>

<div class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16"
	*ngIf="this.state === 'success'">

	<h2 class="ctw-flex ctw-justify-between">
		Domain Management
		<button mat-stroked-button color="primary" (click)="this.addThirdPartyDomain()">
			<mat-icon class="ctw-relative ctw-mb-2 ctw-pr-3 ctw-mr-1 ctw-text-2xl mat-primary">dns</mat-icon>
			Control an External Domain
		</button>
	</h2>
	<dash-card cardTitle="Domain Management" icon="language" disabled="true"
		[subTitle]="['Point your domain to our DNS for easier site management']" [transparent]="true">
		<div class="" *ngIf="this.state">
			<p class="ctw-text-center ctw-mt-4" [hidden]="true">
				<strong>Managing your own DNS?</strong> Locate your production server's
				IP address from the Environment Overview.
			</p>

			<failed-request *ngIf="'failed' === thirdPartyRequestState"></failed-request>

			<p *ngIf="!allDomains.length && 'failed' !== thirdPartyRequestState"
				class="no-content empty-label ctw-mt-32">
				<button mat-stroked-button color="primary" (click)="this.addThirdPartyDomain()">
					<mat-icon class="ctw-relative ctw-mb-2 ctw-pr-3 ctw-text-2xl mat-primary">add</mat-icon>
					Add Your Team's First DNS Zone
				</button>
			</p>

			<table mat-table matSort [dataSource]="allDomains" *ngIf="allDomains.length" class="team-content ctw-mt-8">
				<ng-container matColumnDef="party">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" class="notranslate ctw-w-1/6">
						<img class="ctw-h-5 " *ngIf="!element.id" src="/assets/branding/default/favicon.svg">
					</td>
				</ng-container>

				<ng-container matColumnDef="domain">
					<th mat-header-cell *matHeaderCellDef>Domain Name</th>
					<td mat-cell *matCellDef="let element" class="notranslate">
						<div class="ctw-flex">
							<img class="ctw-h-5 ctw-mt-1" *ngIf="!element.id"
								src="/assets/branding/default/favicon.png">
							<mat-icon
								[matTooltip]="element.domainName + ' is a 3rd Party domain, not registered through Platform InMotion.'"
								class="ctw-h-5 ctw-ml-1 ctw-text-lg ctw-text-imh-gray-200"
								style="width:0.975rem !important"
								*ngIf="element.id">nest_remote_comfort_sensor</mat-icon> &nbsp;
							{{
							element.domainName }}
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>Owner</th>
					<td mat-cell *matCellDef="let element" class="notranslate">
						{{ getAccountNameFromId(element.accountId) }}
					</td>
				</ng-container>

				<ng-container matColumnDef="expires">
					<th mat-header-cell *matHeaderCellDef>Renews</th>
					<td mat-cell *matCellDef="let element" class="notranslate"
						[class.ctw-text-imh-gray-200]="!element.info.domainExpireDate">
						{{ element.info.domainExpireDate ? element.info.domainExpireDate : 'N/A' }}
						<mat-icon *ngIf="element.id"
							[matTooltip]="element.domainName + ' is a 3rd Party domain, not registered through Platform InMotion. We are currently unaware of when it expires.'"
							class="ctw-h-5 ctw-text-lg">help</mat-icon>
					</td>
				</ng-container>

				<ng-container matColumnDef="manage">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element; index as i" class="no-wrap">
						<button mat-stroked-button [matMenuTriggerFor]="domainOptions" class="ctw-border-none">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #domainOptions="matMenu" xPosition="before">
							<ng-container *ngIf="null !== element.id">
								<button mat-menu-item color="primary"
									[routerLink]="['/domains/manage/', element.domainName, 'dns']">
									<mat-icon>dns</mat-icon> DNS Records
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="deleteThirdPartyDomain(element, i)"
									class="ctw-text-red-700">
									<mat-icon class="ctw-text-red-700">delete_forever</mat-icon> Delete Zone
								</button>
							</ng-container>
							<ng-container *ngIf="null === element.id">
								<button mat-menu-item color="primary"
									[routerLink]="['/domains/manage/', element.domainName, 'dns']">
									<mat-icon class="ctw-">dns</mat-icon> DNS Records
								</button>
								<button mat-menu-item color="primary"
									[routerLink]="['/domains/manage/', element.domainName]">
									<mat-icon>language</mat-icon> Registration
								</button>
							</ng-container>
						</mat-menu>
						<ng-container *ngIf="
									'inactive' === thirdPartyDeleteRequestState
								">
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>
		</div>
	</dash-card>
</div>
