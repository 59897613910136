import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'amp-stepper',
	templateUrl: 'amp-stepper.component.html',
	styleUrls: ['amp-stepper.component.scss'],
})
export class AmpStepperComponent implements AfterViewInit {
	@Input() public steps = [];
	@Output() public changedStep = new EventEmitter();
	public selectedIndex = 1;

	public constructor(private cdr :ChangeDetectorRef) {}

	public ngAfterViewInit() {
		this.steps[this.selectedIndex].selected = true;
		this.cdr.detectChanges();
	}

	public next() {
		if(this.selectedIndex + 1 <= this.steps.length) {
			this.steps[this.selectedIndex].selected = false
			this.steps[this.selectedIndex].completed = true
			this.selectedIndex++;
			this.steps[this.selectedIndex].selected = true
		}
	}

	public previous(step) {
		const stepLength = this.steps[this.selectedIndex].step - step
		for(let i=0; i<stepLength; i++) {
			const previousStepIndex = this.selectedIndex-1
			if(this.selectedIndex - 1 >= 0 && this.steps[previousStepIndex].disabled === false) {
				this.steps[this.selectedIndex].selected = false
				this.selectedIndex--;
				this.steps[this.selectedIndex].completed = false
				this.steps[this.selectedIndex].selected = true
			}
		}
	}

	public handleStep(step) {
		if(step.isEditable && this.steps[this.selectedIndex] !== step) {
			const currentStep = this.selectedIndex + 1;
			const newStep = step.step
			if(newStep > currentStep) {
				this.next()
			} else {
				this.previous(newStep)
			}
			this.changedStep.emit({toStep: newStep, fromStep: currentStep})
		}
	}

	public getStepperClasses(step) {
		let classString = '';
		if(step.selected || (step.completed && step.isEditable)) {
			classString = 'ctw-text-blue-800 ctw-cursor-pointer';
			if(step.selected) {
				classString += ' ctw-underline'
			}
			return classString;
		}
		if(step.isEditable === false || step.step > this.steps[this.selectedIndex].step) {
			classString = 'ctw-text-gray-600 ctw-text-opacity-50';
			if(step.completed && step.disabled === false) {
				classString += ' ctw-cursor-pointer';
			}
			return classString;
		}
	}
}