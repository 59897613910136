import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { DeviceOption, TwoFaUserData } from '../two-factor-auth.component';
import { ConfigurationService } from '../../../core/configuration.service';

@Component({
	selector: 'central-two-fa-send-code',
	templateUrl: './two-fa-send-code.component.html',
	styleUrls: ['./two-fa-send-code.component.scss'],
})
export class TwoFaSendCodeComponent implements OnInit {
	constructor(public configService: ConfigurationService) {}

	@ViewChild('deviceSelection') public deviceSelection: any;
	@Input() public twoFaUserData: TwoFaUserData;
	@Input() public action: string;
	@Input() public sendCodeError: string;
	@Input() public state: string;
	@Output() public sendCode = new EventEmitter();
	@Output() public cancel = new EventEmitter();
	@Input() public sendError;
	public email: string;
	public deviceOptions: DeviceOption[];
	public defaultDevice: string;
	public model = {
		deviceSelection: '',
	};

	ngOnInit(): void {
		this.deviceOptions = this.getDeviceOptions();
		this.model.deviceSelection = this.getDefaultDevice();
	}

	/**
	 * Submit the form to validate the twoFa code
	 *
	 * @since 3.49.0
	 *
	 */
	public submit(): void {
		if (!this.deviceSelection.valid) {
			this.deviceSelection.control.markAsTouched();
			this.deviceSelection.control.markAsDirty();
		} else {
			this.sendCode.emit(
				this.deviceOptions.find(
					(val) => val.name === this.deviceSelection.value
				)
			);
		}
	}

	/**
	 * Iterates through device options to return the value of the selected 2fa device option
	 *
	 * @since 3.49.0
	 *
	 * @returns - 2fa device value (ie. phone / email)
	 */
	public getDeviceValue() {
		const device: any = this.deviceOptions.find((deviceOption: any) => deviceOption.name === this.model.deviceSelection);
		return device.message;
	}

	/**
	 * Handle Cancel button press
	 */
	public handleCancel() {
		this.cancel.emit();
	}

	/**
	 * Does a given field have an error.
	 *
	 * @since 1.17.0
	 *
	 * @param  field Angular form field.
	 * @return       SHould show error?
	 */
	public fieldHasError(field: any): boolean {
		return (
			!field.valid &&
			field.dirty &&
			field.touched &&
			this.state !== 'submitted'
		);
	}

	/**
	 * Set device options from input data
	 *
	 * @since 3.49.0
	 *
	 * @returns DeviceOptions[]
	 */
	private getDeviceOptions(): DeviceOption[] {
		return this.twoFaUserData?.deviceOptions;
	}

	/**
	 * Iterate through the device options and return the device flagged as default
	 *
	 * @since 3.49.0
	 *
	 * @returns string - 'phone', 'email', etc
	 */
	private getDefaultDevice() {
		return this.deviceOptions[0]['name'];
	}
}
