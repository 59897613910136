<mat-progress-bar *ngIf="this.state === 'loading'" mode="indeterminate" color="primary"></mat-progress-bar>

<failed-request *ngIf="this.state === 'failed'"></failed-request>

<ng-container *ngIf="this.state === 'success'">
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-32 ctw-mb-16">
		<central-backup-manager *ngIf="this.configService.hasFeature('backupManager')"></central-backup-manager>
		<central-snapshots></central-snapshots>
	</div>
</ng-container>
