<central-header title="{{ this.projectService.project.label }}" titleIcon="{{ this.projectService.project.fields.project_type
}}" status="{{ this.isReady ? 'success' : 'loading' }}" breakpoint="6xl" actions="hosting"
	(action)="this.header($event)"></central-header>

<mat-horizontal-stepper (selectionChange)="this.stepChange()"
	class="animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-6xl ctw-mt-32 ctw-mb-16" #stepper linear="true">
	<mat-step *ngIf="this.allowPurchase" [editable]="billing?.isComplete() ? false : true">
		<form>
			<div class="ctw-text-center" *ngIf="this.isReady">
				<h2 class="ctw-mb-8">{{ this.isUpdatePlan ? 'Resize Your Cloud' : 'Select Your Performance
					Level' }}
				</h2>
			</div>
			<central-hosting [isUpdatePlan]="this.isUpdatePlan" [currentPlan]="this.currentPlan"
				[couponData]="this.couponData" #centralHosting (hostingComponentReady)="this.isReady = true"
				[dataCenter]="this.dataCenter" (enableNext)="this.enableNext = $event"></central-hosting>
			<div class="ctw-max-w-4xl ctw-mx-auto ctw-mt-10 ctw-flex ctw-justify-between">
				<button *ngIf="'error' === centralHosting.state || this.isReady" mat-button (click)="back()"
					color="primary" mat-stroked-button>
					Back
				</button>
				<button *ngIf="'success' === centralHosting.state && this.isReady" [disabled]="!this.enableNext"
					mat-button matStepperNext color="primary" mat-raised-button>
					Next
				</button>
				<button *ngIf="'error' === centralHosting.state && this.isReady" mat-button (click)="retryFailure()"
					color="primary" mat-raised-button>
					Retry <mat-icon>autorenew</mat-icon>
				</button>
			</div>
		</form>
	</mat-step>
	<mat-step *ngIf="this.allowPurchase" [completed]="billing?.isComplete()" editable="false">
		<deploy-checkout #billing [subscriptionUuid]="this.subscriptionUuid" [isAddPlan]="this.isAddPlan"
			[term]="this.termType" [isUpdatePlan]="this.isUpdatePlan" [couponData]="this.couponData"
			[dataCenters]="this.centralHosting?.dataCenters" [dataCenter]="this.centralHosting?.dataCenter"
			(deployCheckoutReady)="this.isReady = true"></deploy-checkout>
	</mat-step>
	<mat-step>
		<ng-template matStepLabel>
			<div class="step-label ctw-flex ctw-flex-col ctw-items-center">
				<mat-icon mat-list-icon>publish</mat-icon>
				<p>Publish</p>
			</div>
		</ng-template>
	</mat-step>
</mat-horizontal-stepper>
