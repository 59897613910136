import { Injectable } from '@angular/core';
import { SubTrackingService } from '../base/sub-tracking.service';

@Injectable({
    providedIn: 'root',
})
export class FacebookService extends SubTrackingService{
	/**
	 * Track once a user enters payment page.
	 *
	 * @since 1.1
	 *
	 * @param  data Data to be used for tracking.
	 */
	public orderInitiated(data: any) {
		this.send('track', 'InitiateCheckout', this.formatPaymentInfo(data));
	}

	/**
	 * Track once payment information was added.
	 *
	 * @since 1.1
	 *
	 * @param  data Data to be used for tracking.
	 */
	public addPaymentInfo(data: any) {
		this.send('track', 'AddPaymentInfo', this.formatPaymentInfo(data));
	}

	/**
	 * Track a purchase made.
	 *
	 * @since 1.1
	 *
	 * @param  data Data to be used for tracking.
	 */
	public purchase(data: any) {
		this.send('track', 'Purchase', this.formatReceiptInfo(data.response));
	}

	/**
	 * Track a new user signup.
	 *
	 * @since 1.1
	 *
	 * @param  data Data to be used for tracking.
	 */
	public signup(data: any) {
		this.send('track', 'Lead', {
			content_category: 'InMotion Central',
		});
	}

	/**
	 * Send the data to facebook.
	 *
	 * Simply a wrapper of the fbq method.
	 * See: See: https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.10.
	 *
	 * @since 1.1
	 *
	 * @param   method    Facebook tracking string, usually 'track'.
	 * @param   eventName Event to track.
	 * @param   data      Data to pass to facebooks.
	 */
	public send(method: string, eventName: string, data: object) {
		if (window['fbq']) {
			window['fbq'](method, eventName, data);
		}
	}

	/**
	 * Format the payment data into Facebook format. Pulls from cart.
	 *
	 * @since 1.1
	 *
	 * @param  data Data passed to tracking.
	 * @return       Formatted data.
	 */
	private formatPaymentInfo(data: any) {
		const payload = data.cart.getPayload();

		return {
			content_name: data.cart.getPackageLabels().join(','),
			content_type: 'product',
			value: +data.cart.total,
			currency: 'USD',
			content_ids: payload.plans.map((plan: any) => plan.code),
			num_items: payload.plans.length,
		};
	}

	/**
	 * Format the receipt. Pulls from receipt.
	 *
	 * Note this is important for purchases that do not use the cart.
	 *
	 * @since 1.1
	 *
	 * @param  receipt Data passed to tracking.
	 * @return       Formatted data.
	 */
	private formatReceiptInfo(receipt: any) {
		return {
			content_name: receipt.products
				.map((product: any) => product.label)
				.join(','),
			content_type: 'product',
			value: +Math.abs(receipt.invoice.total_in_cents / 100),
			currency: 'USD',
			content_ids: receipt.products.map((product: any) => product.code),
			num_items: receipt.products.length,
		};
	}
}
