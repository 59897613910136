// logged-in.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ConfigurationService } from '../../../core/configuration.service';

@Injectable()
export class AgentGuard implements CanActivate {
	constructor(
		private auth: AuthService,
		private configService: ConfigurationService,
		private router: Router
	) {}

	public canActivate(activatedRouteSnapshot) {
		let canActivate = false;

		if (this.auth.isLoggedIn() && this.auth.isAdmin()) {
			canActivate = true;
		} else {
			// User tries to access a page that needs authentication, save the page
			// they wanted to visit.
			this.auth.loginUrl = this.getCurrenRoute(activatedRouteSnapshot);

			// If not, they redirect them to the login page
			this.auth.silentLogout();
			this.router.navigate([this.configService.config.routes.login]);
		}

		return canActivate;
	}

	/**
	 * Get the app relative route name.
	 *
	 * @since 1.18.0
	 *
	 * @return Route name.
	 */
	private getCurrenRoute(
		activatedRouteSnapshot: ActivatedRouteSnapshot
	): string {
		const urlPath = activatedRouteSnapshot['_routerState'].url;
		const currentRoute = urlPath;
		return currentRoute;
	}
}
