import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { ProjectService } from '../../../project.service';
import { AnsibleService } from '../../services/ansible.service';
import { AppService } from 'apps/central/src/app/app.service';
import { ServerSoftwareComponent } from './server-software/server-software.component';
import { SiteHealthComponent } from './site-health/site-health.component';

@Component({
	selector: 'central-diagnostic',
	templateUrl: './diagnostic.component.html',
	styleUrls: ['./diagnostic.component.scss'],
})
export class DiagnosticComponent implements OnInit, OnDestroy {

	@Input() public env: string;
	@Input() public url: string;
	@Input() public containerStatus: string;

	@Output() public connect = new EventEmitter();
	@Output() updatesPending = new EventEmitter();

	@ViewChild('siteHealth') public siteHealth: SiteHealthComponent;

	public state = 'loading';
	public request = 'pending';

	public checks = [];
	public failedChecks = [];
	public recommended = [];
	public title;
	public message = '';
	public issue = '';
	public attempts = 0;
	public latestPlaybookVersion;
	public currentPlaybookVersion;

	public siteHealthRequestState = 'pending';
	public hasUpdates;
	public keyStats = [];

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		public appService: AppService,
		public projectService: ProjectService,
		public configService: ConfigurationService,
		private ansibleService: AnsibleService
	) {}

	ngOnInit() {
		this.appService.getPlaybookVersion(this.projectService.project.fields.project_type)
			.subscribe(version => {
				if (this.env === 'vps') {
					this.latestPlaybookVersion = version;
					this.currentPlaybookVersion =
						this.projectService.environment.fields.playbook_values?.version ||
						'Not available';
					this.runDiag();
				} else {
					this.state = 'success';
				}
			});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	public updateSiteHealth($event) {
		this.keyStats = this.siteHealth.keyStats;
		this.hasUpdates = $event;
		this.siteHealthRequestState = 'success';
	}

	public openPlaybookDialog() {
		const dialogRef = this.appService.openDialog(ServerSoftwareComponent);

		dialogRef.componentInstance.projectService = this.projectService;
		dialogRef.componentInstance.ansibleService = this.ansibleService;
		dialogRef.afterClosed().subscribe((result) => {
			switch (result) {
				case 'run':
					this.ansibleService.runPlaybook({}).subscribe();
					break;
				case 'toggle-override':
					this.ansibleService.toggleOverride();
					break;
			}
		});
	}

	public runDiag(delay = 1000) {
		if (delay === 0) {
			return;
		}

		this.state = 'loading';

		if (this.title !== 'Attempting Repairs') {
			this.attempts++;
		}

		if (this.attempts > 5) {
			this.attempts = 0;
			this.issue = 'Service Unreachable';
		} else {
			if (this.failedChecks.length) {
				this.title = 'Attempting Repairs';
				this.issue = 'This may take a couple of minutes...';
				this.request = 'submitted';
				delay = this.failedChecks[0]?.fix_delay || 1000;
			}

			if (delay === 1000) {
				this.state = 'loading';
				this.checks = [];
				this.failedChecks = [];
				this.recommended = [];
				this.request = 'pending';
			}

			setTimeout(() => {
				if (
					this.projectService.environment.fields.playbook_running ===
					'complete' || !this.projectService.environment.fields.playbook_running
				) {
					this.diagnose(
						this.projectService.environment.fields?.environment_type,
						this.projectService.project.fields.project_type
					);
				} else {
					//this.runDiag();
				}

			}, delay);
		}
	}

	private diagnose(environmentType: string, projectType: string) {
		this.apiService
			.get('/v1/diagnose/' + this.projectService.environment.id, { environmentType, projectType })
			.subscribe((response: any) => {
				if (response.status && response.status === 'pending') {
					// Fix in progress
					this.request = 'submitted';
					this.runDiag(this.failedChecks[0]?.fix_delay || 1000);
				} else {
					this.checks = [];
					this.failedChecks = [];
					this.recommended = [];
					this.state = 'loading';
					this.request = 'pending';
					this.message = '';
					this.issue = '';

					// Sort by weight
					response.checks.results.sort(function (a, b) {
						if (a.checkWeight < b.checkWeight) return -1;
						if (a.checkWeight > b.checkWeight) return 1;
						return 0;
					});

					// Identify recomended fix (lowest weight)
					response.checks.results.forEach((check, index) => {
						// Sort by weight
						check.diags.sort(function (a, b) {
							if (a.diagWeight < b.diagWeight) return -1;
							if (a.diagWeight > b.diagWeight) return 1;
							return 0;
						});

						check.diags.forEach((diag) => {
							if (diag.diagWeight) {
								diag.input?.forEach((input) => {
									if (input.action === 'link') {
										input.href = input.href.replace(
											'PROJECT_ID',
											this.projectService.project.id
										);
										input.href = input.href.replace(
											'SITE_URL',
											this.projectService.environment.fields.site_url.split('/')[2]
										);
									}
								});
								if (
									diag.diagResult !== 'pass' &&
									this.recommended.length === 0
								) {
									diag.recommended = true;
									this.checks.push(check);
									diag.fix_delay = check.fix_delay;
									this.recommended.push(diag);
								}
							}
						});
					});

					this.failedChecks = this.checks.filter(
						(check) => check.checkResult !== 'pass'
					);

					if (this.failedChecks.length) {
						this.state = 'success';
						this.request = 'pending';
						this.issue = this.recommended[0].diagTitle;
					} else {
						this.state = 'success';
						this.request = 'pending';
						if (!this.projectService.client) {
							this.projectService
								.reloadProject()
								.subscribe();
						}
					}
				}
			});
	}
}
