<div
	[class.wp-central]="!this.brandingService.isDefault()"
	[class]="
		!this.brandingService.isDefault()
			? 'animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl'
			: ''
	"
>
	<div
		class="page-title loading-animation {{ titleStyle }}"
		*ngIf="pageTitle !== 'InMotion Central'"
	>
		<div [class]="this.container">
			<h1>
				{{ pageTitle
				}}<span
					*ngIf="beta"
					class="beta-badge"
					matTooltip="Provide feedback at https://www.boldgrid.com/feedback"
					>Beta</span
				>
			</h1>
		</div>
	</div>
	<div
		class="welcome-banner page-title loading-animation {{ titleStyle }}"
		*ngIf="pageTitle === 'InMotion Central'"
	>
		<div class="container">
			<div
				class="app-name"
				inlineSVG="./assets/img/boldgrid-central.svg"
			></div>
		</div>
	</div>
	<ng-content></ng-content>
	<div class="container" *ngIf="failedLoading">
		<failed-request></failed-request>
	</div>
</div>
