import {
	AfterViewInit,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LoginFormComponent } from '../login-form/login-form.component';

// tslint:disable-next-line:no-submodule-imports
import { filter } from 'rxjs/operators';

@Component({
	// eslint-disable-next-line
	selector: 'bg-login-modal',
	templateUrl: 'login-modal.component.html',
	styleUrls: ['login-modal.component.scss'],
})
export class LoginModalComponent implements AfterViewInit, OnDestroy, OnInit {
	@ViewChild(LoginFormComponent) public loginForm: LoginFormComponent;

	private routeSubscription: any;
	public acceptTos = false;

	/**
	 * @param dialogRef Modal Reference
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<LoginModalComponent>,
		private router: Router
	) {}

	/**
	 * Close dialog when page changes.
	 *
	 * @since 1.13.1
	 */
	public ngOnInit() {
		this.routeSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => this.dialogRef.close());
	}

	public ngAfterViewInit() {
		this.updateSize();
	}
	/**
	 * Unsubscribe to route handler.
	 *
	 * @since 1.13.1
	 */
	public ngOnDestroy() {
		this.routeSubscription.unsubscribe();
	}

	/**
	 * Depending on the elements visible in the login form, update the size.
	 */
	public updateSize(): void {
		this.dialogRef.updateSize(
			'400px'
		);
	}
}
