import { Component } from '@angular/core';

@Component({
	selector: 'central-failed-request',
	styleUrls: ['failed-request.component.scss'],
	templateUrl: 'failed-request.component.html',
})
export class FailedRequestComponent {
	public reload() {
		window.location.reload();
	}
}
