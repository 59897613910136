import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { CoreModule } from '@central/ng-shared';
import { SharedModule } from '../../shared/shared.module';

import { ReceiptComponent } from './invoice/view/post-purchase/receipt/receipt.component';

@NgModule({
	declarations: [ReceiptComponent],
	imports: [
		MomentModule,
		SharedModule,
		CoreModule
	],
	exports: [ReceiptComponent],
})
export class BillingModule {}
