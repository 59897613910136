<h2 mat-dialog-title class="ctw-capitalize">Add DNS Zone</h2>
<mat-dialog-content>
	<p>
		If you control a domain registered through a
		third party, you can manage it's DNS records here.
	</p>
	<central-domain-add [organizationId]="ownerAccountId" (domainUpdated)="domainUpdated($event)"></central-domain-add>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="ctw-w-full ctw-text-right ctw-mt-10 ctw-pb-5">
		<button *ngIf="showLearnMore" mat-button mat-raised-button (click)="openLearnMore()" style="float: left;"
			color="primary">Learn More</button>
		<button mat-button [mat-dialog-close]="false">Cancel</button>
		<central-progress-button mat-raised-button color="primary" [disabled]="!this.domainIsValid || !this.isReady"
			[state]="progressButtonState" (click)="addDomain()" class="ctw-ml-3">Add Zone</central-progress-button>
	</div>
</mat-dialog-actions>
