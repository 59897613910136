<div class="ctw-w-full ctw-flex ctw-justify-center">
	<div class="ctw-w-full ctw-max-w-5xl" *ngIf="loading === false">
        <div class="ctw-mb-5" *ngFor="let category of this.configs">
            <dash-card disabled="1" [cardTitle]="category.label" icon="manufacturing" *ngIf="category.configs.length > 0">
                <div class="ctw-p-8">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="category.selected" (change)="this.updateConfiguration()"
                                class="ctw-flex ctw-flex-col ctw-items-start">
                        <div *ngFor="let config of category.configs">
                            <mat-radio-button class="ctw-ml-5 ctw-mb-2" [value]="config">
                                {{config.ProductName}} 
                                <span *ngIf="config.AddonIncluded && isSelected(config,category)"
                                [class]="freeCss" >Included</span>
                                <span [innerHTML]="showPrice(config,category)"></span>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </dash-card>
        </div>
        <div class="ctw-flex ctw-justify-end">
            <div class="sm:ctw-ml-3 ctw-mt-3 sm:ctw-mt-0 ctw-w-full sm:ctw-w-min">
                <button mat-button mat-raised-button mat-ripple type="button" color="primary"
                    (click)="configSet()">
                    Continue
                </button>
            </div>
        </div>
    </div>
    <progress-message *ngIf="this.loading" [progressMessages]="[
        { msg: 'Checking configurations...', time: 0, type: 'any'},
    ]" />
</div>