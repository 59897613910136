import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ApiService, ConfigurationService } from '@central/ng-shared';
import { HttpClient } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AppService } from '../../../app.service';

@Component({
	selector: 'central-project-details-dialog',
	templateUrl: './project-details-dialog.component.html',
	styleUrls: ['./project-details-dialog.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailsDialogComponent {
	public type = '';
	public state = 'loading';
	public status = 'loading';
	public resource: object;
	public originalResource: object;

	constructor(
		public appService: AppService,
		public projectService: ProjectService,
		public configService: ConfigurationService,
		private apiService: ApiService,
		public httpClient: HttpClient,
		@Inject(MAT_DIALOG_DATA) public data,
	) {
		this.originalResource = this.appService.get('project')[0].label;
		this.type = this.appService.get('project')[0].fields?.project_type
			.replace(/wordpress/, 'WordPress')
	}

	/**
	 * Revert to original.
	 *
	 * @since 1.19.0
	 */
	public reset(): void {
		this.appService.get('project')[0].label = this.originalResource;
		this.status = 'success';
	}

	public save() {
		this.status = 'submitted';

		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.appService.get('project')[0].id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.httpClient
			.patch(
				url,
				{
					label: this.appService.get('project')[0].label.trim(),
					description:
						this.appService.get('project')[0].description.trim(),
				},
				{ headers }
			)
			.subscribe(
				(projectUpdated) => {
					this.appService.get('project')[0].label =
						projectUpdated['label'];
					this.appService.get('project')[0].fields =
						projectUpdated['fields'];
					this.appService.showConfirmation('Project Updated!')
					this.projectService.fetchProjects(false).subscribe();
					this.status = 'success';
				},
				() => {
					this.status = 'failed';
				}
			);
	}
}
