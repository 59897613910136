import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BrandingService } from '../../core/branding/branding.service';

/**
 * This class represents the lazy loaded AboutComponent.
 */
@Component({
	// eslint-disable-next-line
	selector: 'firebase-auth',
	templateUrl: 'firebase-auth.component.html',
	styleUrls: ['firebase-auth.component.scss'],
})
export class FirebaseAuthComponent implements OnInit {
	@Input() public journey = 'login';
	@Input() public hint: string;
	@Input() public authMethods: string[] = [];

	// eslint-disable-next-line
	@Output() public onAuth = new EventEmitter();

	public loaded = false;

	constructor(
		public auth: AuthService,
		public brandingService: BrandingService
	) {}

	public ngOnInit() {
		this.renderForm();
	}

	public reset() {
		this.renderForm();
	}

	public renderForm() {
		const uiConfig = this.auth.firebase.getConfig();
		uiConfig['callbacks'].signInSuccessWithAuthResult = (
			authResult: any,
			redirectUrl: any
		) => {
			this.onAuth.emit({ authResult, redirectUrl });
			return false;
		};
		uiConfig['callbacks'].uiShown = () => {
			this.loaded = true;
		};

		// Allow disabling of additional auth methods.
		if (this.authMethods && this.authMethods.length) {
			uiConfig['signInOptions'] = uiConfig['signInOptions'].filter(
				(val) => {
					if (typeof val !== 'string') {
						val = val['provider'];
					}

					return -1 !== this.authMethods.indexOf(val);
				}
			);
		}

		// Update URls.
		uiConfig['tosUrl'] = this.brandingService.getBrandOpt('termsOfService');
		uiConfig['privacyPolicyUrl'] =
			this.brandingService.getBrandOpt('privacyPolicy');

		this.auth.firebase.initUI('#firebase-ui', uiConfig);
	}
}
