import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'file-transfer',
	templateUrl: './file-transfer.component.html',
})
export class FileTransferComponent {
	serverDown = false;
	status = 'loading';
	password = null;
	hasPassword = false;

	constructor(
		public dialogRef: MatDialogRef<FileTransferComponent>,
		private apiService: ApiService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {}

	resetPassword() {
		this.status = 'submitted';
		const url = '/v1/environments/' + this.data.env_id + '/reset-ftp';
		this.apiService.post(url, {
			/*
			headerOptions: {
				xOrgId: this.data.organization_id
			},
			*/
			body: JSON.stringify({
				'organization_id': this.data.organization_id,
				'env_id': this.data.env_id,
			})
		})
			.subscribe(
				(response: any) => {
					// returns 200 for success and 803 for fail(there may be other status codes).
					if (400 === response.status || 400 === response?.data?.status) {
						this.status = 'failed';
						// server is not up.
					} else if (404 === response.status || 404 === response?.data?.status) {
						this.status = 'failed';
						this.serverDown = true;
					} else {
						this.status = 'success';
						this.password = response.password;
						this.hasPassword = true;
					}
				},
				(e: HttpErrorResponse) => {
					this.status = 'failed';
					this.dialogRef.close('error');
				}
			);
	}
}
