import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ApiService } from '../../../core';
import { ConfigurationService } from '../../../core/configuration.service';

@Component({
	selector: 'central-delete-member-dialog',
	templateUrl: './delete-member-dialog.component.html',
	styleUrls: ['./delete-member-dialog.component.scss'],
})
export class DeleteMemberDialogComponent {
	public state = 'pending';
	public confirm = false;

	constructor(
		public dialogRef: MatDialogRef<DeleteMemberDialogComponent>,
		private apiService: ApiService,
		public configurationService: ConfigurationService,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	deleteMember() {
		this.state = 'submitted';

		this.apiService
			.delete(
				`/v1/${this.data.resourceType}/` +
					this.data.resourceId +
					'/memberships/' +
					this.data.membership.membership_id,
				{}
			)
			.subscribe({
				next: () => {
					this.state = 'success';
					this.dialogRef.close();
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}
}
