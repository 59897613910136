<h2 class="ctw-capatalize">sFTP Access</h2>
<p>
	To access, transfer, and manage your files over over a <a
		href="https://www.inmotionhosting.com/support/website/ftp/connecting-scp-sftp/" target="_blank">Secure FTP
		Connection</a>, <span *ngIf="this.password === null">reset your</span><span *ngIf="this.password !== null">use
		the following</span> credentials below:
</p>
<p *ngIf="serverDown" class="ctw-text-red-700">
	Server is currently down, so we can't update your SSH keys. Start the server
	to update the keys!
</p>

<p>
	<strong>Username:</strong> wordpress<br>
	<strong>Password:</strong> <span *ngIf="this.password === null"> <strong>
			&middot;&middot;&middot;&middot;&middot;&middot;&middot;</strong><span
			class="ctw-text-imh-blue ctw-ml-2 ctw-cursor-pointer"
			(click)="this.resetPassword()">[reset]</span></span><span data-private *ngIf="this.password !== null"> {{ this.password
		}}</span>
<p>
<p *ngIf="this.password !== null" class="alert ctw-mb-8">
	Store this password securely as it will not be displayed again!
</p>

<div class="ctw-flex ctw-justify-end ctw-mt-10">
	<central-progress-button class="ctw-ml-2" mat-dialog-close mat-raised-button color="primary" [state]="this.status">
		<span *ngIf="this.status === 'submitted'" class="ctw-ml-2">Resetting Password...</span>
		<span *ngIf="this.status !== 'submitted'">OK</span>
	</central-progress-button>
</div>
