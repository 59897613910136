<ng-container *ngIf="this.subState === 'loading'">
	<mat-spinner class="ctw-mx-auto" diameter="50"></mat-spinner>
</ng-container>
<ng-container *ngIf="this.subState === 'success'">
	<ng-container *ngIf="!hasSubscriptions">
		<h2 *ngIf="!data.isPrivate" mat-dialog-title class="ctw-capitalize">
			Are You Sure?
		</h2>
		<mat-dialog-content class="mat-typography">
			<p>
				You will lose access to all Projects, Domains and Email managed by
				<strong>{{ data.teamName }}</strong>.
			</p>
			<p class="error" *ngIf="this.errorMessage">{{ this.errorMessage }}</p>
		</mat-dialog-content>
		<mat-dialog-actions align="end" class="ctw-mt-10 ctw-pb-5">
			<button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
			<central-progress-button color="warn" [disabled]="false" [state]="state" (click)="archiveTeam()"
				class="ctw-ml-3">Yes, Continue</central-progress-button>
		</mat-dialog-actions>
	</ng-container>
	<ng-container *ngIf="hasSubscriptions">
		<h2 mat-dialog-title class="ctw-capitalize">Unable To Archive</h2>
		<mat-dialog-content class="mat-typography">
			<p>
				<strong>{{ data.teamName }}</strong> has 1 or more active subscriptions. You cannot archive a
				{{
				this.configurationService.config.groupLabels[
				'group-label-lower-case'
				]
				}}
				with an active subscription.<br />
			</p>
		</mat-dialog-content>
		<mat-dialog-actions align="end" class="ctw-mt-10 ctw-pb-5">
			<button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>
				OK
			</button>
		</mat-dialog-actions>
	</ng-container>
</ng-container>
