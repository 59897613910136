import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfigurationService } from '../../../../core/configuration.service';

@Component({
	selector: 'central-shared-dialog',
	templateUrl: './shared-dialog.component.html',
	styleUrls: ['./shared-dialog.component.scss'],
})
export class SharedDialogComponent {
	public copyText = 'Copy to Clipboard';
	public loading = true;
	public state = 'pending';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar,
		public configService: ConfigurationService
	) {}

	public copy() {
		this.clipboard.copy(this.data.itemToCopy);
		this.snackbar.open(`Copied ${this.data.itemToCopyName} to Clipboard!`, '', {
			duration: 4000,
			panelClass: ['notranslate']
		});
	}
}
