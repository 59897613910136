import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ApiService, ConfigurationService } from '../../../core';

@Component({
	selector: 'central-user-table',
	templateUrl: './organizations-table.component.html',
	styleUrls: ['./organizations-table.component.scss'],
})
export class OrganizationTableComponent implements OnInit {
	public id: any;
	public data: any;
	public organizations: any;
	public columnsToDisplay = [
		'display_name',
		'type',
		'account_id',
		'role',
		'has_billing',
	];
	public state = 'submitted';

	constructor(
		private route: ActivatedRoute,
		public apiService: ApiService,
		public configService: ConfigurationService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.organizationsTable();
	}

	private organizationsTable() {
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.id = params.get('user_id');
			this.apiService
				.get(`/v1/agents/fraud-report/` + this.id, {})
				.subscribe({
					next: (account: any) => {
						this.data = account;
						this.organizations = this.data.account_access.filter(
							(val) => val.type === 'organization'
						);
						this.state = 'success';
					},
					error: (error) => {
						this.state = 'fail';
					},
				});
		});
	}
}
