import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '@central/ng-shared';
import { Config } from '../../../../../shared/config/env.config';
import { ProjectService } from '../../../../project.service';
@Component({
	selector: 'central-domain-change',
	templateUrl: './domain-change.component.html',
	styleUrls: ['./domain-change.component.scss'],
})
export class DomainChangeComponent {
	@Input() public state = 'pending';
	@Input() public validating = 'pending';
	@Input() context: string;
	@Input() current: string;
	@Input() hostname: string;
	@Input() usedDomains: string[] = [];
	@Input() currentDomain: string;
	@Input() invalid = false;
	@Input() disabled = false;

	@Output() domainChangeReady = new EventEmitter();
	@Output() domainChangeBack = new EventEmitter();
	@Output() domainChangeSubmit = new EventEmitter();

	public view = 'change-domain';
	public isDisabled = true;
	public status = 'pending';

	private baseDomain;
	private subDomain;
	private fqdn;

	constructor(
		public projectService: ProjectService,
		private httpClient: HttpClient,
		private apiService: ApiService,
	) {}

	isReady() {
		this.domainChangeReady.emit();
		this.status = 'ready';
	}

	getIsDisabled(selectedDomain) {
		return !selectedDomain || selectedDomain === ' ' || this.isDisabled;
	}

	updateIsDisabled(data) {
		this.isDisabled = !!!data.trim();
	}

	useSelected() {
		if (this.currentDomain.includes(this.hostname) ||
			this.currentDomain.includes('inmotionhosting')) {
			// TODO: Account for any other manual entry of IMH branded domains
			// and force to hostname
			this.domainChangeSubmit.emit({
				baseDomain: this.hostname,
				subDomain: '',
				fqdn: this.hostname,
			});
			this.validating = 'pending';
		} else {
			this.domainChangeSubmit.emit({
				baseDomain: this.baseDomain,
				subDomain: this.subDomain,
				fqdn: this.fqdn,
			});
			this.validating = 'pending';
		}
	}

	validateSelected(selectedDomain) {
		this.validating = 'submitted';
		this.invalid = false;
		this.currentDomain = selectedDomain.trim();
		if (selectedDomain.trim().split(' ')[0].includes(this.hostname)) {
			this.currentDomain = selectedDomain.trim();
			this.useSelected();
		} else {
			const url =
				Config['host'] +
				'/v1/domain-service/domain/info/' +
				selectedDomain.trim();
			const headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});
			this.httpClient.get(url, { headers }).subscribe(
				(response: any) => {
					this.baseDomain = response.domain || selectedDomain.trim();
					this.subDomain = response.subdomain || '';
					this.fqdn = response.hostname || selectedDomain.trim();
					this.useSelected();
				},
				(error) => {
					console.log("Error catch", error);
					this.invalid = true;
					this.validating = 'pending';
				}
			);
		}
	}
}
