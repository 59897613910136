<mat-form-field appearance="standard" floatLabel="always" class="ctw-w-full ctw-mb-0">
	<mat-label>Domain Name</mat-label>
	<input matInput type="text" [(ngModel)]="domainName" [formControl]="domainValidator" autofocus
		placeholder="example.com" />
	<mat-error *ngIf="domainValidator.hasError('invalidDomain')">Not a valid domain name</mat-error>
	<mat-error *ngIf="domainValidator.hasError('taken')">Domain <span class="notranslate">{{ domainInvalid }}</span> is
		already owned by a Team. </mat-error>
	<mat-error *ngIf="domainStatus === 'authUser invalid'">Domain <span class="notranslate">{{ domainInvalid }}</span>
		is already managed by another application. </mat-error>
</mat-form-field>
