import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DomainRegisterComponent } from './register/domain-register.component';
import { DomainCollectionComponent } from './management/collection/domain-collection.component';
import { DomainRegistrationComponent } from './management/registration/domain-registration.component';
import { DnsZoneEditorComponent } from './management/dns/dns-zone-editor.component';

const routes: Routes = [
	{
		path: '',
		component: DomainRegisterComponent,
	},
	{
		path: 'register',
		redirectTo: '',
	},
	{
		path: 'manage',
		children: [
			{
				path: '',
				component: DomainCollectionComponent,
			},
			{
				path: ':domainName',
				component: DomainRegistrationComponent,
			},
			{
				path: ':domainName/dns',
				component: DnsZoneEditorComponent,
			},
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DomainRoutingModule {}
