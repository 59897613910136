import {
	Injectable,
	OnDestroy,
	ApplicationRef,

} from '@angular/core';
import { ApiService, AuthService, SocketService, ConfigurationService, ProfileService, } from '@central/ng-shared';
import { Observable, ReplaySubject, concat, interval } from 'rxjs';
import { WpRestService } from './environment/services/wordpress.service';
import { AppService } from '../app.service';
import { HttpClient } from '@angular/common/http';
import { map, flatMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { Config } from '../shared';
import { AccountService } from '../checkout/account.service';
import { LicenseService } from '../shared/license/license.service';
import { RollbarService, CouponService } from '@central/ng-shared';

import config from './environment/publish/plan-selector/config';
import { PublicStorageService } from 'libs/ng-shared/src/lib/core/local-storage/public-storage.service';

@Injectable()
export class ProjectService implements OnDestroy {

	public onEnvironmentChange = new ReplaySubject(1);
	public onClientConnected = new ReplaySubject(1);
	public getServerInfo = new ReplaySubject(1);
	public getPlaybookStatus = new ReplaySubject(1);
	public getBillingSubscription = new ReplaySubject(1);
	public getEnvironmentState = new ReplaySubject(1);

	public updateState = 'pending';
	public environmentState = 'pending';
	public environment;
	public project;
	public projects;
	public client;
	public siteStats;

	private subscription;
	public owner;
	public projectAccess;
	public socket;
	public observer;
	public currentUserCanPurchase = false;
	public reconnectAttempts = 0;
	public stagingWordPress;
	public organizationId;
	public stagingEnvironment;
	public environmentOfferings = config.plans;

	public servers = [];
	public serverStatus;

	public capabilities = {
		owner: ['manage', 'write', 'read'],
		maintainer: ['write', 'read'],
		member: ['read'],
	};

	private serverSubscription;

	public constructor(
		public httpClient: HttpClient,
		public appService: AppService,
		public wpRestService: WpRestService,
		public socketService: SocketService,
		public authService: AuthService,
		public licenseService: LicenseService,
		public rollbarService: RollbarService,
		public configService: ConfigurationService,
		public couponService: CouponService,
		public publicStorage: PublicStorageService,
		public profileService: ProfileService,
		private apiService: ApiService,
		private accountService: AccountService,
		private appRef: ApplicationRef,
	) {}

	public ngOnDestroy(): void {
		this.stopListeningToWebsocket();

		if (this.observer) {
			this.observer.unsubscribe();
		}
	}





	public hasEnvironments() {
		return this.appService.get('project')[0]?.children?.length || 0;
	}

	public hasEnvironmentControl(environment = null): boolean {
		environment = environment || this.environment;

		if (+environment?.fields.is_development) {
			return true;
		} else {
			this.setProjectAccess();
			return this.hasCapability('write');
		}
	}

	public hasCapability(capability: string): boolean {
		const role = this.projectAccess?.role || '';

		let hasCapability = false;
		if (
			this.capabilities[role] &&
			this.capabilities[role].includes(capability)
		) {
			hasCapability = true;
		}

		return hasCapability;
	}

	public hasPlaygrounds() {
		return this.project.children.filter(
			(project) => project.fields.environment_type !== 'vps'
		).length;
	}

	public hasServers() {
		return this.project.children.filter(
			(project) => project.fields.environment_type === 'vps'
		).length;
	}




	public fetchProjects(fromCache: boolean = true) {
		return this.appService.getProjects(fromCache);
	}

	public fetchProject(projectId: string, fromCache: boolean = true, init: boolean = true) {
		return new Observable((observer) => {
			if (fromCache &&
				this.appService.get('project') &&
				this.appService.get('project')[0]?.id === projectId) {
				this.project = this.appService.get('project')[0];
				this.loadProject(init);

				observer.next(this.project);
				observer.complete();
			} else {
				this.appService.getProjects(fromCache).subscribe(
					(proj: any[]) => {
						this.project = proj.find((project) => project.id === projectId);
						this.appService.setProject(this.project);
						this.loadProject(true);

						observer.next(this.project);
						observer.complete();
					},
					(error) => {
						console.log(error.message);
						observer.error(error);
					}
				);
			}
		});
	}


	private loadProject(init = false) {
		//this.standardizeFields();
		this.updateRollbarProject();
		this.profileService.onReady().subscribe(() => {
			if (this.project) {
				this.owner = this.profileService.data.account_access.find(
					(access) => access.account_id === this.project.fields?.organization_id
				);
				if (this.owner?.account_id) {
					this.accountService['accountId'] =
						this.owner.account_id;
				}

				if (!this.subscription) {
					this.getSubscription();
				}
				this.getWebsocket();

				if (init) {
					if (this.project.fields.project_type === 'wordpress') {
						this.project.children.forEach((environment, index) => {
							setTimeout(() => {
								if (environment !== this.environment) {
									this.wpRestService.getClient(environment).subscribe()
								}
							}, (index + 1) * 500);
						});
					}
				}
			}

			if (!this.appService.getProjects()) {
				this.fetchProjects().subscribe()
			}
		});
	}

	public reloadProject(setEnvironment = true, polling = false) {
		return new Observable((observer) => {
			// Eventhout the project is loaded, this.project is undfined at times
			// Need to track this down. Pulling id from cache as a fallback.
			if (!this.project?.id) {
				this.project = this.appService.get('project')[0];
			}
			this.fetchProject(this.project.id, false, false)
				.subscribe((response: any) => {
					this.project.fields = {
						...this.project.fields,
						...response.fields,
					};
					this.project.children = response.children;

					if (setEnvironment) {
						if (this.environment) {
							this.setEnvironment(this.environment.id);
						} else {
							if (this.getDefaultEnvironment(this.project)) {
								this.setEnvironment(
									this.getDefaultEnvironment(this.project).id
								);
							}
						}
					}

					observer.next(response);
					observer.complete();
				});
		});
	}


	/**
	 * Determines if the active project has VPS environments and
	 * fetches container info for each.
	 *
	 * TODO: Improve upon this for multi-server
	 */
	public getServers(): void {
		this.servers = this.project.children.filter(
			(vpsEnvironment) =>
				vpsEnvironment.fields.environment_type === 'vps' ||
				vpsEnvironment.fields.machine_details);

		this.servers.forEach((server, index) => {
			// TODO Improve upon this for multi-server
			if (server.fields?.machine_details?.veid && !this.serverStatus) {
				this.apiService
					.get('/v1/vps/server/info', {
						veid: server.fields?.machine_details?.veid
					}, 100000)
					.subscribe(
						(response) => {
							// TODO Improve upon this for multi-server
							this.serverStatus = response;
							this.getServerInfo.next(response);
						},
						(error) => {
							this.rollbarService.rollbar.warn(error)
						}
					);
			}
		});
	}

	/**
	 * Configures Rollbar project with active project details
	 */
	private updateRollbarProject() {
		if (this.project?.fields) {
			this.rollbarService.rollbar.configure({
				payload: {
					project: {
						id: this.project.id,
						label: this.project.label,
						subscription_uuid: this.project.fields.primary_subscription_uuid,
						organization_id: this.project.fields.organization_id,
						connect_id: this.project.fields.connect_id,
						project_type: this.project.fields.project_type,
					},
				},
			});
		}
	}

	/**
	 * Clears existing environment_details in Rollbar to prevent mixing of environment data
	 */
	private clearRollbarEnvironment() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {}
			}
		});
	}

	/**
	 * Configures Rollbar environment with current environment details
	 */
	private updateRollbarEnvironment() {
		this.clearRollbarEnvironment();

		if (this.environment?.fields) {
			//Check for cloud_id to determine if environment is vps or playground
			if (this.environment.fields.cloud_id) {
				// Playground Environment
				this.updateRollbarEnvironmentPlayground();
			} else {
				// VPS Environment
				this.updateRollbarEnvironmentWordpress();
			}
		}
	}

	/**
	 * Updates Rollbar conifguration with WordPress Environment Fields
	 */
	private updateRollbarEnvironmentWordpress() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {
					id: this.environment.id,
					label: this.environment.label,
					hostname: this.environment.fields.hostname,
					site_url: this.environment.fields.site_url,
					package_code: this.environment.fields.package_code,
					machine_details: {
						veid: this.environment.fields.machine_details?.veid,
						machine_ip: this.environment.fields.machine_details?.machine_ip,
						machine: this.environment.fields.machine_details?.machine,
					},
				}
			}
		});
	}

	/**
	 * Updates Rollbar with Playground Environment Fields
	 */
	private updateRollbarEnvironmentPlayground() {
		this.rollbarService.rollbar.configure({
			payload: {
				environment_details: {
					id: this.environment.id,
					label: this.environment.label,
					site_url: this.environment.fields.site_url,
					cloud_id: this.environment.fields.cloud_id,
					organization_id: this.environment.fields.organization_id,
				}
			}
		});
	}

	protected getWebsocket() {
		this.stopListeningToWebsocket();
		this.socket = this.socketService.getSocket();
		const listener = () => {
			this.stopListeningToWebsocket();
			this.socket.emit('authenticateResource', {
				name: 'projects',
				id: this.project.id,
				token: this.authService.getToken(),
			});

			this.socket.on(
				`resource_update_projects_${this.project.id}`,
				(data) => {
					const response = data.payload;
					this.project.label = response.label;
					this.project.fields = {
						...this.project.fields,
						...response.fields,
					};
					this.project.children = response.children;
				}
			);
		};

		if (this.observer) {
			this.observer.unsubscribe();
		}

		this.observer = this.socketService.onAuthenticated.subscribe(listener);
	}

	public stopListeningToWebsocket() {
		if (this.socket && this.project?.id) {
			this.socket.removeAllListeners(
				`resource_update_projects_${this.project.id}`
			);
		}
	}





	public login(url?: string) {
		return this.wpRestService.login(this.environment, url || '');
	}

	public loadEnvironmentClient() {
		return this.wpRestService.getClient(this.environment);
	}

	public getEnvironmentName(environment) {
		return environment?.label; /* === 'VPS Environment' ?
			this.environmentOfferings[environment.fields.package_code?.replace('wphosting_tier_',
			'')?.replace('a', '') - 1]?.name : environment.label;*/
	}

	public setEnvironment(environmentId) {
		const changed = environmentId !== this.environment?.id;
		this.environment = this.project?.children
			?.find((environment) => environmentId === environment.id);

		if (!this.environment) {
			return;
		}

		if (changed) {
			this.setEnvironmentState('pending');
			this.environment.name = this.getEnvironmentName(this.environment);
			this.environment.is_dev_cloud = !this.environment?.fields?.cloud_id;
			this.environment.is_vps = this.environment?.fields?.environment_type === 'vps';

			this.updateRollbarEnvironment();
			this.siteStats = null;
			this.apiService
				.get(
					'/v1/connect-keys/' +
						this.project.fields.connect_id,
					{}
				)
				.subscribe(
					(connect) => {
						this.environment.hash = connect['key_hash']
					},
					() => {}
				);

			this.apiService.post('createEnvironmentToken', {
				environment_id: this.environment.id,
			}).subscribe(auth => {
				this.environment.token = auth['token']
			}, error => console.log);

			this.onEnvironmentChange.next(this.environment);
			this.setReachableState();
		}

		// Attempt to reconnect if not connected
		this.wpRestService.sitesUnavailable =
			this.wpRestService.sitesUnavailable.filter(url => url !== this.environment.fields.site_url)

		this.loadEnvironmentClient().subscribe(
			(client) => {
				if (client) {
					this.client = client;
					this.onClientConnected.next('ready');
				} else {
					this.client = null;
					this.onClientConnected.next('failed');
				}
			},
			(error) => {
				this.client = null;
				this.onClientConnected.next('failed');
			}
		);
	}

	public setReachableState() {
		let isPublic = this.environment.fields.is_publicly_accessible;

		this.apiService
			.get(`/v1/environments/connection`, {
				url: this.environment.fields.site_url,
			})
			.pipe(
				map((result: any) => {
					isPublic = !!result.is_success;
					if (isPublic) {
						this.setEnvironmentState('accessible');
					}
				})
			)
			.pipe(
				flatMap(() => this.updateEnvironmentField({
						is_publicly_accessible: isPublic,
					})
				)
			)
			.subscribe();

		if (this.configService.hasFeature('IMC-683')) {
			const env = this.environment.fields.cloud_id
				? this.environment.fields.site_url
				: this.environment.fields.connection_url;
			this.httpClient.get(env).subscribe(
				(response: any) => {
					this.setEnvironmentState('accessible');
				},
				(error) => {
					// console.log(error);
				}
			);
		} else {
			this.setEnvironmentState('accessible');
		}
	}

	public getSiteStats(cached = true) {
		if (this.environment.fields?.playbook_running !== 'is-running') {
			return new Observable((observer) => {
				if (this.siteStats && cached) {
					observer.next(this.siteStats);
					observer.complete();
				} else {
					this.client
						.namespace('bgc/v1')
						.stats()
						.get()
						.then((stats) => {
							this.siteStats = stats;
							observer.next(this.siteStats);
							observer.complete();
						})
						.catch((error) => {
							if ('restx_logged_out' === error.code) {
								// Re-authenticating.
								this.wpRestService
									.reloadClient(this.environment)
									.subscribe(
										(client) => {
											this.client = client;
											this.getSiteStats(cached);
										},
										() => {
											this.onClientConnected.next('failed');
											observer.error(error);
										}
									);
							} else {
								this.onClientConnected.next('failed');
								observer.error(error);
							}
						});
				}
			});
		}

		return new Observable((observer) => {
			observer.next({});
			observer.complete();
		});
	}


	public getEnvironmentUrl(project) {
		let url: string;
		const defaultEnvironment = this.getDefaultEnvironment(project);

		if (defaultEnvironment?.fields?.has_publish_setup_user || defaultEnvironment?.fields?.cloud_id) {
			url = defaultEnvironment.fields.site_url;
		}

		return url;
	}

	public getDefaultEnvironment(project): Environment {
		if (!project) {
			return;
		}

		let environment = this.getVpsEnvironment(project);

		// If no vps environment is found grab the first playground.
		if (!environment) {
			environment = project.children[0];
		}

		return environment;
	}

	public getVpsEnvironment(project): Environment {
		const environment = project.children.find((val) => val.fields.environment_type === 'vps');

		return environment;
	}

	public getProductionEnvironment(): Environment {
		return this.project.children.find((val) => !+val.fields.is_development);
	}

	public getScreenshot(project: any, environment: any = {}) {
		if (!project?.id) {
			return '';
		};
		if (this.appService.getProjectScreenshots() &&
			this.appService.getProjectScreenshots()[project.id]) {
			if (!environment?.id) {
				return this.appService.getProjectScreenshots()[project.id][this.getDefaultEnvironment(project)?.id]?.img
			} else {
				return this.appService.getProjectScreenshots()[project.id][environment?.id]?.img
			}
		}
	}

	public currentEnvironmentIsProduction(): boolean {
		return !+this.environment.fields.is_development;
	}

	public resetClient() {
		this.client = this.wpRestService.getSite(this.environment).rest;
	}

	public updateEnvironmentField(fields) {
		const url = this.apiService.formatter.getUrl(
			'/v1/environments/' + this.environment.id
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.httpClient.patch(url, { fields }, { headers }).pipe(
			map((response: any) => {
				this.environment.fields = {
					...this.environment.fields,
					...response.fields,
				};
				this.appRef.tick();
				return response;
			})
		);
	}


	public updateProjectFields(fields) {
		const url = this.apiService.formatter.getUrl(
			'/v1/projects/' + this.project.id
		);

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.httpClient.patch(url, { fields }, { headers }).pipe(
			map((response: any) => {
				this.project.fields = {
					...this.project.fields,
					...response.fields,
				};
				return response;
			})
		);
	}

	public resolveEnvironmentTodo(todo) {
		const fields = {};
		fields[todo] = true;
		return this.updateEnvironmentField(fields);
	}

	public getSubscription() {
		/*
		if (!this.hasCapability('write')) {
			return;
		}
		*/

		const subscriptionUuid = this.project.fields.primary_subscription_uuid;
		const organizationId = this.project.fields.organization_id;

		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/accounts/${this.getAccountId()}/subscriptions/${subscriptionUuid}`
		);
		let headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		headers = headers.append('X-Organization-Id', organizationId)

		if (!subscriptionUuid) {
			this.getBillingSubscription.error({
				error: {
					message: 'No subscription found.',
				},
			});

			return;
		}

		this.apiService.http.get(url, { headers }).subscribe({
			next: (subscription) => {
				this.subscription = subscription;
				this.getBillingSubscription.next(this.subscription);
			},
			error: (error) => {
				this.getBillingSubscription.error(error);
			},
		});
	}

	public getAccountId() {
		return (
			this.project.fields.organization_id ||
			this.authService.getAccountId()
		);
	}

	public setProjectAccess() {
		this.projectAccess = this.profileService.data.account_access.find(
			(val) => val.account_id === this.project?.fields?.organization_id
		);

		if (
			this.projectAccess &&
			(this.projectAccess.role === 'owner' ||
			(this.projectAccess.role === 'maintainer' && this.projectAccess.has_billing))
		) {
			this.currentUserCanPurchase = true;
		}
	}

	public addEnvironment(options: any) {
		return concat(
			this.createCWP(options),
			this.createEnvironment((environment) => {
				const fields = {
					is_development: true,
					site_url: this.stagingWordPress.site_url,
					cloud_id: this.stagingWordPress.cloud_id,
					environment_usage: "unknown",
					environment_type: "playground"
				};

				if (options.connect_id) {
					fields['connect_id'] = options.connect_id;
				}

				return {
					property: 'stagingEnvironment',
					label: options.label,
					fields,
				};
			})
		);
	}

	/**
	 * Create a new Cloud WordPress.
	 *
	 * @return Observable
	 */
	public createCWP(options?: any): Observable<any> {
		return new Observable((observer) => {
			const url = this.apiService.formatter.getUrl('createEnvironment');

			let headers = this.apiService.getHeaders({
				contentType: 'application/x-www-form-urlencoded',
			});

			// Check options so project can be passed into this method.
			if (!this.project && options.project) {
				this.project = options.project;
			}

			if (this.project?.fields?.organization_id) {
				headers = headers.append('X-Organization-Id', this.project.fields.organization_id);
			}

			this.apiService.http
				.post(
					this.apiService.formatter.getUrl('installCloud'),
					{
						type: 'wordpress',
						connect_id: this.project?.fields?.connect_id,
					},
					{ headers }
				)
				.subscribe(
					(response) => {
						this.stagingWordPress = response;
						observer.next(response);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}

	/**
	 * Create the Environment resource.
	 *
	 * @param getOptions Callback to get environment details.
	 * @return Observable
	 */
	public createEnvironment(getOptions): Observable<any> {
		return new Observable((observer) => {
			const options = getOptions();
			const url = this.apiService.formatter.getUrl('createEnvironment');

			let headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			if (this.project?.fields?.organization_id) {
				headers = headers.append(
					'X-Organization-Id',
					this.project.fields.organization_id
				);
			}

			this.apiService.http
				.post(
					url,
					{
						label: options.label,
						parentId: this.project.id,
						fields: options.fields || null,
					},
					{ headers }
				)
				.subscribe(
					(response) => {
						this[options.property] = response;
						observer.next(response);
						observer.complete();
					},
					(error) => {
						observer.error(error);
					}
				);
		});
	}

	/**
	 * Create a user project resource.
	 *
	 * @return Observable
	 */
	public createProject(options = {}): Observable<any> {
		return new Observable((observer) => {
			const url = this.apiService.formatter.getUrl('/v1/projects');
			let headers = this.apiService.getHeaders({
				contentType: 'application/json',
			});

			// Add organization id to the header.
			if (this.organizationId) {
				headers = headers.append(
					'X-Organization-Id',
					this.organizationId
				);
			}

			this.httpClient.post(url, options, { headers }).subscribe(
				(response) => {
					this.project = response;
					// Update licenses because we created a key.
					this.fetchProjects(false).subscribe(() => {
						this.licenseService.fetch(false);
						observer.next(response);
						observer.complete();
					});
				},
				(error) => {
					observer.error(error);
				}
			);
		});
	}

	private setEnvironmentState(state) {
		this.environment.state = state;
		this.environmentState = state;
		this.getEnvironmentState.next(
			this.environmentState
		);
	}
}
