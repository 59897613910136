import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from './configuration.service';
import { ProgressButtonComponent } from './progress-button/progress-button.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { FailedRequestComponent } from './error/failed-request.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { CentralValidationUrlDirective } from './validators/url.directive';
import { CentralValidationUrlOptionalDirective } from './validators/url-optional.directive';
import { CheckFreeDirective } from './validators/check-free.directive';
import { CaptchaComponent } from './captcha/captcha.component';
import { ListSelectComponent } from './list-select/list-select.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { PublicKeyValidator } from './validators/publickey.directive';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SuspensionComponent } from '../account/suspension/suspension.component';
import { BetaTesterComponent } from '../account/beta-tester/beta-tester.component';
import { RouterLink, RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { TeamSelectorComponent } from './header/selector/selector.component';
import { CentralLocaleSettingsComponent } from './locale-settings/central-locale-settings.component';
@NgModule({
	declarations: [
		ProgressButtonComponent,
		ListSelectComponent,
		HeaderLogoComponent,
		FailedRequestComponent,
		CentralValidationUrlDirective,
		CentralValidationUrlOptionalDirective,
		PublicKeyValidator,
		CheckFreeDirective,
		CaptchaComponent,
		HeaderComponent,
		FooterComponent,
		SuspensionComponent,
		BetaTesterComponent,
		TeamSelectorComponent,
		CentralLocaleSettingsComponent,
	],
	exports: [
		HeaderLogoComponent,
		ListSelectComponent,
		FailedRequestComponent,
		InlineSVGModule,
		CentralValidationUrlDirective,
		CentralValidationUrlOptionalDirective,
		PublicKeyValidator,
		CheckFreeDirective,
		CaptchaComponent,
		ProgressButtonComponent,
		HeaderComponent,
		FooterComponent,
		SuspensionComponent,
		BetaTesterComponent,
		TeamSelectorComponent,
		CentralLocaleSettingsComponent,
	],
	imports: [
		HttpClientModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatCardModule,
		MatListModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatButtonModule,
		MatMenuModule,
		MatBadgeModule,
		RouterModule,
		RouterLink,
	],
})
export class CoreModule {
	static forRoot(config): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{
					provide: ConfigurationService,
					useFactory: () => new ConfigurationService(config),
				},
			],
		};
	}
}
