export default {
	pricingPage: {
		'default': 'https://www.inmotionhosting.com/wordpress-hosting/pricing',
		'freecodecamp': 'https://www.inmotionhosting.com/wordpress-hosting/fcc'
	},
	stepTypes: {
		connectSteps: [ 'size', /*'signup',*/ 'payment'],
		newSiteSteps: [ 'size', /*'signup',*/ 'payment'],
		postPurchaseTransfer: ['done'],
		postPurchaseNew: ['done'],
	},
	steps: [
		{
			priority: 20,
			name: 'size',
			route: '/guide/project/size',
			label: 'Cloud Server',
			title: 'Choose Your Cloud Server',
			alt_title: 'Preparing Your Cloud Server',
			description: `Each production website runs in its own cloud server for maximum performance, flexibility and security.`,
		},/*
		{
			priority: 50,
			name: 'signup',
			route: '/guide/project/signup',
			label: 'Sign Up',
			title: 'Sign Up',
			description: "Let's connect you to the platform",
		},*/
		{
			priority: 100,
			name: 'payment',
			route: '/guide/project/payment',
			label: 'Checkout',
			title: 'Checkout',
			description: `You are just moments away from your first deployment!`,
		},
		{
			priority: 200,
			name: 'done',
			route: '/projects',
			label: 'Done',
			title: 'Done',
			description: `Done`,
		},
	],
};
