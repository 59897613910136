<!-- Load State -->
<div *ngIf="state === 'submitted'">
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- Success State -->
<div *ngIf="state === 'success'" class="main-content">
	<table mat-table [dataSource]="organizations">
		<ng-container matColumnDef="display_name">
			<th mat-header-cell *matHeaderCellDef>{{ this.configService.config.brandConfig.id === 'imh' ? 'TEAM NAME' :
				'COMPANY' }}</th>
			<td mat-cell *matCellDef="let element">
				{{ element.display_name }}
			</td>
		</ng-container>

		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef [hidden]="this.configService.config.brandConfig.id === 'imh'">TYPE
			</th>
			<td mat-cell *matCellDef="let element" class="ctw-capitalize"
				[hidden]="this.configService.config.brandConfig.id === 'imh'">{{
				this.configService.config.brandConfig.id
				===
				'imh' ?
				element.type.replace('organization', 'Team') : element.type }}</td>
		</ng-container>

		<ng-container matColumnDef="account_id">
			<th mat-header-cell *matHeaderCellDef [hidden]="this.configService.config.brandConfig.id === 'imh'">ACCOUNT
				ID</th>
			<td mat-cell *matCellDef="let element" [hidden]="this.configService.config.brandConfig.id === 'imh'">{{
				element.account_id }}</td>
		</ng-container>

		<ng-container matColumnDef="role">
			<th mat-header-cell *matHeaderCellDef>ROLE</th>
			<td mat-cell *matCellDef="let element" class="ctw-capitalize">{{ element.role }}</td>
		</ng-container>

		<ng-container matColumnDef="has_billing">
			<th mat-header-cell *matHeaderCellDef class="ctw-text-left">BILLING ENABLED</th>
			<td mat-cell *matCellDef="let element" class="ctw-text-left">
				<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
					<div class="flex"><mat-icon
							class="ctw-text-{{ element.has_billing ? 'green' : 'red' }}-700 ctw-ml-12 ctw-mr-3 ctw-mt-3">{{
							element.has_billing ?
							'task_alt' : 'horizontal_rule' }}</mat-icon>
						<a *ngIf="element.has_billing"
							href="https://{{ this.configService.config.environment === 'staging' ? 'stage-boldgrid' : 'inmotioncentral' }}.recurly.com/accounts/{{element.uuid}}"
							target="_blank" class="ctw-absolute ctw-mt-3">Recurly Account<mat-icon
								class="ctw-text-base ctw-absolute ctw-ml-2">open_in_new</mat-icon></a>
					</div>
				</ng-container>
				<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
					{{ element.has_billing ? 'Yes' : 'No' }}
				</ng-container>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
		<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
	</table>
</div>

<!-- Fail State -->
<div *ngIf="state === 'fail'">
	<p>Sorry, could not find data. Please refresh the page.</p>
</div>
