import { Component, Inject } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'central-dns-record-delete-dialog',
	templateUrl: './dns-record-delete-dialog.component.html',
	styleUrls: ['./dns-record-delete-dialog.component.scss'],
})
export class DnsRecordDeleteDialogComponent {
	public record;
	public domain;

	constructor(
		public dialogRef: MatDialogRef<DnsRecordDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	getValue() {
		return this.record.rdata[Object.keys(this.record.rdata)[0]];
	}
}
