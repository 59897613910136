import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, ApiService } from '@central/ng-shared';

@Component({
	selector: 'receipt-view',
	templateUrl: 'view.component.html',
	styleUrls: ['view.component.scss'],
})
export class InvoiceViewComponent implements OnInit, OnDestroy {
	public sub: any;
	public receiptId: string;
	public receipt: any;
	public status = 'pending';

	private subscriptions = [];

	constructor(
		private apiService: ApiService,
		public accountService: AccountService,
		private route: ActivatedRoute
	) {}

	public ngOnInit() {
		this.subscriptions.push(
			this.accountService.authAccountUpdate.subscribe(() => {
				this.subscriptions.push(
					this.route.params.subscribe((params) => {
						this.receiptId = params['receiptId'];
						this.getInvoice();
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	private getInvoice() {
		this.apiService
			.get(
				`/v1/wpcr/accounts/${this.accountService.id}/invoices/${this.receiptId}`,
				{}
			)
			.subscribe({
				next: (invoice: any[]) => {
					this.receipt = invoice;
					this.status = 'success';
				},
				error: () => {
					this.status = 'failed';
				},
			});
	}
}
