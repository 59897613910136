<div class="delete-confirm {{this.configService.config.brandConfig.id}}">
	<div *ngIf="this.configService.config.brandConfig.id !== 'imh'">
		<h3>Turn off 2-factor Verification?</h3>
		<p>
			Are you sure you want to remove this device as a secondary
			authentication device?
		</p>
		<p class="error" [class.invisible]="'failed' !== this.state">
			Unable to remove device. Please try again later.
		</p>
		<div class="ctw-text-right ctw-mt-2">
			<span class="ctw-mr-5"><button matDialogClose mat-button>Cancel</button></span>
			<progress-button color="warn" (click)="this.deleteDevice()">Yes, Disable</progress-button>
		</div>
	</div>
	<div *ngIf="this.configService.config.brandConfig.id === 'imh'">
		<h2>Are You Sure?</h2>
		<p>
			Are you sure you want to remove this device as a secondary
			authentication device?
		</p>
		<p>
			Confirm this action by entering the device address:
			<strong class="notranslate">{{this.data.device.message}}</strong>
		</p>
		<mat-form-field appearance="outline">
			<mat-label>Device Address</mat-label>
			<input autocomplete="off" #confirmationInput="ngModel" matInput name="confirmation"
				[(ngModel)]="this.confirmation" required />
			<mat-hint class="error" *ngIf="
							this.confirmation && this.confirmation.toLowerCase().trim() !==
						this.data.device.message.toLowerCase() &&
					confirmationInput.dirty &&
					confirmationInput.touched
				">
				The entered value does not match the Device Address.
			</mat-hint>
		</mat-form-field>
		<p class="error" *ngIf="'failed' === this.state">
			Unable to remove device. Please try again later.
		</p>
		<div class="ctw-text-right ctw-mt-10">
			<button matDialogClose mat-button>Cancel</button>
			<progress-button color="warn" (click)="this.doDelete()" [state]="this.state" class="ctw-ml-3">Yes,
				Continue</progress-button>
		</div>
	</div>
</div>
