export class Payload {
	/**
	 * Format the cart object into the payload expected by the API.
	 *
	 * @returns object
	 */
	public format(cart) {
		const payload = {
			plans: this.formatPlans(cart),
			adjustments: this.formatAdjustments(cart),
			options: {},
		};
		return payload;
	}

	private formatAdjustments(cart: any[]): any[] {
		const adjustments = cart.filter((item) => item.transaction_type === 'one-time');

		return adjustments.map((adjustment) => ({
				code: adjustment.code,
				options: adjustment.options || {},
			}));
	}

	private formatPlans(cart: any[]) {
		const cartAddons = cart.filter((item) => item.term && 'addon' === item.term.purchaseType);

		let plans = [];
		for (const addon of cartAddons) {
			const existingParentPlan = this.findExistingParent(plans, addon);

			// Add the parent item to plans.
			if (existingParentPlan) {
				existingParentPlan.addons = existingParentPlan.addons || [];

				existingParentPlan.addons.push({
					code: addon.code,
					options: addon.options || {},
				});
			} else {
				// Add the parent.
				plans.push({
					code: addon.term.planCode,
					addons: [
						{ code: addon.code, options: addon.options || {} },
					],
				});
			}
		}

		plans = [...plans, ...this.getPurchaseablePlans(cart)];

		return plans;
	}

	private findExistingParent(plans: any[], addon) {
		return plans.find((val) => {
			if (val.code === addon.term.planCode) {
				if (
					val.addons &&
					!val.addons.find((valAddon) => valAddon.code === addon.code)
				) {
					return val;
				}
			}
		});
	}

	private getPurchaseablePlans(cart: any[]): any[] {
		const plans = [];
		for (const item of cart) {
			if (item.term && 'plan' === item.term.purchaseType) {
				plans.push({
					code: item.code,
					quantity: item.quantity,
					options: item.options || {},
				});
			}
		}

		return plans;
	}
}
