<h2 mat-dialog-title class="ctw-capitalize">
	<ng-container>Are You Sure?</ng-container>
</h2>
<mat-dialog-content>
	<div>
		<p class="ctw-mb-6">
			Starting over will permanently delete all your data and reset your Cloud Server back to it's original state
			at the time of purchase.
		</p>
		<p>
			Confirm this action by typing the Environment's name: <strong>{{ environment.label }}</strong>
		</p>
		<mat-form-field appearance="outline">
			<mat-label>Environment Name</mat-label>
			<input class="env-name-confirm" matInput required [(ngModel)]="this.envConfirm" />
		</mat-form-field>
		<p *ngIf="state === 'failed'" class="error animate__animated animate__fadeInUp">
			There was an issue submitting your reset request. Please try again later.
		</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-between ctw-mt-10">
	<span class="ctw-text-red-700">
		<mat-icon class="ctw-align-bottom ctw-text-xl">warning</mat-icon><strong>This is IRREVERSABLE</strong>
	</span>
	<span>
		<button mat-button mat-ripple type="button" [mat-dialog-close]="true" color="secondary">
			Cancel
		</button>
		<button mat-raised-button mat-ripple (click)="submitReset()" type="button" color="warn"
			[disabled]="environment?.label !== envConfirm" class="ctw-ml-3">
			Reset
		</button>
	</span>

</mat-dialog-actions>
