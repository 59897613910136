import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';
import { AppService } from '../../../../app.service';

@Component({
	selector: 'central-domain-selector',
	templateUrl: './domain-selector.component.html',
	styleUrls: ['./domain-selector.component.scss'],
})

export class DomainSelectorComponent implements OnInit {
	public componentState = 'loading';
	private firstPartyRequestState = 'loading';
	private thirdPartyRequestState = 'loading';

	private adminAccountIds = [];
	public options: string[] = [];

	public selectedDomainName: string;
	public enteredDomainName: string;
	public manual = false;
	public status = '';

	@Input() allowManual: boolean;
	@Input() context: string;
	@Input() usedDomains: string[] = [];
	@Input() hostname: string;
	@Input() invalid: boolean;
	@Input() validating: boolean;
	@Input() disabled = false;
	@Input() current = '';
	@Input() label = "Select one of your Team's domains..."

	@Output() public domainInputChange = new EventEmitter();
	@Output() domainSelectReady = new EventEmitter();

	constructor(
		public apiService: ApiService,
		private appService: AppService,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
		if (this.context) {
			this.adminAccountIds.push(this.context);
		} else {
			this.adminAccountIds.push(this.profileService.data.options.public_selected_team);
		}

		this.fetchDomains();
	}

	public fetchDomains() {
		// Load first-party domains
		this.appService.getFirstPartyDomains().subscribe(
			(response: any[]) => {
				response.forEach((domain) => {
					if (
						this.adminAccountIds?.includes(domain.account_id) &&
						!this.usedDomains?.includes(domain.label)
					) {
						this.options.push(domain.name);
					}
				});
				this.firstPartyRequestState = 'succeeded';
				this.updateComponentState();
			},
			(error) => {
				this.firstPartyRequestState = 'failed';
				this.updateComponentState();
			}
		);

		// Load third-party domains
		this.appService.getThirdPartyDomains().subscribe(
			(response: any[]) => {
				response.forEach((domain) => {
					if (
						this.adminAccountIds?.includes(
							domain.fields.organization_id
						) &&
						!this.usedDomains?.includes(domain.label)
					) {
						this.options.push(domain.label);
					}
				});
				this.thirdPartyRequestState = 'succeeded';
				this.updateComponentState();
			},
			(error) => {
				this.thirdPartyRequestState = 'failed';
				this.updateComponentState();
			}
		);
	}
	private updateComponentState() {
		if (
			this.firstPartyRequestState === 'succeeded' &&
			this.thirdPartyRequestState === 'succeeded'
		) {
			this.options.sort();
			if (this.hostname && !this.current.includes(this.hostname)) {
				this.options.push(`${this.hostname}`);
			}

			this.componentState = 'success';
			this.domainSelectReady.emit();
			this.status = 'ready';
		} else if (
			this.firstPartyRequestState === 'failed' ||
			this.thirdPartyRequestState === 'failed'
		) {
			this.componentState = 'error';
		}
	}

	public domainSelectChanged(domain) {
		this.domainInputChange.emit(domain);
		this.selectedDomainName = domain;
	}
}
