<div class="ctw-flex ctw-mb-4">
	<h2 mat-dialog-title class="ctw-flex">{{ blueprint.label.replace('Site', 'Blueprint') }}</h2>
</div>

<mat-dialog-content>
	<div *ngIf="this.state !== 'failed'" [innerHtml]="this.getConfigValue('description')"></div>
	<div *ngIf="this.state === 'failed'">
		<p class="ctw-text-red-700 ctw-flex"><mat-icon
				class="ctw-w-1/6 ctw-mr-2 ctw-text-red-700">warning</mat-icon>There was a problem deploying the selected
			blueprint. We've been notified about this and will be working to get this corrected soon.</p>
		You can skip this process and continue on to the environment, or attempt to deploy another blueprint.
	</div>
</mat-dialog-content>
<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-p-0 ctw-pb-5">
	<button mat-button mat-ripple type="button" [mat-dialog-close]="true" [disabled]="this.state === 'submitted'">
		{{this.state === 'failed' ? 'Try Again' : 'Cancel' }}
	</button>
	<central-progress-button *ngIf="this.state !== 'failed'" [state]="this.state" mat-raised-button mat-ripple
		type="button" color="primary" (click)="installBlueprint()" class="ctw-ml-3">
		<mat-icon *ngIf="this.state !== 'submitted'" class="ctw-mr-2">rocket_launch</mat-icon> {{ this.state !==
		'submitted' ? 'Deploy Blueprint' : 'Deploying...' }}
	</central-progress-button>
	<central-progress-button *ngIf="this.state === 'failed'" [state]="this.state" mat-raised-button mat-ripple
		type="button" color="primary" (click)="this.installed.emit('false')" [mat-dialog-close]="true" class="ctw-ml-3">
		Continue
	</central-progress-button>
</mat-dialog-actions>
