import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService, ProfileService } from '../../authentication/services';
import { ConfigurationService } from '../../core';

@Component({
	selector: 'central-beta-tester',
	templateUrl: './beta-tester.component.html',
	styleUrls: ['./beta-tester.component.scss'],
})
export class BetaTesterComponent implements OnInit {
	@Input() accountId = false;
	@Input() isTester = false;
	@Input() isMenuItem = false;
	@Output() updated = new EventEmitter();

	public state = 'pending';

	constructor(
		private authService: AuthService,
		private profileService: ProfileService,
		private configService: ConfigurationService,
		public snackBar: MatSnackBar,

	) {
		this.isTester = this.profileService.data.tester;
	}

	ngOnInit() {
		if (!this.accountId) {
			this.accountId = this.authService.getAccountId();
			this.isTester = this.profileService.data.options['public_beta_tester'];
		}
	}

	toggleState() {
		this.state = 'submitted';

		this.profileService
			.update({
				public_beta_tester: !this.isTester,
			})
			.subscribe({
				next: (response: any) => {
					this.isTester = !!response.options['public_beta_tester'];
					this.snackBar.open(this.isTester ? 'User is enrolled in Beta Testing!' : 'User has been removed from Beta Testing!', '', {
						...this.configService.config.snackBar,
					});
					this.state = 'pending';
				},
				error: (error) => {
					this.snackBar.open('Error', '', {
						...this.configService.config.snackBar
					});
					this.state = 'pending';
				},
			});
	}
}
