<form class="twoFa-form" (ngSubmit)="submit()" novalidate>
	<label class="ctw-font-bold">Where should we send your security code?</label>
	<mat-radio-group aria-labelledby="Two Factor Authenticatin Device List" class="device-options"
		name="deviceSelection" [(ngModel)]="model.deviceSelection" #deviceSelection="ngModel" required
		[class.ctw-mb-8]="this.configService.config.brandConfig.id === 'imh'">
		<mat-radio-button class="device-option-radio" *ngFor="let device of this.deviceOptions" [value]="device.name"
			[name]="device">
			{{ device.message }}
		</mat-radio-button>
		<mat-hint align="start" class="animate__animated error" [class.animate__slideInUp]="
				this.fieldHasError(deviceSelection) || this.sendError
			">
			<span *ngIf="this.fieldHasError(deviceSelection)">A device selection is required.</span>
			<span *ngIf="
					this.sendError &&
					this.sendError?.name === 'rate_limit_exceeded'
				">Number of resubmissions reached. Please try again later.</span>
			<span *ngIf="
					this.sendError &&
					this.sendError?.name !== 'rate_limit_exceeded'
				">Unknown Error. Please try again later.</span>
		</mat-hint>
	</mat-radio-group>

	<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
		<button type="submit" name="sendCode" [disabled]="this.state === 'submitted'" class="send-code-button"
			mat-raised-button color="accent">
			Send Code
		</button>
		<button type="button" [disabled]="this.state === 'submitted'" (click)="this.handleCancel()"
			class="cancel-button" mat-raised-button>
			<mat-icon>arrow_back</mat-icon> Cancel
		</button>
	</ng-container>
	<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
		<mat-dialog-actions class="ctw-flex ctw-justify-end ctw-mt-10 ctw-p-0">
			<button type="button" [disabled]="this.state === 'submitted'" (click)="this.handleCancel()" mat-button>
				Cancel
			</button>
			<button type="submit" name="sendCode" [disabled]="this.state === 'submitted'" mat-raised-button
				color="primary" class="ctw-ml-3">
				Send Code
			</button>
		</mat-dialog-actions>
	</ng-container>
</form>