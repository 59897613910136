<div class="loader" *ngIf="!this.loaded">
	<mat-spinner diameter="{{
		this.brandingService.getId() === 'imh'
			? 80
			: 40
	}}" color="{{
		this.brandingService.getId() === 'imh'
			? 'primary'
			: 'accent'
	}}"></mat-spinner>
</div>
<div [hidden]="!this.loaded" class="journey-{{ this.journey }} bg-firebase-auth" [attr.provider]="hint"
	id="firebase-ui"></div>
