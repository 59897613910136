import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginFormComponent } from '../login-form/login-form.component';
import { BrandingService } from '../../core/branding/branding.service';
import { ProfileService } from '../services';

@Component({
	// eslint-disable-next-line
	selector: 'bg-registration',
	templateUrl: 'registration.component.html',
	styleUrls: ['registration.component.scss'],
})
export class RegistrationComponent {
	@Input() public hideIntro = false;
	@Input() public logoutEnabled = true;
	@Input() public isGuide = false;
	@Input() public shouldRedirectOnReset = false;
	@Input() public wasInvited = false;
	@Input() public hideSignedInAs = false;
	@ViewChild('loginForm', { static: true })
	public loginForm: LoginFormComponent;

	public enableDescription = true;

	constructor(
		public authService: AuthService,
		public brandingService: BrandingService,
		public router: Router,
		public profileService: ProfileService,
	) {}

	/**
	 * Is this a new user?
	 *
	 * @since 1.3
	 *
	 * @return  Is this a new user?
	 */
	public isNewUser(): boolean {
		return this.loginForm.isNewUser();
	}

	/**
	 * Is registration process complete?
	 *
	 * @since 1.3
	 *
	 * @return  Is registration process complete?
	 */
	public isComplete(): boolean {
		return this.authService.isLoggedIn();
	}

	/**
	 * Get the users email.
	 *
	 * @since 1.3
	 *
	 * @return  Users email.
	 */
	public getEmail(): string {
		return this.authService.getEmail();
	}

	/**
	 * Reset this form, logging the user out.
	 *
	 * @since 1.3
	 */
	public reset() {
		this.authService.silentLogout();
		this.loginForm.reset();
		if (this.shouldRedirectOnReset) {
			this.router.navigate(['/', 'logout']);
		}
	}
}
