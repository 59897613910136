import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserGuard } from '@central/ng-shared';

import {
	CheckoutComponent,
	ConfirmationComponent,
	ForgotPasswordComponent,
	GuestComponent,
	LoginComponent,
	LogoutComponent,
	ResetSentComponent,
	UpdatePasswordComponent,
} from './index';

import { AppAuthComponent } from './app-auth/app-auth.component';
import { InviteComponent } from './invite/invite.component';
import { DomainRegisterComponent } from '../domain/register/domain-register.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'prefix',
		component: GuestComponent,
		children: [
            {
				path: 'signup',
				redirectTo: 'login',
			},
			{
				path: 'login',
				component: LoginComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'sso',
				component: LoginComponent,
			},
			{
				path: 'logout',
				component: LogoutComponent,
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'reset-sent',
				component: ResetSentComponent,
				canActivate: [UserGuard],
			},
			{
				path: 'update-password/:email/:code',
				component: UpdatePasswordComponent,
			},
			{
				path: 'confirm-account/:email/:code',
				component: ConfirmationComponent,
			},
			{
				path: 'oauth2/:application_id',
				component: AppAuthComponent,
			},
			{
				path: 'auth/invite/:membershipId/:membershipCode',
				component: InviteComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class GuestRoutingModule {}
