import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper/stepper.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
	declarations: [StepperComponent],
	exports: [StepperComponent],
	imports: [RouterModule, MatIconModule, MatButtonModule, CommonModule],
})
export class StepperModule {}
