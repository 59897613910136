import { Component, EventEmitter, Input, Output, OnInit, ViewChild, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, DoCheck } from '@angular/core';
import {  } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { Location } from '@angular/common';
import { LocalStorageService } from "../local-storage/local-storage.service";
import { AuthService, ProfileService } from '../../authentication/services';
import { MatMenuTrigger } from '@angular/material/menu';
import {
	MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { SharedDialogComponent } from '../../agent/user-page/user-inspection/shared-dialog/shared-dialog.component';
import { ContextualHelpDialogComponent } from '../../contextual-help/help-dialog/contextual-help-dialog.component';

@Component({
	selector: 'central-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, DoCheck {

	@Input() status = 'loading';
	@Input() title = '';
	@Input() titleIcon;
	@Input() subTitle = '';
	@Input() tag;
	@Input() tagIcon;
	@Input() actions = '';
	@Input() actionsEnabled: boolean;
	@Input() menu = '';
	@Input() cart;
	@Input() checkout = false;
	@Input() service;
	@Input() data;
	@Input() notice;
	@Input() noticeIcon;
	@Input() noticeBgClass = '';
	@Input() noticeTextClass = '';
	@Input() noticeExpanded; // {}
	@Input() breakpoint = '4xl';

	@Output() action = new EventEmitter()

	@ViewChild('shoppingCartTrigger') shoppingCartTrigger: MatMenuTrigger;

	public locale = '';
	public currency = '';
	public cartTotal = 0;
	public chatEnabled = false;
	public phoneNumber = '';

	public allowAgentControls = false;
	public allowTeamSwitching = false;
	public isSwitchingTeams = false;

	public $notice;

	private currencyFormatter;
	private cartChanges: KeyValueDiffer<string, any>;

	constructor(
		public configService: ConfigurationService,
		public authService: AuthService,
		public location: Location,
		public localStorage: LocalStorageService,
		private differs: KeyValueDiffers,
		private dialog: MatDialog,
		private profileService: ProfileService,
	) { }

	ngOnInit() {
		this.cartChanges = this.differs.find(this.cart || []).create();
		if (this.location.path().match(/^\/(projects|domains(\/manage|)|email)$/)) {
			this.allowTeamSwitching = true;
		}
		if (this.location.path().match(/^\/(agent\/user\/[0-9]{6,7})$/)) {
			this.allowAgentControls = true;
		}
		if (this.configService.config?.zendesk?.chatIsEnabled
			&& window['zE'] && window['zE']?.widget?.match(/(classic|messenger)/)) {
			this.chatEnabled = true;
		}

		this.setModels();
		this.phoneNumber = this.configService.config.powerPanel.phoneNumber;

		if (!this.location.path().match(/^\/amp/)) {
			if (this.localStorage.getItem('notice')) {
				this.$notice = this.localStorage.getItem('notice')[0];
			}
		}
	}

	ngDoCheck(): void {
		const changes = this.cartChanges?.diff(this.cart);
		if (changes) {
		  this.cartChanged(changes);
		}
	}

	public setModels() {
		this.locale = this.profileService?.data?.locale || 'en-US';
		this.currency = this.profileService?.data?.currency || 'USD';
	}

	public teamChanged(event) {
		switch (event) {
			case 'pending':
				this.isSwitchingTeams = true;
				break;
			case 'failed':
				this.isSwitchingTeams = false
				break;
			default:
				this.isSwitchingTeams = false;
				this.action.emit('reload')
				break;
		}
	}

	public cartChanged(changes: KeyValueChanges<string, any>) {
		changes.forEachAddedItem((record) => {
			this.shoppingCartTrigger?.openMenu();
		});

		this.cartTotal = 0;
		if (this.cart?.length > 0) {
			this.cart.forEach((item) => {
				this.cartTotal += parseFloat(item.price);
			})
		}
	}


	public formatLink(search, replacement) {
		const re = new RegExp("\\b"+search+"\\b")
		return this.location.path().replace(re, replacement);
	}

	public getTag() {
		switch (this.tag) {
			case 'team':
				this.tagIcon = 'groups';
				return this.localStorage.getItem('team');
				break;
		}
	}

	public moneyFormat(price) {
		this.currencyFormatter = new Intl.NumberFormat(this.locale, {
			style: 'currency',
			currency: this.currency,
		});

		return this.currencyFormatter.format(price);
	}

	public openNotice() {
		this.dialog.open(ContextualHelpDialogComponent, {
			disableClose: true,
			width: '500px',
			panelClass: this.$notice ? 'offer' : '',
			data: {
				panelClass: this.$notice ? 'offer' : '',
				...this.$notice.acf ?? this.noticeExpanded,
			},
		});
	}

	public openAmpCall (phone) {
		const phoneHref = "tel:" + phone
		window.location.href=phoneHref;
	}
}
