import { Component} from '@angular/core';
import { ProjectService } from '../../project.service';

@Component({
	selector: 'central-pro-services',
	templateUrl: './pro-services.component.html',
	styleUrls: ['./pro-services.component.scss'],
})
export class ProServicesComponent {
	state = 'success';

	private subscriptions = [];

	constructor(
		public projectService: ProjectService,
	) {}
}
